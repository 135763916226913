import React, { useEffect, useState } from "react";
import {takeLatest} from 'redux-saga/effects';

import { Col, Form, Badge } from 'react-bootstrap';
import { extend, has, remove } from "lodash";

import "../scss/legacy-user-table.scss";
import ExtendedAttributes from "./ExtendedAttributes";
import {requestExtendedAttributes, clearExtendedAttributes} from "../actions"
import { useDispatch, useSelector } from "react-redux";

const LegacyUserRow = props => {

  const {
    setIsToReadOnly,
    isToReadOnly,
    handleAuxList,
    listaAux,
    setListaAux,
    finList,
    isToSave,
    legacyApp,
    legacyUsers,
    legacyApplications,
    openModal,
    username,
    isEditing,
    extendedApplicationAttributes
  } = props;


  const editExtendedList = extendedApplicationAttributes;

  const legacyAppVar = legacyApp;

  const [hasAccess, setAccess] = useState(isEditing);

  const [isEditingSameSwitchs, setEditingSameSwitchs] = useState(isEditing);

  const dispatch = useDispatch();

  const {extendedMiddlewareAttributes} = useSelector((state) => state.userManagementReducer);

  const [arrayAttr, setArrAttr] = useState([]);


  const changeVisibilityExternalParams = () => {

    const switchOn = !hasAccess;

    if(switchOn) {
        if(legacyAppVar?.label === 'portalrh') {
            if (extendedMiddlewareAttributes === undefined) {
                dispatch(requestExtendedAttributes(legacyAppVar?.id));
            }
        
        }
    }
    else {
      if(legacyAppVar?.label === 'portalrh') {
          setIsToReadOnly(false);
      }
    }
  }

  const toggleLegacy = () => {

    
    changeVisibilityExternalParams();
    const toggleAccess = !hasAccess;
    setAccess(toggleAccess);
    if (!toggleAccess) {
      setEditingSameSwitchs(false);
      remove(legacyUsers, { legacyApplication: legacyApp })
      remove(legacyApplications, legacyApp);
    }

  }

  function getSelectedLegacyUser() {
    return legacyUsers && legacyUsers.find(item => item.legacyApplication.id === legacyApp.id)
  }

  function getSelectedLegacyApplication() {
    return legacyApplications && legacyApplications.find(item => item.id === legacyApp.id)
  }

  function addObjectToArray() {

    const exists = listaAux?.findIndex(obj => obj.application === legacyApp?.label) !== -1;
    if(exists) {
      const newListAux = listaAux;
      let objIndex = listaAux?.findIndex(obj => obj.application === legacyApp?.label);

      const obj = newListAux[objIndex];

      obj.attributes = arrayAttr

      handleAuxList(obj);
    }
    else {
      const obj = {
        application: legacyApp?.label,
        attributes: arrayAttr
      }

      handleAuxList(obj);
    }

  }

  function addLabelToArr(key, value) {
    let auxArr = arrayAttr;
    let obj = {
      key: key,
      value: value
    }

    let existe = auxArr?.findIndex(obj => obj.key === key) !== -1;
    if(auxArr !== undefined) {
        if(!existe) {
          auxArr.push(obj);
        }
        else {
          var objIndex = auxArr.findIndex(obj => obj.key === key);
          auxArr[objIndex].key = key;
          auxArr[objIndex].value = value;
        }
    
        setArrAttr(auxArr);

        addObjectToArray();
        
    } 
  }


  const createUsersColumnData = () => {
    const selectedLegacyUser = getSelectedLegacyUser();
    const selectedLegacyApplication = getSelectedLegacyApplication();

    if (selectedLegacyUser) {

      return (
        <Badge
          pill
          variant="info"
          className="pointer lg-badge"
          onClick={() => openModal(legacyApp)}
        >
          {selectedLegacyUser.username}
        </Badge>
      )
    } if (selectedLegacyApplication) {
      return (
        <Badge
          pill
          variant="success"
          className="pointer lg-badge"
          onClick={() => openModal(legacyApp)}
        >
          {username}
        </Badge>
      )
    } else {
      return (
        <i
          className="icon-add lg-icon add-table-icon pointer"
          onClick={() => openModal(legacyApp)}
        />
      )
    }
  }

  return (
    <>
      <tr>
        <th>{legacyApp.description}</th>
        <th>
          <Form.Check
            type="switch"
            id={legacyApp.id}
            label=""
            checked={hasAccess}
            onChange={() => toggleLegacy()}
          />
        </th>
        <th>
          {hasAccess &&
            <Col xs={4}>
              {createUsersColumnData()}
            </Col>
          }
        </th>
      </tr>
      <tr>
    {
      isEditing && isEditingSameSwitchs ? 
            editExtendedList && legacyAppVar.label === editExtendedList?.application && hasAccess ?
              <td style={{display: 'flex', paddingBottom: 10}}>
                {
                  editExtendedList && editExtendedList?.attributes?.map((element, index) => (
                      <ExtendedAttributes 
                        key={index}
                        element={element}
                        readOnly={true}
                      />
                  ))
                }
              </td> 
            : null 
          :
          
          !isToReadOnly ?
              extendedMiddlewareAttributes && legacyAppVar.label === extendedMiddlewareAttributes?.application && hasAccess ?
                  <td style={{display: 'flex', paddingBottom: 10}}>
                    {

                      extendedMiddlewareAttributes && extendedMiddlewareAttributes?.attributes?.map((element, index) => (
                          <ExtendedAttributes
                            key={index}
                            element={element}
                            readOnly={isToReadOnly}
                            addLabelToArr={addLabelToArr}
                          />
                      ))
                    }
                  </td> 
                      : null 
                :
                extendedApplicationAttributes && legacyAppVar.label === extendedApplicationAttributes?.application && hasAccess ?
                  <td style={{display: 'flex', paddingBottom: 10}}>
                    {

                        extendedApplicationAttributes && extendedApplicationAttributes?.attributes?.map((element, index) => (
                          <ExtendedAttributes
                            key={index}
                            element={element}
                            readOnly={isToReadOnly}
                            addLabelToArr={addLabelToArr}
                          />
                      ))
                    }
                  </td> 
                      : null 
    }
            </tr>
      </>    

  )
}

export default LegacyUserRow;