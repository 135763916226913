import React, { useState, useEffect } from "react";
import "../assets/scss/_projectDescriptionSection.scss";
import "../assets/scss/_item.scss";
import { useDispatch, useSelector } from "react-redux";
import TextAreaInput from "../components/TextAreaInput";
import uuid from "uuid/v1";
import Textbox from "./Textbox";
import iconPlus from "~/pages/Liftworld/assets/img/icon-plus-filled.png";
import Input from "./Input";
import Activity from "./Activity";
import { setAllWorkplans } from "~/store/ducks/budgetManagement/actionTypes";
import AddButton from "~/components/Buttons/AddButton";

const ItemComponent = ({ item, workplanIndex, index }) => {

    const dispatch = useDispatch();

  const { workplans } = useSelector((state) => state.budgetManagementReducer);

  const [showContent, setShowContent] = useState(true);

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".");
    const finalString = newArray[0].replace(",", ".") + "," + newArray[1];
    return finalString;
  }

  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 100,
      text: text,
    };
  }

  const handleShowContent = () => {
    setShowContent(!showContent)
  };

  function deleteItem(index) {
    workplans[workplanIndex].items.splice(index, 1);
    dispatch(setAllWorkplans(workplans));
  }
  

  const newActivity = {
    nomeAtividade: "",
    valorCustoAct: 0,
    valorVendaAct: 0,
    duracaoAct: 0,
    dataInicioAct: "",
    dataFimAct: "",
    opcoes: [
      {
        nomeOpcao: "",
        valorCustoOpt: 0,
        selected: false,
        imprimir : false,
        valorVendaOpt: 0,
        observaceosOpt: "",
        imagensOpt: [
          {
            filename: "",
            docdata: "",
          },
        ],
        horas: [],
        fornecedores: [],
        despesas: [],
      },
    ],
  };

  function addActivity(index) {
    workplans[workplanIndex].items[index].atividades.push(newActivity);
    dispatch(setAllWorkplans(workplans));
  }

  return (
    <div className="liftworld-item-main-wrapper">
      <>
        <div className="liftworld-content-container">
          <div className="liftworld-item-option-row-wrapper">
            <div
              className="easypay-text-icon"
              onClick={() => handleShowContent(index)}
            >
              <img src={iconPlus} alt="Icon Check" />
            </div>
            <div style={{ width: "70%" }}>
              <Input
                field={buildInputField(
                  "Atividade Principal " + (index + 1),
                  item.nomeObjetivo
                )}
                key={uuid()}
                color={"item"}
                size={"large-size"}
                name={"nomeObjetivo"}
                itemIndex={index}
                workplanIndex={workplanIndex}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Textbox
                title={"Valor de Custo"}
                value={
                  isNaN(item.valorCustoObj)
                    ? "0 €"
                    : numberWithCommas(
                        parseFloat(item.valorCustoObj).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
              <Textbox
                title={"Valor de Venda"}
                value={
                  isNaN(item.valorVendaObj)
                    ? "0 €"
                    : numberWithCommas(
                        parseFloat(item.valorVendaObj).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
              <div className="liftworld-delete-button-wrapper">
                <div
                  className="easypay-text-icon"
                  onClick={() => deleteItem(index)}
                >
                  <i className="icon-trash table-action-icon" />
                </div>
              </div>
            </div>
          </div>
          {showContent && (
            <TextAreaInput
              field={buildInputField(
                "Descritivo Específico (Não se reflete no Orçamento)",
                item.objetivoEspecifico
              )}
              key={uuid()}
              itemIndex={index}
              workplanIndex={workplanIndex}
              name={"objetivoEspecifico"}
              size = {"small"}
              color = {"color"}
            />
          )}
          {showContent && (
            <div className="liftworld-item-content-wrapper">
              <div className="liftworld-objective-row-wrapper"></div>
              <div style={{ paddingTop: 30 }} />
              <div>
                {item.atividades &&
                  Object.keys(item.atividades).length !== 0 &&
                  item.atividades.map((itemAtividade, indexAtividade) => {
                    return (
                      <Activity
                        info={itemAtividade}
                        index={indexAtividade}
                        itemIndex={index}
                        workplanIndex={workplanIndex}
                      />
                    );
                  })}
              </div>
            </div>
          )}
          {showContent && (
            <div className="liftworld-activity-bottom-button-wrapper">
              <AddButton
                text="Adicionar Nova Atividade Secundária"
                onClick={() => addActivity(index)}
              />
            </div>
          )}
        </div>
      </>
    </div>
  );
};
export default ItemComponent;
