import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import Sidebar from "../../RhCollaborator/Sidebar";
import Spinner from "~/components/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { getCollaborators } from "~/store/ducks/portalrh/actionTypes";
import { toLocaleDateString } from "~/utils";
import Dropdown from "~/components/Dropdown";
import { Row, Col, Form } from "react-bootstrap";
import { Button, Stack } from "@mui/material";
const devLogConsole = require("~/utils/devLog");
const TabelaScheduler = ({
  path,
  portalRhMenus,
  designacao,
  t,
  loadEventsStart,
  setLoadEventsStart,
  isEditable,
  setSelectedUser,
  selectedUser,
  grupos,
  setgrupos,
  activeButton,
  setActiveButton,
  saldos_hours,
  setSaldos_hours,
  setInsencao_hours,
  insencao_hours,
  picagens_Exists,
  setPicagens_Exists,
}) => {
  const { collaborators, scheduler, isLoadingScheduler } = useSelector(
    (state) => state.portalrhReducer
  );

  const dispatch = useDispatch();
  const [table_hours, setTable_hours] = useState([]);
  const [table_saldoHours, setTable_saldoHours] = useState([]);

  const [menuSideBar, setMenuSideBar] = useState(null);
  const [field, setField] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [hiddenDefaultColumns, setHiddenDeafultColumns] = useState(undefined);

  useEffect(() => {
    if (Object.keys(scheduler).length > 0) {
      setTable_hours(scheduler.registoPresencaTabela);
      setTable_saldoHours(scheduler.saldosTabela);
      setSaldos_hours(scheduler.saldosBolsaHoras);
      setInsencao_hours(scheduler.isenção);
      setPicagens_Exists(scheduler.semPicagens);
    }
  }, [scheduler]);

  const handleStatusSort = (field) => {
    setOrder((order) => (order === "desc" ? "asc" : "desc"));
    setField(field);
  };

  const isHidden = (column) => {
    if (hiddenDefaultColumns) {
      return hiddenDefaultColumns.some((c) => c === column) ? true : false;
    } else return false;
  };

  const ChecksFormatter = (cell, row, rowIndex, formatExtraData) => {
    let value = undefined;
    if (row[formatExtraData.value] == null) {
      value = "00:00";
    } else {
      value = row[formatExtraData.value];
    }
    return value;
  };

  const columns = [
    {
      dataField: "timeSheetWeekId",
      formatter: (_, row, rowIndex) => `${row.timeSheetWeekId}`,
      text: "ID Semana",
      hidden: true,
    },
    {
      dataField: "formattedDay",
      text: t("portalrh.scheduler.day"),
      sort: true,
      onSort: () => handleStatusSort("created"),
      hidden: isHidden("created"),
      formatter: (_, row, {}) => {
        if (row.formattedDay !== null && row.formattedDay !== undefined) {
          const formattedDate = toLocaleDateString(row.formattedDay);
          const [day, , year] = formattedDate.split("/");
          return `${day} ${t("portalrh.workDays.de")} ${year}`;
        }
      },
      style: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          width: "90px",
        };
      },
    },
    {
      dataField: "hourCheckIn1",
      text: t("portalrh.scheduler.in"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("hourCheckIn1"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "hourCheckIn1",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "hourCheckOut1",
      text: t("portalrh.scheduler.out"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("hourCheckOut1"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "hourCheckOut1",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "hourCheckIn2",
      text: t("portalrh.scheduler.in"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("hourCheckIn2"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "hourCheckIn2",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "hourCheckOut2",
      text: t("portalrh.scheduler.out"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("hourCheckOut2"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "hourCheckOut2",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "hourCheckIn3",
      text: t("portalrh.scheduler.in"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("hourCheckIn3"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "hourCheckIn3",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "hourCheckOut3",
      text: t("portalrh.scheduler.out"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("hourCheckOut3"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "hourCheckOut3",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "hourCheckIn4",
      text: t("portalrh.scheduler.in"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("hourCheckIn4"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "hourCheckIn4",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "hourCheckOut4",
      text: t("portalrh.scheduler.out"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("hourCheckOut4"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "hourCheckOut4",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "hourCheckIn5",
      text: t("portalrh.scheduler.in"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("hourCheckIn5"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "hourCheckIn5",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "hourCheckOut5",
      text: t("portalrh.scheduler.out"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("hourCheckOut5"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "hourCheckOut5",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "potentialHours",
      text: t("portalrh.scheduler.potencialHours"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("potentialHours"),
      formatter: (_, row, rowIndex) => `${row.potentialHours}`,
      formatExtraData: {
        value: "potentialHours",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor = `#286DA4${Math.floor(opacity * 255).toString(
          16
        )}`;
        const color = "#286DA4";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "hours",
      text: t("portalrh.scheduler.hours"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("hours"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "hours",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          color,
          fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "diferencaHoras",
      text: t("portalrh.scheduler.diferenceHours"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("diferencaHoras"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "diferencaHoras",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        devLogConsole(cell, parseFloat(cell));
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          color,
          fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "ausencias",
      text: t("portalrh.scheduler.abscenses"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("ausencias"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "ausencias",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          color,
          fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "bolsaHoras",
      text: t("portalrh.scheduler.bolsaHours"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("bolsaHoras"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "bolsaHoras",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          color,
          fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "horasExtra",
      text: t("portalrh.scheduler.Bolsa Horas"),
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("horasExtra"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "horasExtra",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          color,
          fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
  ];

  const columns_Total = [
    {
      dataField: "timeSheetWeekId",
      formatter: (_, row, rowIndex) => `${row.timeSheetWeekId}`,
      text: "ID Semana",
      hidden: true,
    },
    {
      dataField: "total",
      text: "",
      hidden: isHidden("total"),
      formatter: (_, row, {}) => {
        return t("portalrh.scheduler.totalhours");
      },
      style: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          fontWeight: "700",
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "",
      text: "",
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden(""),
      formatter: (_, row, {}) => {
        return "-----";
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "",
      text: "",
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden(""),
      formatter: (_, row, {}) => {
        return "-----";
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "",
      text: "",
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden(""),
      formatter: (_, row, {}) => {
        return "-----";
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "",
      text: "",
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden(""),
      formatter: (_, row, {}) => {
        return "-----";
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "",
      text: "",
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden(""),
      formatter: (_, row, {}) => {
        return "-----";
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "",
      text: "",
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden(""),
      formatter: (_, row, {}) => {
        return "-----";
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell == null ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "500";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "saldoHorasPotencias",
      text: "",
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("saldoHorasPotencias"),
      formatter: (_, row, rowIndex) => `${row.saldoHorasPotencias}`,
      formatExtraData: {
        value: "saldoHorasPotencias",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor = `#286DA4${Math.floor(opacity * 255).toString(
          16
        )}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          color,
          fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "saldoHoras",
      text: "",
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("saldoHoras"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "saldoHoras",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          color,
          fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "saldoDiferencial",
      text: "",
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden("saldoDiferencial"),
      formatter: ChecksFormatter,
      formatExtraData: {
        value: "saldoDiferencial",
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          color,
          fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "",
      text: "",
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden(""),
      formatter: (_, row, {}) => {
        return "-----";
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "",
      text: "",
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden(""),
      formatter: (_, row, {}) => {
        return "-----";
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
    {
      dataField: "",
      text: "",
      sort: false,
      //onSort: () => handleStatusSort("created"),
      hidden: isHidden(""),
      formatter: (_, row, {}) => {
        return "-----";
      },
      style: (cell, row, rowIndex, columnIndex) => {
        const opacity = 0.3; // Defina a opacidade desejada entre 0 e 1
        const backgroundColor =
          cell == null
            ? `#AD3E3E${Math.floor(opacity * 255).toString(16)}`
            : `#5AB45E${Math.floor(opacity * 255).toString(16)}`;
        const color = cell && cell.includes("-") ? "#AD3E3E" : "#5AB45E";
        const fontWeight = "700";
        return {
          textAlignLast: "center",
          breakWord: "break-all",
          //color,
          //fontWeight,
        };
      },
      headerStyle: () => {
        return {
          textAlignLast: "center",
          breakWord: "break-all",
        };
      },
    },
  ];

  const { SearchBar } = Search;
  return (
    <>
      <Sidebar menuSideBar={menuSideBar} path={path} />
      {isLoadingScheduler ? (
        <Spinner spinning={isLoadingScheduler} wrapper />
      ) : (
        <>
          <div className="custom-table task-table">
            <ToolkitProvider
              keyField="timeSheetWeekId"
              data={
                loadEventsStart
                  ? table_hours != undefined
                    ? table_hours
                    : []
                  : []
              }
              columns={columns}
              search
            >
              {(props) => (
                <div style={{ paddingBottom: "15px", marginTop: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <SearchBar
                      {...props.searchProps}
                      style={{ paddingLeft: "20px" }}
                      placeholder={t("general.search")}
                    />
                  </div>
                  <div>
                    <Row style={{ paddingRight: "0px" }}>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        {loadEventsStart &&
                          saldos_hours != undefined &&
                          saldos_hours.length > 0 && (
                            <p>
                              {t("portalrh.scheduler.horaInicial")}{" "}
                              {saldos_hours[0].saldoInicial}
                            </p>
                          )}
                        {loadEventsStart &&
                          picagens_Exists != undefined &&
                          picagens_Exists.length > 0 &&
                          picagens_Exists[0] === "1" && (
                            <p>{t("portalrh.scheduler.picagensExists")}</p>
                          )}
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                        {/*{loadEventsStart &&
                          insencao_hours != undefined &&
                          insencao_hours.length > 0 && (
                            <p style={{ float: "right" }}>
                              {insencao_hours[0] === "0"
                                ? t("portalrh.scheduler.sisencao")
                                : t("portalrh.scheduler.cisencao")}
                            </p>
                          )}*/}
                      </Col>
                    </Row>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4
                      noDataIndication={t("portalrh.scheduler.noHoursfound")}
                      sort={{
                        dataField: field,
                        order: order,
                      }}
                      striped={true}
                      hover={true}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
          <div
            className="custom-table task-table"
            style={{ marginTop: "-40px" }}
          >
            <ToolkitProvider
              keyField="0"
              data={
                loadEventsStart
                  ? table_saldoHours != undefined
                    ? table_saldoHours
                    : []
                  : []
              }
              columns={columns_Total}
              search
            >
              {(props) => (
                <div style={{ paddingBottom: "15px", marginTop: "20px" }}>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    sort={{
                      dataField: field,
                      order: order,
                    }}
                    striped={true}
                    hover={true}
                  />
                  {loadEventsStart &&
                    saldos_hours != undefined &&
                    saldos_hours.length > 0 && (
                      <p style={{ float: "right" }}>
                        {t("portalrh.scheduler.horaFinal")}{" "}
                        {saldos_hours[0].saldoFinal}
                      </p>
                    )}
                </div>
              )}
            </ToolkitProvider>
          </div>
        </>
      )}
    </>
  );
};

export default withNamespaces()(TabelaScheduler);
