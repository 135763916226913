import React, { useRef, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Spinner } from "react-bootstrap";
import Chart from "chart.js";
import { pieChart } from "./chartDetail.js";
import CustomDropdown from "../CustomDropdown";
import ReportFilters from "../../ReportFilters";
import { withNamespaces } from "react-i18next";
import { findReportSla } from "~/store/ducks/iflowReports/actionTypes";
import { cloneDeep } from "lodash";
import "~/assets/css/icons.css";
import { updateUserWidget } from '~/pages/User/actions';

const SlaReportWidget = ({ apagarWidget, widget,handleMouseDown,isResizing,textMove, textClick, ...props }) => {
  const { t } = props;
  const myRef = useRef(null);
  const dispatch = useDispatch();
  const widgetId = widget.id;

  const getReportSla = (state) => {
    const reports = state.iflowReportsReducer.reportSla;
    let report = [];
    const savedReport = reports.find((r) => r.id === widgetId);
    if (savedReport) report = savedReport.report;
    return report;
  };
  const reportSla = useSelector(getReportSla);

  const [pieChart_, setPieChart] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (widget.userConfiguration == null) {
       const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "6"
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  useEffect(() => {
    if (reportSla && reportSla.length > 0) {
      let chart = cloneDeep(pieChart); //clone object, each widget instance needs its own chart object
      let accomplished = 0;
      let failed = 0;
      reportSla.forEach((item) => {
        const dateTTL = new Date(item.ttl);
        let dateStop =
          item.stop_reporting === null
            ? new Date()
            : new Date(item.stop_reporting);
        if (dateTTL) {
          const diff = dateTTL - dateStop;
          if (diff < 0) {
            failed++;
          } else {
            accomplished++;
          }
        }
      });

      const graphColors = ["#044513", "#f03d02"];
      let backgroundColors = [];
      let hoverBackgroundColor = [];
      for (let i = 0; i < 2; i++) {
        backgroundColors.push(graphColors[i]);
        hoverBackgroundColor.push("#99ff99");
      }

      chart.data.datasets[0].backgroundColor = backgroundColors;
      chart.data.datasets[0].hoverBackgroundColor = hoverBackgroundColor;

      let labels = [
        t("SlaReportWidget.accomplished"),
        t("SlaReportWidget.failed"),
      ];
      chart.data.labels = labels;

      chart.data.datasets[0].data = [accomplished, failed];
      setPieChart(chart);
      setIsUpdating(false);
    }
  }, [reportSla]);

  useEffect(() => {
    if (pieChart_ && !isUpdating) {
      const myChartRef = myRef.current.getContext("2d");
      new Chart(myChartRef, pieChart_);
    }
  }, [pieChart_, isUpdating]);

  const executeGraph = (startAt, endAt, flowId) => {
    const data = {
      payload: {
        flowId: flowId === "" ? null : flowId,
        startDate: startAt,
        endDate: endAt,
      },
      widgetId: widget.id,
    };
    dispatch(findReportSla(data));
    setIsUpdating(true);
  };

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>
            <i className="icon-bell mr-2" />
            {t("SlaReportWidget.title")}
          </h6>
          <h6>
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown apagarWidget={apagarWidget} handleMouseDown={handleMouseDown} />
        </Card.Header>
        {!isResizing && (<Card.Body style={{ padding: "0px 10px" }}>
          <Row style={{ flexWrap: "nowrap", height: "90%" }}>
            <Col
              xs={4}
              md={4}
              lg={4}
              style={{ margin: "0 10px 10px 10px", padding: "10px" }}
            >
              <ReportFilters executeGraph={executeGraph} widget={widget} />
            </Col>
            <Col
              style={{
                padding: "10px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isUpdating && reportSla.length > 0 && (
                <Spinner animation="grow" variant="secondary" />
              )}
              {reportSla && reportSla.length > 0 && !isUpdating && (
                <div style={{ height: "100%", width: "auto" }}>
                  <canvas id="myPieChart" ref={myRef}></canvas>
                </div>
              )}
            </Col>
          </Row>
        </Card.Body>)}
      </Card>
    </div>
  );
};
export default withNamespaces()(SlaReportWidget);
