import { takeEvery } from 'redux-saga/effects';

import {
  FIND_USAGE_REPORT
} from "../ducks/reports/actionTypes";

import {
  findUsageReport
} from "../ducks/reports/sagas";

export const reportSagas = [
  takeEvery(FIND_USAGE_REPORT, findUsageReport)
]
