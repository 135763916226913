import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Button, Spinner, Row, Container } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import {
  toggleCheck,
  getCheck,
  setblockCheckIn,
  clearCheck,
} from "~/store/ducks/portalrh/actionTypes";
import CustomDropdown from "../CustomDropdown";
import "./transparentCard.css";
import { updateUserWidget } from "~/pages/User/actions";
import Toast from "react-bootstrap/Toast";

const CheckInAndOutWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  ...props
}) => {
  const { t } = props;

  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);

  const { isCheckedIn, isLoadingCheck, reachedMaximumChecks, isblockCheckIn } =
    useSelector((state) => state.portalrhReducer);

  useEffect(() => {
    if (isblockCheckIn) {
      setShowToast(true);
      dispatch(clearCheck());
    } else setShowToast(false);
  }, [isblockCheckIn]);

  useEffect(() => {
    setTimeout(() => {
      setShowToast(false);
      dispatch(setblockCheckIn(false));
    }, 50000);
  }, [showToast]);

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "4",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  useEffect(() => {
    dispatch(getCheck());
  }, []);

  const toastResult = () => {
    return (
      <div
        aria-live="polite"
        aria-atomic="true"
        className="position-fixed"
        style={{
          zIndex: "100",
          padding: "0px 15px",
          backgroundColor: "transparent!important",
        }}
      >
        <Toast>
          <Toast.Body className="bg-danger" style={{ color: "white" }}>
            Não pode registar presença, tem dia de férias marcado!
            {/* {t("portalrh.abscenses.exportSucess")} */}
          </Toast.Body>
        </Toast>
      </div>
    );
  };

  return (
    // style={{background: "rgba(255,255,255,0.4)"}} ------ style={{opacity: ".4"}} ----- style={{border: "none", opacity: ".2"}}
    <div className="main-card-v2">
      <div style={{ position: "absolute" }}>
        <Card
          bsPrefix="card-transparent"
          style={{ padding: "25px 30px 10px 30px" }}
        >
          <Card.Header className="justify-content-between">
            <div>
              <h6>
                <i className="icon-bell mr-2" />
                {t("CheckInAndOutWidget.title")}
              </h6>
              <h6>
                {textMove && t("widgets.resizeMove")}
                {textClick && t("widgets.resizeClick")}
              </h6>
            </div>
            <div>
              <CustomDropdown
                apagarWidget={apagarWidget}
                handleMouseDown={handleMouseDown}
              />
            </div>
          </Card.Header>
          {!isResizing && (
            <Card.Body>
              <div
                style={{
                  paddingTop: showToast ? "50%" : "85%",
                  paddingRight: "280px",
                }}
              ></div>
            </Card.Body>
          )}
        </Card>
        {showToast && toastResult()}
      </div>

      {!isResizing && (
        <Container>
          <Row>
            <Button
              size="lg"
              className="card-button btn btn-primary checkin-landing-button "
              style={{
                border: "0",
                position: "relative",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                height: "45px",
                width: "30px",
                marginTop: "150px",
              }}
              onClick={() => {
                dispatch(toggleCheck(t));
              }}
              disabled={isCheckedIn || isLoadingCheck || reachedMaximumChecks}
            >
              {!isLoadingCheck ? (
                t("portalrh.login.checkin")
              ) : (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {t("portalrh.login.loading")}
                </>
              )}
            </Button>
          </Row>
          <Row>
            <Button
              size="lg"
              className="card-button btn btn-primary checkin-landing-button"
              style={{
                border: "0",
                position: "relative",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                height: "45px",
                width: "30px",
                marginTop: "45px",
              }}
              onClick={() => {
                dispatch(toggleCheck(t));
              }}
              disabled={!isCheckedIn || isLoadingCheck || reachedMaximumChecks}
            >
              {!isLoadingCheck ? (
                t("portalrh.login.checkout")
              ) : (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {t("portalrh.login.loading")}
                </>
              )}
            </Button>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default withNamespaces()(CheckInAndOutWidget);
