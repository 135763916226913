import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { Form, Col, Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Output from "../Output/index";

import "~/assets/css/icons.css";
import "../OnboardingMainPage.css";

import { getInquiry, postInquiry } from "~/store/ducks/onboarding/actionTypes";

/**
 * Dun&Bradstreet Peps list
 *
 * This service tests an API from Dun&Bradstreet
 *
 * It allows to the user to search for a Person's name and see
 * if it is a Politically Exposed Person.
 *
 * The API documentation is available though the following link:
 * https://directplus.documentation.dnb.com/html/pages/RestrictedPartyScreeningAPIs.html
 *
 */

const Test = ({ t }) => {
  const dispatch = useDispatch();

  const {
    isLoadingPostInquiry,
    isLoadingGetInquiry,
    getInquiryResponse,
    postInquiryResponse,
  } = useSelector((state) => state.onboardingReducer);

  const { apiSettings } = useSelector(
    (state) => state.onboardingSettingsReducer
  );

  const { user } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    if (getInquiryResponse) {
      Object.keys(getInquiryResponse).length > 0 &&
        setOutput(JSON.stringify(getInquiryResponse, null, 2));
    }
  }, [getInquiryResponse]);

  useEffect(() => {
    if (Object.keys(postInquiryResponse).length > 0) {
      if (postInquiryResponse?.inquiries) {
        dispatch(getInquiry(postInquiryResponse?.inquiries[0]?.inquiryID));
      }
    }
  }, [postInquiryResponse]);

  const [subjectTypeText, setSubjectTypeText] = useState("IndividualName");
  const [subjectName, setSubjectName] = useState("");
  const [countryISO, setCountryISO] = useState("");
  const [streetAddressLine, setStreetAddressLine] = useState("");
  const [townName, setTownName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [output, setOutput] = useState("");
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      testPepCheck();
    }
  };

  const testPepCheck = () => {
    /* Conditions needed so that input value is not null */
    const payload = {
      subjectName: subjectName === "" ? null : subjectName,
      subjectTypeText: subjectTypeText === "" ? null : subjectTypeText,
      countryISO: countryISO === "" ? null : countryISO,
      streetAddressLine: streetAddressLine === "" ? null : streetAddressLine,
      townName: townName === "" ? null : townName,
      postalCode: postalCode === "" ? null : postalCode,
      birthDate: birthDate === "" ? null : birthDate,
      screeningMonitoringMode: "NoMonitoring",
    };

    dispatch(postInquiry(payload, apiSettings?.id));
    //dispatch(getInquiry("8acf9143-f28e-4c73-93b2-d5c7d440e4b4"));
  };

  return (
    <div className="onboarding-execute-form">
      <Form
        noValidate
        validated={validated}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        encType="multipart/form-data"
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <h3 className="onboarding-form-header">
          {t("onboarding.general.test")}
        </h3>
        <Form.Row>
          <Col sm="12" md="8" lg="6">
            <Form.Group controlId="validationDBConfigSearchType">
              <Form.Label>{t("onboarding.persist.subjectType")}</Form.Label>
              <div>
                <Form.Check
                  inline
                  label="Person"
                  name="group1"
                  type="radio"
                  id="dBPerson"
                  defaultChecked={true}
                  style={{ paddingRight: "15px" }}
                  onChange={(e) => {
                    setSubjectTypeText(e.target.value && "IndividualName");
                  }}
                />
                <Form.Check
                  inline
                  label="Organization"
                  name="group1"
                  type="radio"
                  id="dBOrganization"
                  onChange={(e) => {
                    setSubjectTypeText(e.target.value && "OrganizationName");
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group controlId="validationDBConfigCompanyName">
              <Form.Label>{t("onboarding.persist.subjectName")}</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                value={subjectName}
                required
                onChange={(e) => setSubjectName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredSubjectName")}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="validationDBConfigAlpha2">
              <Form.Label>{t("onboarding.persist.countryAlpha2")}</Form.Label>
              <Form.Control
                type="text"
                pattern="[a-zA-Z]{2}"
                autoComplete="off"
                value={countryISO}
                required
                onChange={(e) => setCountryISO(e.target.value.toUpperCase())}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requireCountryAlpha2")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationDBConfigStreetName">
              <Form.Label>{t("onboarding.persist.streetAddress")}</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                value={streetAddressLine}
                onChange={(e) => setStreetAddressLine(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredStreetAddress")}
              </Form.Control.Feedback>
              <small>
                <i>{t("onboarding.persist.optional")}</i>
              </small>
            </Form.Group>
            <Form.Group controlId="validationDBConfigTownName">
              <Form.Label>{t("onboarding.persist.townName")}</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                value={townName}
                onChange={(e) => setTownName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredTown")}
              </Form.Control.Feedback>
              <small>
                <i>{t("onboarding.persist.optional")}</i>
              </small>
            </Form.Group>
            <Form.Group controlId="validationDBConfigPostalCode">
              <Form.Label>{t("onboarding.persist.zipCode")}</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredZipCode")}
              </Form.Control.Feedback>
              <small>
                <i>{t("onboarding.persist.optional")}</i>
              </small>
            </Form.Group>
            <Form.Group controlId="validationDBConfigBirthdate">
              <Form.Label>{t("onboarding.persist.birthDate")}</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {t("onboarding.persist.requiredBirthdate")}
              </Form.Control.Feedback>
              <small>
                <i>{t("onboarding.persist.optionalDate")}</i>
              </small>
            </Form.Group>
          </Col>
        </Form.Row>
        <Button
          disabled={!user?.organization?.id}
          className="card-button onboarding-button"
          type="submit"
        >
          {isLoadingPostInquiry || isLoadingGetInquiry ? (
            <>
              {t("onboarding.general.loading")}
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </>
          ) : (
            t("onboarding.general.test")
          )}
        </Button>
      </Form>
      <Output output={output} />
    </div>
  );
};
export default withNamespaces()(Test);
