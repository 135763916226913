import { put } from "redux-saga/effects";
import http from "~/utils/config/http";

import { activeSuccess, activeError } from "~/components/Messages/actions";

import * as types from "./actionTypes";
import * as middlewares from "~/utils/constants";
const devLogConsole = require("~/utils/devLog");
export function* pullDataFromBPM() {
  try {
    const resp = yield http.get(
      "/api/middleware/pull-data",
      middlewares.BPM_MIDDLEWARE
    );
    yield put(types.setPulledDataFromBPM(true));
    yield put(activeSuccess(resp.data.message));
  } catch (e) {
    yield put(types.setPulledDataFromBPM(false));
    yield put(activeError("admin.applications.bpm.errorPulledMessage"));
  }
}

export function* pullDataAllMiddleware() {
  try {
    const resp = yield http.get(
      "/api/middleware/pull-data-all-middleware",
      middlewares.BPM_MIDDLEWARE
    );
    yield put(types.setPulledAllMiddleware(true));
    yield put(activeSuccess(resp.data.message));
  } catch (e) {
    devLogConsole(e);
    yield put(types.setPulledAllMiddleware(false));
    yield put(activeError("admin.applications.bpm.errorPulledMessage"));
  }
}

export function* pingMiddlewareApplication({ middleware }) {
  try {
    yield http.get("/api/open/about", middleware);
    yield put(types.setPingMiddlewareApplication({ middleware, status: true }));
  } catch (e) {
    yield put(
      types.setPingMiddlewareApplication({ middleware, status: false })
    );
  }
}

export function* getSyncListMW() {
  try {
    const resp = yield http.get("/api/synchronization");
    yield put(types.setSyncListMW(resp?.data));
  } catch (e) {
    yield put(types.setSyncListMW({}));
  }
}

export function* getSyncEncrypt({ encrypt }) {
  try {
    const resp = yield http.get(`/api/synchronization/encrypt/${encrypt}`);
    yield put(types.setMessagePostMWEncrypt(resp?.data));
  } catch (e) {
    yield put(types.setMessagePostMWEncrypt({}));
  }
}

export function* postSyncListMW({ payload }) {
  try {
    const resp = yield http.post("/api/synchronization", payload);
    yield put(
      types.setMessagePostMWSync(
        resp?.data ? resp?.data : "Something Went Wrong"
      )
    );
  } catch (e) {
    yield put(types.setMessagePostMWSync("Something Went Wrong"));
  }
}

export function* pingIFlowBasedApplication({ payload }) {
  const { url, application } = payload;
  try {
    yield http.get(url, application);
    yield put(
      types.setPingIFlowBasedApplication({ application, status: true })
    );
  } catch (e) {
    yield put(
      types.setPingIFlowBasedApplication({ application, status: false })
    );
  }
}
