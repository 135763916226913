import React, { useState, useEffect } from "react";
import { Col, Form, Row, Alert, InputGroup } from "react-bootstrap";
import CreateSearchableDropdown from "../CreateSearchableDropdown";
import CreateTextArea from "../CreateTextArea";
import CreateDateInput from "../CreateDateInput";
import CreateTextInput from "../CreateTextInput";
import CreateTableTextInput from "./CreateTableTextInput";
import CreateButtonsFromSelection from "../CreateButtonsFromSelection";
import uuid from "uuid/v1";

const CreateCalculosComparativo = (props) => {
  const { blockdivision, selection, styleSheet } = props;

  const [licCamarariaComp, setFormData1] = useState("");
  const [dtValidadeLicComp, setFormData2] = useState("");
  const [ma_observacoes, setFormData3] = useState("");
  const [orcClienteComp, setFormData4] = useState("");
  const [orcAceiteComp, setFormData5] = useState("");
  const [numLicComp, setFormData6] = useState("");
  const [empreiteiroComp, setFormData7] = useState("");
  const [aplicarTodas, setFormData8] = useState("");
  const [fracaoRai, setFormData9] = useState("");
  const [seccao, setFormData10] = useState("");
  const [vSeguro, setFormData19] = useState("");
  const [vComercial, setFormData21] = useState("");
  const [valorAntesObrasComp, setFormData11] = useState(0);
  const [percObraRealizadaComp, setFormData12] = useState(0);
  const [areaBruta, setFormData13] = useState("");
  const [SeguroEncargoPerc, setFormData14] = useState("");
  const [SeguroEncargoPerc_all, setFormData14_all] = useState("");
  const [valorActual, setFormData15] = useState("");
  const [ivAdoptado, setFormData16] = useState("");
  const [ltv, setFormData30] = useState("");
const [encargoPercValidation,setencargoPercValidation] = useState(false)
  const [tableComparativo, setTable1] = useState("");
  const [tableTerreno, setTable2] = useState("");
  const [textSize, setTextSize] = useState("")
  const [tableSize, setTableSize] = useState("")
  useEffect(() => {
    if (
      styleSheet?.zoomIn &&
      styleSheet?.zoomInTextSize
    ) {
      setTextSize(`${styleSheet?.zoomInTextSize}`)
    }
    if (
      styleSheet?.zoomIn &&
      styleSheet?.tablefontsize
    ) {
      setTableSize(`${styleSheet?.tablefontsize}`)
    }
    
  }, [styleSheet]);

  useEffect(() => {
    if (blockdivision) {
      //find form data
      let licCamarariaComp;
      let dtValidadeLicComp;
      let orcClienteComp;
      let orcAceiteComp;
      let numLicComp;
      let empreiteiroComp;
      let aplicarTodas;
      let fracaoRai;
      let seccao;
      let tableComparativo;
      let tableTerreno;
      let valorAntesObrasComp;
      let percObraRealizadaComp;
      let ma_observacoes;
      let areaComp;
      let vSeguro;
      let vComercial;
      let SeguroEncargoPerc;
      let valorActual;
      let ivAdoptado;
      let ltv_value;

      const setValue = (f) => {
        if (f?.variable == "licCamarariaComp") {
          licCamarariaComp = f;
        }
        if (f?.variable == "dtValidadeLicComp") dtValidadeLicComp = f;
        if (f?.variable == "orcClienteComp") orcClienteComp = f;
        if (f?.variable == "orcAceiteComp") orcAceiteComp = f;
        if (f?.variable == "numLicComp") numLicComp = f;
        if (f?.variable == "empreiteiroComp") empreiteiroComp = f;
        if (f?.variable == "valorAntesObrasComp") {
         
          valorAntesObrasComp = f; 
        }
        if (f?.variable == "percObraRealizadaComp") percObraRealizadaComp = f;
        if (f?.variable == "ma_observacoes") ma_observacoes = f;
        if (f?.variable == "areaComp") areaComp = f;
        if (f?.variable == "SeguroEncargoPerc") SeguroEncargoPerc = f
        if (f?.text == "Valor de Seguro (€) (CC+E)") vSeguro = f?.value;
        if (f?.text == "V – Valor Comercial") vComercial = f?.value;
        if (f?.text == "IV adoptado") ivAdoptado = f?.value;
        if (f?.variable == "aplicarTodas") aplicarTodas = f;
        if (f?.variable == "fracaoRai") fracaoRai = f;
        if (f?.variable == "seccao") seccao = f;
        if (f?.text == "LTV") ltv_value = f.value;

        if (f?.type == "arraytable") {
          if (f?.row[0]?.col[0]?.value === "Estimativa do valor de mercado") {
            tableComparativo = f;
          }
          if (f?.row[0]?.col[0]?.value === "Terreno ( T )") {
            tableTerreno = f;
          }
        }
        if (f?.text == "Valor Actual ( xx% de obra )") valorActual = f.value;
      };
      let blockdivision_ = JSON.parse(JSON.stringify(blockdivision));
      if (!Array.isArray(blockdivision_)) {
        blockdivision_ = [blockdivision_];
      }
      blockdivision.forEach((b) => {
        if (Array.isArray(b.columndivision)) {
          b.columndivision.forEach((c) => {
            if (Array.isArray(c.field)) {
              c.field.forEach((f) => {
                setValue(f);
              });
            } else {
              setValue(c.field);
            }
          });
        } else {
          if (Array.isArray(b.columndivision?.field)) {
            b.columndivision.field.forEach((f) => {
              setValue(f);
            });
          } else {
            setValue(b.columndivision.field);
          }
        }
      });

      setFormData1(licCamarariaComp);
      setFormData2(dtValidadeLicComp);
      setFormData3(ma_observacoes);
      setFormData4(orcClienteComp);
      setFormData5(orcAceiteComp);
      setFormData6(numLicComp);
      setFormData7(empreiteiroComp);
      setFormData8(aplicarTodas);
      setFormData9(fracaoRai);
      setFormData10(seccao);
      setFormData11(valorAntesObrasComp);
      setFormData12(percObraRealizadaComp);
      setFormData13(areaComp);
      setFormData19(formatter.format(vSeguro));
      setFormData21(formatter.format(vComercial))
      if (SeguroEncargoPerc != undefined) {
        setFormData14(SeguroEncargoPerc.value);
        setFormData14_all(SeguroEncargoPerc);
      }
      setFormData15(valorActual);
      setFormData16(ivAdoptado)
      setFormData30(ltv_value);

      setTable1(tableComparativo);
      setTable2(tableTerreno);
      updateTotais(66, 83);
    }
  }, [blockdivision]);

  useEffect(() => {
    updateTotais(66, 83);
  }, [tableComparativo, tableTerreno]);



  //const formatter = new Intl.NumberFormat("pt-PT", {
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  //const decimalFormatter = new Intl.NumberFormat("pt-PT", {
  const decimalFormatter = new Intl.NumberFormat("de-DE", {
    style: "decimal",
  });

  const numberFormatter = new Intl.NumberFormat("de-DE");

  function parseCurrency(arg) {
    return arg.replace(/[^\d,]/g, "").replace(/,/g, ".");
  }

  function parseNumber(arg) {
    if(arg != undefined) return Number(arg.replace(/[,]/g, ""));
  }

  function updateTotais(tabelaComparativo, tabelaTerrenos) {
    let numLinhasComparativo = 3;
    let valorTot = 0;

    while (
      document.getElementById(
        tabelaComparativo + "_" + numLinhasComparativo + "_5"
      ) != null
    ) {
      valorTot += Number(
        parseCurrency(
          document.getElementById(
            tabelaComparativo + "_" + numLinhasComparativo + "_5"
          ).textContent
        )
      );
      numLinhasComparativo++;
    }

    document.getElementById("totalParcial").textContent = formatter.format(
      valorTot.toFixed(2)
    );

    let numLinhasSeguro = 3;
    let segTot = 0;

    while (
      document.getElementById(
        tabelaComparativo + "_" + numLinhasSeguro + "_7"
      ) != null
    ) {
      segTot += Number(
        parseCurrency(
          document.getElementById(
            tabelaComparativo + "_" + numLinhasSeguro + "_7"
          ).textContent
        )
      );
      numLinhasSeguro++;
    }

    document.getElementById("custoConstrucao").textContent = formatter.format(
      segTot.toFixed(2)
    );

    let numLinhasTerreno = 2;
    let totTerreno = 0;
    while (
      document.getElementById(tabelaTerrenos + "_" + numLinhasTerreno + "_4") !=
      null
    ) {
      totTerreno += Number(
        parseCurrency(
          document.getElementById(
            tabelaTerrenos + "_" + numLinhasTerreno + "_4"
          ).textContent
        )
      );
      numLinhasTerreno++;
    }

    document.getElementById("totalTerreno").textContent = formatter.format(
      totTerreno.toFixed(2)
    );

    let perEncargos
    if(document.getElementById("encargos").getElementsByTagName("input")[0] == undefined){
      let docid = document.getElementById("SeguroEncargoPerc").textContent.replace('%', '');
      perEncargos = parseFloat(docid)/100
    } else{
      perEncargos = Number(
        document.getElementById("encargos").getElementsByTagName("input")[0]
          .value
      ) / 100;
    }
      
    let valorSeguro = (1 + perEncargos) * segTot;
if(document.getElementById("valorSeguro").textContent == ""){
      document.getElementById("valorSeguro").textContent = formatter.format(
      valorSeguro.toFixed(2)
    );} else {
      setFormData19(formatter.format(valorSeguro.toFixed(2)))
    }

    let valorComercial = valorTot + totTerreno;
if(document.getElementById("valorComercial").textContent == ""){
      document.getElementById("valorComercial").textContent = formatter.format(
      valorComercial.toFixed(2)
    );} else{
      setFormData21(formatter.format(valorComercial.toFixed(2)))
    }

    let areaBruta;
    if (document.getElementById("areaBruta")) {
      areaBruta = parseNumber(document.getElementById("areaBruta").value);
    }

    let ivAdoptadoVal = 0;
    if (areaBruta > 0) {
      ivAdoptadoVal = valorComercial / areaBruta;
    } else {
      if(ivAdoptado === "" || ivAdoptado === undefined) ivAdoptadoVal = 0;
      else ivAdoptadoVal = parseFloat(ivAdoptado);
    }

    document.getElementById("ivAdoptado").textContent = decimalFormatter.format(
      ivAdoptadoVal.toFixed(2)
    );

    let valAntesObras
    let valorStringvalAntesObras
    let percObraRealizada;
    let valorStringperObraRealizada

    if(valorAntesObrasComp?.type == "textbox"){
      if(valorAntesObrasComp.disabled == "false"){
        if(document.getElementById("valorAntesObrasComp").value == ''){
          valorStringvalAntesObras = 0
        }
         else valorStringvalAntesObras= parseFloat(document.getElementById("valorAntesObrasComp").value.replace(/,/g, ''))
      } else{
        valorStringvalAntesObras = document.getElementById("valorAntesObrasComp").textContent
        if(valorStringvalAntesObras == ""){
          valorStringvalAntesObras = valorAntesObrasComp.value
        }
      }
    }
    if(percObraRealizadaComp?.type == "textbox"){
      if(percObraRealizadaComp.disabled == "false"){
        valorStringperObraRealizada= document.getElementById("percObraRealizadaComp").value
      } else{
        valorStringperObraRealizada = document.getElementById("percObraRealizadaComp").textContent
        if(valorStringperObraRealizada == ""){
          valorStringperObraRealizada = percObraRealizadaComp.value
        }
      }
    }
    if(valorStringperObraRealizada == "") valorStringperObraRealizada = 0
    if(valorStringvalAntesObras == "") valorStringvalAntesObras = 0

    percObraRealizada = valorStringperObraRealizada != undefined && parseFloat(valorStringperObraRealizada)/100;
    if(valorStringvalAntesObras!= undefined && typeof valorStringvalAntesObras === 'string' && valorStringvalAntesObras.includes('.')) {
      valAntesObras = valorStringvalAntesObras!= undefined && parseFloat(valorStringvalAntesObras.replace('.', '').replace(',', '.'));
    } 
    else valAntesObras = valorStringvalAntesObras!= undefined && parseFloat(valorStringvalAntesObras);
    
    let valorActual = valAntesObras + (valorComercial - valAntesObras) * percObraRealizada;

    if (document.getElementById("valorActual").textContent == "") {
      document.getElementById("valorActual").textContent = formatter.format(
        valorActual.toFixed(2)
      );
    } else{
      setFormData15(formatter.format(valorActual.toFixed(2)))
    }
  }

  function updateTabelaComparativo(linha, tabelaComparativo, tabelaTerrenos) {
    let tableid = tabelaComparativo;

    let colArea = "2";
    let colUnit = "3";
    let colDepre = "4";
    let colTot = "5";

    let colSegUnit = "6";
    let colSegTot = "7";

    /*
    let valorArea = parseNumber(
      document.getElementById(tableid + "_" + linha + "_" + colArea).textContent
    );*/

    let valorArea = Number(
      document
        .getElementById(tableid + "_" + linha + "_" + colArea)
        .textContent.replace(/[.]/g, "")
        .replace(/[,]/g, ".")
    );

    let valorUnit = parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colUnit)
        .getElementsByTagName("input")[0].value
    );
    let valorDepre = parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colDepre)
        .getElementsByTagName("input")[0].value
    );

    let valorTot = valorArea * valorUnit * valorDepre;

    document.getElementById(tableid + "_" + linha + "_" + colTot).textContent =
      formatter.format(valorTot.toFixed(2));

    let valorSegUnit = parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colSegUnit)
        .getElementsByTagName("input")[0].value
    );
    let valorSegTot = valorArea * valorSegUnit;

    document.getElementById(
      tableid + "_" + linha + "_" + colSegTot
    ).textContent = formatter.format(valorSegTot.toFixed(2));

    updateTotais(tabelaComparativo, tabelaTerrenos);
  }

  function updateTabelaTerrenos(linha, tabelaComparativo, tabelaTerrenos) {
    let tableid = tabelaTerrenos;

    let colArea = "2";
    let colUnit = "3";
    let colTot = "4";

    let valorArea = parseNumber(
      document.getElementById(tableid + "_" + linha + "_" + colArea).textContent
    );
    let valorUnit = parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colUnit)
        .getElementsByTagName("input")[0].value
    );

    let valorTot = valorArea * valorUnit;

    document.getElementById(tableid + "_" + linha + "_" + colTot).textContent =
      formatter.format(valorTot.toFixed(2));

    updateTotais(tabelaComparativo, tabelaTerrenos);
  }

  const renderTableComparativo = () => {
    if (tableComparativo && Array.isArray(tableComparativo.row)) {
      return tableComparativo.row.map((r, index) => {
        if (index != 0 && index != 1) {
          return (
            <tr key={index}>
              {Array.isArray(r.col) &&
                r.col.map((c, i) => {
                  let style =
                    i !== 0 ? { textAlign: "end" } : { textAlign: "start" };

                  if (i == 1) {
                    return (
                      <td
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        style={{ ...style, fontSize: `${textSize}px` }}
                      >
                        {
                          /*1,250.08 -> 1.250,08*/
                          numberFormatter.format(c.value.replace(/[,]/g, ""))
                        }
                      </td>
                    );
                  } else if (i == 2) {
                    return c?.value?.input ? (
                      <CreateTableTextInput
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        name={`listaValorUnit[${index - 2}]`}
                        value={c?.value?.input?.value}
                        linha={index + 1}
                        onBlur={updateTabelaComparativo}
                        size={c?.value?.input?.size}
                        maxlength={c?.value?.input?.maxlength}
                        fontSize={textSize}
                      />
                    ) : (
                      <td
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        style={{ ...style, fontSize: `${textSize}px` }}
                      >
                        {c.value}
                      </td>
                    );
                  } else if (i == 3) {
                    return c?.value?.input ? (
                      <CreateTableTextInput
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        name={`lista_coeficiente[${index - 2}]`}
                        value={c?.value?.input?.value}
                        linha={index + 1}
                        onBlur={updateTabelaComparativo}
                        size={c?.value?.input?.size}
                        maxlength={c?.value?.input?.maxlength}
                        fontSize={textSize}
                      />
                    ) : (
                      <td
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        style={{ ...style, fontSize: `${textSize}px` }}
                      >
                        {c.value}
                      </td>
                    );
                  }
                  if (i == 5) {
                    return c?.value?.input ? (
                      <CreateTableTextInput
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        name={`lista_valorSeguro[${index - 2}]`}
                        value={c?.value?.input?.value}
                        linha={index + 1}
                        onBlur={updateTabelaComparativo}
                        size={c?.value?.input?.size}
                        maxLength={c?.value?.input?.maxLength}
                        fontSize={textSize}
                      />
                    ) : (
                      <td
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        style={{ ...style, fontSize: `${textSize}px` }}
                      >
                        {c.value}
                      </td>
                    );
                  } else
                    return (
                      <td
                        key={i}
                        id={`66_${index + 1}_${i + 1}`}
                        style={{ ...style, fontSize: `${textSize}px` }}
                      >
                        {c.value}
                      </td>
                    );
                })}
            </tr>
          );
        }
      });
    }
  };

  const renderTableTerrenos = () => {
    if (tableTerreno && Array.isArray(tableTerreno.row)) {
      return tableTerreno.row.map((r, index) => {
        if (index != 0) {
          return (
            <tr key={index}>
              {Array.isArray(r.col) &&
                r.col.map((c, i) => {
                  let style =
                    i !== 0 ? { textAlign: "end" } : { textAlign: "start" };
                  if (i == 2) {
                    return c?.value?.input ? (
                      <CreateTableTextInput
                        key={i}
                        id={`83_${index + 1}_${i + 1}`}
                        name={`listaValorTerreno[${index - 1}]`}
                        value={c?.value?.input?.value}
                        linha={index + 1}
                        onBlur={updateTabelaTerrenos}
                        size={c?.value?.input?.size}
                        maxlength={c?.value?.input?.maxlength}
                        fontSize={textSize}
                      />
                      ) : (
                        <td
                        key={i}
                        id={`83_${index + 1}_${i + 1}`}
                        style={{ ...style, fontSize: `${textSize}px` }}
                      >
                        {c.value}
                      </td>
                      );
                  } else
                    return (
                      <td
                        key={i}
                        id={`83_${index + 1}_${i + 1}`}
                        style={{ ...style, fontSize: `${textSize}px` }}
                      >
                        {c.value}
                      </td>
                    );
                })}
            </tr>
          );
        }
      });
    }
  };


  const style1 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "0px",
    flexGrow: "1",
  };

  const style2 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
    justifyContent: "flex-end",
  };

  const style3 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
  };

  const style4 = {
    padding: "5px 5px 10px 0px",
    overflowWrap: "break-word",
    verticalAlign: "baseline",
    fontSize: `${textSize}px`,
  };

  const style6 = {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
  };

  const style7 = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "start",
  };

  const style8 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
  };

  const style9 = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "end",
    paddingRight: "0px",
    paddingLeft: "0px",
  };
  const style9const = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "end",
    paddingRight: "8px",
    paddingLeft: "0px",
  };
  const style9seguroenc = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "end",
    paddingRight: "0px",
    paddingLeft: "0px",
    marginRight:'17px'
  };
  

  const style10 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "0px",
  };

  const style11 = {
    width: "34%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "center",
  };

  const style12 = {
    width: "66%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "center",
  };

  const renderLine = () => {
    return (
      <div style={{ width: "90%" }}>
        <hr />
      </div>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const renderColumnContent = () => {
    return (
      <>
        {seccao && (
          <CreateButtonsFromSelection field={seccao} styleSheet={styleSheet} />
        )}

        {fracaoRai && (
          <CreateSearchableDropdown
            field={fracaoRai}
            key={"fracaoRai"}
            fontSize={textSize}
            styleSheet={styleSheet}
          />
        )}
        {aplicarTodas && (
          <CreateSearchableDropdown
            field={aplicarTodas}
            key={"aplicarTodas"}
            fontSize={textSize}
            styleSheet={styleSheet}
          />
        )}
        <Form.Group key={uuid()} style={style1}>
          <Form.Label column style={{ flexGrow: "1" }}>
            <h4 style={{ fontSize: `${textSize}px` }}>
              Método comparativo de mercado
            </h4>{" "}
            <hr />
          </Form.Label>
        </Form.Group>
        <Alert variant="info">
          O método comparativo de mercado tem por referência os valores de
          transacção de imóveis semelhantes e comparáveis ao imóvel objecto de
          análise, obtidos através de prospecção de mercado realizada na mesma
          localização. Após a recolha da informação que sustentará a estimativa
          de valor, terá de existir uma classificação da mesma de acordo com os
          itens (localização, estado de conservação, nível de acabamentos,
          áreas, ...) que se coadunam com o tipo de propriedade a avaliar.
        </Alert>

        <table style={{ width: "100%" }} className="bordertableMCCR">
          <thead>
            <tr style={{ fontWeight: "bold" }}>
              <td
                id="66_1_1"
                colSpan="5"
                style={{
                  fontSize: `${textSize}px`,
                  paddingBottom: "10px",
                  textAlign: "center",
                }}
              >
                Estimativa do valor de mercado
              </td>
              <td
                id="66_1_2"
                colSpan="2"
                style={{
                  fontSize: `${textSize}px`,
                  paddingBottom: "10px",
                  textAlign: "center",
                }}
              >
                Valor para Seguro
              </td>
            </tr>
            <tr style={{ paddingBottom: "10px" }}>
              <th
                id="66_2_1"
                style={{ ...style4, textAlign: "start", width: "12%" }}
              >
                Designação
              </th>
              <th
                id="66_2_2"
                style={{ ...style4, textAlign: "end", width: "14%" }}
              >
                Área (m2)
              </th>
              <th
                id="66_2_3"
                style={{ ...style4, textAlign: "end", width: "16%" }}
              >
                Valor Unitário (€/m2)
              </th>
              <th
                id="66_2_4"
                style={{ ...style4, textAlign: "end", width: "14%" }}
              >
                Coef. Depreciativo
              </th>
              <th
                id="66_2_5"
                style={{ ...style4, textAlign: "end", width: "14%" }}
              >
                Totais Parciais (€)
              </th>
              <th
                id="66_2_6"
                style={{ ...style4, textAlign: "end", width: "15%" }}
              >
                Custo Unitário (€/m2)
              </th>
              <th
                id="66_2_7"
                style={{ ...style4, textAlign: "end", width: "15%" }}
              >
                Totais Parciais (€)
              </th>
            </tr>
          </thead>

          <tbody>{renderTableComparativo()}</tbody>
        </table>
        <br />
        <Form.Group as={Row} style={style2}>
          <Col
            key={`columndivision_1}`}
            style={{ flexGrow: "0.46", padding: "0" }}
          >
            <Form.Group as={Row} style={style2}>
              <div
                style={{ ...style11, marginRight: "5px", paddingRight: "5px" }}
              >
                <Form.Group as={Row} style={{ ...style10, width: "100%" }}>
                  <Form.Label
                    column
                    style={{ flexGrow: "1", fontSize: `${textSize}px` }}
                  >
                    Total Parcial
                  </Form.Label>
                  <Form.Label
                    id="totalParcial"
                    column
                    style={{ ...style9, fontSize: `${textSize}px` }}
                  >
                    {" "}
                  </Form.Label>
                </Form.Group>
                {renderLine()}
              </div>

              <div style={style12}>
                <Form.Group
                  as={Row}
                  style={{ ...style10, paddingLeft: "10px", width: "100%" }}
                >
                  <Form.Label
                    column
                    style={{ flexGrow: "1", fontSize: `${textSize}px` }}
                  >
                    CC – Total Custo de construção
                  </Form.Label>
                  <Form.Label
                    id="custoConstrucao"
                    column
                    style={{ ...style9const, fontSize: `${textSize}px` }}
                  >
                    {" "}
                  </Form.Label>
                </Form.Group>
                {renderLine()}
              </div>
            </Form.Group>
          </Col>
        </Form.Group>

        <Form.Group as={Row} style={style3}>
          <table
            style={{ width: "70%", marginTop: "7px" }}
            className="bordertableMCCR"
          >
            <thead>
              <tr>
                <th
                  id="83_1_1"
                  style={{ ...style4, width: "17%", textAlign: "start" }}
                >
                  Terreno ( T )
                </th>
                <th
                  id="83_1_2"
                  style={{ ...style4, width: "20%", textAlign: "end" }}
                >
                  Área (m2)
                </th>
                <th
                  id="83_1_3"
                  style={{ ...style4, width: "22%", textAlign: "end" }}
                >
                  Valor Unitário (€/m2)
                </th>
                <th
                  id="83_1_4"
                  style={{ ...style4, width: "40%", textAlign: "end" }}
                >
                  Totais Parciais (€)
                </th>
              </tr>
            </thead>

            <tbody>{renderTableTerrenos()}</tbody>
          </table>

          <div style={style6}>
            <Form.Group
              as={Row}
              style={{ width: "100%", margin: "0px", marginLeft: "20px" }}
            >
              <Form.Group
                id="encargos"
                as={Row}
                style={{
                  justifyContent: "space-between",
                  flexGrow: "1",
                  margin: "0px",
                }}
              >
                <Form.Label
                  id="label_mccr_percE"
                  column
                  style={{ flexGrow: "1", fontSize: `${textSize}px` }}
                >
                  E - Encargos (%)
                </Form.Label>
                {SeguroEncargoPerc_all.disabled === "true" &&
                SeguroEncargoPerc_all?.type === "textbox" ? (
                  <Form.Label
                    id="SeguroEncargoPerc"
                    column
                    style={{ ...style9seguroenc, fontSize: `${textSize}px` }}
                  >
                    {SeguroEncargoPerc ? `${SeguroEncargoPerc} %` : ""}
                  </Form.Label>
                ) : (
                  <>
                    <input
                      id="SeguroEncargoPerc"
                      style={{ width: "auto", fontSize: `${textSize}px` }}
                      className="form-control create-text-input-suffix-fix"
                      type="text"
                      size={5}
                      name={"SeguroEncargoPerc"}
                      maxLength={5}
                      value={SeguroEncargoPerc}
                      onChange={(e) => setFormData14(e.target.value)}
                      onBlur={() => {
                        updateTotais(66, 83);
                      }}
                      onKeyDown={handleKeyDown}
                    />
                    <InputGroup.Append style={{ width:"auto", padding:"0px" }}>
                      <InputGroup.Text
                        className={"create-text-input-suffix"}
                        style={{ height: "44px" }}
                      >
                        %
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </>
                )}
              </Form.Group>
            </Form.Group>
            {renderLine()}
          </div>
        </Form.Group>

        <br />
        <Form.Group as={Row} style={style2}>
          <Col
            key={`columndivision_1}`}
            style={{ flexGrow: "0.46", padding: "0" }}
          >
            <Form.Group as={Row} style={style2}>
              <div style={style11}>
                <Form.Group as={Row} style={{ ...style10, width: "93%" }}>
                  <Form.Label
                    column
                    style={{ flexGrow: "1", fontSize: `${textSize}px` }}
                  >
                    Total Terreno
                  </Form.Label>
                  <Form.Label
                    id="totalTerreno"
                    column
                    style={{ ...style9, fontSize: `${textSize}px` }}
                  >
                    {" "}
                  </Form.Label>
                </Form.Group>
                {renderLine()}
              </div>

              <div style={style12}>
                <Form.Group
                  as={Row}
                  style={{ ...style10, width: "93%", marginLeft: "7px" }}
                >
                  <Form.Label
                    column
                    style={{ flexGrow: "1", fontSize: `${textSize}px` }}
                  >
                    Valor de Seguro (&euro;) (CC+E)
                  </Form.Label>
                  <Form.Label
                    id="valorSeguro"
                    column
                    style={{ ...style9const, fontSize: `${textSize}px` }}
                  >
                    {vSeguro ? `${vSeguro} ` : ""}
                  </Form.Label>
                </Form.Group>
                {renderLine()}
              </div>
            </Form.Group>
          </Col>
        </Form.Group>

        <Form.Group as={Row} style={style2}>
          <Col
            key={`columndivision_1}`}
            style={{ flexGrow: "0.46", padding: "0" }}
          >
            <Form.Group
              as={Row}
              style={{ ...style10, width: "33%", marginRight: "-10px" }}
            >
              <Form.Label
                column
                style={{
                  flexGrow: "1",
                  fontSize: `${textSize}px`,
                  paddingLeft: "28px",
                }}
              >
                V &ndash; Valor Comercial
              </Form.Label>
              <Form.Label
                id="valorComercial"
                column
                style={{ ...style9, fontSize: `${textSize}px` }}
              >
                {vComercial ? `${vComercial}` : ""}
              </Form.Label>
            </Form.Group>
          </Col>
        </Form.Group>
        <br />

        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
          <Form.Group as={Row} style={style3}>
            {areaBruta && (
              <CreateTextInput
                id="areaBruta"
                field={areaBruta}
                key={areaBruta.variable}
                onBlur={() => updateTotais(66, 83)}
                fontSize={textSize}
                maxWidth={'275px'}
                marginRight={'30px'}
              />
            )}

            {ltv && (
              <>
                <Form.Group as={Row} style={style3}>
                  <Form.Label
                    id="label_ltv"
                    column
                    style={{ maxWidth: "125px", fontSize: `${textSize}px` }}
                  >
                    LTV
                  </Form.Label>
                  <Form.Label
                    id="ltv"
                    column
                    style={{ ...style7, fontSize: `${textSize}px`, maxWidth:'90px' }}
                  >
                    {ltv ? `${ltv}%` : ""}
                  </Form.Label>
                  <Form.Label
                    id="montanteCredito"
                    column
                    style={{ ...style7, fontSize: `${textSize}px` }}
                  >
                    Montante de Crédito
                  </Form.Label>
                </Form.Group>
              </>
            )}
            </Form.Group>
            <Form.Group as={Row} style={style3}>
              <Form.Label
                id="label_lvRep"
                column
                style={{ maxWidth: "225px", fontSize: `${textSize}px` }}
              >
                IV adoptado
              </Form.Label>
              <Form.Label
                id="ivAdoptado"
                column
                style={{ ...style7, fontSize: `${textSize}px` }}
              ></Form.Label>
            </Form.Group>
          </Col>
        </Form.Group>

        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            {orcClienteComp && (
              <CreateTextInput
                field={orcClienteComp}
                key={orcClienteComp.variable}
                fontSize={textSize}
              />
            )}
            {orcAceiteComp && (
              <CreateTextInput
                field={orcAceiteComp}
                key={orcAceiteComp.variable}
                fontSize={textSize}
              />
            )}

            {empreiteiroComp && (
              <CreateTextInput
                field={empreiteiroComp}
                key={"empreiteiroComp"}
                fontSize={textSize}
              />
            )}

            {licCamarariaComp && (
              <CreateSearchableDropdown
                field={licCamarariaComp}
                key={"licCamarariaComp"}
                fontSize={textSize}
              />
            )}

            {numLicComp && (
              <CreateTextInput
                field={numLicComp}
                key={"numLicComp"}
                fontSize={textSize}
              />
            )}

            {dtValidadeLicComp && (
              <CreateDateInput
                field={dtValidadeLicComp}
                key={"dtValidadeLicComp"}
                fontSize={textSize}
              />
            )}

            {valorAntesObrasComp && (
              <CreateTextInput
                id={"valorAntesObrasComp"}
                field={valorAntesObrasComp}
                key={"valorAntesObrasComp"}
                onBlur={() => updateTotais(66, 83)}
                fontSize={textSize}
              />
            )}

            {percObraRealizadaComp && (
              <CreateTextInput
                id={"percObraRealizadaComp"}
                field={percObraRealizadaComp}
                key={"percObraRealizadaComp"}
                onBlur={() => updateTotais(66, 83)}
                fontSize={textSize}
              />
            )}

            <Form.Group
              as={Row}
              style={{
                flexWrap: "nowrap",
                margin: "0px",
              }}
            >
              <Form.Label
                column
                style={{ maxWidth: "225px", fontSize: `${textSize}px` }}
              >
                Valor Actual ( xx% de obra )
              </Form.Label>
              <Form.Label
                id="valorActual"
                column
                style={{ ...style7, fontSize: `${textSize}px` }}
              >
                {valorActual ? `${valorActual}` : ""}
              </Form.Label>
            </Form.Group>

            {ma_observacoes && (
              <CreateTextArea field={ma_observacoes} key={"ma_observacoes"} />
            )}
          </Col>
        </Form.Group>
      </>
    );
  };

  return (
    <Row
      key={`blockdivision_comparativo`}
      style={{ width: "100%", flexWrap: "nowrap", margin: "0px" }}
    >
      <Col key={`columndivision_comparativo`} style={{ flexGrow: "1" }}>
        {renderColumnContent()}
      </Col>
    </Row>
  );
};
export default CreateCalculosComparativo;
