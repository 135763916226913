import { reject } from "q";
import { put } from "redux-saga/effects";
import http from "~/utils/config/http";
import { PORTALRH_MIDDLEWARE } from "~/utils/constants";

import {
  setRHAdminConfiguration,
  clearRHAdminConfiguration,
  sucessesPersistRHAdminConfiguration,
  setRHBolsaHorasConfiguration,
  clearRHBolsaHorasConfiguration,
  setRHBolsaHorasUsersGroup,
  clearRHBolsaHorasUsersGroup,
  clearRHSaldoBolsaHoras,
  setRHSaldoBolsaHoras,
  clearRHSaldoInicialFinalBolsaHoras,
  setRHSaldoInicialFinalBolsaHoras,
  sucessesPersistRHBolsaHorasConfiguration,
} from "./actionTypes";

export function* getRHAdminConfiguration({ organizationId }) {
  try {
    yield put(clearRHAdminConfiguration());
    const resp = yield http.get(
      `/api/rhadmin-configuration/find-by-organization?orgId=${organizationId}`
    );
    yield put(setRHAdminConfiguration(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* persistRHAdminConfiguration({ configuration }) {
  try {
    yield put(clearRHAdminConfiguration());
    yield http.put("/api/rhadmin-configuration/create", configuration);
    yield put(sucessesPersistRHAdminConfiguration());
  } catch (error) {
    reject(error);
  }
}

// BOLSA DE HORAS

export function* getRHBolsaHorasConfiguration({ organizationId }) {
  try {
    yield put(clearRHBolsaHorasConfiguration());
    const resp = yield http.get(
      `/api/configuration-flow/find-by-organization?orgId=${organizationId}`
    );
    yield put(setRHBolsaHorasConfiguration(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getRHBolsaHorasUsersGroup() {
  try {
    yield put(clearRHBolsaHorasUsersGroup());
    const resp = yield http.get(
      `/api/get-group-users-ausencias`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setRHBolsaHorasUsersGroup(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getRHSaldoBolsaHoras({ usrId }) {
  try {
    yield put(clearRHSaldoBolsaHoras());
    const resp = yield http.get(
      `/api/get-saldo-by-usr-id?usrId=${usrId}`,
      PORTALRH_MIDDLEWARE
    );
    yield put(setRHSaldoBolsaHoras(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* getRHSaldoInicialFinalBolsaHoras({ usrId, data }) {
  try {
    yield put(clearRHSaldoInicialFinalBolsaHoras());
    const resp = yield http.get(
      `/api/get-saldo-by-id?usrId=${usrId}&date=${data}`, PORTALRH_MIDDLEWARE
    );
    yield put(setRHSaldoInicialFinalBolsaHoras(resp.data));
  } catch (error) {
    reject(error);
  }
}

export function* persistRHBolsaHorasConfiguration({ configuration }) {
  try {
    yield put(clearRHBolsaHorasConfiguration());
    yield http.put("/api/configuration-flow/save", configuration);
    yield put(sucessesPersistRHBolsaHorasConfiguration());
  } catch (error) {
    reject(error);
  }
}
