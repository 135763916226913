import React, { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import Dropdown from "~/components/Dropdown";
import { withNamespaces } from "react-i18next";
import { useSelector } from "react-redux";
import ButtonsUpdate from "./ButtonsUpdate";
import SearchableDropDown from "~/components/SearchableDropDown";
import DateTimePicker from "react-datetime-picker";

const RhPersonalData = ({ rowCollaborator, t }) => {
  const [fullName, setFullName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [genderList, setGenderList] = useState([]);
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [parishList, setParishList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [citizenCard, setCitizenCard] = useState("");
  const [emissionDate, setEmissionDate] = useState("");
  const [expiration, setExpirationDate] = useState("");
  const [archive, setArchive] = useState("");
  const [passaport, setPassaport] = useState("");
  const [passaportEmissionDate, setPassaportEmissionDate] = useState("");
  const [passaportExpirationDate, setPassaportExpirationDate] = useState("");
  const [passaportArchive, setPassaportArchive] = useState("");
  const [taxIdNumber, setTaxIdNumber] = useState("");
  const [apportionmentList, setApportionmentList] = useState([]);
  const [socialSecurity, setSocialSecuryty] = useState("");
  const [socialSecurityDate, setSocialSecurytyDate] = useState("");
  const [genderSelected, setGenderSelected] = useState("");
  const [maritalStatusSelected, setMaritalStatusSelected] = useState("");
  const [districtSelected, setDistrictSelected] = useState("");
  const [citySelected, setCitySelected] = useState("");
  const [parishSelected, setParishSelected] = useState("");
  const [nationality1Selected, setNationality1Selected] = useState("");
  const [nationality2Selected, setNationality2Selected] = useState("");
  const [naturalnessSelected, setNaturalnessSelected] = useState("");
  const [apportionmentSelected, setApportionmentSelected] = useState("");
  const [isClicked, setIsClicked] = useState(false);

  const { personalData, personalDataDropdown } = useSelector(
    (state) => state.personalReducer
  );

  const handleName = (e) => {
    setFullName(e.target.value);
  };

  const handleCitizenCard = (e) => {
    setCitizenCard(e.target.value);
  };

  const handleArchive = (e) => {
    setArchive(e.target.value);
  };

  const handleEmissionDate = (e) => {
    setEmissionDate(e);
  };

  const handleExpirationDate = (e) => {
    setExpirationDate(e);
  };

  const handlePassport = (e) => {
    setPassaport(e.target.value);
  };

  const handlePassportArchive = (e) => {
    setPassaportArchive(e.target.value);
  };

  const handlePassportEmission = (e) => {
    setPassaportEmissionDate(e);
  };

  const handlePassportExpiration = (e) => {
    setPassaportExpirationDate(e);
  };

  const handleTaxIdNumber = (e) => {
    setTaxIdNumber(e.target.value);
  };

  const handleSocialSecurity = (e) => {
    setSocialSecuryty(e.target.value);
  };

  const handleSocialSecurityDate = (e) => {
    setSocialSecurytyDate(e);
  };

  const handleBirthday = (e) => {
    setBirthDate(e);
  };

  const handleGender = (e) => {
    setGenderSelected(e);
  };

  const handleMaritalStatus = (e) => {
    setMaritalStatusSelected(e);
  };

  const handleDistrict = (e) => {
    setDistrictSelected(e);
  };

  const handleCity = (e) => {
    setCitySelected(e);
  };

  const handleParish = (e) => {
    setParishSelected(e);
  };

  const handleNationality1 = (e) => {
    setNationality1Selected(e);
  };

  const handleNationality2 = (e) => {
    setNationality2Selected(e);
  };

  const handleNaturalness = (e) => {
    setNaturalnessSelected(e);
  };

  const hadleApportinmonet = (e) => {
    setApportionmentSelected(e);
  };

  useEffect(() => {
    if (
      personalData.length > 0 &&
      personalDataDropdown &&
      Object.keys(personalDataDropdown).length > 0
    ) {
      setGenderList(personalDataDropdown.collaboratorCode);
      setMaritalStatusList(personalDataDropdown.maritalStatus);
      setDistrictList(personalDataDropdown.districtCode);
      setNationalityList(personalDataDropdown.nationalityCode);
      setApportionmentList(personalDataDropdown.apportionmentCode);

      let parsihFiltered = personalDataDropdown.parishCode?.filter(
        (e) =>
          e.codeDST == personalData[0].distritCode &&
          e.codeCLH == personalData[0].cityCode &&
          e.value == personalData[0].parishCode
      );

      let cityFiltered = personalDataDropdown.cityCode?.filter(
        (e) =>
          e.code == personalData[0].distritCode &&
          e.value == personalData[0].cityCode
      );
      if (cityFiltered !== undefined && cityFiltered !== null) {
        setCitySelected(cityFiltered[0].label);
      }

      if (parsihFiltered !== undefined && parsihFiltered !== null) {
        setParishSelected(parsihFiltered[0].label);
      }

      setGenderSelected(personalData[0].gender);
      setMaritalStatusSelected(personalData[0].maritalStatus);
      setDistrictSelected(personalData[0].distritCode);

      setNationality1Selected(personalData[0].firstNationality);
      setNationality2Selected(personalData[0].secondNatianality);
      setApportionmentSelected(personalData[0].apportionment);
      setNaturalnessSelected(personalData[0].naturalness);

      setFullName(personalData[0].name);
      setCitizenCard(personalData[0].citizenCard);
      setArchive(personalData[0].archive);
      setPassaport(personalData[0].passaport);
      setPassaportArchive(personalData[0].passaportArchive);
      setTaxIdNumber(personalData[0].taxIdNumber);
      setSocialSecuryty(personalData[0].socialSecurity);

      const checkDate = (date, set) => {
        if (date !== null && date !== undefined) {
          return set(new Date(date));
        }
      };

      checkDate(personalData[0].birthDate, setBirthDate);
      checkDate(personalData[0].emissionDate, setEmissionDate);
      checkDate(personalData[0].expirationDate, setExpirationDate);
      checkDate(personalData[0].passEmissionDate, setPassaportEmissionDate);
      checkDate(personalData[0].passExpirationDate, setPassaportExpirationDate);
      checkDate(personalData[0].socialSecurityDate, setSocialSecurytyDate);
    }
  }, [personalData, personalDataDropdown]);

  useEffect(() => {
    if (personalDataDropdown && Object.keys(personalDataDropdown).length > 0) {
      if (districtSelected) {
        let cityFiltered = personalDataDropdown.cityCode?.filter(
          (e) => e.code == districtSelected
        );
        setCityList(cityFiltered);
      } else {
        setCityList(personalDataDropdown.cityCode);
      }

      if (citySelected && districtSelected) {
        let parsihFiltered = personalDataDropdown.parishCode?.filter(
          (e) => e.codeDST == districtSelected && e.codeCLH == citySelected
        );
        setParishList(parsihFiltered);
      } else {
        setParishList(personalDataDropdown.parishCode);
      }
    }
  }, [citySelected, districtSelected, personalDataDropdown]);

  const OnTakingBolean = (data) => {
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "true";
  }

  return (
    <div className="collaboratoRH__view">
      <ButtonsUpdate OnTakingBolean={OnTakingBolean} />
      <Form>
        <>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="8" lg="8" md="8">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.name`)}
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                type="text"
                value={fullName}
                onChange={handleName}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" lg="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.birthDate`)}
              </Form.Label>
              <DateTimePicker
                value={birthDate}
                onChange={handleBirthday}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className="form-control"
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" lg="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Gender`)}
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2"
                    : "collaboratoRH__Dropdown"
                }
                list={genderList}
                handleSubmit={handleGender}
                defaultValue={genderSelected}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" lg="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.MaritalStatus`)}
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2"
                    : "collaboratoRH__Dropdown"
                }
                list={maritalStatusList}
                handleSubmit={handleMaritalStatus}
                defaultValue={maritalStatusSelected}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="4" style={{ marginBottom: "5px" }}>
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.district`)}
              </Form.Label>
              <SearchableDropDown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2"
                    : "collaboratoRH__Dropdown"
                }
                list={districtList}
                handleSubmit={handleDistrict}
                defaultValue={districtSelected}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4" style={{ marginBottom: "5px" }}>
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.City`)}
              </Form.Label>
              <SearchableDropDown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2"
                    : "collaboratoRH__Dropdown"
                }
                list={cityList}
                handleSubmit={handleCity}
                defaultValue={citySelected}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4" style={{ marginBottom: "5px" }}>
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Parish`)}
              </Form.Label>
              <SearchableDropDown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2"
                    : "collaboratoRH__Dropdown"
                }
                list={parishList}
                handleSubmit={handleParish}
                defaultValue={parishSelected}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Nationality1`)}
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2"
                    : "collaboratoRH__Dropdown"
                }
                list={nationalityList}
                handleSubmit={handleNationality1}
                defaultValue={nationality1Selected}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Nationality2`)}
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2"
                    : "collaboratoRH__Dropdown"
                }
                list={nationalityList}
                handleSubmit={handleNationality2}
                defaultValue={nationality2Selected}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Naturalness`)}
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2"
                    : "collaboratoRH__Dropdown"
                }
                list={nationalityList}
                handleSubmit={handleNaturalness}
                defaultValue={naturalnessSelected}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="3">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.BI/CitizenCard`)}
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={citizenCard}
                onChange={handleCitizenCard}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.IssueDate`)}
              </Form.Label>
              <DateTimePicker
                value={emissionDate}
                onChange={handleEmissionDate}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className="form-control"
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.ExpirationDate`)}
              </Form.Label>
              <DateTimePicker
                value={expiration}
                onChange={handleExpirationDate}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className="form-control"
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="3">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Archive`)}
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={archive}
                onChange={handleArchive}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="3">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Passport`)}
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={passaport}
                onChange={handlePassport}
                disabled={isClicked ? false : true}
              />
            </Col>

            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels__before">
                {t(`portalrh.personalData.IssueDate`)}
              </Form.Label>
              <Form.Label className="rhcollaborator__labels__after">
                {t(`portalrh.personalData.ExpirationDate`)}
              </Form.Label>
              <DateTimePicker
                value={passaportEmissionDate}
                onChange={handlePassportEmission}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className="form-control"
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels__before">
                {t(`portalrh.personalData.ExpirationDate`)}
              </Form.Label>
              <Form.Label className="rhcollaborator__labels__after">
                {t(`portalrh.personalData.ExpirationDate`)}
              </Form.Label>
              <DateTimePicker
                value={passaportExpirationDate}
                onChange={handlePassportExpiration}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className="form-control"
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="3">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Archive`)}
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={passaportArchive}
                onChange={handlePassportArchive}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="3">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.TaxIDNumber`)}
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={taxIdNumber}
                onChange={handleTaxIdNumber}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels__before">
                {t(`portalrh.personalData.SocialSecurity`)}
              </Form.Label>
              <Form.Label className="rhcollaborator__labels__after">
                {t(`portalrh.personalData.SocialSecurity`)}
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={socialSecurity}
                onChange={handleSocialSecurity}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {" "}
                {t(`portalrh.personalData.date`)}
              </Form.Label>
              <DateTimePicker
                value={socialSecurityDate}
                onChange={handleSocialSecurityDate}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className="form-control"
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="3">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Taxoffice`)}
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2"
                    : "collaboratoRH__Dropdown"
                }
                list={apportionmentList}
                handleSubmit={hadleApportinmonet}
                defaultValue={apportionmentSelected}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
        </>
      </Form>
    </div>
  );
};
export default withNamespaces()(RhPersonalData);
