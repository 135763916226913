import { put } from "redux-saga/effects";
import axios from "axios";
import http from "~/utils/config/http";

import {
  doneSendEmail,
  setSendEmailResponse,
  doneSendEmailEndPoint,
  setSendEmailEndPointResponse,
  doneSendSms,
  setSendSmsResponse,
  doneSendSmsOTP,
  setSendSmsOTPResponse,
  donePostInquiry,
  setPostInquiryResponse,
  doneGetInquiry,
  setGetInquiryResponse,
  doneGetCrcDocument,
  doneGetIesDocument,
  setGetCrcDocumentResponse,
  setGetIesDocumentResponse,
  doneCreateAliceUser,
  setAliceUserToken,
  setAliceUserID,
  setAliceReport,
  doneGetAliceReport,
  doneCreateMerchant,
  setMerchant,
  doneGetMerchant,
  setTinkId,
  doneGetTinkId,
  setTinkReport,
  setTinkToken,
  doneGetSignedPdf,
  setSignedPdf,
  doneGetCardInformation,
  setCardInformation,
  doneGetSaml,
  setGetSamlReponse,
  doneGetAtributes,
  setAtributes,
  setAllUsers,
  doneGetAllUsers,
  setUser,
  doneGetUser,
  doneUpdateUser,
  doneGetDocumentPdaInfo,
  setDocumentPdaInfo,
  setAliceLink,
  setAliceProcess,
  setCreateDocumentCoresystem,
  addDocumentCoresystemDone,
  setDocumentCoresystem,
  setAllDocuments,
  doneGetAllDocuments,
  setTriggerLoading,
  setVersionSignPdf,
  doneUpdateDocument,
  setDocument,
  setMindeeIban,
  setMindeeAddress
} from "./actionTypes";
import { activeError, activeSuccess } from "~/components/Messages/actions";
const devLogConsole = require("~/utils/devLog");
/**
 * Service to send email using Unik API
 */
export function* sendEmail({ payload, apiSettingsId }) {
  try {
    devLogConsole("EMAIL NORMAL");
    yield put(setSendEmailResponse(""));
    const resp = yield http.post(
      `/api/onboarding/send_mail/${apiSettingsId}`,
      payload
    );
    yield put(doneSendEmail());
    yield put(setSendEmailResponse(resp.data));
    yield put(activeSuccess("onboarding.general.successEmail"));
  } catch (e) {
    yield put(activeError("onboarding.general.errorEmail"));
    yield put(doneSendEmail());
    yield put(setSendEmailResponse(e));
  }
}

/**
 * Service to send email using EasyPay API (only works within EasyPay network)
 */
export function* sendEmailEndPoint({ payload, apiSettingsId }) {
  try {
    yield put(setSendEmailEndPointResponse(""));
    const testPayload = { name: payload.template, data: payload.data };
    const resp = yield http.post(
      `/api/open/onboarding/send_emailEndpoint/${apiSettingsId}`,
      testPayload
    );
    yield put(doneSendEmailEndPoint());
    yield put(setSendEmailEndPointResponse(resp.data));
    yield put(activeSuccess("onboarding.general.successEmail"));
  } catch (e) {
    yield put(activeError("onboarding.general.errorEmail"));
    yield put(doneSendEmailEndPoint());
    yield put(setSendEmailEndPointResponse(e));
  }
}

/**
 * Service to send sms using EasyPay API (only works within EasyPay network)
 */
export function* sendSmsOTP({ payload, apiSettingsId }) {
  try {
    yield put(setSendSmsOTPResponse(""));
    const resp = yield http.post(
      `/api/open/onboarding/send_smsOTP/${apiSettingsId}`,
      payload
    );
    yield put(doneSendSmsOTP());
    yield put(setSendSmsOTPResponse(resp.data.message));
    if (resp.data === "BAD_REQUEST") {
      yield put(activeError("onboarding.general.errorSms"));
    } else yield put(activeSuccess("onboarding.general.successSms"));
  } catch (e) {
    yield put(activeError("onboarding.general.errorSms"));
    yield put(doneSendSmsOTP());
    yield put(setSendSmsOTPResponse(e));
  }
}

/**
 * Service to send sms using uSendit API
 */
export function* sendSms({ payload, apiSettingsId }) {
  try {
    yield put(setSendSmsResponse(""));
    const resp = yield http.post(
      `/api/onboarding/send_sms/${apiSettingsId}`,
      payload
    );
    yield put(doneSendSms());
    const messageResponse = resp.data
      .split("</string>")
      .shift()
      .split('">')
      .pop();
    if (messageResponse.includes("Schedule Error")) {
      yield put(activeError("onboarding.general.errorSms"));
    } else yield put(activeSuccess("onboarding.general.successSms"));
    yield put(doneSendSms());
    yield put(setSendSmsResponse(messageResponse));
  } catch (e) {
    yield put(activeError("onboarding.general.errorSms"));
    yield put(doneSendSms());
    yield put(setSendSmsResponse(e));
  }
}

/**
 * Dun&Bradstreet Service : Restricted Screening - Submit Inquiry - Individual Name/ Organization Name
 */
export function* postInquiry({ payload, apiSettingsId }) {
  try {
    yield put(setPostInquiryResponse({}));
    const resp = yield http.post(
      `/api/open/onboarding/checkpep/${apiSettingsId}`,
      payload
    );
    yield put(donePostInquiry());
    yield put(setPostInquiryResponse(resp.data));
  } catch (e) {
    yield put(activeError("ERROR! Could not post inquiry!"));
    yield put(donePostInquiry());
    yield put(setPostInquiryResponse(e));
  }
}

/**
 * Dun&Bradstreet Service : Restricted Screening - Get Inquiry with Inquiry ID
 */
export function* getInquiry({ inquiryId }) {
  try {
    yield put(setGetInquiryResponse(""));
    const resp = yield http.get(
      `/api/open/onboarding/get_dnbInquiry/${inquiryId}`
    );
    yield put(doneGetInquiry());
    yield put(setGetInquiryResponse(resp.data));
    yield put(setPostInquiryResponse({}));
    yield put(activeSuccess("Inquiry result received successfully!"));
  } catch (e) {
    yield put(activeError("ERROR! Could not get inquiry result!"));
    yield put(setPostInquiryResponse({}));
    yield put(doneGetInquiry());
    yield put(setGetInquiryResponse(e));
  }
}

/**
 * Service to get a CRC document, using BizAPI
 */
export function* getCrcDocument({ payload, apiSettingsId }) {
  try {
    yield put(setGetCrcDocumentResponse(""));
    const response = yield http.post(
      `/api/open/onboarding/send_crcDocument/${apiSettingsId}`,
      {
        crc: payload.crc,
        client_id: payload.client_id,
      }
    );
    const boundary = response.data.split("\r\n")[0];
    const crcJson = response.data.split(boundary)[1].split("\r\n\r\n").pop();
    const crcPdf = response.data.split(boundary)[2].split("\r\n\r\n").pop();
    yield put(doneGetCrcDocument());
    yield put(
      setGetCrcDocumentResponse({ json: JSON.parse(crcJson), pdf: crcPdf })
    );
    yield put(activeSuccess("onboarding.general.sucessCrcDocument"));
  } catch (e) {
    yield put(activeError("onboarding.general.errorCrcDocument"));
    yield put(doneGetCrcDocument());
    yield put(setGetCrcDocumentResponse(e));
  }
}

/**
 * Service to get a IES document, using BizAPI
 */
export function* getIesDocument({ payload, apiSettingsId }) {
  try {
    yield put(setGetIesDocumentResponse(""));
    const response = yield http.post(
      `/api/open/onboarding/get_ies/${apiSettingsId}`,
      payload
    );
    yield put(doneGetIesDocument());
    yield put(setGetIesDocumentResponse(response.data));
    yield put(activeSuccess("Success! Could get data"));
  } catch (e) {
    yield put(activeError("ERROR! Could not get data!"));
    yield put(doneGetIesDocument());
    yield put(setGetIesDocumentResponse(e));
  }
}

/**
 * Using MOBILE Alice process
 * Service to create Alice new user and retrive Alice user token (necessary to get the report)
 */
export function* getAliceToken({ id, orgId }) {
  try {
    const payload = {
      id: id,
      //id: "a1fb1872072b6dd08a6e2d378814bb07e024a8db0d9ed6d33f0c76162a68b2bf",
      aliceUserID: "aliceUserID",
      encryptedOrgId: orgId,
    };
    const resp = yield http.post(
      `/api/open/onboarding/alice_process_user`,
      payload
    );
    yield put(setAliceUserToken(resp?.data?.token));
    yield put(setAliceUserID(resp?.data?.userID));
    yield put(doneCreateAliceUser());
    yield put(activeSuccess("Alice User created with success!"));
  } catch (e) {
    yield put(activeError("Error! Could not create User!"));
    yield put(doneCreateAliceUser());
  }
}

/**
 * Using MOBILE Alice process
 * Service to get Alice Report and update kyc database
 */
export function* processAliceReport({ aliceUserID, orgId, id }) {
  const payload = {
    id: id,
    aliceUserID: aliceUserID,
    encryptedOrgId: orgId,
  };
  try {
    const resp = yield http.post(
      `/api/open/onboarding/alice_process_report`,
      payload
    );
    //devLogConsole(resp.data);
    yield put(setAliceReport(resp.data));
    yield put(doneGetAliceReport());
  } catch (e) {
    yield put(activeError("Error!"));
    yield put(doneGetAliceReport());
  }
}

/**
 * Using MOBILE Alice process
 * Service to generate Alice link
 */
export function* generateAliceLink({ pid, orgId }) {
  devLogConsole("GERAR ALICE LINK");
  try {
    const resp = yield http.get(
      `/api/onboarding/alice-generate-link?pid=${pid}&orgId=${orgId}`
    );
    yield put(setAliceLink(resp.data));
    localStorage.setItem("aliceProcessLink", resp.data);
  } catch (e) {
    yield put(activeError("Error!"));
    yield put(setAliceLink(null));
  }
}

/**
 * Using MOBILE Alice process
 * Service to get Alice procress
 */
export function* getAliceProcess({ pid }) {
  try {
    const resp = yield http.get(`api/onboarding/alice-process?pid=${pid}`);
    yield put(setAliceProcess(resp.data));
  } catch (e) {
    yield put(activeError("Error!"));
    yield put(setAliceProcess(null));
  }
}

/**
 * Service to create Alice new user and retrive Alice user token (necessary to get the report)
 */
export function* createAliceUser({ payload, apiSettingsId }) {
  try {
    const resp = yield http.post(
      `/api/open/onboarding/alice_user/${apiSettingsId}`,
      payload
    );
    yield put(setAliceUserToken(resp?.data?.token));
    yield put(setAliceUserID(resp?.data?.userID));
    yield put(doneCreateAliceUser());
    yield put(activeSuccess("Alice User created with success!"));
  } catch (e) {
    yield put(activeError("Error! Could not create User!"));
    yield put(doneCreateAliceUser());
  }
}

/**
 * Service to request Alice report for the created user
 */
export function* getAliceReport({ payload }) {
  try {
    const resp = yield http.post("/api/open/onboarding/alice_report", payload);
    yield put(setAliceReport(resp.data));
    yield put(doneGetAliceReport());
    yield put(activeSuccess("Alice report received with success!"));
  } catch (e) {
    yield put(activeError("Error! Could not get Alice report!"));
    yield put(doneGetAliceReport());
  }
}

/**
 * Service to create Easypay merchant
 */
export function* createMerchant({ payload, apiSettingsId }) {
  try {
    yield http.post(
      `/api/open/onboarding/create_merchant/${apiSettingsId}`,
      payload
    );
    yield put(doneCreateMerchant());
    yield put(activeSuccess("Easypay merchant created!"));
  } catch (e) {
    yield put(activeError("Error! Could not create Easypay merchant!"));
    yield put(doneCreateMerchant());
  }
}

/**
 * Service to get Easypay merchant
 */
export function* getMerchant({ payload, apiSettingsId }) {
  try {
    const resp = yield http.post(
      `/api/open/onboarding/get_merchant/${apiSettingsId}`,
      payload
    );
    yield put(
      setMerchant(
        Array.isArray(resp.data?.data) && resp.data.data.length > 0
          ? resp.data.data[0]
          : null
      )
    );
    yield put(doneGetMerchant());
  } catch (e) {
    yield put(activeError("Error! Could not get Easypay merchant!"));
    yield put(doneGetMerchant());
  }
}

/**
 * Service to add documnet to Easypay merchant
 */
export function* addDocumentToMerchant({ payload, apiSettingsId }) {
  try {
    yield http.post(
      `/api/onboarding/merchant/document/${apiSettingsId}`,
      payload
    );
  } catch (e) {}
}

/**
 * Service to get Tink id
 */
export function* getTinkId() {
  try {
    const resp = yield axios.get(
      "https://link.tink.com/1.0/account-check/?client_id=671f1f538d414766902ac0c7dc78c726&redirect_uri=https%3A%2F%2Fconsole.tink.com%2Fcallback&market=PT&locale=pt_PT&test=true"
    );
    yield put(setTinkId(resp.data));
    yield put(doneGetTinkId());
  } catch (e) {
    yield put(activeError("Error! Could not get Tink Id!"));
    yield put(doneGetTinkId());
  }
}

/**
 * Service to get Tink report
 */
export function* getTinkReport({ apiSettingsId, userId }) {
  try {
    const resp = yield http.post(
      `/api/open/onboarding/tink_report/${apiSettingsId}`,
      userId
    );
    yield put(setTinkReport(resp));
  } catch (e) {}
}

/**
 * Service to get Tink Token
 */
export function* getTinkToken({ apiSettingsId }) {
  try {
    const resp = yield http.post(
      `/api/open/onboarding/tink_token/${apiSettingsId}`
    );
    yield put(setTinkToken(resp));
  } catch (e) {}
}

/**
 * Service to digital sign PDF documents using AMA service
 */

export function* getSignedPdfs({ payload, queryParams }) {
  try {
    yield put(setSignedPdf({}));
    const resp = yield axios.post(
      queryParams.stampWithoutSign
        ? `http://localhost:8378/stamp-pdf`
        : `http://localhost:8378/multiSignPdf`,
      payload,
      {
        params: {
          posX: queryParams.posX,
          posY: queryParams.posY,
          pagina: queryParams.pagina,
          ultima: queryParams.ultima,
          visivel: queryParams.visivel,
          reduzido: queryParams.reduzido,
          ...(queryParams.givenLocation && {
            givenLocation: queryParams.givenLocation,
          }),
          ...(queryParams.givenReason && {
            givenReason: queryParams.givenReason,
          }),
          ...(queryParams.additionalText && {
            additionalText: queryParams.additionalText,
            boxLowerLeftX: queryParams.boxLowerLeftX,
            boxLowerLeftY: queryParams.boxLowerLeftY,
            boxHigerLeftX: queryParams.boxHigerLeftX,
            boxHigherLeftY: queryParams.boxHigherLeftY,
            boxLowerRightX: queryParams.boxLowerRightX,
            boxLowerRightY: queryParams.boxLowerRightY,
          }),
          stampAll: queryParams.stampAll,
          stampWithoutSign: queryParams.stampWithoutSign,
          isCustomLogo: queryParams.isCustomLogo,
        },
        headers: { "Content-Type": "application/json" },
      }
    );
    yield put(doneGetSignedPdf());
    yield put(setSignedPdf(resp.data));
  } catch (e) {
    yield put(
      activeError(
        e.response?.data?.error
          ? "signature.errorSign"
          : e.response?.data || "signature.errorSign"
      )
    );
    yield put(doneGetSignedPdf());
  }
}

/**
 * Service to get signpdf version
 */
export function* getVersionSignPdf() {
  try {
    //yield put(setVersionSignPdf(""));
    const resp = yield axios.get(`http://localhost:8378/check-auth-version`);
    yield put(setVersionSignPdf(resp.data));
  } catch (e) {
    const { response, message } = e;
    if (message === "Network Error") {
      yield put(setVersionSignPdf({ data: "Network Error" }));
    } else yield put(setVersionSignPdf(response));
  }
}

/**
 * Service to extract Card information
 */
export function* getCardInformation() {
  try {
    yield put(setCardInformation({}));
    const resp = yield axios.get(`http://localhost:8378/getInformation`);
    yield put(doneGetCardInformation());
    yield put(setCardInformation(resp.data));
    yield put(activeSuccess("Data extracted successfully!"));
  } catch (e) {
    yield put(
      activeError(e.response?.data || "ERROR! COULD NOT GET CARD INFORMATION")
    );
    yield put(doneGetCardInformation());
  }
}

/**
 * Service to get SAML Request
 */
export function* getSaml({ payload }) {
  try {
    yield put(setGetSamlReponse(""));
    const resp = yield http.post(`/api/open/onboarding/cmd`, payload);
    yield put(setGetSamlReponse(resp?.data?.SAMLRequest));
    yield put(doneGetSaml());
  } catch (e) {
    yield put(activeError("onboarding.general.errorGetSaml"));
    yield put(doneGetSaml());
    yield put(setGetSamlReponse(""));
  }
}

/**
 * Service to get SAML Atributes in mongo by id
 */
export function* getAtributes({ queryParams }) {
  try {
    yield put(setAtributes(""));
    const resp = yield http.get(`/api/open/onboarding/cmd-user`, {
      params: {
        ...queryParams,
      },
    });
    yield put(setAtributes(resp?.data));
    yield put(doneGetAtributes());
    yield put(activeSuccess("onboarding.general.successGetAtributes"));
  } catch (e) {
    yield put(activeError("onboarding.general.errorGetAtributes"));
    yield put(doneGetAtributes());
    yield put(setAtributes(""));
  }
}

/**
 * Gets all Documents
 */
export function* getAllDocuments() {
  try {
    const resp = yield http.get(`/api/back-office/get/documents`);
    /* const token = "ef420f20-bd0b-48dd-bfc9-d63db504113b";
    const resp = yield axios.get(
      `https://onboarding.easypay.pt/uniksystem/api/back-office/user/get`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ); */
    yield put(setAllDocuments(resp?.data));
    yield put(doneGetAllDocuments());
  } catch (e) {
    yield put(doneGetAllDocuments());
    yield put(setAllDocuments([]));
  }
}

/**
 * Gets all Leads/Onboarding
 */
export function* getAllUsers() {
  try {
    const resp = yield http.get(`/api/back-office/user/get`);
    /* const token = "ef420f20-bd0b-48dd-bfc9-d63db504113b";
    const resp = yield axios.get(
      `https://onboarding.easypay.pt/uniksystem/api/back-office/user/get`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ); */
    yield put(setAllUsers(resp?.data));
    yield put(doneGetAllUsers());
  } catch (e) {
    yield put(doneGetAllUsers());
    yield put(setAllUsers([]));
  }
}

/**
 * Gets user Lead/Onboarding depending on id
 */
export function* getUser({ id }) {
  try {
    const resp = yield http.get(`/api/back-office/user/get`, {
      params: { pid: id },
    });
    devLogConsole(resp?.data);
    /*  const token = "ef420f20-bd0b-48dd-bfc9-d63db504113b";
    const resp = yield axios.get(
      `https://onboarding.easypay.pt/uniksystem/api/back-office/user/get`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { pid: id },
      }
    ); */
    yield put(setUser(resp?.data));
    yield put(doneGetUser());
  } catch (e) {
    yield put(doneGetUser());
    yield put(setUser([]));
  }
}

/**
 * Updates user
 */
export function* updateUser({ user }) {
  try {
    yield http.post(`/api/back-office/user/save`, user.user);
    /* const token = "ef420f20-bd0b-48dd-bfc9-d63db504113b";
    yield axios.post(
      `https://onboarding.easypay.pt/uniksystem/api/back-office/user/save`,
      user,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ); */
    yield put(doneUpdateUser());
    setTriggerLoading(true);
    const resp = yield http.get(`/api/back-office/user/get`, {
      params: { pid: user.pid },
    });
    devLogConsole(resp?.data);
    /*  const token = "ef420f20-bd0b-48dd-bfc9-d63db504113b";
    const resp = yield axios.get(
      `https://onboarding.easypay.pt/uniksystem/api/back-office/user/get`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { pid: id },
      }
    ); */
    yield put(setUser(resp?.data));
    yield put(doneGetUser());
  } catch (e) {
    yield put(doneUpdateUser());
    yield put(setUser([]));
  }
}

/**
 * Updates user
 */
export function* updateDocument({ document }) {
  try {
    const resp = yield http.post(`/api/back-office/save/document`, {
      idDocumento: document.id,
      estadoDocumento: document.estadoDocumento,
    });
    devLogConsole(resp?.data);
    yield put(setDocument(resp?.data));
    yield put(doneUpdateDocument());
  } catch (e) {
    devLogConsole(e);
    yield put(doneUpdateDocument());
    //yield put(setUser([]));
  }
}

/**
 * Gets document metadata depending on id from easypay
 */
export function* getDocumentMetadata({ id }) {
  try {
    //const resp = yield http.get(`/api/back-office/user/get`, {params: {pid: id}});
    const token = "ef420f20-bd0b-48dd-bfc9-d63db504113b";
    const resp = yield axios.get(
      `https://onboarding.easypay.pt/uniksystem/api/back-office/user/get`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { pid: id },
      }
    );
    yield put(setUser(resp?.data));
    yield put(doneGetUser());
  } catch (e) {
    yield put(doneGetUser());
    yield put(setUser([]));
  }
}

/**
 * Get document with Workplan ID
 */
export function* getDocumentByPid({ queryParams }) {
  try {
    const resp = yield http.get(
      `/api/back-office/get/pdaLink?pid=${queryParams.pid}`
    );
    //devLogConsole(resp?.data);
    yield put(setDocumentPdaInfo(resp?.data));
    yield put(doneGetDocumentPdaInfo());
  } catch (e) {
    yield put(setDocumentPdaInfo([]));
    yield put(doneGetDocumentPdaInfo());
  }
}

/*Coresystem start*/
export function* createDocumentCoresystem({ payload, apiSettingsId }) {
  try {
    const resp = yield http.post(
      `/api/open/onboarding/create-document/${apiSettingsId}`,
      payload
    );
    yield put(setCreateDocumentCoresystem(resp.data));
  } catch (e) {
    yield put(setCreateDocumentCoresystem(null));
  }
}

export function* addDocumentCoresystem({ document, apiSettingsId }) {
  try {
    const resp = yield http.post(
      `/api/open/onboarding/add-document/${apiSettingsId}`,
      document
    );
    yield put(addDocumentCoresystemDone(resp.data));
  } catch (e) {
    yield put(addDocumentCoresystemDone(null));
  }
}

export function* getDocumentCoresystem({ id, apiSettingsId }) {
  try {
    const resp = yield http.post(
      `/api/open/onboarding/get-document/${apiSettingsId}?frontend=true`,
      { information: id }
    );

    yield put(setDocumentCoresystem(resp?.data));
  } catch (e) {
    yield put(setDocumentCoresystem(null));
  }
}
/*Coresystem end*/

export function* addMindee({ document, tabKey, apiSettingsId }) {
  const headers = {
    'Content-Type': 'multipart/form-data', // Defina o cabeçalho FormData
  };
  if (tabKey === "mindeeIban") {
    try {
      const formData = new FormData();
      formData.append("file", document);

      const resp = yield axios.post(
        `uniksystem/api/open/onboarding/mindee/iban/${apiSettingsId}`,
        formData,
        { headers } 
      );
      yield put(setMindeeIban(resp));
    } catch (e) {
      yield put(setMindeeIban(null));
    }
  }
  if(tabKey == "mindeeMorada"){
      try {
        const formData = new FormData();
        formData.append("file", document);
  
        const resp = yield axios.post(
          `uniksystem/api/open/onboarding/mindee/adress-proof/${apiSettingsId}`,
          formData,
          { headers } 
        );
        yield put(setMindeeAddress(resp));
      } catch (e) {
        yield put(setMindeeAddress(null));
      }
    }
}
