import React, { useCallback, useEffect, useState } from "react";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";
import "../../RhCollaborator/RhCollaborator.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getCollaborators,
  getScheduler,
  getExportedTable,
  setExportVals,
} from "~/store/ducks/portalrh/actionTypes";
import TabelaScheduler from "../TabelaScheduler";
import { getIflowFormdata } from "store/ducks/processes/actionTypes";
import Sidebar from "../../RhCollaborator/Sidebar";
import { v4 as uuidv4 } from "uuid";
import { withNamespaces } from "react-i18next";
import { Card, Row, Col, Tabs, Tab, Form } from "react-bootstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "~/assets/css/scheduler.css";
import Dropdown from "~/components/Dropdown";
import moment from "moment";
import {
  colors,
  iflowConstants,
  variablesEvents,
  navigate,
} from "./ColorVariables";
import Spinner from "~/components/Spinner";
import { Button, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import oauth from "~/utils/oauth";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  FaCalendarDay,
  FaCalendarWeek,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaCircle,
  FaCalendarPlus,
  FaListUl,
  FaCheckCircle,
  FaTimesCircle,
  FaClock,
  FaSearchMinus,
  FaCalendarAlt,
  FaPrint,
} from "react-icons/fa";
import { FaFileExport } from "react-icons/fa6";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import FlowModal from "~/components/FlowModal";
import {
  postIflowForm,
  setIflowPidScheduler,
} from "~/store/ducks/processes/actionTypes";

import { getOrganizationById } from "~/store/ducks/organizations/actionTypes";
import { getRHBolsaHorasConfiguration } from "~/store/ducks/rhadminConfiguration/actionTypes";

const AlertMaterial = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const devLogConsole = require("~/utils/devLog");

const SchedulerComponent = ({
  portalRhMenus,
  designacao,
  path,
  t,
  isEditable,
  showCalendarAndTable,
  loadEventsStart,
  setLoadEventsStart,
  selectedUser,
  setSelectedUser,
  grupos,
  setgrupos,
  activeButton,
  setActiveButton,
}) => {
  const { user } = useSelector((state) => state.globalReducer);
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { iflowpid } = useSelector((state) => state.processesReducer);
  const { rhbolsaHorasConfiguration } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );
  const {
    collaborators,
    scheduler,
    isLoadingScheduler,
    exportMsg,
    isLoadingExport,
  } = useSelector((state) => state.portalrhReducer);
  const { rhbolsaHorasUsersGroup } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );
  const { organization } = useSelector((state) => state.organizationsReducer);

  const [menuSideBar, setMenuSideBar] = useState(null);
  const [selectedLocale, setSelectedLocale] = useState(userSettings.language);
  const [forceUpdate, setForceUpdate] = useState(0);

  const [events, setEvents] = useState(undefined);
  const [localizer, setLocalizer] = useState(momentLocalizer(moment));
  const [dateSelected, setDateSelected] = useState(undefined);
  const [scheduler_hours, setscheduler_hours] = useState({});
  const [showFlowModal, setShowFlowModal] = useState(false);
  const [defaultView, setDefaultView] = useState("week");
  const [flowIdSelected, setFlowIdSelected] = useState(null);
  const [cleanDropdown, setCleanDropdown] = useState(false);
  const [saldos_hours, setSaldos_hours] = useState([]);
  const [insencao_hours, setInsencao_hours] = useState([]);
  const [picagens_Exists, setPicagens_Exists] = useState([]);
  const [monthSelected, setMonthSelected] = useState(undefined);
  const [openExport, setOpenExport] = useState(false);
  const [messageTranslated, setmessageTranslated] = useState("");
  const [openExportError, setOpenExportError] = useState(false);
  const [openExportInfo, setOpenExportInfo] = useState(false);

  const [key, setKey] = useState(designacao);

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEditable) {
      handleClearFilter();
    }
  }, []);

  useEffect(() => {
    if (exportMsg !== "") {
      if (exportMsg === "error") {
        let messagetrans = t("portalrh.scheduler.exportTableError");
        setmessageTranslated(messagetrans);
        setOpenExportError(true);
      } else {
        let messagetrans = t("portalrh.scheduler.exportTableSuccess");
        setmessageTranslated(messagetrans);
        setOpenExport(true);
      }
    }
  }, [exportMsg]);

  useEffect(() => {
    if (Object.keys(scheduler).length > 0) {
      setscheduler_hours(scheduler);
      setSaldos_hours(scheduler.saldosBolsaHoras);
      setInsencao_hours(scheduler.isenção);
      setPicagens_Exists(scheduler.semPicagens);
    } else {
      setscheduler_hours([]);
      setSaldos_hours([]);
      setInsencao_hours([]);
      setPicagens_Exists([]);
    }
  }, [scheduler]);

  useEffect(() => {
    dispatch(getCollaborators());
    portalRhMenus.map((menu) => {
      if (menu.designacao == designacao) {
        setMenuSideBar(menu);
      }
    });
  }, [dispatch]);

  const userNamePortalRH = collaborators.find((collaborator) => {
    return user.legacyUsers.some((legacyUser) => {
      return (
        legacyUser.username === collaborator.username &&
        legacyUser.legacyApplication.label === "portalrh"
      );
    });
  });

  useEffect(() => {
    //if (Object.keys(scheduler_hours).length > 0) {
    const supportedLanguages = ["es", "pt"];
    if (userSettings != undefined) {
      if (supportedLanguages.includes(selectedLocale)) {
        require(`moment/locale/${selectedLocale}.js`);
        moment.locale(selectedLocale);
        moment.updateLocale(selectedLocale, {
          week: {
            dow: 0,
          },
        });
        setLocalizer(momentLocalizer(moment));
      } else {
        moment.locale("en");
        setLocalizer(momentLocalizer(moment));
      }
      setSelectedLocale(userSettings.language);
      //if (userNamePortalRH != undefined) getDatesFromDays(dateSelected);
    }
    hideCalendar();
    // }
  }, [userSettings.language, selectedLocale, scheduler_hours]);

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "default";
    setDateSelected(new Date());
  }, []);

  useEffect(() => {
    getDatesFromDays();
  }, [userNamePortalRH, isEditable]);

  useEffect(() => {
    if (selectedUser != undefined) {
      getDatesFromDays(dateSelected);
    }
  }, [selectedUser]);

  const getColorForEventType = (eventType) => {
    const colorObj = colors.find((color) => color.eventType === eventType);
    return colorObj ? colorObj.color : "#f2f2f2";
  };

  function convertToEvents(data, color, type, rightleft) {
    return data.map((item) => {
      const startUtc = new Date(item.begin);
      const endUtc = new Date(item.end);
      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(startUtc.getTime() - offset * 60 * 1000);
      const endLocal = new Date(endUtc.getTime() - offset * 60 * 1000);

      return {
        id: uuidv4(),
        codId: item.id,
        title: t(`portalrh.scheduler.${item.descricao}`),
        start: startUtc,
        end: endUtc,
        color: color,
        allDay: item.allDay,
        type: type,
        right: rightleft ? "0%" : "",
        left: rightleft ? "0%" : "",
      };
    });
  }

  function convertToEventsJustificacao(data, color, type) {
    const consolidatedEvents = [];
    data.map((item) => {
      if (item.allDay) {
        const date = moment(item.data);
        date.set({ hour: 8, minute: 0, second: 0 });
        const startUtc = date.clone();
        const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

        const offset = new Date().getTimezoneOffset();
        const startLocal = new Date(
          startUtc.toDate().getTime() - offset * 60 * 1000
        );
        const endLocal = new Date(
          endUtc.toDate().getTime() - offset * 60 * 1000
        );
        consolidatedEvents.push({
          id: uuidv4(),
          codId: item.id,
          title: t(`portalrh.scheduler.${item.descricao}`),
          start: startLocal,
          end: endLocal,
          color: color,
          allDay: item.allDay,
          type: type,
          justificacao: item.justificacao,
          state: item.estado,
        });
      } else {
        item.justificacao.map((itemJust) => {
          const startUtc = new Date(itemJust.begin);
          const endUtc = new Date(itemJust.end);
          const offset = new Date().getTimezoneOffset();
          const startLocal = new Date(startUtc.getTime() - offset * 60 * 1000);
          const endLocal = new Date(endUtc.getTime() - offset * 60 * 1000);
          consolidatedEvents.push({
            id: uuidv4(),
            codId: itemJust.id,
            title: t(`portalrh.scheduler.${item.descricao}`),
            start: startUtc,
            end: endUtc,
            color: color,
            type: type,
            state: itemJust.estado,
            idRegisto: itemJust.idRegisto,
          });
        });
      }
    });
    return consolidatedEvents;
  }

  function convertToEventsFeriados(data, color, type) {
    return data.map((item) => {
      const date = moment(item.data);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);

      return {
        id: uuidv4(),
        title: item.obs,
        start: startLocal,
        end: endLocal,
        color: color,
        allDay: "true",
        type: type,
      };
    });
  }

  function convertToEventsFerias(data, color, type) {
    return data.map((item) => {
      const date = moment(item);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      return {
        id: uuidv4(),
        title: t(`portalrh.scheduler.Ferias`),
        start: startLocal,
        end: endLocal,
        color: color,
        type: type,
      };
    });
  }

  function convertToEventsBolsaHora(data, color, type) {
    return data.map((item) => {
      const date = moment(item.data);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);

      return {
        id: uuidv4(),
        title: item.numHoras + "h " + t(`portalrh.scheduler.${item.descricao}`),
        start: startLocal,
        end: endLocal,
        color: color,
        allDay: "true",
        type: type,
        bolsa: item.bolsa,
        state: item.estado,
      };
    });
  }

  useEffect(() => {
    if (Object.keys(scheduler_hours).length > 0) {
      const horario = convertToEvents(
        scheduler_hours.horario,
        getColorForEventType(variablesEvents.HORARIO),
        variablesEvents.HORARIO,
        true
      );
      const registoPresenca = convertToEvents(
        scheduler_hours.registoPresenca,
        getColorForEventType(variablesEvents.REGISTO_PRESENCA),
        variablesEvents.REGISTO_PRESENCA,
        true
      );
      const justificacao = convertToEventsJustificacao(
        scheduler_hours.justificacao,
        getColorForEventType(variablesEvents.JUSTIFICACAO),
        variablesEvents.JUSTIFICACAO
      );
      const feriados = convertToEventsFeriados(
        scheduler_hours.feriados,
        getColorForEventType(variablesEvents.FERIADOS),
        variablesEvents.FERIADOS
      );
      const bolsaDeHoras = convertToEventsBolsaHora(
        scheduler_hours.bolsaDeHoras,
        getColorForEventType(variablesEvents.BOLSA_HORAS),
        variablesEvents.BOLSA_HORAS
      );
      const ferias = convertToEventsFerias(
        scheduler_hours.ferias,
        getColorForEventType(variablesEvents.FERIAS),
        variablesEvents.FERIAS
      );

      setEvents([
        ...horario,
        ...registoPresenca,
        ...justificacao,
        ...bolsaDeHoras,
        ...feriados,
        ...ferias,
      ]);
    } else {
      if (!isEditable) setLoadEventsStart(false);
    }
  }, [scheduler_hours]);

  useEffect(() => {
    hideCalendar();
  }, [key, designacao, scheduler]);

  const hideCalendar = () => {
    const timeViewElements = document.getElementsByClassName("rbc-time-view");
    const legendaElement = document.getElementById("legenda");
    if (key != designacao) {
      Array.from(timeViewElements).forEach((element) => {
        element.style.display = "none";
      });
      if (legendaElement) {
        legendaElement.style.display = "none";
      }
    } else {
      Array.from(timeViewElements).forEach((element) => {
        element.style.display = "flex";
      });
      if (legendaElement) {
        legendaElement.style.display = "contents";
      }
      setForceUpdate((prevKey) => prevKey + 1);
    }
  };

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
    },
    [setEvents]
  );
  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }

      setEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, allDay }];
      });
    },
    [setEvents]
  );

  const dayPropGetter = useCallback(
    (date) => {
      const dateFerias = (scheduler_hours?.ferias || []).map((item) =>
        moment(item).format("YYYY-MM-DD")
      );
      const dateFeriados = (scheduler_hours?.feriados || []).map((item) =>
        moment(item.data).format("YYYY-MM-DD")
      );
      const dateKey = moment(date).format("YYYY-MM-DD");
      const isSunday = moment(date).day() === 0;
      const isSaturday = moment(date).day() === 6;
      const isDifferentMonth = moment(date).month() + 1 !== monthSelected;
      if (dateFerias.includes(dateKey) || dateFeriados.includes(dateKey)) {
        return {
          style: {
            backgroundColor: "#ededed",
            color: "#666",
          },
        };
      }
      if (isSunday || isSaturday || isDifferentMonth) {
        return {
          style: {
            backgroundColor: "#ededed",
            color: "#666",
          },
        };
      }
      return {};
    },
    [scheduler_hours]
  );

  const popoverProps = {
    style: {
      backgroundColor: "#f0f0f0",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
  };
  const getDatesFromDays = (date) => {
    let currentDate = undefined;
    if (date) {
      setDateSelected(date);
      currentDate = new Date(date);
    } else {
      currentDate = new Date();
    }
    const currentDay = currentDate.getDay();
    const weekStartDay = 0;
    const daysUntilWeekStart = (7 + currentDay - weekStartDay) % 7;
    // const datePrevious = new Date(currentDate);
    // datePrevious.setDate(currentDate.getDate() - daysUntilWeekStart);

    const daysUntilWeekEnd = (6 - daysUntilWeekStart) % 7;
    // const dateNext = new Date(currentDate);
    // dateNext.setDate(currentDate.getDate() + daysUntilWeekEnd);

    const datePrevious = moment(currentDate).startOf("month").toDate();
    const dateNext = moment(currentDate).endOf("month").toDate();
    if (moment(currentDate).month() + 1 !== monthSelected) {
      setMonthSelected(moment(currentDate).month() + 1);
    }
    if (selectedUser != undefined) {
      const payload = {
        dataInicio: datePrevious,
        dataFim: dateNext,
        usrId: selectedUser.id,
        entidade: selectedUser.empCode,
        numFunc: selectedUser.numFuncionario,
      };
      dispatch(getScheduler(payload));
    } else {
      if (userNamePortalRH != undefined) {
        const payload = {
          dataInicio: datePrevious,
          dataFim: dateNext,
          usrId: userNamePortalRH.id,
          entidade: userNamePortalRH.codEntidade,
          numFunc: userNamePortalRH.funcionarioNum,
        };
        dispatch(getScheduler(payload));
      }
    }
  };

  const getStateValue = (state) => {
    let tooltip = "";
    switch (state) {
      case "1":
        tooltip = t("portalrh.scheduler.openState");
        break;
      case "2":
        tooltip = t("portalrh.scheduler.pendingState");
        break;
      case "3":
        tooltip = t("portalrh.scheduler.acceptState");
        break;
      case "4":
        tooltip = t("portalrh.scheduler.rejectedState");
        break;
      default:
        tooltip = t("portalrh.scheduler.openState");
        break;
    }
    return tooltip;
  };

  const constructTooltip = (event) => {
    if (
      event.type === variablesEvents.FERIAS ||
      event.type === variablesEvents.FERIADOS
    ) {
      return event.title;
    } else if (event.allDay || event.allDay === "true") {
      if (
        event.type === variablesEvents.BOLSA_HORAS &&
        Array.isArray(event.bolsa)
      ) {
        let arr = [];
        event.bolsa.forEach((entry) => {
          let dataStart = new Date(entry.begin);
          let dataEnd = new Date(entry.end);
          arr.push(
            getStateValue(entry.estado) +
              " das " +
              getHoraEvento(dataStart).hora +
              ":" +
              getHoraEvento(dataStart).minuto +
              "-" +
              getHoraEvento(dataEnd).hora +
              ":" +
              getHoraEvento(dataEnd).minuto
          );
        });
        return arr.join("\n");
      }
      if (
        event.type === variablesEvents.JUSTIFICACAO &&
        Array.isArray(event.justificacao)
      ) {
        let arr = [];
        event.justificacao.forEach((entry) => {
          let dataStart = new Date(entry.begin);
          let dataEnd = new Date(entry.end);
          arr.push(
            getStateValue(entry.estado) +
              " das " +
              getHoraEvento(dataStart).hora +
              ":" +
              getHoraEvento(dataStart).minuto +
              "-" +
              getHoraEvento(dataEnd).hora +
              ":" +
              getHoraEvento(dataEnd).minuto
          );
        });
        return arr.join("\n");
      }
    } else if (event.state) {
      return (
        getStateValue(event.state) +
        " das " +
        getHoraEvento(new Date(event.start)).hora +
        ":" +
        getHoraEvento(new Date(event.start)).minuto +
        "-" +
        getHoraEvento(new Date(event.end)).hora +
        ":" +
        getHoraEvento(new Date(event.end)).minuto
      );
    } else {
      return `${getHoraEvento(event.start).hora}:${
        getHoraEvento(event.start).minuto
      } - ${getHoraEvento(event.end).hora}:${getHoraEvento(event.end).minuto} ${
        event.title
      }`;
    }
  };

  const schedulerContrutor = () => {
    return (
      <div key={forceUpdate}>
        <>
          {isEditable ? (
            <Calendar
              events={events}
              localizer={localizer}
              dayPropGetter={dayPropGetter}
              date={new Date(dateSelected)}
              //dayLayoutAlgorithm="no-overlap"
              defaultView={defaultView}
              views={["week", "day", "month"]}
              toolbar={true}
              max={moment(new Date().setHours(20, 0, 0, 0)).toDate()}
              min={moment(new Date().setHours(8, 0, 0, 0)).toDate()}
              startAccessor="start"
              endAccessor="end"
              selectable
              onNavigate={getDatesFromDays}
              onView={(e) => setDefaultView(e)}
              onSelectSlot={onSelectSlot}
              onSelectEvent={onSelectEvent}
              tooltipAccessor={constructTooltip}
              popoverProps={popoverProps}
              onEventDrop={moveEvent}
              onEventResize={resizeEvent}
              popup
              resizable
              selected={true}
              formats={{ eventTimeRangeFormat: () => null }}
              eventPropGetter={(myEventsList) => {
                const border = myEventsList.color
                  ? "2px solid " + myEventsList.color
                  : "blue";
                const opacity = 0.3;

                if (
                  myEventsList.type === variablesEvents.HORARIO ||
                  myEventsList.type === variablesEvents.REGISTO_PRESENCA
                ) {
                  return {
                    className:
                      myEventsList.type === variablesEvents.HORARIO
                        ? "horarioEventClass"
                        : myEventsList.type === variablesEvents.REGISTO_PRESENCA
                        ? "registoEventClass"
                        : "",
                    style: {
                      border,
                      cursor: "default",
                      color: myEventsList.color || "blue",
                    },
                  };
                }
                if (
                  myEventsList.type === variablesEvents.FERIAS ||
                  myEventsList.type === variablesEvents.FERIADOS
                ) {
                  const backgroundColor = myEventsList.color
                    ? `${myEventsList.color}${Math.floor(
                        opacity * 255
                      ).toString(16)}`
                    : "blue";
                  return {
                    style: {
                      border,
                      zIndex: "200",
                      color: "rgb(102, 102, 102)",
                      backgroundColor,
                    },
                  };
                }
                const backgroundColor = myEventsList.color
                  ? `${myEventsList.color}${Math.floor(opacity * 255).toString(
                      16
                    )}`
                  : "blue";
                return {
                  style: {
                    border,
                    zIndex: "200",
                    color: myEventsList.color || "blue",
                    backgroundColor,
                  },
                };
              }}
              components={{
                event: Event,
                toolbar: CustomToolbar,
              }}
            />
          ) : (
            <Calendar
              events={!loadEventsStart ? undefined : events}
              localizer={localizer}
              dayPropGetter={dayPropGetter}
              date={new Date(dateSelected)}
              //dayLayoutAlgorithm="no-overlap"
              defaultView={defaultView}
              views={["week", "day", "month"]}
              toolbar={true}
              max={moment(new Date().setHours(20, 0, 0, 0)).toDate()}
              min={moment(new Date().setHours(8, 0, 0, 0)).toDate()}
              startAccessor="start"
              endAccessor="end"
              onNavigate={getDatesFromDays}
              onView={(e) => setDefaultView(e)}
              tooltipAccessor={constructTooltip}
              popoverProps={popoverProps}
              selected={false}
              formats={{ eventTimeRangeFormat: () => null }}
              eventPropGetter={(myEventsList) => {
                const border = myEventsList.color
                  ? "2px solid " + myEventsList.color
                  : "blue";
                const opacity = 0.3;

                if (
                  myEventsList.type === variablesEvents.HORARIO ||
                  myEventsList.type === variablesEvents.REGISTO_PRESENCA ||
                  myEventsList.type === variablesEvents.FERIAS ||
                  myEventsList.type === variablesEvents.FERIADOS
                ) {
                  return {
                    className:
                      myEventsList.type === variablesEvents.HORARIO
                        ? "horarioEventClass"
                        : myEventsList.type === variablesEvents.REGISTO_PRESENCA
                        ? "registoEventClass"
                        : "",
                    style: {
                      border,
                      cursor: "default",
                      color: myEventsList.color || "blue",
                    },
                  };
                }

                const backgroundColor = myEventsList.color
                  ? `${myEventsList.color}${Math.floor(opacity * 255).toString(
                      16
                    )}`
                  : "blue";
                return {
                  style: {
                    border,
                    zIndex: "200",
                    color: myEventsList.color || "blue",
                    backgroundColor,
                  },
                };
              }}
              components={{
                event: Event,
                toolbar: CustomToolbar,
              }}
            />
          )}
          {key == designacao &&
            loadEventsStart &&
            saldos_hours != undefined &&
            saldos_hours.length > 0 && (
              <Stack
                id="saldoHoras"
                direction="row"
                alignItems="center"
                spacing={2}
                margin="20px"
                display="contents"
              >
                <p style={{ float: "right" }}>
                  {t("portalrh.scheduler.horaFinal")}{" "}
                  {saldos_hours[0].saldoFinal}
                </p>
                <br />
              </Stack>
            )}
          <Stack
            id="legenda"
            direction="row"
            alignItems="center"
            spacing={2}
            margin="20px"
            display="contents"
          >
            <ColorButtonDescricao
              size="small"
              variant="text"
              startIcon={
                <FaCircle
                  color={getColorForEventType(variablesEvents.HORARIO)}
                />
              }
            >
              {t(`portalrh.scheduler.horario`)}
            </ColorButtonDescricao>
            <ColorButtonDescricao
              size="small"
              variant="text"
              startIcon={
                <FaCircle
                  color={getColorForEventType(variablesEvents.REGISTO_PRESENCA)}
                />
              }
            >
              {t(`portalrh.scheduler.registoPresenca`)}
            </ColorButtonDescricao>
            <ColorButtonDescricao
              size="small"
              variant="text"
              startIcon={
                <FaCircle
                  color={getColorForEventType(variablesEvents.JUSTIFICACAO)}
                />
              }
            >
              {t(`portalrh.scheduler.justificacao`)}
            </ColorButtonDescricao>
            <ColorButtonDescricao
              size="small"
              variant="text"
              startIcon={
                <FaCircle
                  color={getColorForEventType(variablesEvents.BOLSA_HORAS)}
                />
              }
            >
              {t(`portalrh.scheduler.bolsaHoras`)}
            </ColorButtonDescricao>
            <ColorButtonDescricao
              size="small"
              variant="text"
              startIcon={
                <FaCircle
                  color={getColorForEventType(variablesEvents.FERIADOS)}
                />
              }
            >
              {t(`portalrh.scheduler.feriados`)}
            </ColorButtonDescricao>
          </Stack>
        </>
      </div>
    );
  };

  const buildtooltipDependingState = (event) => {
    if (event.state === "1") {
      return (
        `${getHoraEvento(event.start).hora}:${
          getHoraEvento(event.start).minuto
        } - ${getHoraEvento(event.end).hora}:${
          getHoraEvento(event.end).minuto
        } ` + t("portalrh.scheduler.openState")
      );
    }
    if (event.state === "2") {
      return (
        `${getHoraEvento(event.start).hora}:${
          getHoraEvento(event.start).minuto
        } - ${getHoraEvento(event.end).hora}:${
          getHoraEvento(event.end).minuto
        } ` + t("portalrh.scheduler.pendingState")
      );
    }
    if (event.state === "3") {
      return (
        `${getHoraEvento(event.start).hora}:${
          getHoraEvento(event.start).minuto
        } - ${getHoraEvento(event.end).hora}:${
          getHoraEvento(event.end).minuto
        } ` + t("portalrh.scheduler.acceptState")
      );
    }
    if (event.state === "4") {
      return (
        `${getHoraEvento(event.start).hora}:${
          getHoraEvento(event.start).minuto
        } - ${getHoraEvento(event.end).hora}:${
          getHoraEvento(event.end).minuto
        } ` + t("portalrh.scheduler.rejectedState")
      );
    }
  };

  function getHoraEvento(dataString) {
    const data = new Date(dataString);
    const hora = data.getHours();
    const minuto = data.getMinutes();

    const horaFormatada = hora.toString().padStart(2, "0");
    const minutoFormatado = minuto.toString().padStart(2, "0");

    return { hora: horaFormatada, minuto: minutoFormatado };
  }

  function Event({ event }) {
    const endDateEvent = getHoraEvento(event.end);
    const startDateEvent = getHoraEvento(event.start);
    if (event.allDay == "true") {
      return (
        <div>
          <span
            className="eventSpan"
            style={{ display: "inline-flex", marginTop: "2px" }}
          >
            {event.state && createEventImageDependingState(event.state)}
            {event.title}
          </span>
        </div>
      );
    }
    if (event.type == "ferias" || event.type == "feriados") {
      return (
        <div>
          <span
            className="eventSpan"
            style={{ display: "inline-flex", marginTop: "2px" }}
          >
            {event.title}
          </span>
        </div>
      );
    }
    return (
      <div>
        <span
          className="eventSpan"
          style={{ display: "inline-flex", marginTop: "2px" }}
        >
          {event.state && createEventImageDependingState(event.state)}
          {startDateEvent.hora + ":" + startDateEvent.minuto} -{" "}
          {endDateEvent.hora + ":" + endDateEvent.minuto}
        </span>
      </div>
    );
  }

  const renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;

  const createEventImageDependingState = (state) => {
    if (state === "1") {
      return (
        <FaCircle
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "grey",
          }}
        />
      );
    }
    if (state === "2") {
      return (
        <FaClock
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "orange",
          }}
        />
      );
    }
    if (state === "3") {
      return (
        <FaCheckCircle
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "green",
          }}
        />
      );
    }
    if (state === "4") {
      return (
        <FaTimesCircle
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "red",
          }}
        />
      );
    }
  };
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[400]),
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: grey[200],
    },
  }));
  const ColorButtonDescricao = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[400]),
    cursor: "default!important",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  }));
  const ColorButtonArrow = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[600],
    "&:hover": {
      backgroundColor: grey[500],
    },
  }));
  const AddColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: "#32058b",
    padding: "0px 22px",
    minWidth: "132px",
    borderRadius: "23px",
    height: "45px",
    "&:hover": {
      backgroundColor: "#230361",
    },
  }));

  const dropdownListgrupos = rhbolsaHorasUsersGroup.map((item) => {
    return {
      label: item.grupo.designacao,
      value: item.grupo.designacao,
      selected: item.grupo.designacao === grupos,
    };
  });

  const handleSubmitGrupos = (e) => {
    if (grupos != e) {
      setLoadEventsStart(false);
      setSelectedUser(undefined);
      setActiveButton("");
    }
    setgrupos(e);
  };

  const handleChooseUser = (user) => {
    setSelectedUser(user);
    setLoadEventsStart(true);
    setActiveButton(user.nomeCurto);
  };

  const UsersButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: "#32058b",
    padding: "0px 22px",
    minWidth: "132px",
    borderRadius: "23px",
    height: "45px",
    "&:hover": {
      backgroundColor: "#230361",
    },
  }));
  const ClearColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: "#32058b",
    padding: "0px 22px",
    minWidth: "132px",
    borderRadius: "23px",
    height: "45px",
    "&:hover": {
      backgroundColor: "#230361",
    },
  }));

  const handleClearFilter = () => {
    setLoadEventsStart(false);
    setSelectedUser(undefined);
    setgrupos("");
    setActiveButton("");
    setCleanDropdown(true);
  };

  const handleExportTable = () => {
    if (selectedUser === undefined) {
      let messagetrans = t("portalrh.scheduler.exportTableInfo");
      setmessageTranslated(messagetrans);
      setOpenExportInfo(true);
      return
    }
    const timesheet = scheduler.registoPresencaTabela;
    const payload = {
      timesheet,
    };
    dispatch(getExportedTable(payload));
  };
  class CustomToolbar extends React.Component {
    render() {
      let {
        localizer: { messages },
        label,
      } = this.props;
      return (
        <>
          <div className="rbc-toolbar">
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              margin="20px"
            >
              <ColorButtonArrow
                size="small"
                variant="text"
                startIcon={<FaAngleDoubleLeft />}
                onClick={this.navigate.bind(null, navigate.PREVIOUS)}
              />
              <span className="rbc-toolbar-label">{label}</span>
              <ColorButtonArrow
                size="small"
                variant="text"
                startIcon={<FaAngleDoubleRight />}
                onClick={this.navigate.bind(null, navigate.NEXT)}
              />
            </Stack>
          </div>
          <Row style={{ alignItems: "center" }}>
            {isEditable ? (
              <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  margin="20px"
                >
                  <AddColorButton
                    size="small"
                    variant="text"
                    startIcon={<FaCalendarPlus />}
                    onClick={handleAddNewEvent}
                  >
                    {t("portalrh.scheduler.addRegistry")}
                  </AddColorButton>
                  <AddColorButton
                    size="small"
                    variant="text"
                    startIcon={<FaListUl />}
                    onClick={handleSendMultipleReasons}
                  >
                    {t("portalrh.scheduler.justify")}
                  </AddColorButton>
                  {/* <AddColorButton
                    size="small"
                    variant="text"
                    startIcon={<FaClock />}
                    onClick={handleSendNewExtraHour}
                  >
                    {t("portalrh.scheduler.extraHour")}
                  </AddColorButton> */}
                </Stack>
              </Col>
            ) : (
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Form.Label style={{ marginRight: "10px" }}>
                    {t("portalrh.scheduler.groups")}
                  </Form.Label>
                  <Dropdown
                    haveWidth={true}
                    list={dropdownListgrupos}
                    handleSubmit={handleSubmitGrupos}
                    emptyDefault={true}
                    resetDropdown={cleanDropdown}
                    setResetDropdown={setCleanDropdown}
                  />
                </div>
              </Col>
            )}
            {key == designacao && (
              <Col>
                <Stack
                  direction="row-reverse"
                  alignItems="center"
                  spacing={2}
                  margin="20px"
                >
                  <ColorButton
                    size="small"
                    variant="text"
                    startIcon={<FaCalendarDay />}
                    onClick={this.changeView.bind(null, "day")}
                  >
                    {t("portalrh.scheduler.day")}
                  </ColorButton>
                  <ColorButton
                    size="small"
                    variant="text"
                    startIcon={<FaCalendarWeek />}
                    onClick={this.changeView.bind(null, "week")}
                  >
                    {t("portalrh.scheduler.week")}
                  </ColorButton>
                  <ColorButton
                    size="small"
                    variant="text"
                    startIcon={<FaCalendarAlt />}
                    onClick={this.changeView.bind(null, "month")}
                  >
                    {t("portalrh.scheduler.month")}
                  </ColorButton>
                </Stack>
              </Col>
            )}
          </Row>
          {grupos !== "" && (
            <Stack direction="row" alignItems="center" spacing={2} margin="0px">
              <ClearColorButton
                size="small"
                variant="text"
                className="btnstocleanandprint"
                startIcon={<FaSearchMinus />}
                onClick={handleClearFilter}
              >
                {t("portalrh.scheduler.clean")}
              </ClearColorButton>
              <ClearColorButton
                size="small"
                variant="text"
                className="btnstocleanandprint"
                startIcon={<FaFileExport />}
                onClick={handleExportTable}
              >
                {t("portalrh.scheduler.exportTable")}
              </ClearColorButton>
            </Stack>
          )}
          {grupos !== "" && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              margin="0px"
              className="stackButtonsGroup"
            >
              {rhbolsaHorasUsersGroup
                .filter((group) => group.grupo.designacao === grupos)
                .map((group) =>
                  group.userList.map((user) => (
                    <UsersButton
                      size="x-small"
                      variant="text"
                      key={user.id}
                      className={`button-scheduler ${
                        activeButton === user.nomeCurto ? "active" : ""
                      }`}
                      onClick={() => handleChooseUser(user)}
                    >
                      {user.nomeCurto}
                    </UsersButton>
                  ))
                )}
            </Stack>
          )}
          {showCalendarAndTable && (
            <Row>
              <Tabs
                className="custom-tabs"
                id="adminSettingsTab"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                mountOnEnter={false}
              >
                <Tab
                  eventKey={designacao}
                  title={t("portalrh.scheduler.calendario")}
                >
                  <br />
                </Tab>
                <Tab eventKey={"Tabela"} title={t("portalrh.scheduler.tabela")}>
                  <br />
                  <TabelaScheduler
                    portalRhMenus={portalRhMenus}
                    path={path}
                    designacao={designacao}
                    isEditable={isEditable}
                    loadEventsStart={loadEventsStart}
                    setLoadEventsStart={setLoadEventsStart}
                    setSelectedUser={setSelectedUser}
                    selectedUser={selectedUser}
                    grupos={grupos}
                    setgrupos={setgrupos}
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    saldos_hours={saldos_hours}
                    setSaldos_hours={setSaldos_hours}
                    setInsencao_hours={setInsencao_hours}
                    insencao_hours={insencao_hours}
                    picagens_Exists={picagens_Exists}
                    setPicagens_Exists={setPicagens_Exists}
                  />
                </Tab>
              </Tabs>
              <Row style={{ paddingRight: "0px" }}>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                  {key == designacao &&
                    loadEventsStart &&
                    saldos_hours != undefined &&
                    saldos_hours.length > 0 && (
                      <p>
                        {t("portalrh.scheduler.horaInicial")}{" "}
                        {saldos_hours[0].saldoInicial}
                      </p>
                    )}
                  {key == designacao &&
                    loadEventsStart &&
                    picagens_Exists != undefined &&
                    picagens_Exists.length > 0 &&
                    picagens_Exists[0] === "1" && (
                      <p>{t("portalrh.scheduler.picagensExists")}</p>
                    )}
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ padding: "0px" }}
                >
                  {/*{key == designacao &&
                    loadEventsStart &&
                    insencao_hours != undefined &&
                    insencao_hours.length > 0 && (
                      <p style={{ float: "right" }}>
                        {insencao_hours[0] === "0"
                          ? t("portalrh.scheduler.sisencao")
                          : t("portalrh.scheduler.cisencao")}
                      </p>
                    )}*/}
                </Col>
              </Row>
            </Row>
          )}
        </>
      );
    }
    navigate = (action) => {
      this.props.onNavigate(action);
    };
    changeView = (view) => {
      this.props.onView(view);
    };
  }

  const handleChange = (value, name) => {
    // devLogConsole(value, name);
    // setCustomFormState((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
  };
  const handleSubmit = () => {
    // if (customFormState.title == "") {
    //   setCustomFormState({
    //     ...customFormState,
    //   });
    //   return;
    // }
    // devLogConsole("SUBMIT", customFormState);
    // handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSelectSlot = (event) => {
    //handleAddNewEvent(event);
    return;
  };

  const onSelectEvent = (event) => {
    if (
      event.type === variablesEvents.HORARIO ||
      event.type === variablesEvents.REGISTO_PRESENCA ||
      event.type === variablesEvents.FERIAS ||
      event.type === variablesEvents.FERIADOS
    )
      return;
    if (event.state != "1" && event.type === variablesEvents.JUSTIFICACAO)
      return;
    const url = "/startProcess?Authorization=" + oauth.getAccessToken();
    if (rhbolsaHorasConfiguration.length > 0) {
      const flowid = rhbolsaHorasConfiguration.find(
        (config) => config.description === event.type
      );
      if (flowid != undefined) {
        let payloadtoGet = undefined;
        let endDate = undefined;
        let startDate = undefined;
        let registo = undefined;
        if (event.allDay === true) {
          endDate = event.justificacao[1].end;
          startDate = event.justificacao[0].begin;
          registo = event.justificacao.map(
            (justificacaoItem) => justificacaoItem.idRegisto
          );
        } else {
          endDate = transformDate(event.end);
          startDate = transformDate(event.start);
          registo = [event.idRegisto];
        }
        if (event.type === variablesEvents.BOLSA_HORAS) {
          payloadtoGet = {
            flowid: flowid.flowId,
            weekid: event.codId,
            end: event.bolsa.map((bolsaItem) => bolsaItem.end),
            begin: event.bolsa.map((bolsaItem) => bolsaItem.begin),
            registoID: event.bolsa.map((bolsaItem) => bolsaItem.idregisto),
          };
        }

        if (event.type === variablesEvents.JUSTIFICACAO) {
          payloadtoGet = {
            flowid: flowid.flowId,
            weekid: event.codId,
            datafim: endDate,
            datainicio: startDate,
            registoID: registo,
          };
        }
        // } else if (event.type === variablesEvents.REGISTO_PRESENCA) {
        //   payloadtoGet = {
        //     flowid: flowid.flowId,
        //     weekid: event.codId,
        //     end: [endDate],
        //     begin: [startDate],
        //   };
        // }
        if (payloadtoGet != undefined) {
          setFlowIdSelected(payloadtoGet.flowid);
          dispatch(postIflowForm(payloadtoGet, url));
        }
      } else {
        devLogConsole(
          "PROBLEMA NO FLOW ID----",
          flowid,
          rhbolsaHorasConfiguration
        );
      }
    }
  };

  const transformDate = (dataOriginal) => {
    const data = new Date(dataOriginal);
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const dia = String(data.getDate()).padStart(2, "0");
    const hora = String(data.getHours()).padStart(2, "0");
    const minuto = String(data.getMinutes()).padStart(2, "0");
    const segundo = String(data.getSeconds()).padStart(2, "0");
    return `${ano}-${mes}-${dia} ${hora}:${minuto}:${segundo}`;
  };

  const handleAddNewEvent = (event) => {
    const url = "/startProcess?Authorization=" + oauth.getAccessToken();
    if (rhbolsaHorasConfiguration.length > 0) {
      const flowid = rhbolsaHorasConfiguration.find(
        (config) => config.description === variablesEvents.REGISTO_PRESENCA
      );
      if (flowid != undefined) {
        const payloadtoGet = {
          flowid: flowid.flowId,
        };
        if (event.end != undefined && event.start != undefined) {
          payloadtoGet.end = transformDate(event.end);
          payloadtoGet.begin = transformDate(event.start);
        }
        setFlowIdSelected(payloadtoGet.flowid);
        dispatch(postIflowForm(payloadtoGet, url));
      }
    }
  };

  const handleSendMultipleReasons = () => {
    const url = "/startProcess?Authorization=" + oauth.getAccessToken();
    if (rhbolsaHorasConfiguration.length > 0) {
      const flowid = rhbolsaHorasConfiguration.find(
        (config) => config.description === variablesEvents.JUSTIFICACAO
      );
      const payloadtoGet = {
        flowid: flowid.flowId,
      };
      setFlowIdSelected(payloadtoGet.flowid);
      dispatch(postIflowForm(payloadtoGet, url));
    }
  };

  const handleSendNewExtraHour = () => {
    const url = "/startProcess?Authorization=" + oauth.getAccessToken();
    if (rhbolsaHorasConfiguration.length > 0) {
      const flowid = rhbolsaHorasConfiguration.find(
        (config) => config.description === variablesEvents.BOLSA_HORAS
      );
      const payloadtoGet = {
        flowid: flowid.flowId,
      };
      setFlowIdSelected(payloadtoGet.flowid);
      dispatch(postIflowForm(payloadtoGet, url));
    }
  };

  useEffect(() => {
    if (iflowpid != undefined && Object.keys(iflowpid).length > 0) {
      const payload = {
        url:
          "Forward/forward.jsp?flowid=" +
          flowIdSelected +
          "&pid=" +
          iflowpid.pid +
          "&subpid=1&Authorization=" +
          oauth.getAccessToken(),
      };
      const isNewForm = true;
      dispatch(getIflowFormdata(payload, isNewForm));
      setShowFlowModal(true);
    }
  }, [iflowpid]);

  const closeModal = () => {
    dispatch(setIflowPidScheduler({}));
    setShowFlowModal(false);
  };

  const handleCloseExport = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setExportVals(""));
    setOpenExport(false);
    setmessageTranslated("");
  };

  const handleCloseExportError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setExportVals(""));
    setOpenExportError(false);
    setmessageTranslated("");
  };
  const handleCloseExportInfo = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenExportInfo(false);
    setmessageTranslated("");
  };

  return (
    <>
      <Spinner spinning={isLoadingExport} wrapper />
      <Snackbar
        open={openExport}
        autoHideDuration={5000}
        onClose={handleCloseExport}
      >
        <AlertMaterial
          onClose={handleCloseExport}
          severity="success"
          sx={{ width: "100%" }}
        >
          {messageTranslated}
        </AlertMaterial>
      </Snackbar>
      <Snackbar
        open={openExportError}
        autoHideDuration={5000}
        onClose={handleCloseExportError}
      >
        <AlertMaterial
          onClose={handleCloseExportError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {messageTranslated}
        </AlertMaterial>
      </Snackbar>
      <Snackbar
        open={openExportInfo}
        autoHideDuration={5000}
        onClose={handleCloseExportInfo}
      >
        <AlertMaterial
          onClose={handleCloseExportInfo}
          severity="info"
          sx={{ width: "100%" }}
        >
          {messageTranslated}
        </AlertMaterial>
      </Snackbar>
      <Sidebar menuSideBar={menuSideBar} path={path} />
      {isLoadingScheduler ? (
        <Spinner spinning={isLoadingScheduler} wrapper />
      ) : (
        <>
          <>{selectedLocale && schedulerContrutor()}</>
        </>
      )}
      <FlowModal
        showModal={showFlowModal}
        closeModal={closeModal}
        isPortal={true}
      />
    </>
  );
};

export default withNamespaces()(SchedulerComponent);
