import React, { useEffect } from "react";
import { Card, Badge } from "react-bootstrap";
import "../../assets/css/icons.css";
import CustomDropdown from "./CustomDropdown";
import { useSelector, useDispatch } from "react-redux";
import { toggleIsAvailable } from "../../containers/Layout/actions";
import { withNamespaces } from "react-i18next";
import avatarDefault from "../../assets/img/avatar/avatar-default.png";
import Spinner from "../Spinner";
import { updateUserWidget } from '~/pages/User/actions';

const ProfileWidget = ({ apagarWidget,widget,handleMouseDown,isResizing,textMove, textClick, ...props }) => {
  const { t } = props;
  const { user, isLoadingUser, isAvailable } = useSelector(
    (state) => state.globalReducer
  );
  const { isLoadingUserSettings } = useSelector(
    (state) => state.userSettingsReducer
  );
  const dispatch = useDispatch();

  const toggleAvailable = () => {
    dispatch(toggleIsAvailable());
  };

  useEffect(() => {
    if (widget.userConfiguration == null) {
       const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "6"
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  const picture = user.detail
    ? user.detail.picture
      ? "data:image/png;base64, " + user.detail.picture
      : avatarDefault
    : avatarDefault;

  const firstName = user.detail
    ? user.detail.firstName
      ? user.detail.firstName
      : ""
    : "";
  const lastName = user.detail
    ? user.detail.lastName
      ? user.detail.lastName
      : ""
    : "";
  const fullName = firstName + " " + lastName;

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>
            <i className="icon-bell mr-2" />
            {t("ProfileWidget.title")}
          </h6>
          <h6>
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown apagarWidget={apagarWidget} handleMouseDown={handleMouseDown} />
        </Card.Header>
        {!isResizing && (<Card.Body style={{ paddingTop: "10px" }}>
          <Spinner spinning={isLoadingUser || isLoadingUserSettings} size="md">
            <div className="row align-items-center">
              <div className="col align-items-center">
                <img className="img-profile" src={picture} alt="" />
              </div>
              <div className="col">
                <h5 className="font-weight-bold">{fullName}</h5>
                <hr />
                <p className="profile-info">
                  <i className="icon-phone mr-2"></i>
                  {user.detail ? user.detail.mobilePhone : ""}
                </p>
                <p className="profile-info">
                  <i className="icon-mail mr-2"></i>
                  {user.detail ? user.detail.email : ""}
                </p>
                <hr />
                {isAvailable ? (
                  <span className="row">
                    Status &nbsp;{" "}
                    <h4>
                      <Badge variant="primary" onClick={toggleAvailable}>
                        {t("ProfileWidget.available")}
                      </Badge>
                    </h4>
                  </span>
                ) : (
                  <span className="row">
                    Status &nbsp;{" "}
                    <h4>
                      <Badge variant="secondary" onClick={toggleAvailable}>
                        {t("ProfileWidget.away")}
                      </Badge>
                    </h4>
                  </span>
                )}
              </div>
            </div>
          </Spinner>
        </Card.Body>)}
      </Card>
    </div>
  );
};
export default withNamespaces()(ProfileWidget);
