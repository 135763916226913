import { takeLatest, takeEvery } from "redux-saga/effects";

import * as types from "../ducks/systemSettings/applications/actionTypes";
import * as sagas from "../ducks/systemSettings/applications/sagas";

export const systemSettingsApplicationsSagas = [
  takeLatest(types.PULL_DATA_FROM_BPM, sagas.pullDataFromBPM),
  takeLatest(types.PULL_DATA_ALL_MIDDLEWARE, sagas.pullDataAllMiddleware),
  takeEvery(types.PING_MIDDLEWARE_APPLICATION, sagas.pingMiddlewareApplication),
  takeEvery(
    types.PING_IFLOW_BASED_APPLICATION,
    sagas.pingIFlowBasedApplication
  ),
  takeLatest(types.GET_SYNC_LIST_MW, sagas.getSyncListMW),
  takeLatest(types.GET_SYNC_ENCRYPT, sagas.getSyncEncrypt),
  takeLatest(types.POST_SYNC_LIST_MW, sagas.postSyncListMW),
];
