import React, { useState, useEffect } from "react";
import Title from "../components/Title";
import "../assets/scss/_initialDataSection.scss";
import "../assets/scss/_contentContainer.scss";
import { useForm } from "react-hook-form";
import TableInput from "./TableInput";
import uuid from "uuid/v1";
import Dropdown from "./TableInputDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllWorkplans,
  saveChangesWorkplan,
  setErrorUpdating,
  setNumGanho,
} from "~/store/ducks/budgetManagement/actionTypes";
import InputDateSingle from "./InputDateSingle";
import Modal from "~/pages/Easypay/components/Modal";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
const devLogConsole = require("~/utils/devLog");
const ProposalDataAccordion = ({ items, workplanIndex }) => {
  const { register, formState, watch } = useForm();
  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);
  function buildInputField(text, value) {
    if (text === "Nº Projeto") {
      //devLogConsole(value)
    }
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 100,
      text: text,
    };
  }

  const dispatch = useDispatch();

  const {
    workplans,
    proposals,
    probabilities,
    projectTypes,
    businesses,
    states,
    invoicingModes,
    proposalTypes,
    expenses,
    collaborators,
    workplan,
    errorUpdating,
    numGanho,
  } = useSelector((state) => state.budgetManagementReducer);

  const [isModalErrorUpdatingShowing, setIsModalErrorUpdatingShowing] =
    useState(false);

  function createBodyError() {
    return (
      <div className="lift-modal-body-wrapper">
        <div>
          <ProvaDeVida text="Erro Nº Projeto" status="error" />
          <div style={{ fontWeight: "bold" }}>
            {"Formato inválido de nº projeto."}
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (errorUpdating === true) {
      setIsModalErrorUpdatingShowing(true);
      setErrorUpdating(null);
    } else if (
      errorUpdating === false &&
      errorUpdating != null &&
      numGanho != "" &&
      numGanho != null &&
      numGanho != undefined
    ) {
      //workplans[workplanIndex].numeroGanho = numGanho
      //dispatch(setNumGanho(""));
      //dispatch(setAllWorkplans(workplans));
      //setIsModalSaveShowing(true);
      //setErrorUpdating(null)
    }
  }, [workplans]);

  var gpi = 1.0;
  var fse = 1.0;
  var pc = 1.0;
  var pv = 1.0;

  function buildNameArray(info) {
    if (info != null) {
      var newArray = [];
      for (var i = 0; i < info.length; i++) {
        newArray.push({
          label: info[i].nome,
          value: info[i].nome,
          selected: false,
        });
      }
      return newArray;
    }
  }

  function getNameFromId() {
    const projType = projectTypes.find(
      (x) => x.idTipoProjeto === parseInt(workplans[workplanIndex].tipoProjeto)
    );
    if (projType != null) return projType?.nome;
  }

  function getIdFromName(e, name) {
    if (name === "proposta" && proposalTypes != null) {
      const prop = proposalTypes.find((x) => x.nome === e);
      if (prop != null) return prop?.idTipoProposta;
    } else if (name === "probabilidade" && probabilities != null) {
      const prob = probabilities.find((x) => x.nome === e);
      if (prob != null) return prob?.idProbabilidade;
    } else if (name === "tipoProjeto" && projectTypes != null) {
      const projType = projectTypes.find((x) => x.nome === e);
      if (projType != null && proposals != null) return projType?.idTipoProjeto;
    } else if (name === "business" && businesses != null) {
      const busi = businesses.find((x) => x.nome === e);
      if (busi != null) return busi?.idBusiness;
    } else if (name === "estado" && states != null) {
      const state = states.find((x) => x.nome === e);
      if (state != null) return state?.idEstado;
    } else if (
      (name === "modoFaturacao" || name === "distribuicaoHoras") &&
      invoicingModes != null
    ) {
      const invMode = invoicingModes.find((x) => x.nome === e);
      if (invMode != null) return invMode?.idModoFaturacao;
    }
  }

  function handleTableChange(e, name) {
    if (e === undefined) return;
    if (name === "estado") {
      workplans[workplanIndex][name] = e;
      workplans[workplanIndex].estadoId = getIdFromName(e, name).toString();
    } else {
      workplans[workplanIndex][name] = getIdFromName(e, name).toString();
      if (name === "tipoProjeto") {
        workplans[workplanIndex].tipoProjetoId = getIdFromName(
          e,
          name
        ).toString();
        if (e === "Avença") {
          workplans[workplanIndex].modoFaturacao = getIdFromName(
            "Mensal",
            "modoFaturacao"
          );
          workplans[workplanIndex].modoFaturacaoId = getIdFromName(
            "Mensal",
            "modoFaturacao"
          );
          workplans[workplanIndex].distribuicaoHoras = getIdFromName(
            "Mensal",
            "modoFaturacao"
          );
          workplans[workplanIndex].distribuicaoHorasId = getIdFromName(
            "Mensal",
            "modoFaturacao"
          );
        } else {
          workplans[workplanIndex].modoFaturacao = getIdFromName(
            "Projeto",
            "modoFaturacao"
          );
          workplans[workplanIndex].modoFaturacaoId = getIdFromName(
            "Projeto",
            "modoFaturacao"
          );
          workplans[workplanIndex].distribuicaoHoras = getIdFromName(
            "Projeto",
            "modoFaturacao"
          );
          workplans[workplanIndex].distribuicaoHorasId = getIdFromName(
            "Projeto",
            "modoFaturacao"
          );
        }
      }
      if (name === "modoFaturacao") {
        workplans[workplanIndex].modoFaturacaoId = getIdFromName(
          e,
          name
        ).toString();
      }
      if (name === "distribuicaoHoras") {
        workplans[workplanIndex].distribuicaoHorasId = getIdFromName(
          e,
          name
        ).toString();
      }
      if (name === "proposta") {
        workplans[workplanIndex].propostaId = getIdFromName(e, name).toString();
      }
    }
    dispatch(setAllWorkplans(workplans));
  }

  function convertIdToName(id, name) {
    if (name === "proposalTypes" && proposalTypes != null) {
      const prop = proposalTypes.find((x) => x.idTipoProposta === parseInt(id));
      if (prop != null) return prop?.nome;
    } else if (name === "probabilities" && probabilities != null) {
      const prob = probabilities.find(
        (x) => x.idProbabilidade === parseInt(id)
      );
      if (prob != null) return prob?.nome;
    } else if (name === "projectTypes" && projectTypes != null) {
      const projType = projectTypes.find(
        (x) => x.idTipoProjeto === parseInt(id)
      );
      if (projType != null) return projType?.nome;
    } else if (name === "businesses" && businesses != null) {
      const busi = businesses.find((x) => x.idBusiness === parseInt(id));
      if (busi != null) return busi?.nome;
    } else if (name === "states" && states != null) {
      const state = states.find((x) => x.idEstado === parseInt(id));
      if (state != null) return state?.nome;
    } else if (name === "invoicingModes" && invoicingModes != null) {
      const invMode = invoicingModes.find(
        (x) => x.idModoFaturacao === parseInt(id)
      );
      if (invMode != null) return invMode?.nome;
    }
  }

  function handleNormalTable(e, name) {
    if (name === "faseFinal")
      workplans[workplanIndex].resumoFinaceiro.toChange = 1;

    workplans[workplanIndex][name] = e;
    dispatch(setAllWorkplans(workplans));
  }

  const projectTypeList = [
    { label: "Avença", value: "Avença", selected: false },
    { label: "Pontual", value: "Pontual", selected: false },
    { label: "Press kit", value: "Press kit", selected: false },
  ];

  const proposalsList = [
    { label: "Proativa", value: "Proativa", selected: false },
    { label: "Reativa", value: "Reativa", selected: false },
    { label: "Ongoing", value: "Ongoing", selected: false },
  ];

  const facturationMethodList = [
    { label: "Projeto", value: "Projeto", selected: false },
    { label: "Mensal", value: "Mensal", selected: false },
  ];

  const finalList = [
    { label: "Inicio", value: "Inicio", selected: false },
    { label: "Final", value: "Final", selected: false },
    { label: "Repartido", value: "Repartido", selected: false },
  ];

  function getProjectName() {
    const version = workplans[workplanIndex]?.version;
    var versionString = "";
    if (version >= 10) {
      versionString = "" + version;
    } else {
      versionString = "0" + version;
    }

    return (
      workplans[workplanIndex]?.projectName +
      "." +
      versionString +
      "/" +
      (workplans[workplanIndex]?.dataIdentDaLead).substring(0, 4)
    );
  }

  function handleStateChange(e, name) {
    workplans[workplanIndex][name] = e;
    if (e === "Proposta") {
      let yourDate = new Date();
      const offset = yourDate.getTimezoneOffset();
      yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
      workplans[workplanIndex].dataEntregaPorposta = yourDate
        .toISOString()
        .split("T")[0];
    }
    if (
      e === "Adjudicado" ||
      e === "Ganho" ||
      e === "Ongoing" ||
      e === "Adiado" ||
      e === "Cancelado " ||
      e === "Perdido"
    ) {
      if(e === "Adjudicado")
      {
        const state = states.find((x) => x.nome === ("Inativo"));
        for(var i = 0;i < workplans.length ; i++)
        {
          if(workplans[i].projectName.split(".")[0] === workplans[workplanIndex].projectName.split(".")[0] && i != workplanIndex)
          {
            if(workplans[i].estado === "Proposta")
            {
              workplans[i].estado = "Inativo"
              workplans[i].estadoId = state.idEstado
            }
          }
        }
      }
      let yourDate = new Date();
      const offset = yourDate.getTimezoneOffset();
      yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
      workplans[workplanIndex].dataAdjouFecho = yourDate
        .toISOString()
        .split("T")[0];
    }

    if (e === "Lead") {
      workplans[workplanIndex].dataAdjouFecho = null;
    }
    workplans[workplanIndex].estadoId = getIdFromName(e, name).toString();

    dispatch(setAllWorkplans(workplans));
    /*if (e === "Ganho" || e === "Ongoing") {
      dispatch(
        saveChangesWorkplan({ content: workplans[workplanIndex], show: true })
      );
    }*/
  }

  function calculateHours(index, optionIndex, activityIndex, itemIndex) {
    const currentColabId =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].horas[index].colaborador;
    const colab = collaborators.find(
      (x) => x.idColaborador === parseInt(currentColabId)
    );
    if (colab != null) {
      pc = colab.precoCompra;
      pv = colab.precoVenda;
    }

    const expGPI = expenses.find(
      (x) => x.tipo === "Gastos Pessoal Indireto (GPI)"
    );
    if (expGPI != null) gpi = expGPI.percentagem / 100;

    const expFSE = expenses.find((x) => x.tipo === "FSE");
    if (expFSE != null) fse = expFSE.percentagem / 100;

    if (true) {
      const currentHoras =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].horas[index];

      const currentHorasPropostas =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].horas[index].horasPropostas;
      if (currentHorasPropostas === undefined) {
        return;
      }
      if ((currentHorasPropostas?.split(":")).length === 0) {
        return;
      }
      const hoursArray = currentHoras.horasPropostas.split(":");
      var newHoras = parseFloat(hoursArray[0]) + parseFloat(hoursArray[1]) / 60;

      if (getNameFromId() === "Avença") {
        //newHoras =
        // newHoras *
        // workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        //   .opcoes[optionIndex].horas[index].horasPropostasArray.length;
      }

      var newVVP = 0;
      var newCHP = 0;
      var newMargem = 0;
      if (
        currentHoras.valorAdicional != undefined &&
        currentHoras.valorAdicional != 0
      ) {
        newVVP =
          parseFloat(newHoras) * pv + parseFloat(currentHoras.valorAdicional);
        const sub = parseFloat(
          newVVP - parseFloat(currentHoras.valorAdicional)
        );
        const sub2 = parseFloat(
          newVVP - parseFloat(currentHoras.valorAdicional)
        );
        newCHP = parseFloat(newHoras) * pc + sub * gpi;
        newCHP = newCHP + sub2 * fse;
        newMargem = 100 - (newCHP * 100) / newVVP;
      } else {
        newVVP = parseFloat(newHoras) * pv;
        newCHP = parseFloat(newHoras) * pc + gpi * newVVP + fse * newVVP;
        newMargem = 100 - (newCHP * 100) / newVVP;
      }

      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].horas[index].valorVendaHoras = newVVP.toFixed(2);
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].horas[index].custosHoras = newCHP.toFixed(2);

      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].horas[index].margemHoras = newMargem.toFixed(2);

      var newOptionCostValue = 0;
      var newOptionSaleValue = 0;
      const newCurrentHoras =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].horas;
      const currentForn =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].fornecedores;
      const currentDespesas =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].despesas;
      for (var i = 0; i < newCurrentHoras.length; i++) {
        newOptionCostValue += parseFloat(newCurrentHoras[i].custosHoras);
        newOptionSaleValue += parseFloat(newCurrentHoras[i].valorVendaHoras);
      }
      for (var i = 0; i < currentForn.length; i++) {
        newOptionCostValue += parseFloat(currentForn[i].precoCompraForn);
        newOptionSaleValue += parseFloat(currentForn[i].valorVendaForn);
      }
      for (var i = 0; i < currentDespesas.length; i++) {
        newOptionCostValue += parseFloat(currentDespesas[i].precoCompraForn);
        newOptionSaleValue += parseFloat(currentDespesas[i].valorVendaDesp);
      }

      if (!(newOptionCostValue instanceof String))
        workplans[workplanIndex].items[itemIndex].atividades[
          activityIndex
        ].opcoes[optionIndex].valorCustoOpt = newOptionCostValue?.toFixed(2);

      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].valorVendaOpt = newOptionSaleValue?.toFixed(2);

      var currentActivityCost = 0;
      var currentActivitySale = 0;

      var currentItemCost = 0;
      var currentItemSale = 0;

      const currentOptions =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes;

      for (var i = 0; i < currentOptions.length; i++) {
        if (currentOptions[i].selected) {
          currentActivityCost += parseFloat(currentOptions[i].valorCustoOpt);
          currentActivitySale += parseFloat(currentOptions[i].valorVendaOpt);
        }
      }

      if (currentActivityCost === 0 && currentActivitySale === 0) {
        currentActivityCost = parseFloat(currentOptions[0].valorCustoOpt);
        currentActivitySale = parseFloat(currentOptions[0].valorVendaOpt);
      }

      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].valorCustoAct = currentActivityCost;
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].valorVendaAct = currentActivitySale;

      const currentActivities =
        workplans[workplanIndex].items[itemIndex].atividades;

      for (var i = 0; i < currentActivities.length; i++) {
        currentItemCost += parseFloat(currentActivities[i].valorCustoAct);
        currentItemSale += parseFloat(currentActivities[i].valorVendaAct);
      }

      workplans[workplanIndex].items[itemIndex].valorCustoObj =
        parseFloat(currentItemCost);
      workplans[workplanIndex].items[itemIndex].valorVendaObj =
        parseFloat(currentItemSale);
    }
  }

  function handleProjectTypeChange(e) {
    workplans[workplanIndex].resumoFinaceiro.toChange = 1;
    workplans[workplanIndex].tipoProjeto = getIdFromName(
      e,
      "tipoProjeto"
    )?.toString();
    workplans[workplanIndex].tipoProjetoId = getIdFromName(
      e,
      "tipoProjeto"
    )?.toString();

    if (e === "Avença") {
      workplans[workplanIndex].modoFaturacao = getIdFromName(
        "Mensal",
        "modoFaturacao"
      );
      workplans[workplanIndex].modoFaturacaoId = getIdFromName(
        "Mensal",
        "modoFaturacao"
      );
      workplans[workplanIndex].distribuicaoHoras = getIdFromName(
        "Mensal",
        "modoFaturacao"
      );
      workplans[workplanIndex].distribuicaoHorasId = getIdFromName(
        "Mensal",
        "modoFaturacao"
      );
    } else {
      workplans[workplanIndex].modoFaturacao = getIdFromName(
        "Projeto",
        "modoFaturacao"
      );
      workplans[workplanIndex].modoFaturacaoId = getIdFromName(
        "Projeto",
        "modoFaturacao"
      );
      workplans[workplanIndex].distribuicaoHoras = getIdFromName(
        "Projeto",
        "modoFaturacao"
      );
      workplans[workplanIndex].distribuicaoHorasId = getIdFromName(
        "Projeto",
        "modoFaturacao"
      );
    }
    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          for (
            var m = 0;
            m <
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
              .length;
            m++
          ) {
            var currentHours =
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                m
              ];
            if (
              currentHours.horasPropostas != undefined ||
              currentHours.horasPropostas != null ||
              currentHours.horasPropostas?.length > 0
            ) {
              if (e === "Avença") {
                for (
                  var a = 0;
                  a < currentHours.horasPropostasArray.length;
                  a++
                ) {
                  workplans[workplanIndex].items[i].atividades[j].opcoes[
                    k
                  ].horas[m].horasPropostasArray[a].hoursValue =
                    workplans[workplanIndex].items[i].atividades[j].opcoes[
                      k
                    ].horas[m].horasPropostas;
                }
              } else {
                const arrayHours = currentHours.horasPropostas.split(":");
                const newHoras =
                  parseFloat(arrayHours[0]) + parseFloat(arrayHours[1]) / 60;

                const currentDividedHours =
                  newHoras / currentHours.horasPropostasArray.length;
                var n = new Date(0, 0);
                n.setSeconds(+currentDividedHours * 60 * 60);
                const dividedHoursString = n.toTimeString().slice(0, 5);

                for (
                  var a = 0;
                  a < currentHours.horasPropostasArray.length;
                  a++
                ) {
                  workplans[workplanIndex].items[i].atividades[j].opcoes[
                    k
                  ].horas[m].horasPropostasArray[a].hoursValue =
                    dividedHoursString;
                }
              }
              calculateHours(m, k, j, i);
            }
          }
        }
      }
    }
    dispatch(setAllWorkplans(workplans));
  }

  function buildStateNameArray(info) {
    if (info != null) {
      const currentName = workplans[workplanIndex].estado;
      var newArray = [];
      if (currentName === "Lead") {
        for (var i = 0; i < info.length; i++) {
          if (
            info[i].nome === "Proposta" ||
            info[i].nome === "Adiado" ||
            info[i].nome === "Cancelado"
          )
            newArray.push({
              label: info[i].nome,
              value: info[i].nome,
              selected: false,
            });
        }
      } else if (
        currentName === "Ganho" ||
        currentName === "Ongoing" ||
        currentName === "Cancelado" ||
        currentName === "Perdido" ||
        currentName === "Inativo"
      ) {
        return newArray;
      } else if (currentName === "Adiado") {
        for (var i = 0; i < info.length; i++) {
          if (
            info[i].nome === "Lead" ||
            info[i].nome === "Cancelado" ||
            info[i].nome === "Perdido"
          )
            newArray.push({
              label: info[i].nome,
              value: info[i].nome,
              selected: false,
            });
        }
      } else if (currentName === "Adjudicado") {
        for (var i = 0; i < info.length; i++) {
          if (info[i].nome === "Ganho" || info[i].nome === "Ongoing")
            newArray.push({
              label: info[i].nome,
              value: info[i].nome,
              selected: false,
            });
        }
      } else if (currentName === "Proposta") {
        for (var i = 0; i < info.length; i++) {
          if (
            info[i].nome === "Adjudicado" ||
            info[i].nome === "Ganho" ||
            info[i].nome === "Ongoing" ||
            info[i].nome === "Adiado" ||
            info[i].nome === "Cancelado" ||
            info[i].nome === "Perdido" ||
            info[i].nome === "Inativo"
          )
            newArray.push({
              label: info[i].nome,
              value: info[i].nome,
              selected: false,
            });
        }
      }
      return newArray;
    }
  }

  function getProjectTypeAvailable() {
    const name = convertIdToName(
      workplans[workplanIndex].modoFaturacao,
      "invoicingModes"
    );
    if (name === "Projeto") {
      return false;
    }
    return true;
  }

  const monthsBtwnDates = (startDate, endDate) => {
    if (startDate === null || endDate === null) return;
    var newStart = new Date(startDate);
    var newEnd = new Date(endDate);
    newStart = new Date(
      newStart.getTime() - newStart.getTimezoneOffset() * +60000
    );
    newEnd = new Date(newEnd.getTime() - newEnd.getTimezoneOffset() * +60000);
    var startStr = newStart.toISOString();
    var endStr = newEnd.toISOString();

    startStr = startStr.slice(0, 10);
    endStr = endStr.slice(0, 10);
    var start = startStr.split("-");
    var end = endStr.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]);
      var startMon = i === startYear ? parseInt(start[1]) : 0;

      for (var j = startMon; j <= endMonth; j++) {
        var month = j;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }

    return dates;
  };

  function changeHoursArraySize() {
    const startDate = workplans[workplanIndex].dataInicioProjeto;
    const endDate = workplans[workplanIndex].dataFinalProjeto;

    const array = monthsBtwnDates(startDate, endDate);

    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          for (
            var m = 0;
            m <
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
              .length;
            m++
          ) {
            var currentHours =
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                m
              ];
            if (
              currentHours.horasPropostas != undefined ||
              currentHours.horasPropostas != null ||
              currentHours.horasPropostas?.length > 0
            ) {
              if (getNameFromId() === "Avença") {
                var hoursArray = [];

                if (array.length > 0) {
                  for (var a = 0; a < array.length; a++) {
                    const dateArray = array[a].split("-");
                    const currentMonth = parseInt(dateArray[1]);
                    hoursArray.push({
                      month: currentMonth,
                      year: parseInt(dateArray[0]),
                      hoursValue: currentHours.horasPropostas,
                    });
                  }
                }

                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ].horasPropostasArray = hoursArray;
              } else {
                const arrayHours = currentHours.horasPropostas.split(":");
                const newHoras =
                  parseFloat(arrayHours[0]) + parseFloat(arrayHours[1]) / 60;

                const currentDividedHours = newHoras / array.length;
                var n = new Date(0, 0);
                n.setSeconds(+currentDividedHours * 60 * 60);
                const dividedHoursString = n.toTimeString().slice(0, 5);
                var hoursArray = [];

                if (array.length > 0) {
                  for (var a = 0; a < array.length; a++) {
                    const dateArray = array[a].split("-");
                    const currentMonth = parseInt(dateArray[1]);
                    hoursArray.push({
                      month: currentMonth,
                      year: parseInt(dateArray[0]),
                      hoursValue: dividedHoursString,
                    });
                  }
                }

                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ].horasPropostasArray = hoursArray;
              }
              calculateHours(m, k, j, i);
            } else {
              var hoursArray = [];
              if (array.length > 0) {
                for (var i = 0; i < array.length; i++) {
                  const dateArray = array[i].split("-");
                  const currentMonth = parseInt(dateArray[1]);
                  hoursArray.push({
                    month: currentMonth,
                    year: parseInt(dateArray[0]),
                    hoursValue: "00:00",
                  });
                }
              }
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                m
              ].horasPropostasArray = hoursArray;
            }
          }
        }
      }
    }
  }

  function handleSubmitDates(dates, name) {
    if (name === "dataInicioProjeto") {
      if (workplans[workplanIndex].dataFinalProjeto === null) {
        workplans[workplanIndex].dataInicioProjeto = dates.data[0];
      } else {
        if (
          dates.data[0] <= new Date(workplans[workplanIndex].dataFinalProjeto)
        ) {
          workplans[workplanIndex].dataInicioProjeto = dates.data[0];
        } else {
          return;
        }
      }
    } else {
      if (workplans[workplanIndex].dataInicioProjeto === null) {
        workplans[workplanIndex].dataFinalProjeto = dates.data[0];
      } else {
        if (
          dates.data[0] >= new Date(workplans[workplanIndex].dataInicioProjeto)
        ) {
          workplans[workplanIndex].dataFinalProjeto = dates.data[0];
        } else {
          return;
        }
      }
      workplans[workplanIndex].dataFinalProjeto = dates.data[0];
    }
    changeHoursArraySize();
    /*
    workplans[workplanIndex].resumoFinaceiro
      ? (workplans[workplanIndex].resumoFinaceiro.toChange = 1)
      : (workplans[workplanIndex].resumoFinaceiro = { toChange: 1 });*/
    workplans[workplanIndex].resumoFinaceiro.toChange = 1;

    dispatch(setAllWorkplans(workplans));
  }

  function formatDate(text) {
    if (text === null || text === "" || text === undefined) return "";
    const dateInfo = text.split("-");
    return dateInfo[2] + "/" + dateInfo[1] + "/" + dateInfo[0];
  }

  return (
    <div className={"liftworld-proposal-accordion-wrapper"}>
      <Modal
        className="easypay-modal"
        showModal={isModalErrorShowing}
        setShowModal={setIsModalErrorShowing}
        headerContent={"Erro"}
        bodyContent={createBodyError()}
        key={uuid()}
      ></Modal>
      <Title text={"DADOS DA PROPOSTA"} type={"large"} />
      <div style={{ paddingTop: 15 }} />
      <>
        <div className={"liftworld-single-table"}>
          <div className={"liftworld-table-25-wrapper"}>
            <TableInput
              field={buildInputField("Nº Proposta", getProjectName())}
              size={"normal"}
              position={"start"}
              key={uuid()}
              disabled={true}
              workplanIndex={workplanIndex}
              name={"numProposta"}
            />
          </div>
          <div className={"liftworld-table-25-wrapper"}>
            <TableInput
              field={buildInputField("Nome da Proposta", items.nomeProposta)}
              size={"large"}
              position={"middle"}
              key={uuid()}
              workplanIndex={workplanIndex}
              name={"nomeProposta"}
            />
          </div>
          <div className={"liftworld-table-25-wrapper"}>
            <TableInput
              field={buildInputField("Nº Projeto", items.numeroGanho)}
              size={"normal"}
              position={"middle"}
              key={uuid()}
              workplanIndex={workplanIndex}
              name={"numeroGanho"}
              setIsModalErrorShowing={setIsModalErrorShowing}
            />
          </div>
          <div className={"liftworld-table-25-wrapper"}>
            <div>
              <div className={`${"liftworld-table-header-element"} ` + "end"}>
                Tipo de Projeto
              </div>
              <Dropdown
                text={""}
                required={true}
                list={projectTypeList}
                handleSubmit={(e) => handleProjectTypeChange(e)}
                initialSelected={convertIdToName(
                  items.tipoProjeto,
                  "projectTypes"
                )}
                position={"end"}
                size={"normal"}
              />
            </div>
          </div>
        </div>
        <div className={"liftworld-single-table"}>
          <div className={"liftworld-table-25-wrapper"}>
            <div className={`${"liftworld-table-header-element"} ` + "start"}>
              Estado
            </div>
            <Dropdown
              text={""}
              required={true}
              list={buildStateNameArray(states)}
              handleSubmit={(e) => handleStateChange(e, "estado")}
              initialSelected={items.estado}
              position={"start"}
              size={"normal"}
            />
          </div>
          <div className={"liftworld-table-25-wrapper"}>
            <div className={`${"liftworld-table-header-element"} ` + "middle"}>
              Probabilidade
            </div>
            <Dropdown
              text={""}
              required={true}
              list={buildNameArray(probabilities)}
              handleSubmit={(e) => handleTableChange(e, "probabilidade")}
              initialSelected={convertIdToName(
                items.probabilidade,
                "probabilities"
              )}
              position={"middle"}
              size={"normal"}
            />
          </div>
          <div className={"liftworld-table-25-wrapper"}>
            <div className={`${"liftworld-table-header-element"} ` + "middle"}>
              Business
            </div>
            <Dropdown
              text={""}
              required={true}
              list={buildNameArray(businesses)}
              handleSubmit={(e) => handleTableChange(e, "business")}
              initialSelected={convertIdToName(items.business, "businesses")}
              position={"middle"}
              size={"normal"}
            />
          </div>
          <div className={"liftworld-table-25-wrapper"}>
            <div className={`${"liftworld-table-header-element"} ` + "end"}>
              Proposta
            </div>
            <Dropdown
              text={""}
              required={true}
              list={proposalsList}
              handleSubmit={(e) => handleTableChange(e, "proposta")}
              initialSelected={convertIdToName(items.proposta, "proposalTypes")}
              position={"end"}
              size={"normal"}
            />
          </div>
        </div>
        <div className={"liftworld-single-table"}>
          <div className={"liftworld-table-25-wrapper"}>
            <div className={`${"liftworld-table-header-element"} ` + "start"}>
              Data Inicio Projeto
            </div>
            <InputDateSingle
              text={""}
              register={register}
              formState={formState}
              errorMessage="This field is required"
              watch={watch}
              type="created_at"
              placeholder=""
              dates={
                items.dataInicioProjeto != "" &&
                items.dataInicioProjeto != null &&
                items.dataInicioProjeto != undefined
                  ? [
                      new Date(items.dataInicioProjeto),
                      new Date(items.dataInicioProjeto),
                    ]
                  : null
              }
              handleChange={(dates) =>
                handleSubmitDates(dates, "dataInicioProjeto")
              }
              position={"start"}
            />
          </div>
          <div className={"liftworld-table-25-wrapper"}>
            <div className={`${"liftworld-table-header-element"} ` + "middle"}>
              Data Final Projeto
            </div>
            <InputDateSingle
              text={""}
              register={register}
              formState={formState}
              errorMessage="This field is required"
              watch={watch}
              type="created_at"
              placeholder=""
              dates={
                items.dataFinalProjeto != "" &&
                items.dataFinalProjeto != null &&
                items.dataFinalProjeto != undefined
                  ? [
                      new Date(items.dataFinalProjeto),
                      new Date(items.dataFinalProjeto),
                    ]
                  : null
              }
              handleChange={(dates) =>
                handleSubmitDates(dates, "dataFinalProjeto")
              }
              position={""}
            />
          </div>
          <div className={"liftworld-table-25-wrapper"}>
            <TableInput
              field={buildInputField(
                "Data Ident. da Lead",
                items.createdDate?.length > 10
                  ? formatDate(items.createdDate.slice(0, 10))
                  : items.createdDate
              )}
              size={"normal"}
              position={"middle"}
              disabled={true}
              key={uuid()}
              workplanIndex={workplanIndex}
              name={"dataIdentDaLead"}
              date={"date"}
            />
          </div>
          <div className={"liftworld-table-25-wrapper"}>
            <TableInput
              field={buildInputField(
                "Data Entrega da Proposta",
                items.dataEntregaPorposta?.length >= 10
                  ? formatDate(items.dataEntregaPorposta.slice(0, 10))
                  : items.dataEntregaPorposta
              )}
              size={"normal"}
              position={"end"}
              key={uuid()}
              workplanIndex={workplanIndex}
              name={"dataEntregaPorposta"}
              disabled={true}
              date={"date"}
            />
          </div>
        </div>
        <div className={"liftworld-single-table"}>
          <div className={"liftworld-table-25-wrapper"}>
            <TableInput
              field={buildInputField(
                "Data Adj/Fecho",
                items.dataAdjouFecho?.length >= 10
                  ? formatDate(items.dataAdjouFecho.slice(0, 10))
                  : items.dataAdjouFecho
              )}
              size={"normal"}
              position={"start"}
              key={uuid()}
              workplanIndex={workplanIndex}
              name={"dataAdjouFecho"}
              disabled={true}
              date={"date"}
            />
          </div>
          <div className={"liftworld-table-25-wrapper"}>
            {" "}
            <div className={`${"liftworld-table-header-element"} ` + "middle"}>
              Distribuição das Horas
            </div>
            <Dropdown
              text={""}
              required={true}
              list={facturationMethodList}
              handleSubmit={(e) => handleTableChange(e, "distribuicaoHoras")}
              initialSelected={convertIdToName(
                items.distribuicaoHoras,
                "invoicingModes"
              )}
              position={"middle"}
              size={"normal"}
              disabled={true}
            />
          </div>
          <div className={"liftworld-table-25-wrapper"}>
            <div className={`${"liftworld-table-header-element"} ` + "middle"}>
              Modo de Faturação
            </div>
            <Dropdown
              text={""}
              required={true}
              list={facturationMethodList}
              handleSubmit={(e) => handleTableChange(e, "modoFaturacao")}
              initialSelected={convertIdToName(
                items.modoFaturacao,
                "invoicingModes"
              )}
              position={"middle"}
              size={"normal"}
              disabled={true}
            />
          </div>
          <div className={"liftworld-table-25-wrapper"}>
            <div className={`${"liftworld-table-header-element"} ` + "end"}>
              Periocidade de Faturação
            </div>
            <Dropdown
              text={""}
              required={true}
              list={finalList}
              handleSubmit={(e) => handleNormalTable(e, "faseFinal")}
              initialSelected={items.faseFinal}
              position={"end"}
              size={"normal"}
              disabled={getProjectTypeAvailable()}
            />
          </div>
        </div>
      </>
    </div>
  );
};
export default ProposalDataAccordion;
