import React, { useRef } from "react";
import { useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import "../../../assets/css/styles.css";
import { updateUserSettings } from "../../../pages/User/actions";
import ItemTypes from "./ItemTypes";
import Widget from "./Widget";

const List = ({ listId, widgets }) => {
  const dispatch = useDispatch();
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  let { path } = useRouteMatch();
  const ref = useRef(null);
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.BOX,
    drop(item, monitor) {
      const isOverCurrent = monitor.isOver({ shallow: true });
      if (isOverCurrent) {
        const destinationListIndex = listId;
        const newIndex = undefined;
        const oldIndex = item.index;
        const sourceListIndex = item.list;
        const dragId = item.id;
        const dashboard = userSettings.dashboards.find(
          (item) => item.application === path
        );
        const placements = dashboard.placements ? dashboard.placements : [[]];

        const length = placements[destinationListIndex].length;

        if (destinationListIndex === sourceListIndex && length === 1)
          return undefined;

        if (destinationListIndex === sourceListIndex && newIndex === oldIndex)
          return undefined;

        let newPlacements = Array.from(placements);
        if (destinationListIndex === sourceListIndex) {
          let newIndex_;
          if (newIndex === undefined) {
            newIndex_ = length - 1;
          } else {
            newIndex_ = newIndex;
          }
          /*exists bug on the backend, sometimes widgets do not match placements, here is workaround*/
          const newDestinationList = Array.from(
            placements[destinationListIndex]
          ).filter((id) => widgets.some((widget) => widget.id === id));

          newDestinationList.splice(oldIndex, 1);
          newDestinationList.splice(newIndex_, 0, dragId);
          newPlacements.splice(destinationListIndex, 1);
          newPlacements.splice(destinationListIndex, 0, newDestinationList);
        } else {
          const newDestinationList = Array.from(
            placements[destinationListIndex]
          ).filter((id) => widgets.some((widget) => widget.id === id));
          const newSourceList = Array.from(placements[sourceListIndex]).filter(
            (id) => widgets.some((widget) => widget.id === id)
          );

          let newIndex_;
          if (length === 0) newIndex_ = 0;
          if (newIndex === undefined && length > 0) newIndex_ = length;
          newDestinationList.splice(newIndex_, 0, dragId);
          newSourceList.splice(oldIndex, 1);
          newPlacements.splice(destinationListIndex, 1);
          newPlacements.splice(destinationListIndex, 0, newDestinationList);
          newPlacements.splice(sourceListIndex, 1);
          newPlacements.splice(sourceListIndex, 0, newSourceList);
        }
        const newDashboards = userSettings.dashboards.map((item) => {
          if (item.application === path) {
            item.placements = newPlacements;
            return item;
          } else return item;
        });
        const payload = {
          ...userSettings,
          dashboards: newDashboards,
        };
        dispatch(updateUserSettings(payload));
      }
      return undefined;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  drop(ref);

  return (
    <div ref={ref} className="list">
      {widgets.map((widget, index) => {
        return (
          <Widget
            key={index}
            widget={widget}
            widgets={widgets}
            list={listId}
            index={index}
          ></Widget>
        );
      })}
    </div>
  );
};
export default List;
