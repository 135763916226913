import React, { useEffect, useState} from "react";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";
import { Card } from "react-bootstrap";
import "../RhCollaborator.scss";
import { useSelector, useDispatch } from "react-redux";
import { getCollaborators } from "~/store/ducks/portalrh/actionTypes";
import RhNavBar from "./PersonalItems/RhNavBar";
import Sidebar from '../Sidebar';
const devLogConsole = require("~/utils/devLog");
const Personal_RHCollaborator = ({portalRhMenus, designacao, path}) => {
  const { user } = useSelector((state) => state.globalReducer);
  const { collaborators } = useSelector((state) => state.portalrhReducer);
  const [menuSideBar, setMenuSideBar] = useState(null)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCollaborators());
    portalRhMenus.map((menu)=>{
      if(menu.designacao == designacao){
        devLogConsole(menu)
        setMenuSideBar(menu)
      }
    })

  }, [dispatch]);

  const userNamePortalRH = collaborators.find(
    (e) => e.username == user.legacyUsers[0].username
  );

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "default";
  }, []);

  return (
    <div style={{display:'flex'}}>
    <Sidebar menuSideBar={menuSideBar} path={path} />
      <div
        className="main-card-v2"
        style={{ overflow: "auto", scrollY: "auto" }}
      >
        <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
          <Card.Body>
            <>
              <RhNavBar rowCollaborator={userNamePortalRH || ""} />
            </>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Personal_RHCollaborator;
