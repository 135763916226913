import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { withNamespaces } from "react-i18next";

import Spinner from "~/components/Spinner";
import pagination from "~/components/pagination/currentSizePerPage/10";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { PORTALRH_MIDDLEWARE } from "~/utils/constants";
import { useInterval } from "~/components/Notifications/utils/useInterval.js";

import {
  getRequestsByUsername,
  setGenericRequestState,
  setapproveDeleteRemoveDayRemoteWork,
  setapproveDeleteRemoveMarcacaoFerias
} from "~/store/ducks/portalrh/actionTypes";

import { Tabs, Tab } from "react-bootstrap";

import { RequestState } from "~/components/widgets/Requests/TabsEnum.js";

const PersonalAreaRequestTable = ({
  searchFilter,
  t,
  handleOpenFlowModal,
  handleOpenModalRemoteWork,
  handleOpenModalMarcacaoFerias,
  makeUpdate,
  setmakeUpdate,
  setmessageToast,
}) => {
  const { genericRequestState, infoApproveDeleteRemoveDayRemoteWork, infoApproveDeleteRemoveMarcacaoFerias} =
    useSelector((state) => state.portalrhReducer);
  const [requestsValid, setRequestsValid] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);

  const { user } = useSelector((state) => state.globalReducer);

  const dispatch = useDispatch();
  const usernamePortalRh = user.legacyUsers.find(
    (item) => item.legacyApplication.label === PORTALRH_MIDDLEWARE.context
  ).username;
  const { requests, isLoadingRequests } = useSelector(
    (state) => state.portalrhReducer
  );

  useEffect(() => {
    if (Object.keys(infoApproveDeleteRemoveDayRemoteWork).length > 0) {
      const updatedRequestsValid = requestsValid.map((request) => {
        if (request.id === infoApproveDeleteRemoveDayRemoteWork[0].id) {
          return infoApproveDeleteRemoveDayRemoteWork[0];
        }
        return request;
      });
      if (infoApproveDeleteRemoveDayRemoteWork[0].state === "Anulado") {
        setmessageToast("O seu pedido foi Cancelado");
      }
      setRequestsValid(updatedRequestsValid);
      let validateProcGeneric = checkValidateProc();
      if (validateProcGeneric) {
        dispatch(getRequestsByUsername(usernamePortalRh, 1));
      } else {
        dispatch(getRequestsByUsername(usernamePortalRh));
      }
      dispatch(setapproveDeleteRemoveDayRemoteWork({}));
      dispatch(setapproveDeleteRemoveMarcacaoFerias({}))
    } else {
      const approvedRequests = requests.filter((request) => {
        if (searchFilter === 'marcacaoFerias') {
          return request.authorizer_approves === -1 && request.description.includes('vacationsManagement');
        } else {
          return request.authorizer_approves === -1 && request.description.includes(searchFilter);
        }
      });
      setRequestsValid(approvedRequests);
    }
  }, [infoApproveDeleteRemoveDayRemoteWork, requests]);

  useEffect(() => {
    if (Object.keys(infoApproveDeleteRemoveMarcacaoFerias).length > 0) {
      const updatedRequestsValid = requestsValid.map((request) => {
        if (request.id === infoApproveDeleteRemoveMarcacaoFerias[0].id) {
          return infoApproveDeleteRemoveMarcacaoFerias[0];
        }
        return request;
      });
      if (infoApproveDeleteRemoveMarcacaoFerias[0].state === "Anulado") {
        setmessageToast("O seu pedido foi Cancelado");
      }
      setRequestsValid(updatedRequestsValid);
      let validateProcGeneric = checkValidateProc();
      if (validateProcGeneric) {
        dispatch(getRequestsByUsername(usernamePortalRh, 1));
      } else {
        dispatch(getRequestsByUsername(usernamePortalRh));
      }
      dispatch(setapproveDeleteRemoveDayRemoteWork({}));
      dispatch(setapproveDeleteRemoveMarcacaoFerias({}))
    } else {
      const approvedRequests = requests.filter((request) => {
        if (searchFilter === 'marcacaoFerias') {
          return request.authorizer_approves === -1 && request.description.includes('vacationsManagement');
        } else {
          return request.authorizer_approves === -1 && request.description.includes(searchFilter);
        }
      });
      setRequestsValid(approvedRequests);
    }
  }, [infoApproveDeleteRemoveMarcacaoFerias, requests]);

  useEffect(() => {
    //devLogConsole(requests);
    const approvedRequests = requests.filter(
      (request) => request.authorizer_approves === -1
    );
    setRequestsValid(approvedRequests);
  }, [requests]);

  const checkValidateProc = () => {
    switch (searchFilter) {
      case "absenceRecord":
        return false;
      case "extraTime":
        return false;
      case "insertPresence":
        return false;
      case "pagamentosBolsa":
        return false;
      case "remoteWork":
        return false;
      case "marcacaoFerias":
        return false;
      default:
        return true;
    }
  };

  useEffect(() => {
    let validateProcGeneric = checkValidateProc();
    if (makeUpdate) {
      if (validateProcGeneric) {
        dispatch(getRequestsByUsername(usernamePortalRh, 1));
      } else {
        dispatch(getRequestsByUsername(usernamePortalRh));
      }
      //setmakeUpdate(false);
    }
  }, [makeUpdate]);

  useInterval(() => {
    let validateProcGeneric = checkValidateProc();
    if (validateProcGeneric) {
      dispatch(getRequestsByUsername(usernamePortalRh, 1));
    } else {
      dispatch(getRequestsByUsername(usernamePortalRh));
    }
  }, 1000 * 60);

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "id",
      text: t("portalrh.requestTable.requestId"),
      sort: true,
    },
    {
      dataField: "auth_designation",
      text: t("portalrh.requestTable.description"),
      sort: true,
    },
    {
      dataField: "creation_date",
      text: t("portalrh.requestTable.date"),
      sort: true,
    },
    {
      dataField: "change_type",
      text: t("portalrh.requestTable.type"),
      sort: true,
      hidden: true,
      // formatter: (cellContent, row) => {
      //   return t(`portalrh.workDays.${row.type}`);
      // },
    },
    {
      dataField: "state",
      text: t("portalrh.requestTable.state"),
      sort: true,
      formatter: (cellContent, row) => {
        return t(`portalrh.workDays.${row.state}`);
      },
    },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.stopPropagation();
      e.preventDefault();
      if (searchFilter === "remoteWork") {
        handleOpenModalRemoteWork(row);
      } else if (searchFilter.trim() === "marcacaoFerias") {
        handleOpenModalMarcacaoFerias(row);
      } else {
        handleOpenFlowModal(row);
      }
    },
  };

  useEffect(() => {
    const filteredData = requestsValid.filter((request) => {
      if (searchFilter !== undefined) {
        if (genericRequestState === RequestState.PENDING) {
          return request.state === "Pendente";
        } else if (genericRequestState === RequestState.APPROVED) {
          return request.state === "Aprovado";
        } else if (genericRequestState === RequestState.REJECTED) {
          return request.state === "Recusado";
        }
      } else {
        return request.description === searchFilter;
      }
    });
    setFilteredRequests(filteredData);
  }, [searchFilter, genericRequestState, requestsValid]);

  return (
    <>
      <div className="custom-table custom-table-small cursor-pointer">
        <ToolkitProvider
          keyField="id"
          data={filteredRequests.sort((a, b) => b.id - a.id)}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div style={{ paddingBottom: "20px", display: "flex" }}>
                <SearchBar {...props.searchProps} placeholder={t("Search")} />
              </div>

              <Tabs
                id="authorizationTabs"
                defaultActiveKey={genericRequestState}
                className="custom-tabs"
                activeKey={genericRequestState}
                onSelect={(k) => dispatch(setGenericRequestState(k))}
              >
                <Tab
                  eventKey={RequestState.PENDING}
                  title={t("portalrh.requests.pendingRequests")}
                ></Tab>
                <Tab
                  eventKey={RequestState.APPROVED}
                  title={t("portalrh.requests.approvedRequests")}
                ></Tab>
                <Tab
                  eventKey={RequestState.REJECTED}
                  title={t("portalrh.requests.refusedRequests")}
                ></Tab>
              </Tabs>

              <Spinner spinning={isLoadingRequests} wrapper />
              {!isLoadingRequests && ( 
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  pagination={paginationFactory(pagination)}
                  noDataIndication={t("portalrh.personalData.noRequests")}
                  rowEvents={rowEvents}
                  striped
                  hover
                />
              )}
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
};
export default withNamespaces()(PersonalAreaRequestTable);
