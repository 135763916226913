import React, { useState, useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import uuid from "uuid/v1";
import { some } from "lodash";
import "../assets/scss/_backOfficeRepLegTable.scss";
import "../assets/scss/_backOfficeDocumentTable.scss";
import Button from "./Button";
import iconGreenCheckmark from "~/pages/Easypay/assets/img/icons/green-checkmark.png";
import iconDocument from "~/pages/Easypay/assets/img/icons/icon-contract.png";
import iconError from "~/pages/Easypay/assets/img/icons/icon-error-filled.png";
import iconWaiting from "~/pages/Easypay/assets/img/icons/icon-waiting.png";
import Table from "~/pages/Easypay/components/Table";
import Modal from "~/pages/Easypay/components/Modal";
import ButtonUpload from "./ButtonUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  updateUser,
  getDocumentCoresystem,
  setDocumentCoresystem,
  createDocumentCoresystem,
  addDocumentCoresystem,
  setCreateDocumentCoresystem,
} from "~/store/ducks/onboarding/actionTypes";
const devLogConsole = require("~/utils/devLog");
const BackOfficeDocumentsSection = ({ id, t, data }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const inputFile = useRef(null);
  const testIndex = useRef(null);

  const { documentCoresystem, createDocumentCoresystemId } = useSelector(
    (state) => state.onboardingReducer
  );

  const { user } = useSelector((state) => state.globalReducer);
  const addButtonField = {
    type: "link",
    text: "",
    cssclass: "",
    onclick: "",
    onmouseover: "center",
    align: "left",
    href: "javascript:disableForm(false);document.dados.toggleCodigoCertidao.value='submitMain';document.dados.op.value='3';document.dados.submit();",
    newwindow: "false",
    newwindowname: "",
    disabled: "false",
    even_field: "true",
  };
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [docIndex, setDocIndex] = useState();
  //const [currentUploadDocIndex, setCurrentUploadDocIndex] = useState();

  const [currentBase64, setCurrentBase64] = useState();
  const [file, setFile] = useState();

  const dateFormatter = (cell) => {
    if (!cell) {
      return cell;
    }
    const dateAux = new Date(cell);
    return dateAux
      .getUTCDate()
      .toString()
      .concat("-", dateAux.getMonth() + 1, "-", dateAux.getFullYear());
  };

  useEffect(() => {
    if (documentCoresystem != null && documentCoresystem != undefined) {
      const a = document.createElement("a");
      a.href = `data:${"pdf"};base64,${documentCoresystem}`;
      a.download = "test.pdf";
      a.click();

      dispatch(setDocumentCoresystem(null));
    }
  }, [documentCoresystem]);

  useEffect(() => {
    if (
      createDocumentCoresystemId != null &&
      file != null &&
      file != undefined
    ) {
      const type = file.type ? file.type : "NOT SUPPORTED";

      data.documents[testIndex.current].createdDate = new Date();
      data.documents[testIndex.current].modifiedDate = new Date();
      data.documents[testIndex.current].nomeDocumento = file.name;
      data.documents[testIndex.current].idCoresystem =
        createDocumentCoresystemId.id;
      data.documents[testIndex.current].content = currentBase64;
      data.documents[testIndex.current].submited = "1";
      data.documents[testIndex.current].mimeType = file.name;
      data.documents[testIndex.current].emitted = file.name;
      data.documents[testIndex.current].expired = file.name;
      dispatch(
        addDocumentCoresystem(
          {
            id: createDocumentCoresystemId.id,
            fileName: file.name,
            applicationType: type,
            content: currentBase64,
          },
          "61798db3a0ac1d6257017e87"
        )
      );
      dispatch(setCreateDocumentCoresystem(null));
      for (var i = 0; i < data.documents.length; i++) {
        if (
          data.documents[i].nomeDocumento === "" ||
          data.documents[i].nomeDocumento === null ||
          data.documents[i].nomeDocumento === undefined
        ) {
          if (
            data.documents[i].tipoDocumento === "" ||
            data.documents[i].tipoDocumento === null ||
            data.documents[i].tipoDocumento === undefined
          ) {
            data.account.estadoLead = "DOC";
            break;
          } else if (
            data.documents[i].tipoDocumento === "RL" &&
            data.account.estadoLead != "DOC"
          ) {
            data.account.estadoLead = "RL";
            break;
          } else if (
            data.documents[i].tipoDocumento === "BE" &&
            data.account.estadoLead != "RL" &&
            data.account.estadoLead != "DOC"
          ) {
            data.account.estadoLead = "BE";
            break;
          }
        }
      }
      devLogConsole(testIndex.current);
      devLogConsole(data.documents[testIndex.current]);
      dispatch(updateUser({ user: data, pid: id }));
    }
  }, [createDocumentCoresystemId]);

  function setDocState(index) {
    if (
      data.documents[index].nomeDocumento === "" ||
      data.documents[index].nomeDocumento === null ||
      data.documents[index].nomeDocumento === undefined
    )
      return;
    setDocIndex(index);
    setIsModalShowing(true);
  }

  function onButtonClick(index) {
    // `current` points to the mounted file input element
    testIndex.current = index;
    inputFile.current.click();
  }

  function getBase64(file) {
    // encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      setCurrentBase64(base64String);
    };
    reader.readAsDataURL(file);
  }

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();

    var currentFile = event.target.files[0];
    setFile(currentFile);

    const emitted_at = currentFile.lastModifiedDate;
    const expires_at = new Date(
      new Date(currentFile.lastModifiedDate).setFullYear(
        new Date().getFullYear() + 1
      )
    );

    const extension = "." + currentFile.name.split(".")[1];
    var newExpires = expires_at.toISOString().split(".")[0] + "Z";
    var newEmitted = emitted_at.toISOString().split(".")[0] + "Z";
    const type = currentFile.type ? currentFile.type : "NOT SUPPORTED";
    dispatch(
      createDocumentCoresystem(
        {
          topology: "identification_card",
          is_private: true,
          metadata: `{\n  \"topology\": \"identification_card\",\n  \"is_private\": true,\n  \"metadata\": {},\n  \"emitted_at\": \"${newEmitted}\",\n  \"expires_at\": \"${newExpires}\",\n  \"mime_type\": \"${type}\",\n  \"extension\": \"${extension}\"\n}`,
          emitted_at: newEmitted,
          expires_at: newExpires,
          mime_type: type,
          extension: extension,
        },
        "61798db3a0ac1d6257017e87"
      )
    );
    getBase64(currentFile);
  };

  const stateFormatter = (cell) => {
    var currentIndex;
    for (var i = 0; i < data?.documents.length; i++) {
      if (data?.documents[i].id === cell) {
        currentIndex = i;
        break;
      }
    }

    if (data?.documents[currentIndex].estadoDocumento === 0) {
      return (
        <div
          className="easypay-text-icon-check-documents"
          onClick={() => {
            if (!some(user.roles, { label: "ONBOARDINGComercial" })) {
              setDocState(currentIndex);
            }
          }}
        >
          <img src={iconWaiting} alt="Icon Check" />
        </div>
      );
    } else if (data?.documents[currentIndex].estadoDocumento === -1) {
      return (
        <div
          className="easypay-text-icon-check-documents"
          onClick={() => {
            if (!some(user.roles, { label: "ONBOARDINGComercial" })) {
              setDocState(currentIndex);
            }
          }}
        >
          <img src={iconError} alt="Icon Check" />
        </div>
      );
    } else {
      return (
        <div
          className="easypay-text-icon-check-documents"
          onClick={() => {
            if (!some(user.roles, { label: "ONBOARDINGComercial" })) {
              setDocState(currentIndex);
            }
          }}
        >
          <img src={iconGreenCheckmark} alt="Icon Check" />
        </div>
      );
    }
  };

  function downloadFile(index) {
    dispatch(
      getDocumentCoresystem(
        data?.documents[index].idCoresystem,
        "61798db3a0ac1d6257017e87"
      )
    );
  }

  function approveReproveDocument(operation) {
    devLogConsole(data.documents[docIndex]);
    if (operation) {
      data.documents[docIndex].estadoDocumento = 1;
    } else {
      data.documents[docIndex].estadoDocumento = -1;
    }

    for (var i = 0; i < data.documents.length; i++) {
      if (data.documents[i].estadoDocumento === -1) {
        data.client.hasDocRejected = 1;
        break;
      }
    }
    setIsModalShowing(false);
    dispatch(updateUser({ user: data, pid: id }));
  }

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant={"easypay-button-primary easypay-success"}
            text={t(`bpm.onboarding.backoffice.administration.buttonApprove`)}
            onclick={() => {
              approveReproveDocument(true);
            }}
          />
          <Button
            variant={"easypay-button-primary easypay-error"}
            text={t(`bpm.onboarding.backoffice.administration.buttonFail`)}
            onclick={() => {
              approveReproveDocument(false);
            }}
          />
        </div>
      </div>
    );
  }

  function handleDeleteFile(index) {
    devLogConsole(index);
    devLogConsole(data.documents[index]);
    data.documents[index].idCoresystem = "";
    data.documents[index].nomeDocumento = "";
    data.documents[index].content = "";
    data.documents[index].submited = "0";
    data.documents[index].estadoDocumento = 0;
    data.account.modifiedDate = new Date();
    var reject = 0;
    for (var i = 0; i < data.documents.length; i++) {
      if (data.documents[i].estadoDocumento === -1) {
        reject = 1;
        break;
      }
    }
    data.client.hasDocRejected = reject;
    dispatch(updateUser({ user: data, pid: data?.account?.pid }));
  }

  const fileNameFormatter = (cell) => {
    if (cell === "" || cell === undefined) return "";

    var currentIndex;
    for (var i = 0; i < data?.documents.length; i++) {
      if (data?.documents[i].id === cell) {
        currentIndex = i;
        break;
      }
    }
    if (
      !(
        data?.documents[currentIndex].nomeDocumento === "" ||
        data?.documents[currentIndex].nomeDocumento === undefined ||
        data?.documents[currentIndex].nomeDocumento === null
      )
    ) {
      return (
        <div className="easypay-backoffice-document-table-item-doc-img-name-wrapper">
          <div
            className="easypay-x-icon-padding-right"
            onClick={() => handleDeleteFile(currentIndex)}
          >
            X
          </div>
          <div className="easypay-text-icon-check">
            <img src={iconDocument} alt="Icon Check" />
          </div>
          <div
            className="easypay-backoffice-document-table-item-doc-name"
            onClick={() => downloadFile(currentIndex)}
          >
            {data?.documents[currentIndex].nomeDocumento}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {" "}
          <ButtonUpload
            text={t(`bpm.onboarding.backoffice.user.buttonUploadDocument`)}
            variant={"easypay-button-secondary-icon"}
            onclick={() => {
              onButtonClick(currentIndex);
            }}
            onChange={handleChange}
            key={uuid()}
            inputFile={inputFile}
            id={"id1"}
            className="card-button"
            align={"left"}
            disabledColored={false}
            disabled={false}
          />
        </div>
      );
    }
  };

  function nameFormatter(cell) {
    var currentIndex;
    for (var i = 0; i < data?.documents.length; i++) {
      if (data?.documents[i].id === cell) {
        currentIndex = i;
        break;
      }
    }
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="easypay-backoffice-document-table-item-doc-title">
          {data?.documents[i].nomeCompleto}
        </div>
        <div className="easypay-backoffice-document-table-item-doc-desc">
          {data?.documents[i].descDocumento}
        </div>
      </div>
    );
  }

  function hourFormatter(cell) {
    return <div>{cell?.toString().substring(11, 16)}</div>;
  }

  const columns = [
    {
      dataField: "id",
      text: t(`bpm.onboarding.backoffice.user.labelDocument`),
      sort: true,
      formatter: nameFormatter,
      style: {
        "min-width": "fit-content",
      },
    },
    {
      dataField: "id",
      text: t(`bpm.onboarding.backoffice.user.labelFileName`),
      sort: true,
      formatter: fileNameFormatter,
    },
    {
      dataField: "createdDate",
      text: t("bpm.onboarding.backoffice.administration.headerDate"),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "modifiedDate",
      text: "Hora",
      sort: true,
      formatter: hourFormatter,
    },
    {
      dataField: "id",
      text: t("bpm.onboarding.backoffice.administration.headerState"),
      sort: false,
      formatter: stateFormatter,
      style: {
        width: "50px",
      },
    },
  ];

  function filterDocList(docList) {
    const result = docList.filter((doc) => doc.isPDA === 0);
    return result;
  }

  return (
    <div className="easypay-backoffice-sections">
      <Modal
        className="easypay-modal"
        showModal={isModalShowing}
        setShowModal={setIsModalShowing}
        headerContent={"Pretende aprovar ou reprovar o documento ?"}
        bodyContent={createBody()}
        key={uuid()}
      ></Modal>
      <div className="easypay-backoffice-registry-section-header">
        {t(`bpm.onboarding.backoffice.user.verticalTabDocuments`)}
      </div>
      <Table
        sort={{ dataField: "client.pid", order: "desc" }}
        docs={data?.documents ? filterDocList(data?.documents) : []}
        columns={columns}
        key="onboarding-table"
        hidePagination={true}
      />
      <Button
        text={t(`bpm.onboarding.backoffice.user.modalHeaderAdd`)}
        variant={"easypay-button-add"}
        onclick={() =>
          history.push({
            pathname: `/uniksystem/easypay/backoffice-request-document-page/${id}`,
          })
        }
        key={uuid()}
        id={addButtonField.id}
        className="card-button"
        align={"left"}
        disabledColored={false}
        disabled={false}
        field={addButtonField}
      />
    </div>
  );
};

export default withNamespaces()(BackOfficeDocumentsSection);
