import React, { useState, useRef, useEffect } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import _uniqueId from "lodash/uniqueId";
import arrowUp from "~/assets/img/icons/arrow-up-blue.png";
import arrowDown from "~/assets/img/icons/arrow-down-grey.png";

/*
    Props explanation:
        - t (used when needing to translate any 'general' words) **REQUIRED**
        - list (list of options the dropdown is going to use) **REQUIRED**
        - handleSubmit={(value) => handleSelectChange(value)} **REQUIRED**
        - placeholder **DEFAULT is "option"**
        - required={required} **DEFAULT is false**
        - disabled (used when user wants the input to be disabled)
        - resetDropdown (used to reset the Dropdown value and label)
        - setResetDropdown (used after every reset to enable dropdown again)
*/

/* IMPORTANT */
/* When using list make sure you have it in the correct layout. Example:
  
        list = [
            {"label": "label here",
            "value": "value here"}
                ]

    If you have any option in the list that you want to make as default input 
    just add in the property "selected" :true to the list option
*/

const Dropdown = (props) => {
  const {
    t,
    list = [{ label: t("general.dataNotFound") }],
    required = false,
    handleSubmit = false,
    placeholder = "signature.selectOption",
    disabled,
    text,
    name,
    emptyDefault,
    hideIcon,
    showTooltip = false,
    order = true,
    resetDropdown,
    setResetDropdown,
    className = "",
    defaultValue,
    hide,
    nomargin,
    overridePlaceholder,
    lang,
    flowId,
    haveWidth
  } = props;
  /*
        Sorts an array by name, or number (both ascending or descending)
    */
  const sortBy = {
    nameAscending: (prop) =>
      prop.sort((a, b) => a.label.localeCompare(b.label)),
    nameDescending: (prop) =>
      prop.sort((a, b) => b.label.localeCompare(a.label)),
    numberAscending: (prop) => prop.sort((a, b) => a - b),
    numberDescending: (prop) => prop.sort((a, b) => b - a),
    noOrder: (prop) => prop,
  };

  //Sorts list
  const optionList = order ? sortBy.nameAscending(list) : list;

  const [selectedValue, setSelectedValue] = useState("");
  const [label, setLabel] = useState("");
  const [displayList, setDisplayList] = useState([optionList]);
  const [showDropdown, setShowDropdown] = useState(false);

  //Randomly creates an id for dropdown and input
  const id = useRef(_uniqueId(""));

  useEffect(() => {
    const defaultItem = optionList.find((item) => item.selected === true);
    const defaultItemFlowId = optionList.find((item) => item.value === flowId);
    if(lang){
      const result = optionList.find((item) => item.value === lang.language);
      if(result){
        setLabel(result.label);
        setSelectedValue(result.value);
      }
    }
    if (defaultItem && lang == undefined && defaultItemFlowId == undefined) {
      setLabel(defaultItem.label);
      setSelectedValue(defaultItem.value);
    }

    if(defaultItemFlowId){
      setLabel(defaultItemFlowId.label);
      setSelectedValue(defaultItemFlowId.value);
    }

    if (!emptyDefault) {
      if (label === "" && list.length > 0) {
        setLabel(list[0].label);
        setSelectedValue(list[0].value);
      }
    }
    if (resetDropdown && setResetDropdown) {
      setLabel("");
      setSelectedValue("");
      setResetDropdown(false);
    }
  }, [list, resetDropdown, emptyDefault, label, optionList, setResetDropdown]);

  useEffect(() => {
    if (
      (defaultValue !== "" || defaultValue !== undefined) &&
      (label === "" || hide)
    ) {
      setLabel(defaultValue);
    }
  }, [hide]);

  useEffect(() => {


    const item = optionList.find((item) => item.value == defaultValue);

    if (defaultValue && item?.label) {
      setLabel(item.label);
    }
    if (defaultValue === "" && !item) {
      if(overridePlaceholder!==undefined && overridePlaceholder !== null)
        setLabel("Selecione uma opção");
      else
        setLabel("Select an option");
    }
  }, [defaultValue, label, optionList]);

  /*
    Function that runs when clicking any option
  */
  const handleSelect = (e) => {
    //Changes input to the selected option
    setSelectedValue(e.target.value);
    const element = optionList.find(
      (element) => element.value === e.target.value
    );

    if (element) {
      setLabel(element.label);
    }
    //Sorts list to display selected first
    sortListAfterSelect(optionList, e.target.value);
    //Hides dropdown after selecting any option
    hideDropDown();
    const defaultItem = optionList.find((item) => item.selected === true);
    const defaultItemIflow = optionList.find((item) => item.value === flowId);

    if (!handleSubmit) {
      setSelectedValue(e.target.value);
      setLabel(e.target.label);
      return;
    }
    if(defaultItemIflow){
      if (defaultItemIflow.value !== e.target.value) {
        handleSubmit(e.target.value);
      }
    } else  if (defaultItem) {
      if (defaultItem.value !== e.target.value) {
        handleSubmit(e.target.value);
      }
    } else {
      handleSubmit(e.target.value);
    }
  };

  /* 
    Sorts list to display selected element in first place. Also deletes it from the displayed list
  */
  const sortListAfterSelect = (list, selected) => {
    let newList = [];
    newList[0] = list.filter((e) => e.value == selected)[0];
    newList = newList.concat(list.filter((e) => e.value != selected));
    setDisplayList(newList);
    return newList;
  };

  /*
    Clears out the input whenever the option isn't in list
  */
  const handleClose = () => {
    const inputValue = document
      .getElementById(`formInput-${id.current}`)
      .value.trim();

    if (
      optionList.some(
        (option) => option.label.toUpperCase() === inputValue.toUpperCase()
      )
    ) {
      const option = optionList.find(
        (option) => option.label.toUpperCase() === inputValue.toUpperCase()
      );
      setSelectedValue(option.value);
      setLabel(option.label);
      if (!handleSubmit) {
        return;
      }
      handleSubmit(option.value);

      const defaultItem = optionList.find((item) => item.selected === true);
      if (defaultItem && defaultItem.value !== option.value) {
        handleSubmit(option.value);
      }
    } else {
      const defaultItem = optionList.find((item) => item.selected === true);
      if (defaultItem) {
        setSelectedValue(defaultItem.value ? defaultItem.value : "");
        setLabel(defaultItem.label ? defaultItem.label : "");
      } else {
        setSelectedValue("");
        setLabel("");
      }
    }
  };

  /*
    Toggles dropdown list everytime user clicks in input
    If first element is selected custom-shows it highlighted
  */
  const toggleList = () => {
    document
      .getElementById(`dropDown-${id.current}`)
      .classList.toggle("custom-show");

    if (
      document
        .getElementById(`dropDown-${id.current}`)
        .className.includes("custom-show")
    ) {
      let newList = sortListAfterSelect(optionList, selectedValue);

      if (newList[0] === undefined) {
        setDisplayList(optionList);
      } else {
        setDisplayList(sortListAfterSelect(optionList, selectedValue));
      }
    } else {
      handleClose();
    }
  };

  const hideDropDown = (desiredId) => {
    desiredId === undefined
      ? document
          .getElementById(`dropDown-${id.current}`)
          .classList.remove("custom-show")
      : document
          .getElementById(`dropDown-${desiredId}`)
          .classList.remove("custom-show");
  };

  /*
     Function that runs whenever the input get out of focus
  */
  const outOfFocus = () => {
    handleClose();
    hideDropDown(parseInt(`formInput-${id.current}`.match(/\d+/)[0]));
  };

  return (
    <>
      {hide ? (
        ""
      ) : (
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id={`tooltip`}>{label}</Tooltip>}
          trigger={showTooltip ? ["hover", "focus"] : []}
        >
          <Form.Group
            controlId={`formText-field`}
            className={className}
            style={{ margin: nomargin ? "0" : "", width: haveWidth ? "100%": "" }}
          >
            <div className={"custom-input-dropdown-wrapper"} onBlur={() => {}}>
              <div className="custom-input-label">{t(text)}</div>
              <div
                className={`custom-input-dropdown-field-wrapper ${
                  disabled ? "input-dropdown-disabled" : ""
                }`}
              >
                <input
                  disabled={disabled}
                  autoComplete="off"
                  onFocus={() => {
                    setShowDropdown(true);
                  }}
                  className={`custom-input-dropdown-field ${
                    label ? "custom-filled" : ""
                  } `}
                  placeholder={t(placeholder)}
                  id={`formInput-${id.current}`}
                  onMouseDown={() => {
                    toggleList();
                    setShowDropdown(!showDropdown);
                  }}
                  value={label !== "" && label !== undefined ? label : ""}
                  required={required}
                  onBlur={() => {
                    outOfFocus();
                    setShowDropdown(false);
                  }}
                  onChange={(e) => {
                    setLabel(e.target.value);
                  }}
                  readOnly
                />
                {!hideIcon && (
                  <div
                    className="custom-input-dropdown-icon-wrapper"
                    onMouseDown={() => {
                      toggleList();
                      setShowDropdown(!showDropdown);
                    }}
                    tabIndex="1"
                    onBlur={() => {
                      outOfFocus();
                      setShowDropdown(false);
                    }}
                  >
                    <img
                      className={`custom-input-dropdown-icon ${
                        !showDropdown ? "" : "custom-rotate-180"
                      }`}
                      src={showDropdown ? arrowUp : arrowDown}
                      alt="search-icon"
                    />
                  </div>
                )}
              </div>

              <div id={`dropDown-${id.current}`} className="custom-dropdown">
                {displayList.map((option, i) => {
                  return option?.label === t("general.dataNotFound") ? (
                    <option id="custom-dropdown-no-result " key={i}>
                      {t("general.dataNotFound")}
                    </option>
                  ) : option.label ===
                      t(
                        "portalrh.documentManagement.selectOperationPlaceholder"
                      ) || option.label === "" ? null : (
                    <option
                      className="custom-dropdown-option "
                      key={i}
                      onMouseDown={(e) => handleSelect(e)}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  );
                })}
              </div>
            </div>
            <input type="hidden" name={name} value={selectedValue} />
            <Form.Control.Feedback type="invalid">
              {`O campo field é de preenchimento obrigatório`}
            </Form.Control.Feedback>
          </Form.Group>
        </OverlayTrigger>
      )}
    </>
  );
};

export default withNamespaces()(Dropdown);
