import React, { useEffect } from "react";
import "../assets/scss/_liftworldTable.scss";
import LiftworldTableInput from "./LiftworldTableInput";
import Dropdown from "./TableInputDropdown";
import TableTextbox from "./TableTextbox";
import { useDispatch, useSelector } from "react-redux";
import iconMinus from "~/pages/Liftworld/assets/img/minus-icon.png";
import { setAllWorkplans } from "~/store/ducks/budgetManagement/actionTypes";
import HoursInputDropdown from "./HoursInputDropdown";
const devLogConsole = require("~/utils/devLog");
const LiftworldHoursTable = ({
  list,
  typeList,
  itemIndex,
  activityIndex,
  optionIndex,
  workplanIndex,
}) => {
  const dispatch = useDispatch();

  const {
    workplans,
    collaborators,
    departments,
    categories,
    functions,
    expenses,
    projectTypes,
  } = useSelector((state) => state.budgetManagementReducer);

  useEffect(() => {}, []);

  function buildNameArray(info) {
    var newArray = [];
    for (var i = 0; i < info.length; i++) {
      newArray.push({
        label: info[i].nome,
        value: info[i].nome,
        selected: false,
      });
    }
    return newArray;
  }

  const months = [
    "jan",
    "feb",
    "mar",
    "abr",
    "mai",
    "jun",
    "jul",
    "ago",
    "set",
    "otu",
    "nov",
    "dec",
  ];

  var gpi = 1.0;
  var fse = 1.0;
  var pc = 1.0;
  var pv = 1.0;

  function buildInputField(text) {
    return {
      value: text,
      obligatory: false,
      variable: "",
      maxlenght: 100,
      text: "",
    };
  }

  function getCatAndDep(e) {
    var infoArray = ["", ""];
    for (var i = 0; i < collaborators.length; i++) {
      if (collaborators[i].nome === e) {
        const cat = categories.find(
          (x) => x.idCategoria === collaborators[i].idCategoria
        );
        const dep = departments.find(
          (x) => x.idDepartamento === collaborators[i].idDepartamento
        );
        if (cat != null) infoArray[0] = (cat?.idCategoria).toString();
        if (dep != null) infoArray[1] = (dep?.idDepartamento).toString();
      }
    }

    return infoArray;
  }
  function handleColabChange(e, currentIndex) {
    const infoArray = getCatAndDep(e);
    if (itemIndex != null && activityIndex != null && optionIndex != null) {
      const colab = collaborators.find((x) => x.nome === e);
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].horas[currentIndex].colaborador =
        colab.idColaborador.toString();
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].horas[currentIndex].categoria = infoArray[0];
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].horas[currentIndex].departamentoHoras =
        infoArray[1];
      calculateHours(currentIndex);
      dispatch(setAllWorkplans(workplans));
    }
  }

  function convertIdToName(id, name) {
    if (name === "departments") {
      if (
        departments?.length === 0 ||
        departments === null ||
        departments === undefined
      )
        return "";
      const dep = departments.find((x) => x.idDepartamento === parseInt(id));
      if (dep != null) return dep?.nome;
    } else if (name === "collaborators") {
      if (
        collaborators?.length === 0 ||
        collaborators === null ||
        collaborators === undefined
      )
        return "";
      const colab = collaborators.find((x) => x.idColaborador === parseInt(id));
      if (colab != null) return colab?.nome;
    } else if (name === "categories") {
      if (
        categories?.length === 0 ||
        categories === null ||
        categories === undefined
      )
        return "";
      const cat = categories.find((x) => x.idCategoria === parseInt(id));
      if (cat != null) return cat?.nome;
    } else if (name === "functions") {
      const func = functions.find((x) => x.idFuncao === parseInt(id));
      if (func != null) return func?.nome;
    }
  }

  function getPosition(index) {
    if (index === 0) return "start";
    if (index === list.length - 1) return "end";
  }

  function handleNormalTableChange(e, index, name) {
    if (name === "departments") {
      const dep = departments.find((x) => x.nome === e);
      if (dep != null)
        workplans[workplanIndex].items[itemIndex].atividades[
          activityIndex
        ].opcoes[optionIndex].horas[index].departamentoHoras =
          (dep?.idDepartamento).toString();
    } else if (name === "functions") {
      const func = functions.find((x) => x.nome === e);
      if (func != null)
        workplans[workplanIndex].items[itemIndex].atividades[
          activityIndex
        ].opcoes[optionIndex].horas[index].idFuncao =
          (func?.idFuncao).toString();
    }
  }

  function removeRow(index) {
    workplans[workplanIndex].resumoFinaceiro.toChange = 1;
    var newArray =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].horas;
    newArray.splice(index, 1);
    workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[
      optionIndex
    ].horas = newArray;
    dispatch(setAllWorkplans(workplans));
  }

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;

    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".");
    const finalString = newArray[0].replace(",", ".") + "," + newArray[1];
    return finalString;
  }

  function buildHoursArray(index) {
    const currentHoursArray =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].horas[index].horasPropostasArray;
    var newArray = [];
    for (var i = 0; i < currentHoursArray?.length; i++) {
      newArray.push({
        label:
          months[currentHoursArray[i].month - 1] +
          "/" +
          currentHoursArray[i].year,
        value:
          months[currentHoursArray[i].month - 1] +
          "/" +
          currentHoursArray[i].year,
        selected: false,
      });
    }
    return newArray;
  }

  function getNameFromId() {
    const projType = projectTypes.find(
      (x) => x.idTipoProjeto === parseInt(workplans[workplanIndex].tipoProjeto)
    );
    if (projType != null) return projType?.nome;
  }

  function calculateHours(index) {
    const currentColabId =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].horas[index].colaborador;

    if (
      collaborators?.length != 0 &&
      collaborators != null &&
      collaborators != undefined
    ) {

      const colab = collaborators.find(
        (x) => x.idColaborador === parseInt(currentColabId)
      );

      if (colab != null) {
        pc = colab.precoCompra;
        pv = colab.precoVenda;
      }
    } else {
      pc = 0;
      pv = 0;
    }

    if (expenses?.length != 0 && expenses != null && expenses != undefined) {
      const expGPI = expenses.find(
        (x) => x.tipo === "Gastos Pessoal Indireto (GPI)"
      );
      if (expGPI != null) gpi = expGPI.percentagem / 100;

      const expFSE = expenses.find((x) => x.tipo === "FSE");
      if (expFSE != null) fse = expFSE.percentagem / 100;
    } else {
      fse = 0;
      gpi = 0;
    }

    if (true) {
      const currentHoras =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].horas[index];

      const currentHorasPropostas =
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].horas[index].horasPropostas;
      if (currentHorasPropostas === undefined) {
        return;
      }
      if ((currentHorasPropostas?.split(":")).length === 0) {
        return;
      }
      const hoursArray = currentHoras.horasPropostas.split(":");
      var newHoras = parseFloat(hoursArray[0]) + parseFloat(hoursArray[1]) / 60;

      if (getNameFromId() === "Avença") {
        //newHoras =
        // newHoras *
        //workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        // .opcoes[optionIndex].horas[index].horasPropostasArray.length;
      }

      var newVVP = 0;
      var newCHP = 0;
      var newMargem = 0;
      if (
        currentHoras.valorAdicional != undefined &&
        currentHoras.valorAdicional != 0
      ) {
        newVVP =
          parseFloat(newHoras) * pv + parseFloat(currentHoras.valorAdicional);
        const sub = parseFloat(
          newVVP - parseFloat(currentHoras.valorAdicional)
        );
        const sub2 = parseFloat(
          newVVP - parseFloat(currentHoras.valorAdicional)
        );
        newCHP = parseFloat(newHoras) * pc + sub * gpi;

        newCHP = newCHP + sub2 * fse;
        newMargem = 100 - (newCHP * 100) / newVVP;
      } else {
        newVVP = parseFloat(newHoras) * pv;
        newCHP = parseFloat(newHoras) * pc + gpi * newVVP + fse * newVVP;
        newMargem = 100 - (newCHP * 100) / newVVP;
      }

      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].horas[index].valorVendaHoras = newVVP.toFixed(2);
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].horas[index].custosHoras = newCHP.toFixed(2);

      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].horas[index].margemHoras = newMargem.toFixed(2);
    }

    var newOptionCostValue = 0;
    var newOptionSaleValue = 0;
    const currentHoras =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].horas;
    const currentForn =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].fornecedores;
    const currentDespesas =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].despesas;
    for (var i = 0; i < currentHoras.length; i++) {
      newOptionCostValue += parseFloat(currentHoras[i].custosHoras);
      newOptionSaleValue += parseFloat(currentHoras[i].valorVendaHoras);
    }
    for (var i = 0; i < currentForn.length; i++) {
      newOptionCostValue += parseFloat(currentForn[i].precoCompraForn);
      newOptionSaleValue += parseFloat(currentForn[i].valorVendaForn);
    }
    for (var i = 0; i < currentDespesas.length; i++) {
      newOptionCostValue += parseFloat(currentDespesas[i].precoCompraForn);
      newOptionSaleValue += parseFloat(currentDespesas[i].valorVendaDesp);
    }

    if (!(newOptionCostValue instanceof String))
      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].valorCustoOpt = newOptionCostValue?.toFixed(2);

    workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[
      optionIndex
    ].valorVendaOpt = newOptionSaleValue?.toFixed(2);

    var currentActivityCost = 0;
    var currentActivitySale = 0;

    var currentItemCost = 0;
    var currentItemSale = 0;

    const currentOptions =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes;

    for (var i = 0; i < currentOptions.length; i++) {
      if (currentOptions[i].selected) {
        currentActivityCost += parseFloat(currentOptions[i].valorCustoOpt);
        currentActivitySale += parseFloat(currentOptions[i].valorVendaOpt);
      }
    }

    if (currentActivityCost === 0 && currentActivitySale === 0) {
      currentActivityCost = parseFloat(currentOptions[0].valorCustoOpt);
      currentActivitySale = parseFloat(currentOptions[0].valorVendaOpt);
    }

    workplans[workplanIndex].items[itemIndex].atividades[
      activityIndex
    ].valorCustoAct = currentActivityCost;
    workplans[workplanIndex].items[itemIndex].atividades[
      activityIndex
    ].valorVendaAct = currentActivitySale;

    const currentActivities =
      workplans[workplanIndex].items[itemIndex].atividades;

    for (var i = 0; i < currentActivities.length; i++) {
      currentItemCost += parseFloat(currentActivities[i].valorCustoAct);
      currentItemSale += parseFloat(currentActivities[i].valorVendaAct);
    }

    workplans[workplanIndex].items[itemIndex].valorCustoObj =
      parseFloat(currentItemCost);
    workplans[workplanIndex].items[itemIndex].valorVendaObj =
      parseFloat(currentItemSale);

    dispatch(setAllWorkplans(workplans));
  }

  function handleHoursChange(e, index) {
    workplans[workplanIndex].resumoFinaceiro.toChange = 1
    workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[
      optionIndex
    ].horas[index].horasPropostas = e;
    const currentHoursArray =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].horas[index].horasPropostasArray;

    if (getNameFromId() === "Avença") {
      for (var i = 0; i < currentHoursArray.length; i++) {
        currentHoursArray[i].hoursValue = e;
      }

      workplans[workplanIndex].items[itemIndex].atividades[
        activityIndex
      ].opcoes[optionIndex].horas[index].horasPropostasArray =
        currentHoursArray;
    } else {
      const newArray = e?.split(":");
      if (newArray.length === 2) {
        const currentHours =
          parseFloat(newArray[0]) + parseFloat(newArray[1]) / 60;
        const dividedHours = currentHours / currentHoursArray.length;
        var n = new Date(0, 0);
        n.setSeconds(+dividedHours * 60 * 60);
        const dividedHoursString = n.toTimeString().slice(0, 5);
        const firstNumber = decimalAdjust("floor", dividedHours, -2);
        var lastNumber = (
          currentHours -
          firstNumber * (currentHoursArray.length - 1)
        ).toFixed(2);
        lastNumber = parseFloat(lastNumber)
        devLogConsole(firstNumber)
        devLogConsole(lastNumber)
        for (var i = 0; i < currentHoursArray.length; i++) {
          if(i === currentHoursArray.length - 1)
          {
            var n2 = new Date(0, 0);
            n2.setMinutes(+lastNumber * 60);
            lastNumber = lastNumber.toFixed(2)
            devLogConsole(lastNumber)
            var hours = lastNumber.split(".")[0]
            if(hours.length === 1)
              hours = "0" + hours

            var minutes = parseInt(((parseFloat(lastNumber.split(".")[1])/100)*60))
            if(minutes.toString().length === 1)
              minutes = "0" + minutes
            devLogConsole(minutes)
            currentHoursArray[i].hoursValue = hours + ":" + minutes;
            devLogConsole(currentHoursArray)
          }
          else
          currentHoursArray[i].hoursValue = dividedHoursString;
        }

        workplans[workplanIndex].items[itemIndex].atividades[
          activityIndex
        ].opcoes[optionIndex].horas[index].horasPropostasArray =
          currentHoursArray;
      }
    }

    calculateHours(index);
  }

  function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split("e");
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
    // Shift back
    value = value.toString().split("e");
    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
  }

  function handleInputHoursChange(e, index) {
    workplans[workplanIndex].resumoFinaceiro.toChange = 1
    const currentHoursArray =
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].horas[index].horasPropostasArray;

    currentHoursArray[e.i].hoursValue = e.hoursValue;
    workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[
      optionIndex
    ].horas[index].horasPropostasArray = currentHoursArray;

    var totalHours = 0;
    for (var i = 0; i < currentHoursArray.length; i++) {
      const currentInfoArray = currentHoursArray[i].hoursValue.split(":");
      const currentHours =
        parseFloat(currentInfoArray[0]) + parseFloat(currentInfoArray[1]) / 60;
      totalHours = totalHours + currentHours;
    }


    totalHours = totalHours * 60 * 60;
    var hours = Math.floor(totalHours / (60 * 60));
    totalHours = totalHours - hours * 60 * 60;
    var minutes = Math.floor(totalHours / 60);
    totalHours = totalHours - minutes * 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    var hoursString = hours + ":" + minutes;

    workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[
      optionIndex
    ].horas[index].horasPropostas = hoursString;

    calculateHours(index);

    //dispatch(setAllWorkplans(workplans));
  }

  function isHoursDisabled() {
    const startDate = workplans[workplanIndex].dataInicioProjeto;
    const endDate = workplans[workplanIndex].dataFinalProjeto;
    const projectType = workplans[workplanIndex].tipoProjeto;
    if (
      startDate === null ||
      startDate === undefined ||
      endDate === null ||
      endDate === undefined ||
      projectType === null ||
      projectType?.length === 0
    ) {
      return true;
    }
    return false;
  }

  return (
    <div className="lifworld-table-wrapper">
      <div className="liftworld-table-header-wrapper">
        {list &&
          Object.keys(list).length !== 0 &&
          list.map((item, index) => {
            return (
              <div
                className={
                  index === 0 || index === 1
                    ? "liftworld-table-11-wrapper"
                    : index === list.length - 1
                    ? "liftworld-table-7-wrapper"
                    : "liftworld-table-10-wrapper"
                }
              >
                <div
                  className={
                    `${"liftworld-table-header-element"} ` + getPosition(index)
                  }
                >
                  {item}
                </div>
              </div>
            );
          })}
      </div>
      {typeList &&
        Object.keys(typeList).length !== 0 &&
        typeList.map((item, index) => {
          return (
            <div className="liftworld-table-header-wrapper">
              <div className="liftworld-table-11-wrapper">
                <Dropdown
                  text={""}
                  required={true}
                  list={buildNameArray(collaborators)}
                  handleSubmit={(e) => handleColabChange(e, index)}
                  position={index === typeList.length - 1 ? "start" : ""}
                  initialSelected={convertIdToName(
                    item.colaborador,
                    "collaborators"
                  )}
                  size={"normal"}
                />
              </div>
              <div className="liftworld-table-11-wrapper">
                <TableTextbox
                  value={convertIdToName(item.categoria, "categories")}
                  position={"middle"}
                />
              </div>
              <div className="liftworld-table-10-wrapper">
                <Dropdown
                  text={""}
                  required={true}
                  list={buildNameArray(departments)}
                  handleSubmit={(e) =>
                    handleNormalTableChange(e, index, "departments")
                  }
                  initialSelected={convertIdToName(
                    item.departamentoHoras,
                    "departments"
                  )}
                  position={"middle"}
                  size={"normal"}
                />
              </div>
              <div className="liftworld-table-10-wrapper">
                <Dropdown
                  text={""}
                  required={true}
                  list={buildNameArray(functions)}
                  handleSubmit={(e) =>
                    handleNormalTableChange(e, index, "functions")
                  }
                  initialSelected={convertIdToName(item.idFuncao, "functions")}
                  position={"middle"}
                  size={"normal"}
                />
              </div>
              <div className="liftworld-table-10-wrapper">
                <LiftworldTableInput
                  field={buildInputField(item.tarefa)}
                  position={"middle"}
                  size="normal"
                  currentIndex={index}
                  itemIndex={itemIndex}
                  activityIndex={activityIndex}
                  optionIndex={optionIndex}
                  name={"tarefa"}
                  tableType={"horas"}
                  workplanIndex={workplanIndex}
                />
              </div>
              <div className="liftworld-table-10-wrapper">
                <HoursInputDropdown
                  text={""}
                  required={true}
                  list={buildHoursArray(index)}
                  handleSubmit={(e) => handleHoursChange(e, index)}
                  handleInputSubmit={(e) => handleInputHoursChange(e, index)}
                  currentIndex={index}
                  itemIndex={itemIndex}
                  activityIndex={activityIndex}
                  optionIndex={optionIndex}
                  workplanIndex={workplanIndex}
                  position={"middle"}
                  initialSelected={""}
                  size={"normal"}
                  hoursList={item.horasPropostasArray}
                  dropdownDisabled={getNameFromId() === "Avença"}
                  disabled={isHoursDisabled()}
                />
              </div>
              <div className="liftworld-table-10-wrapper">
                <LiftworldTableInput
                  field={buildInputField(item.valorAdicional)}
                  position={"middle"}
                  size="normal"
                  currentIndex={index}
                  itemIndex={itemIndex}
                  activityIndex={activityIndex}
                  optionIndex={optionIndex}
                  name={"valorAdicional"}
                  tableType={"horas"}
                  workplanIndex={workplanIndex}
                  number={"number"}
                />
              </div>
              <div className="liftworld-table-10-wrapper">
                <TableTextbox
                  value={
                    isNaN(parseFloat(item.custosHoras)) ||
                    item.custosHoras === undefined
                      ? ""
                      : numberWithCommas(
                          parseFloat(item.custosHoras).toFixed(2)
                        ) + "€"
                  }
                  position={"middle"}
                  color={"colored"}
                  number={"number"}
                />
              </div>
              <div className="liftworld-table-10-wrapper">
                <TableTextbox
                  value={
                    isNaN(parseFloat(item.valorVendaHoras)) ||
                    item.valorVendaHoras === undefined
                      ? ""
                      : numberWithCommas(
                          parseFloat(item.valorVendaHoras).toFixed(2)
                        ) + "€"
                  }
                  position={"middle"}
                  color={"colored"}
                  number={"number"}
                />
              </div>
              <div className="liftworld-table-7-wrapper">
                <TableTextbox
                  value={
                    isNaN(parseFloat(item.margemHoras)) ||
                    item.margemHoras === undefined
                      ? ""
                      : numberWithCommas(
                          parseFloat(item.margemHoras).toFixed(2)
                        ) + "%"
                  }
                  position={index === typeList.length - 1 ? "end" : ""}
                  color={"colored"}
                  number={"number"}
                />
              </div>
              <div className="liftworld-minus-icon">
                <img
                  src={iconMinus}
                  alt="Icon Check"
                  className={"liftworld-minus-icon"}
                  onClick={() => removeRow(index)}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default LiftworldHoursTable;
