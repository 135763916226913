import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getAliceProcess,
  setAliceLink,
  setAliceProcess,
} from "~/store/ducks/onboarding/actionTypes";
import { useInterval } from "~/utils/useInterval.js";
import { postEasypayFormData } from "~/store/ducks/easypay/actionTypes";
import oauth from "~/utils/oauth";
const devLogConsole = require("~/utils/devLog");
const AliceProcessLink = (props) => {
  const { aliceProcess } = useSelector((state) => state.onboardingReducer);
  const dispatch = useDispatch();

  const aliceProcessLink = localStorage.getItem("aliceProcessLink");

  useEffect(() => {
    const pid = window.localStorage.getItem("pid");
    if (pid && aliceProcessLink) {
      dispatch(getAliceProcess(pid));
    }
  }, [aliceProcessLink]);

  useEffect(() => {
    devLogConsole(aliceProcess)
    if (aliceProcess && aliceProcess.closed === 1) {
      if (document.dados.acção) {
        document.dados.acção.value = "next";
        document.dados.op.value = 3;
        document.dados._button_clicked_id.value = 6; //avancar

        const formdata = new FormData(document.getElementsByName("dados")[0]);
        const url =
          "/Form/form.jsp?" +
          "flowid=" +
          formdata.get("flowid") +
          "&pid=" +
          formdata.get("pid") +
          "&subpid=" +
          formdata.get("subpid") +
          "&Authorization=" +
          oauth.getAccessToken();
        dispatch(postEasypayFormData(formdata, url));
        dispatch(setAliceLink(null));
      }
    }
  }, [aliceProcess]);

  useInterval(() => {
    updateAliceProcess();
  }, 1000 * 5);

  const updateAliceProcess = () => {
    devLogConsole(aliceProcess)
    const pid = window.localStorage.getItem("pid");
    if (pid) {
      if (aliceProcess && aliceProcessLink) {
        if (aliceProcess.closed != 1) dispatch(getAliceProcess(pid));
      } else dispatch(getAliceProcess(pid));
    }
  };

  const handleCancelAlice = () => {
    if (aliceProcess && aliceProcess.closed !== 1) {
      if (document.dados.acção) {
        document.dados.acção.value = "cancelar";
        document.dados.op.value = 3;
        document.dados._button_clicked_id.value = 6; //avancar

        const formdata = new FormData(document.getElementsByName("dados")[0]);
        const url =
          "/Form/form.jsp?" +
          "flowid=" +
          formdata.get("flowid") +
          "&pid=" +
          formdata.get("pid") +
          "&subpid=" +
          formdata.get("subpid") +
          "&Authorization=" +
          oauth.getAccessToken();
        dispatch(postEasypayFormData(formdata, url));
        dispatch(setAliceLink(null));
        dispatch(setAliceProcess(null));
        localStorage.removeItem("aliceProcessLink");
      }
    }
  };

  return (
    <>
      {document?.dados?.aliceToken && (
        <div style={{ margin: "20px" }}>
          We have sent you sms, please check it.
        </div>
      )}
      {aliceProcessLink && (
        <div
          style={{ wordBreak: "break-word", margin: "20px" }}
          dangerouslySetInnerHTML={{ __html: aliceProcessLink }}
        ></div>
      )}
      {aliceProcess && aliceProcess.closed !== 1 && (
        <button
          className="easypay-button-link-primary"
          style={{ margin: "20px" }}
          onClick={() => handleCancelAlice()}
        >
          <span className="easypay-button-icon-text">Cancel</span>
        </button>
      )}
    </>
  );
};
export default withNamespaces()(AliceProcessLink);
