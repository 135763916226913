import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import List from './components/List';
import AddWidgetComponent from './components/AddWidget';
import CustomBreadcrumb from '~/components/CustomBreadcrumb';

import { findWidgetsByApplication } from '../../pages/User/actions';
import { closeIframesScreens } from '~/containers/SidebarContainer/util';
import '../../assets/css/styles.css';

const WidgetContainer = () => {

  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { userWidgets, userSettings, lastAddedWidget, isUpdatedWidget } = useSelector(state => state.userSettingsReducer);

  useEffect(() => {
    dispatch(findWidgetsByApplication(path));
  }, [dispatch, lastAddedWidget, userSettings, isUpdatedWidget, path])

  useEffect(() => {
    /*close full screen iframes*/
    closeIframesScreens();
  })

  const { configuration } = useSelector(state => state.adminConfigReducer);
  const isProductionMode = configuration ? configuration.productionMode : true;

  const getWidgets = (userWidgets, dashboard) => {
    if (dashboard === undefined)
      return undefined
    let newlists = [];
    if (isProductionMode) {
      dashboard.placements.map((list) => {
        let newlist = [];
        list.map((id) => {
          userWidgets.map(entity => {
            if (entity.id === id) {
              if (entity.widget && entity.widget.production === true) {
                newlist.push(entity);
              }
            }
            return undefined
          })
          return undefined
        })
        newlists.push(newlist)
        return undefined
      });
    } else {
      dashboard.placements.map((list) => {
        let newlist = [];
        list.map((id) => {
          userWidgets.map(entity => {
            if (entity.id === id) {
              entity.widget && entity.widget.production === true && newlist.push(entity);
            }
            return undefined
          })
          return undefined
        })
        newlists.push(newlist)
        return undefined
      });
    }
    return newlists
  }

  let content = []

  if (userSettings.dashboards) {
    const dashboard = userSettings.dashboards.find(item => item.application === path)
    const widgets = getWidgets(userWidgets, dashboard);
    if (widgets)
      widgets.map((list, listIndex) => {
        content.push(<List key={listIndex} listId={listIndex} widgets={list} />)
        return undefined
      })
  }

const isMobile = window.innerWidth < 992 ? true : false
  return (
    <>
     {isMobile && <CustomBreadcrumb/>}
    <div id="content-wrapper">
      {content}
      <AddWidgetComponent />
    </div>
    </>
  )
}
export default WidgetContainer
