export const CLEAR_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@CLEAR_RHADMIN_CONFIGURATION";
export const GET_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@GET_RHADMIN_CONFIGURATION";
export const SET_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@SET_RHADMIN_CONFIGURATION";
export const PERSIST_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@PERSIST_RHADMIN_CONFIGURATION";
export const SUCCESS_PERSIST_RHADMIN_CONFIGURATION =
  "rhadminConfiguration@SUCCESS_PERSIST_RHADMIN_CONFIGURATION";

export const getRHAdminConfiguration = (organizationId) => {
  return { type: GET_RHADMIN_CONFIGURATION, organizationId };
};

export const setRHAdminConfiguration = (configuration) => {
  return { type: SET_RHADMIN_CONFIGURATION, configuration };
};

export const clearRHAdminConfiguration = () => {
  return { type: CLEAR_RHADMIN_CONFIGURATION };
};

export const persistRHAdminConfiguration = (configuration) => {
  return { type: PERSIST_RHADMIN_CONFIGURATION, configuration };
};

export const sucessesPersistRHAdminConfiguration = () => {
  return { type: SUCCESS_PERSIST_RHADMIN_CONFIGURATION };
};

//BOLSA DE HORAS

export const GET_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@GET_RH_BOLSA_HORAS_CONFIGURATION";
export const SET_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@SET_RH_BOLSA_HORAS_CONFIGURATION";
export const CLEAR_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@CLEAR_RH_BOLSA_HORAS_CONFIGURATION";
export const PERSIST_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@PERSIST_RH_BOLSA_HORAS_CONFIGURATION";
export const SUCCESS_PERSIST_RH_BOLSA_HORAS_CONFIGURATION =
  "rhadminConfiguration@SUCCESS_PERSIST_RH_BOLSA_HORAS_CONFIGURATION";
export const GET_RH_BOLSA_HORAS_USERS_GROUP =
  "rhadminConfiguration@GET_RH_BOLSA_HORAS_USERS_GROUP";
export const CLEAR_RH_BOLSA_HORAS_USERS_GROUP =
  "rhadminConfiguration@CLEAR_RH_BOLSA_HORAS_USERS_GROUP";
export const SET_RH_BOLSA_HORAS_USERS_GROUP =
  "rhadminConfiguration@SET_RH_BOLSA_HORAS_USERS_GROUP";
  export const GET_RH_SALDO_BOLSA_HORAS =
  "rhadminConfiguration@GET_RH_SALDO_BOLSA_HORAS";
  export const SET_RH_SALDO_BOLSA_HORAS =
  "rhadminConfiguration@SET_RH_SALDO_BOLSA_HORAS";
  export const CLEAR_RH_SALDO_BOLSA_HORAS =
  "rhadminConfiguration@CLEAR_RH_SALDO_BOLSA_HORAS";
  export const GET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS =
  "rhadminConfiguration@GET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS";
  export const SET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS =
  "rhadminConfiguration@SET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS";
  export const CLEAR_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS =
  "rhadminConfiguration@CLEAR_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS";
  
  
export const getRHBolsaHorasConfiguration = (organizationId) => {
  return { type: GET_RH_BOLSA_HORAS_CONFIGURATION, organizationId };
};

export const setRHBolsaHorasConfiguration = (configuration) => {
  return { type: SET_RH_BOLSA_HORAS_CONFIGURATION, configuration };
};

export const clearRHBolsaHorasConfiguration = () => {
  return { type: CLEAR_RH_BOLSA_HORAS_CONFIGURATION };
};
export const getRHBolsaHorasUsersGroup = () => {
  return { type: GET_RH_BOLSA_HORAS_USERS_GROUP };
};

export const clearRHBolsaHorasUsersGroup = () => {
  return { type: CLEAR_RH_BOLSA_HORAS_USERS_GROUP };
};

export const setRHBolsaHorasUsersGroup = (usersGroup) => {
  return { type: SET_RH_BOLSA_HORAS_USERS_GROUP, usersGroup };
};

export const persistRHBolsaHorasConfiguration = (configuration) => {
  return { type: PERSIST_RH_BOLSA_HORAS_CONFIGURATION, configuration };
};

export const sucessesPersistRHBolsaHorasConfiguration = () => {
  return { type: SUCCESS_PERSIST_RH_BOLSA_HORAS_CONFIGURATION };
};

export const getRHSaldoBolsaHoras = (usrId) => {
  return { type: GET_RH_SALDO_BOLSA_HORAS, usrId };
};

export const setRHSaldoBolsaHoras = (listSaldo) => {
  return { type: SET_RH_SALDO_BOLSA_HORAS, listSaldo };
};

export const clearRHSaldoBolsaHoras = () => {
  return { type: CLEAR_RH_SALDO_BOLSA_HORAS };
};
export const getRHSaldoInicialFinalBolsaHoras = (usrId, data) => {
  return { type: GET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS, usrId, data };
};

export const setRHSaldoInicialFinalBolsaHoras = (listSaldoInicialFinal) => {
  return { type: SET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS, listSaldoInicialFinal };
};

export const clearRHSaldoInicialFinalBolsaHoras = () => {
  return { type: CLEAR_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS };
};
