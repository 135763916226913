import React from "react";
import { withNamespaces } from "react-i18next";
import iconWarning from "~/pages/Easypay/assets/img/icons/icon-alert.png";
import "../assets/css/styles.css";
import "../assets/scss/_backofficeBusinessSection.scss";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import { useDispatch, useSelector } from "react-redux";

const BackOfficeBusinessSection = ({ data, t }) => {
  const { user } = useSelector((state) => state.onboardingReducer);

  return (
    <div className="easypay-backoffice-business-section-main-wrapper">
      <div className="easypay-backoffice-business-section-header">
        {t("bpm.onboarding.backoffice.administration.businessButton")}
      </div>
      <div className="easypay-backoffice-business-section-subheader-wrapper">
        <div className="easypay-text-icon">
          {/*<img src={iconWarning} alt="Icon Check" />*/}
        </div>
        <div className="easypay-backoffice-business-section-subheader-text">
          {/*t("bpm.onboarding.backoffice.administration.subHeaderChangeBusinessData")*/}
        </div>
      </div>
      <ProtectedComponent
        roles={["ADMIN", "ONBOARDINGUser", "ONBOARDINGCompliance"]}
      >
        <div>
          {data.riskEvaluation &&
            Object.keys(data.riskEvaluation).length !== 0 &&
            data.riskEvaluation.map((item, index) => {
              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="easypay-business-text-title">
                    {item.question}
                  </div>
                  <div className="easypay-business-text-light">
                    {item.answer}
                  </div>
                </div>
              );
            })}
        </div>
      </ProtectedComponent>
    </div>
  );
};
export default withNamespaces()(BackOfficeBusinessSection);
