import React, { useState, useEffect } from "react";
import { Col, Form, Row, Alert, InputGroup } from "react-bootstrap";
import CreateSearchableDropdown from "../CreateSearchableDropdown";
import CreateTextArea from "../CreateTextArea";
import CreateDateInput from "../CreateDateInput";
import CreateTextInput from "../CreateTextInput";
import CreateTableTextInput from "./CreateTableTextInput";
import CreateButtonsFromSelection from "../CreateButtonsFromSelection";
import uuid from "uuid/v1";

const CreateCalculosCusto = (props) => {
  const { blockdivision, styleSheet } = props;

  const [licCamarariaRep, setFormData1] = useState("");
  const [dtValidadeLicRep, setFormData2] = useState("");
  const [mccr_observacoes, setFormData3] = useState("");
  const [orcClienteRep, setFormData4] = useState("");
  const [orcAceiteRep, setFormData5] = useState("");
  const [numLicRep, setFormData6] = useState("");
  const [empreiteiroRep, setFormData7] = useState("");
  const [aplicarTodas, setFormData8] = useState("");
  const [fracaoRai, setFormData9] = useState("");
  const [seccao, setFormData10] = useState("");
  const [areaBruta, setFormData11] = useState("");
  const [mccr_percE, setFormData12] = useState("");
  const [mccr_percE_all, setFormData12_all] = useState("");
  const [mccr_percD, setFormData13] = useState("");
  const [mccr_percD_all, setFormData13_all] = useState("");
  const [mccr_percConstReal, setFormData14] = useState("");
  const [mccr_percConstReal_all, setFormData14_all] = useState("");
  const [mccr_percL, setFormData15] = useState("");
  const [mccr_percL_all, setFormData15_all] = useState("");
  const [warningRight, setWarningRight] = useState("");
  const [mccr_valorD, setFormData19] = useState("");
  const [mccr_valorE, setFormData18] = useState("");
  const [valorMetComp, setvalorMetComp] = useState("");
  const [tableCustoTerreno, setTable1] = useState("");
  const [tableConstrucao, setTable2] = useState("");
  const [valorMetCustos, setvalorMetCustos] = useState("");
  const [mccr_valorCCE, setFormData20] = useState("");
  const [mccr_valorMetodoCusto, setFormData26] = useState("");
  const [mccr_valorCt, setFormData21] = useState("");
  const [mccr_valorAtual, setFormData22] = useState("");
  const [mccr_valorL, setFormData23] = useState("");
  const [mccr_valorSeguro, setFormData24] = useState("");
  const [mccr_valorNovo, setFormData25] = useState("");
  const [cConstrucao, setFormData27] = useState('') 
  const [tTerreno, setFormData28] = useState('') 
  const [ivAdoptado, setFormData16] = useState("");
  const [ltv, setFormData30] = useState("");
  const [textSize, setTextSize] = useState("")
  const [tableSize, setTableSize] = useState("")
  useEffect(() => {
    if (
      styleSheet?.zoomIn &&
      styleSheet?.zoomInTextSize
    ) {
      setTextSize(`${styleSheet?.zoomInTextSize}`)
    }
    if (
      styleSheet?.zoomIn &&
      styleSheet?.tablefontsize
    ) {
      setTableSize(`${styleSheet?.tablefontsize}`)
    }
    
  }, [styleSheet]);

  useEffect(() => {
    if (blockdivision) {
      //find form data
      let licCamarariaRep;
      let dtValidadeLicRep;
      let mccr_observacoes;
      let orcClienteRep;
      let orcAceiteRep;
      let numLicRep;
      let empreiteiroRep;
      let aplicarTodas;
      let fracaoRai;
      let seccao;
      let tableCustoTerreno;
      let tableConstrucao;
      let areaRep;
      let mccr_percE;
      let mccr_percD;
      let mccr_percConstReal;
      let mccr_percL;
      let warning_right;
      let mccr_valorD;
      let valorMetComp;
      let mccr_valorE;
      let mccr_valorCCE;
      let mccr_valorMetodoCusto;
      let mccr_valorAtual;
      let mccr_valorCt;
      let mccr_valorNovo;
      let valorMetCustos;
      let mccr_valorSeguro;
      let mccr_valorL;
      let cConstrucao;
      let tTerreno;
      let ivAdoptado;
      let ltv_value;

      const setValue = (f) => {
        if (f?.variable == "licCamarariaRep") {
          licCamarariaRep = f;
        }
        if (f?.variable == "dtValidadeLicRep") dtValidadeLicRep = f;
        if (f?.variable == "mccr_observacoes") mccr_observacoes = f;
        if (f?.variable == "orcClienteRep") orcClienteRep = f;
        if (f?.variable == "orcAceiteRep") orcAceiteRep = f;
        if (f?.variable == "numLicRep") numLicRep = f;
        if (f?.variable == "empreiteiroRep") empreiteiroRep = f;
        if (f?.variable == "aplicarTodas") aplicarTodas = f;
        if (f?.variable == "fracaoRai") fracaoRai = f;
        if (f?.variable == "seccao") seccao = f;
        if (f?.variable == "areaRep") areaRep = f;
        if (f?.variable == "mccr_percE") mccr_percE = f;
        if (f?.variable == "mccr_percD") mccr_percD = f;
        if (f?.variable == "mccr_percConstReal") mccr_percConstReal = f;
        if (f?.variable == "mccr_percL") mccr_percL = f;
        if (f?.variable == "mccr_valorD") mccr_valorD = f.value;
        if (f?.variable == "mccr_valorE") mccr_valorE = f.value;
        if (f?.variable == "mccr_valorCCE") mccr_valorCCE = f.value;
        if (f?.variable == "mccr_valorAtual") mccr_valorAtual = f.value;
        if (f?.variable == "mccr_valorCt") mccr_valorCt = f.value;
        if (f?.variable == "mccr_valorSeguro") mccr_valorSeguro = f.value;
        if (f?.variable == "mccr_valorL") mccr_valorL = f.value;
        if (f?.variable == "mccr_valorNovo") mccr_valorNovo = f.value;
        if (f?.variable == "mccr_valorMetodoCusto") mccr_valorMetodoCusto = f.value;
        if (f?.text == "Valor Met Comp") valorMetComp = f.value;
        if (f?.text == "IV adoptado") ivAdoptado = f?.value;
        if (f?.text == "T - Terreno =") tTerreno = f.value;
        if (f?.text == "Custos Directos de construção (CC)") cConstrucao = f;
        if (f?.text == "Valor Met Custos") valorMetCustos = f.value;
        if (f?.text == "LTV") ltv_value = f.value;
        if(f?.text == "Rever valor Método Comparativo / Método dos Custos" && f?.type =="textmessage") warning_right = f.text
        if (f?.type == "arraytable") {
          if (f?.row[0]?.col[0]?.value === "Custo do Terreno (T)") {
            tableCustoTerreno = f;
          }
          if (
            f?.row[0]?.col[0]?.value === "Custos Directos de construção (CC)"
          ) {
            tableConstrucao = f;
          }
        }
        
      };
      let blockdivision_ = JSON.parse(JSON.stringify(blockdivision));
      if (!Array.isArray(blockdivision_)) {
        blockdivision_ = [blockdivision_];
      }
      blockdivision.forEach((b) => {
        if (Array.isArray(b.columndivision)) {
          b.columndivision.forEach((c) => {
            if (Array.isArray(c.field)) {
              c.field.forEach((f) => {
                setValue(f);
              });
            } else {
              setValue(c.field);
            }
          });
        } else {
          if (Array.isArray(b.columndivision?.field)) {
            b.columndivision.field.forEach((f) => {
              setValue(f);
            });
          } else {
            setValue(b.columndivision.field);
          }
        }
      });
      setFormData1(licCamarariaRep);
      setFormData2(dtValidadeLicRep);
      setFormData3(mccr_observacoes);
      setFormData4(orcClienteRep);
      setFormData5(orcAceiteRep);
      setFormData6(numLicRep);
      setFormData7(empreiteiroRep);
      setFormData8(aplicarTodas);
      setFormData9(fracaoRai);
      setFormData10(seccao);
      setFormData11(areaRep);
      setFormData12(mccr_percE?.value);
      setFormData12_all(mccr_percE);
      setFormData13(mccr_percD?.value);
      setFormData13_all(mccr_percD);
      setFormData14(mccr_percConstReal?.value);
      setFormData14_all(mccr_percConstReal);
      setFormData15(mccr_percL?.value);
      setFormData15_all(mccr_percL);
      setFormData19(mccr_valorD)
      setFormData18(mccr_valorE);
      setFormData20(mccr_valorCCE);
      setFormData21(mccr_valorCt);
      setFormData22(mccr_valorAtual);
      setvalorMetComp(valorMetComp);
      setTable1(tableCustoTerreno);
      setTable2(tableConstrucao);
      setWarningRight(warning_right)
      setFormData24(mccr_valorSeguro);
      setvalorMetCustos(valorMetCustos);
      setFormData23(mccr_valorL);
      setFormData26(mccr_valorMetodoCusto);
      setFormData25(mccr_valorNovo);
      setFormData27(cConstrucao?.value)
      setFormData28(tTerreno)
      setFormData16(ivAdoptado)
      setFormData30(ltv_value);
    }
  }, [blockdivision]);

  //const formatter = new Intl.NumberFormat("pt-PT", {
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  //const decimalFormatter = new Intl.NumberFormat("pt-PT", {
  const decimalFormatter = new Intl.NumberFormat("de-DE", {
    style: "decimal",
  });

  const numberFormatter = new Intl.NumberFormat("de-DE");

  function parseCurrency(arg) {
    return arg.replace(/[^\d,]/g, "").replace(/,/g, ".");
  }

  function parseNumber(arg) {
    if(arg != undefined) return Number(arg.replace(/[,]/g, ""));
  }

  function updateWarning() {
    let comparativo = "";
    if (
      document.getElementById("valorMetComp")?.textContent &&
      document.getElementById("valorMetCustos")?.textContent
    ) {
      let valorMetComp = Number(
        parseCurrency(document.getElementById("valorMetComp").textContent)
      );
      let valorComercial = Number(
        parseCurrency(document.getElementById("valorMetCustos").textContent)
      );

      if (valorMetComp > valorComercial) {
        if (document.getElementById("reverValor")) {
          document.getElementById("reverValor").style.display = "none";
        }
        comparativo = ">";
      } else if (valorMetComp < valorComercial) {
        if (document.getElementById("reverValor")) {
          document.getElementById("reverValor").style.display = "block";
        }
        comparativo = "<";
      } else {
        if (document.getElementById("reverValor")) {
          document.getElementById("reverValor").style.display = "none";
        }
        comparativo = "=";
      }
    }
    return comparativo;
  }

  /*First render*/
  useEffect(() => {
    const comparativo = updateWarning();
    document.getElementById("comparativo").textContent = comparativo;
    updateTotais(64, 72);
    document.getElementById("comparativo").textContent = comparativo;
    if (comparativo == '<' || comparativo == '=') {
        document.getElementById("warningRight").style.display = "block";
    } else  {
        document.getElementById("warningRight").style.display = "none";
    }
  }, [tableCustoTerreno, tableConstrucao]);

  function updateTotais(tabelaTerrenos, tabelaConstrucao) {
    let numLinhasTerreno = 2;
    let totTerreno = 0;
    while (
      document.getElementById(tabelaTerrenos + "_" + numLinhasTerreno + "_4") !=
      null
    ) {
      totTerreno += Number(
        parseCurrency(
          document.getElementById(
            tabelaTerrenos + "_" + numLinhasTerreno + "_4"
          ).textContent
        )
      );
      numLinhasTerreno++;
    }

    document.getElementById("totalTerreno").textContent = formatter.format(
      totTerreno.toFixed(2)
    );

    let numLinhasConstrucao = 2;
    let totConstrucao = 0;
    while (
      document.getElementById(
        tabelaConstrucao + "_" + numLinhasConstrucao + "_4"
      ) != null
    ) {
      totConstrucao += Number(
        parseCurrency(
          document.getElementById(
            tabelaConstrucao + "_" + numLinhasConstrucao + "_4"
          ).textContent
        )
      );
      numLinhasConstrucao++;
    }
if(document.getElementById("custosConstrucao").textContent == ""){
      document.getElementById("custosConstrucao").textContent = formatter.format(
      totConstrucao.toFixed(2)
    );}
    else{
      setFormData27(formatter.format(totConstrucao.toFixed(2)))
    }

    let perEncargos 
    let valorStringperEncargos
    let margemLucro
    let valorStringmargemLucro
    let perDepreciacao
    let valorStringperDepreciacao
    let perConstrucao 
    let valorStringperConstrucao

    if(mccr_percE_all?.type == "textbox"){
      if(mccr_percE_all?.disabled == "false"){
        valorStringperEncargos= document.getElementById("mccr_percE").value
      } else{
        valorStringperEncargos = document.getElementById("mccr_percE").textContent
        valorStringperEncargos = valorStringperEncargos.replace(/%/g, '');
        if(valorStringperEncargos == ""){
          valorStringperEncargos = mccr_percE_all.value
        }
      }
    }
    if(mccr_percL_all?.type == "textbox"){
      if(mccr_percL_all?.disabled == "false"){
        valorStringmargemLucro= document.getElementById("mccr_percL").value
      } else{
        valorStringmargemLucro = document.getElementById("mccr_percL").textContent
        valorStringmargemLucro = valorStringmargemLucro.replace(/%/g, '');
        if(valorStringmargemLucro == ""){
          valorStringmargemLucro = mccr_percL_all.value
        }
      }
    }
    if(mccr_percD_all?.type == "textbox"){
      if(mccr_percD_all?.disabled == "false"){
        valorStringperDepreciacao= document.getElementById("mccr_percD").value
      } else{
        valorStringperDepreciacao = document.getElementById("mccr_percD").textContent
        valorStringperDepreciacao = valorStringperDepreciacao.replace(/%/g, '');
        if(valorStringperDepreciacao == ""){
          valorStringperDepreciacao = mccr_percD_all.value
        }
      }
    }
    if(mccr_percConstReal_all?.type == "textbox"){
      if(mccr_percConstReal_all?.disabled == "false"){
        valorStringperConstrucao= document.getElementById("mccr_percConstReal").value
      } else{
        valorStringperConstrucao = document.getElementById("mccr_percConstReal").textContent
        valorStringperConstrucao = valorStringperConstrucao.replace(/%/g, '');
        if(valorStringperConstrucao == ""){
          valorStringperConstrucao = mccr_percConstReal_all.value
        }
      }
    }
    if(valorStringperEncargos == "") valorStringperEncargos = "0"
    if(valorStringmargemLucro == "") valorStringmargemLucro = "0"
    if(valorStringperDepreciacao == "") valorStringperDepreciacao = "0"
    if(valorStringperConstrucao == "") valorStringperConstrucao = "0"
    perEncargos = valorStringperEncargos != undefined && parseFloat(valorStringperEncargos.replace(/,/g, '.'))/100;
    margemLucro = valorStringmargemLucro != undefined && parseFloat(valorStringmargemLucro.replace(/,/g, '.'));
    perDepreciacao = valorStringperDepreciacao != undefined && parseFloat(valorStringperDepreciacao.replace(/,/g, '.'));
    perConstrucao = valorStringperConstrucao != undefined && parseFloat(valorStringperConstrucao.replace(/,/g, '.'));
    
    let valorEncargos = perEncargos * totConstrucao;
    document.getElementById("valorEncargos").textContent = formatter.format(
      valorEncargos.toFixed(2)
    );

    let valorCCEncargos = (1 + perEncargos) * totConstrucao;

    document.getElementById("ccEncargos").textContent = formatter.format(
      valorCCEncargos.toFixed(2)
    );

    let custoTotal = valorCCEncargos + totTerreno;

    document.getElementById("custoTotal").textContent = formatter.format(
      custoTotal.toFixed(2)
    );

    let lucro = margemLucro/100 * custoTotal;
    document.getElementById("lucro").textContent = formatter.format(
      lucro.toFixed(2)
    );

    let valorNovo = custoTotal + lucro;

    document.getElementById("valorNovo").textContent = formatter.format(
      valorNovo.toFixed(2)
    );


    let depreciacao = perDepreciacao/100 * (valorCCEncargos + lucro);

    document.getElementById("depreciacao").textContent = formatter.format(
      depreciacao.toFixed(2)
    );




    let valorAtual =
      totTerreno + perConstrucao/100 * (valorCCEncargos + lucro) - depreciacao;
    if (document.getElementById("valorAtual").textContent == "") {
      document.getElementById("valorAtual").textContent = formatter.format(
        valorAtual.toFixed(2)
      );
    } else {
      setFormData22(formatter.format(valorAtual.toFixed(2)))
    }

    //setFormData24(valorCCEncargos.toFixed(2))
    document.getElementById("valorSeguro").textContent = formatter.format(
      valorCCEncargos.toFixed(2)
    );

    let valorComercial = valorNovo - depreciacao;
    document.getElementById("valorComercial").textContent = formatter.format(
      valorComercial.toFixed(2)
    );

    document.getElementById("valorMetCustos").textContent = formatter.format(
      valorComercial.toFixed(2)
    );

    let comparativo = updateWarning();
    document.getElementById("comparativo").textContent = comparativo;
    if (comparativo == '<' || comparativo == '=') {
        document.getElementById("warningRight").style.display = "block";
    } else  {
        document.getElementById("warningRight").style.display = "none";
    }

    let areaBruta;
    if (document.getElementById("areaBruta")) {
      areaBruta = parseNumber(document.getElementById("areaBruta").value);
    }

    let ivAdoptadoVal = 0;
    if (areaBruta > 0) {
      ivAdoptadoVal = valorComercial / areaBruta;
    } else {
      if(ivAdoptado === "") ivAdoptadoVal = 0;
      else ivAdoptadoVal = parseFloat(ivAdoptado);
    }
    document.getElementById("ivAdoptado").innerHTML = decimalFormatter.format(
      ivAdoptadoVal.toFixed(2)
    );
  }

  function updateTabelaTerrenos(linha, tabelaTerrenos, tabelaConstrucao) {
    let tableid = tabelaTerrenos;

    let colArea = "2";
    let colUnit = "3";
    let colTot = "4";

    /*
    let valorArea = parseNumber(
      document.getElementById(tableid + "_" + linha + "_" + colArea).textContent
    );*/

    let valorArea = Number(
      document
        .getElementById(tableid + "_" + linha + "_" + colArea)
        .textContent.replace(/[.]/g, "")
        .replace(/[,]/g, ".")
    );
    let valorUnit = parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colUnit)
        .getElementsByTagName("input")[0].value
    );

    let valorTot = valorArea * valorUnit;

    document.getElementById(tableid + "_" + linha + "_" + colTot).textContent =
      formatter.format(valorTot.toFixed(2));

    updateTotais(tabelaTerrenos, tabelaConstrucao);
  }

  function updateTabelaConstrucao(linha, tabelaTerrenos, tabelaConstrucao) {
    let tableid = tabelaConstrucao;

    //var colTerreno = "1";
    let colArea = "2";
    let colUnit = "3";
    let colTot = "4";

    /*
    let valorArea = parseNumber(
      document.getElementById(tableid + "_" + linha + "_" + colArea).textContent
    );*/

    let valorArea = Number(
      document
        .getElementById(tableid + "_" + linha + "_" + colArea)
        .textContent.replace(/[.]/g, "")
        .replace(/[,]/g, ".")
    );
    let valorUnit = parseNumber(
      document
        .getElementById(tableid + "_" + linha + "_" + colUnit)
        .getElementsByTagName("input")[0].value
    );

    let valorTot = valorArea * valorUnit;

    document.getElementById(tableid + "_" + linha + "_" + colTot).textContent =
      formatter.format(valorTot.toFixed(2));

    updateTotais(tabelaTerrenos, tabelaConstrucao);
  }

  const renderTable = (table, tableId, variable, onBlur) => {
    if (table && Array.isArray(table.row)) {
      return table.row.map((r, index) => {
        if (index != 0) {
          return (
            <tr key={index}>
              {Array.isArray(r.col) &&
                r.col.map((c, i) => {
                  let style =
                    i !== 0 ? { textAlign: "end" } : { textAlign: "start" };

                  if (i == 1) {
                    return (
                      <td
                        key={i}
                        id={`${tableId}_${index + 1}_${i + 1}`}
                        style={{ ...style, fontSize: `${textSize}px` }}
                      >
                        {
                          /*1,250.08 -> 1.250,08*/
                          numberFormatter.format(c.value.replace(/[,]/g, ""))
                        }
                      </td>
                    );
                  } else if (i == 2) {
                    return c?.value?.input ? (
                      <CreateTableTextInput
                        key={i}
                        id={`${tableId}_${index + 1}_${i + 1}`}
                        name={`${variable}[${index - 1}]`}
                        value={c?.value?.input?.value}
                        linha={index + 1}
                        onBlur={onBlur}
                        fontSize={textSize}
                      />
                    ) : (
                      <td
                        key={i}
                        id={`${tableId}_${index + 1}_${i + 1}`}
                        style={{ ...style, fontSize: `${textSize}px` }}
                      >
                        {c.value}
                      </td>
                    );
                  } else
                    return (
                      <td
                        key={i}
                        id={`${tableId}_${index + 1}_${i + 1}`}
                        style={{ ...style, fontSize: `${textSize}px` }}
                      >
                        {c.value}
                      </td>
                    );
                })}
            </tr>
          );
        }
      });
    }
  };


  const style1 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "0px",
    flexGrow: "1",
  };

  const style2 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
    justifyContent: "flex-end",
  };

  const style3 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
  };

  const style4 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    flexGrow: "1",
  };

  const style5 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    width: "30%",
  };

  const style6 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    width: "20%",
  };

  const style7 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    width: "50%",
  };

  const style8 = {
    flexWrap: "nowrap",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "5px",
    flexGrow: "1",
  };

  const style9 = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "end",
    fontSize: "1rem",
  };
  const style9encargos = {
    flexGrow: "0.1",
    fontWeight: "bold",
    textAlign: "end",
    fontSize: "1rem",
  };
  
  const style10 = {
    padding: "5px 0px 10px 0px",
    fontSize: "1rem",
  };

  const style11 = {
    flexGrow: "1",
    fontWeight: "bold",
    textAlign: "start",
  };

  const renderColumnContent = () => {
    return (
      <>
        {seccao && (
          <CreateButtonsFromSelection field={seccao} styleSheet={styleSheet} />
        )}
        {fracaoRai && (
          <CreateSearchableDropdown
            field={fracaoRai}
            key={"fracaoRai"}
            fontSize={textSize}
            styleSheet={styleSheet}
          />
        )}
        {aplicarTodas && (
          <CreateSearchableDropdown
            field={aplicarTodas}
            key={"aplicarTodas"}
            fontSize={textSize}
            styleSheet={styleSheet}
          />
        )}
        <Form.Group key={uuid()} style={style1}>
          <Form.Label column style={{ flexGrow: "1" }}>
            <h4 style={{ fontSize: `${textSize}px` }}>
              Método do custo de construção ou reposição
            </h4>{" "}
            <hr />
          </Form.Label>
        </Form.Group>
        <Alert variant="info">
          O método do custo de reposição / substituição é utilizado na
          determinação do valor de um imóvel através da soma entre o valor de
          mercado do terreno e de todos os custos necessários para a construção
          de um imóvel com as mesmas características físicas e funcionais. Deve
          ser tido em conta no valor final do imóvel, a sua depreciação em
          função da antiguidade, estado de conservação, estimativa de vida útil
          e margens de lucro requeridas.
        </Alert>

        <table style={{ width: "100%" }} className="bordertableMCCR">
          <thead>
            <tr>
              <th
                id="64_1_1"
                style={{
                  ...style10,
                  width: "25%",
                  textAlign: "start",
                  fontSize: `${textSize}px`,
                }}
              >
                Custo do Terreno (T)
              </th>
              <th
                id="64_1_2"
                style={{
                  ...style10,
                  width: "20%",
                  textAlign: "end",
                  fontSize: `${textSize}px`,
                }}
              >
                Área (m2)
              </th>
              <th
                id="64_1_3"
                style={{
                  ...style10,
                  width: "25%",
                  textAlign: "end",
                  fontSize: `${textSize}px`,
                }}
              >
                Valor (€/m2)
              </th>
              <th
                id="64_1_4"
                style={{
                  ...style10,
                  width: "30%",
                  textAlign: "end",
                  fontSize: `${textSize}px`,
                }}
              >
                Valor Total
              </th>
            </tr>
          </thead>

          <tbody>
            {renderTable(
              tableCustoTerreno,
              64,
              "listaValorTerreno_rep",
              updateTabelaTerrenos
            )}
          </tbody>
        </table>
        <br />
        <Form.Group as={Row} style={style2}>
          <Col
            key={`columndivision_1}`}
            style={{ flexGrow: "0.30", padding: "0" }}
          >
            <Form.Group as={Row} style={style1}>
              <Form.Label
                column
                style={{ flexGrow: "1", fontSize: `${textSize}px` }}
              >
                T - Terreno =
              </Form.Label>
              <Form.Label
                id="totalTerreno"
                column
                style={{ ...style9, fontSize: `${textSize}px` }}
              >
                {tTerreno ? `${tTerreno}` : ""}
              </Form.Label>
            </Form.Group>
            <hr />
          </Col>
        </Form.Group>

        <table style={{ width: "100%" }} className="bordertableMCCR">
          <thead>
            <tr>
              <th
                id="72_1_1"
                style={{
                  ...style10,
                  textAlign: "start",
                  width: "25%",
                  fontSize: `${textSize}px`,
                }}
              >
                Custos Directos de construção (CC)
              </th>
              <th
                id="72_1_2"
                style={{
                  ...style10,
                  width: "20%",
                  textAlign: "end",
                  fontSize: `${textSize}px`,
                }}
              >
                Área (m2)
              </th>
              <th
                id="72_1_3"
                style={{
                  ...style10,
                  width: "25%",
                  textAlign: "end",
                  fontSize: `${textSize}px`,
                }}
              >
                Custo (€/m2)
              </th>
              <th
                id="72_1_4"
                style={{
                  ...style10,
                  width: "30%",
                  textAlign: "end",
                  fontSize: `${textSize}px`,
                }}
              >
                Valor Total
              </th>
            </tr>
          </thead>

          <tbody>
            {renderTable(
              tableConstrucao,
              72,
              "listaCusto",
              updateTabelaConstrucao
            )}
          </tbody>
        </table>
        <br />
        <Form.Group as={Row} style={style2}>
          <Col
            key={`columndivision_1}`}
            style={{ flexGrow: "0.3", padding: "0" }}
          >
            <Form.Group as={Row} style={style3}>
              <Form.Label column style={{ flexGrow: "1" }}>
                Custos Directos de construção (CC)
              </Form.Label>
              <Form.Label id="custosConstrucao" column style={style9}>
                {cConstrucao ? `${cConstrucao}` : ""}
              </Form.Label>
            </Form.Group>
            <hr />
          </Col>
        </Form.Group>
        <br />

        <Form.Group as={Row} style={style4}>
          <Form.Group id="encargos" as={Row} style={style5}>
            <Form.Label
              id="label_mccr_percE"
              column
              style={{ flexGrow: "0.75", fontSize: `${textSize}px` }}
            >
              Encargos Indiretos (% de CC)
            </Form.Label>
            {mccr_percE_all?.disabled === "true" &&
            mccr_percE_all?.type == "textbox" ? (
              <Form.Label
                id="mccr_percE"
                column
                style={{ ...style9, fontSize: `${textSize}px` }}
              >
                {mccr_percE ? `${mccr_percE} %` : ""}
              </Form.Label>
            ) : (
              <>
                <input
                  id="mccr_percE"
                  style={{ width: "15%", fontSize: `${textSize}px` }}
                  className="form-control create-text-input-suffix-fix"
                  type="text"
                  size={6}
                  name={"mccr_percE"}
                  maxLength={6}
                  value={mccr_percE}
                  onChange={(e) => setFormData12(e.target.value)}
                  onBlur={() => {
                    updateTotais(64, 72);
                  }}
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    className={"create-text-input-suffix"}
                    style={{ height: "44px" }}
                  >
                    %
                  </InputGroup.Text>
                </InputGroup.Append>
              </>
            )}
          </Form.Group>
          <Form.Group as={Row} style={style6}>
            <Form.Label
              id="label_mccr_valorE"
              column
              style={{ flexGrow: "0.5", fontSize: `${textSize}px` }}
            >
              E =
            </Form.Label>
            <Form.Label
              id="valorEncargos"
              column
              style={{
                ...style9encargos,
                flexGrow: "0.35",
                fontSize: `${textSize}px`,
              }}
            >
              {mccr_valorE ? `${mccr_valorE}€` : ""}
            </Form.Label>
          </Form.Group>
          <Form.Group
            id="perDepreciacao"
            as={Row}
            style={{
              flexWrap: "nowrap",
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "5px",
              fontSize: `${textSize}px`,
              width: "35%",
            }}
          >
            {mccr_percD_all?.disabled === "true" &&
            mccr_percD_all?.type == "textbox" ? (
              <>
                <Form.Label
                  id="label_mccr_percD"
                  column
                  style={{ flexGrow: "0.43", fontSize: `${textSize}px` }}
                >
                  Depreciação Física, Funcional e Económica (CC+E+L)*D
                </Form.Label>
                <Form.Label
                  id="mccr_percD"
                  column
                  style={{
                    ...style9,
                    flexGrow: "0.2",
                    fontSize: `${textSize}px`,
                  }}
                >
                  {mccr_percD ? `${mccr_percD} %` : ""}
                </Form.Label>
              </>
            ) : (
              <>
                <Form.Label
                  id="label_mccr_percD"
                  column
                  style={{ flexGrow: "0.5", fontSize: `${textSize}px` }}
                >
                  Depreciação Física, Funcional e Económica (CC+E+L)*D
                </Form.Label>
                <input
                  id="mccr_percD"
                  style={{ width: "15%", fontSize: `${textSize}px` }}
                  className="form-control create-text-input-suffix-fix"
                  type="text"
                  size={6}
                  name={"mccr_percD"}
                  maxLength={6}
                  value={mccr_percD}
                  onChange={(e) => setFormData13(e.target.value)}
                  onBlur={() => {
                    updateTotais(64, 72);
                  }}
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    className={"create-text-input-suffix"}
                    style={{ height: "44px" }}
                  >
                    %
                  </InputGroup.Text>
                </InputGroup.Append>
              </>
            )}
          </Form.Group>
          <Form.Group as={Row} style={style6}>
            <Form.Label
              id="label_mccr_valorD"
              column
              style={{
                flexGrow: "0.6",
                fontSize: `${textSize}px`,
                textAlign: "end",
              }}
            >
              D =
            </Form.Label>
            <Form.Label
              id="depreciacao"
              column
              style={{ ...style9, fontSize: `${textSize}px` }}
            >
              {mccr_valorD ? `${mccr_valorD}€` : ""}
            </Form.Label>
          </Form.Group>
        </Form.Group>

        <Form.Group as={Row} style={style4}>
          <Form.Group as={Row} style={{ ...style5, width: "28.7%" }}>
            <Form.Label
              id='label_""'
              column
              style={{ flexGrow: "1", fontSize: `${textSize}px` }}
            >
              Custos de construção + Encargos indiretos
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} style={style6}>
            <Form.Label
              id="label_mccr_valorCCE"
              column
              style={{ flexGrow: "0.6", fontSize: `${textSize}px` }}
            >
              CC + E =
            </Form.Label>
            <Form.Label
              id="ccEncargos"
              column
              style={{
                ...style9encargos,
                flexGrow: "0.2",
                fontSize: `${textSize}px`,
              }}
            >
              {mccr_valorCCE ? `${mccr_valorCCE}€` : ""}
            </Form.Label>
          </Form.Group>
          <Form.Group
            id="perConstrucao"
            as={Row}
            style={{ ...style5, width: "35%" }}
          >
            {mccr_percConstReal_all?.disabled === "true" &&
            mccr_percConstReal_all?.type == "textbox" ? (
              <>
                <Form.Label
                  id="label_mccr_percConstReal"
                  column
                  style={{
                    flexGrow: "0.38",
                    paddingLeft: "0px",
                    fontSize: `${textSize}px`,
                  }}
                >
                  % de construção realizada
                </Form.Label>
                <Form.Label
                  id="mccr_percConstReal"
                  column
                  style={{
                    ...style9,
                    flexGrow: "0.2",
                    fontSize: `${textSize}px`,
                  }}
                >
                  {mccr_percConstReal ? `${mccr_percConstReal} %` : ""}
                </Form.Label>
              </>
            ) : (
              <>
                <Form.Label
                  id="label_mccr_percConstReal"
                  column
                  style={{
                    flexGrow: "0.44",
                    paddingLeft: "0px",
                    fontSize: `${textSize}px`,
                  }}
                >
                  % de construção realizada
                </Form.Label>
                <input
                  id="mccr_percConstReal"
                  style={{ width: "15%", fontSize: `${textSize}px` }}
                  className="form-control create-text-input-suffix-fix"
                  type="text"
                  size={6}
                  name={"mccr_percConstReal"}
                  maxLength={13}
                  value={mccr_percConstReal}
                  onChange={(e) => setFormData14(e.target.value)}
                  onBlur={() => {
                    updateTotais(64, 72);
                  }}
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    className={"create-text-input-suffix"}
                    style={{ height: "44px" }}
                  >
                    %
                  </InputGroup.Text>
                </InputGroup.Append>
              </>
            )}
          </Form.Group>
        </Form.Group>

        <Form.Group as={Row} style={style4}>
          <Form.Group as={Row} style={{ ...style5, width: "28.7%" }}>
            <Form.Label
              id='label_""'
              column
              style={{ flexGrow: "1", fontSize: `${textSize}px` }}
            >
              Custo Total (CC+E+T)
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} style={style6}>
            <Form.Label
              id="label_mccr_valorCt"
              column
              style={{ flexGrow: "0.61", fontSize: `${textSize}px` }}
            >
              Ct =
            </Form.Label>
            <Form.Label
              id="custoTotal"
              column
              style={{
                ...style9encargos,
                flexGrow: "0.2",
                fontSize: `${textSize}px`,
              }}
            >
              {mccr_valorCt ? `${mccr_valorCt}€` : ""}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} style={{ ...style7, width: "52%" }}>
            <Form.Label
              id="label_mccr_valorAtual"
              column
              style={{
                flexGrow: "0.5",
                fontSize: `${textSize}px`,
                paddingLeft: "0px",
              }}
            >
              Valor Actual de acordo com % de construção realizada
              (T+%construção*(CC+E+L)-D
            </Form.Label>
            <Form.Label
              id="valorAtual"
              column
              style={{ ...style9, fontSize: `${textSize}px` }}
            >
              {mccr_valorAtual ? `${mccr_valorAtual}` : ""}
            </Form.Label>
          </Form.Group>
        </Form.Group>

        <Form.Group as={Row} style={style4}>
          <Form.Group id="margemLucro" as={Row} style={style5}>
            <Form.Label
              id="label_mccr_percL"
              column
              style={{ flexGrow: "0.7", fontSize: `${textSize}px` }}
            >
              Margem de Lucro do Promotor (% de Ct)
            </Form.Label>
            {mccr_percL_all?.disabled === "true" &&
            mccr_percL_all?.type == "textbox" ? (
              <Form.Label
                id="mccr_percL"
                column
                style={{ ...style9, fontSize: `${textSize}px` }}
              >
                {mccr_percL ? `${mccr_percL} %` : ""}
              </Form.Label>
            ) : (
              <>
                <input
                  id="mccr_percL"
                  style={{ width: "15%", fontSize: `${textSize}px` }}
                  className="form-control create-text-input-suffix-fix"
                  type="text"
                  size={6}
                  name={"mccr_percL"}
                  value={mccr_percL}
                  onChange={(e) => setFormData15(e.target.value)}
                  maxLength={6}
                  onBlur={() => {
                    updateTotais(64, 72);
                  }}
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    className={"create-text-input-suffix"}
                    style={{ height: "44px" }}
                  >
                    %
                  </InputGroup.Text>
                </InputGroup.Append>
              </>
            )}
          </Form.Group>
          <Form.Group as={Row} style={{ ...style6, width: "17.5%" }}>
            <Form.Label
              id="label_mccr_valorL"
              column
              style={{
                flexGrow: "0.67",
                fontSize: `${textSize}px`,
                paddingLeft: "0px",
              }}
            >
              L =
            </Form.Label>
            <Form.Label
              id="lucro"
              column
              style={{
                ...style9encargos,
                flexGrow: "0.2",
                fontSize: `${textSize}px`,
              }}
            >
              {mccr_valorL ? `${mccr_valorL}€` : ""}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} style={{ ...style7, width: "53%" }}>
            <Form.Label
              id="label_mccr_valorSeguro"
              column
              style={{ flexGrow: "1", fontSize: `${textSize}px` }}
            >
              Valor para efeitos de Seguro (CC+E)
            </Form.Label>
            <Form.Label
              id="valorSeguro"
              column
              style={{ ...style9, fontSize: `${textSize}px` }}
            >
              {mccr_valorSeguro ? `${mccr_valorSeguro}€` : ""}
            </Form.Label>
          </Form.Group>
        </Form.Group>

        <Form.Group as={Row} style={style4}>
          <Form.Group
            as={Row}
            style={{
              flexWrap: "nowrap",
              paddingRight: "0px",
              paddingLeft: "0px",
              margin: "0px",
              width: "100%",
            }}
          >
            <Form.Label
              id="label_mccr_valorNovo"
              column
              style={{ flexGrow: "1", fontSize: `${textSize}px` }}
            >
              Valor Novo (Ct + L)
            </Form.Label>
            <Form.Label
              id="valorNovo"
              column
              style={{ ...style9, fontSize: `${textSize}px` }}
            >
              {mccr_valorNovo ? `${mccr_valorNovo}€` : ""}
            </Form.Label>
          </Form.Group>
        </Form.Group>

        <Form.Group as={Row} style={style4}>
          <Form.Group
            as={Row}
            style={{
              flexWrap: "nowrap",
              paddingRight: "0px",
              paddingLeft: "0px",
              margin: "0px",
              width: "100%",
            }}
          >
            <Form.Label
              id="label_mccr_valorMetodoCusto"
              column
              style={{ flexGrow: "1", fontSize: `${textSize}px` }}
            >
              V - Valor Comercial
            </Form.Label>
            <Form.Label
              id="valorComercial"
              column
              style={{ ...style9, fontSize: `${textSize}px` }}
            >
              {mccr_valorMetodoCusto ? `${mccr_valorMetodoCusto}€` : ""}
            </Form.Label>
            <hr />
          </Form.Group>
        </Form.Group>

        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
          <Form.Group as={Row} style={style3}>
            {areaBruta && (
              <CreateTextInput
                fontSize={textSize}
                id="areaBruta"
                field={areaBruta}
                key={areaBruta.variable}
                onBlur={() => {
                  updateTotais(64, 72);
                }}
                maxWidth={'275px'}
                marginRight={'30px'}
              />
            )}

            {ltv && (
              <>
                <Form.Group as={Row} style={style3}>
                  <Form.Label
                    id="label_ltv"
                    column
                    style={{ maxWidth: "125px", fontSize: `${textSize}px` }}
                  >
                    LTV
                  </Form.Label>
                  <Form.Label
                    id="ltv"
                    column
                    style={{ ...style11, fontSize: `${textSize}px`, maxWidth:'90px' }}
                  >
                    {ltv ? `${ltv}%` : ""}
                  </Form.Label>
                  <Form.Label
                    id="montanteCredito"
                    column
                    style={{ ...style11, fontSize: `${textSize}px` }}
                  >
                    Montante de Crédito
                  </Form.Label>
                </Form.Group>
              </>
            )}
            </Form.Group>
            <Form.Group as={Row} style={style3}>
              <Form.Label
                id="label_lvRep"
                column
                style={{ maxWidth: "215px", fontSize: `${textSize}px` }}
              >
                IV adoptado
              </Form.Label>
              <Form.Label
                id="ivAdoptado"
                column
                style={{ ...style11, fontSize: `${textSize}px` }}
              ></Form.Label>
            </Form.Group>
          </Col>
        </Form.Group>

        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`}>
            <Alert variant="info">
              Comparativo de Valores (Método Comparativo / Método Custos de
              construção Reposição)
            </Alert>
          </Col>
        </Form.Group>

        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            // width: "70%",
          }}
        >
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            <Form.Group as={Row} style={style3}>
              <Form.Label
                column
                style={{ flexGrow: "1", fontSize: `${textSize}px` }}
              >
                Valor Met Comp
              </Form.Label>
              <Form.Label
                id="valorMetComp"
                column
                style={{
                  flexGrow: "1",
                  fontWeight: "bold",
                  fontSize: `${textSize}px`,
                }}
              >
                {valorMetComp &&
                  formatter.format(
                    Number(parseCurrency(valorMetComp)).toFixed(2)
                  )}
              </Form.Label>
            </Form.Group>
          </Col>

          <Form.Label
            id="comparativo"
            column
            style={{ flexGrow: "0.2", fontSize: `${textSize}px` }}
          ></Form.Label>

          <Col key={`columndivision_3}`} style={{ padding: "0px" }}>
            <Form.Group as={Row} style={style3}>
              <Form.Label
                column
                style={{ flexGrow: "1", fontSize: `${textSize}px` }}
              >
                Valor Met Custos
              </Form.Label>
              <Form.Label
                id="valorMetCustos"
                column
                style={{
                  flexGrow: "1",
                  fontWeight: "bold",
                  fontSize: `${textSize}px`,
                }}
              >
                {valorMetCustos ? `${valorMetCustos}€` : `0,00€`}
              </Form.Label>
            </Form.Group>
          </Col>
          <Form.Label
            id="warningRight"
            column
            style={{
              flexGrow: "1",
              fontSize: `${textSize}px`,
              color: "red",
            }}
          >
            {warningRight}
          </Form.Label>
        </div>

        <Form.Group as={Row} style={style8}>
          <Col key={`columndivision_1}`} style={{ padding: "0px" }}>
            {orcClienteRep && (
              <CreateTextInput
                field={orcClienteRep}
                key={orcClienteRep.variable}
                fontSize={textSize}
              />
            )}
            {orcAceiteRep && (
              <CreateTextInput
                field={orcAceiteRep}
                key={orcAceiteRep.variable}
                fontSize={textSize}
              />
            )}

            {empreiteiroRep && (
              <CreateTextInput
                field={empreiteiroRep}
                key={"empreiteiroRep"}
                fontSize={textSize}
              />
            )}

            {licCamarariaRep && (
              <CreateSearchableDropdown
                field={licCamarariaRep}
                key={"licCamarariaRep"}
                fontSize={textSize}
              />
            )}

            {numLicRep && (
              <CreateTextInput
                field={numLicRep}
                key={"numLicRep"}
                fontSize={textSize}
              />
            )}

            {dtValidadeLicRep && (
              <CreateDateInput
                field={dtValidadeLicRep}
                key={"dtValidadeLicRep"}
                fontSize={textSize}
              />
            )}

            {mccr_observacoes && (
              <CreateTextArea
                field={mccr_observacoes}
                key={"mccr_observacoes"}
                fontSize={textSize}
              />
            )}
          </Col>
        </Form.Group>
      </>
    );
  };

  return (
    <Row
      key={`blockdivision_comparativo`}
      style={{ width: "100%", flexWrap: "nowrap", margin: "0px" }}
    >
      <Col key={`columndivision_comparativo}`} style={{ flexGrow: "1" }}>
        {renderColumnContent()}
      </Col>
    </Row>
  );
};
export default CreateCalculosCusto;
