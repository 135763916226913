import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import { Card, Button, Form, Col } from "react-bootstrap";
import Toast from "~/components/Toast";

import logo from "~/assets/img/logos/unik-logo.png";

import {
  requestResetAdminPassword,
  clearSuccessRequestResetAdmin,
} from "~/store/ducks/resetPassword/actionTypes";
import { useHistory } from "react-router-dom";

const ResetAdminPassword = (props) => {
  const { t } = props;
  const { code } = props.match?.params;

  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [adminCode, setAdminCode] = useState("");
  const [validated, setValidated] = useState(false);

  const {
    isLoadingRequestResetAdminPassword,
    successRequestResetAdminPassword,
  } = useSelector((state) => state.resetPasswordReducer);

  useEffect(() => {
    setAdminCode(code);
  }, []);

  useEffect(() => {
    successRequestResetAdminPassword && history.push("/confirm-admin-password");
    return () => {
      dispatch(clearSuccessRequestResetAdmin());
    };
  }, [successRequestResetAdminPassword]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (password === confirmPassword) {
        const payload = {
          email,
          password,
          adminKey: adminCode,
        };
        dispatch(requestResetAdminPassword(payload));
      } else {
        toast.warning("⚠️ " + t("login.passwordsNotMatch"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <>
      {
        <div className="overlay">
          <div className="row">
            <div className="col-12 mt-3 mb-2 text-center">
              <img
                className="img-fluid unik-logo"
                src={logo}
                alt="Uniksystem"
              />
            </div>
          </div>
          <Form.Row style={{ paddingTop: 20, color: "gray" }}>
            <Col lg="12" style={{ textAlign: "center" }}>
              <h1 className="display-2">
                {t("login.changePasswordMainLabel")}
              </h1>
            </Col>
          </Form.Row>
          <hr />
          <Card className="card-login">
            <Card.Body>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                method="post"
                encType="multipart/form-data"
              >
                <Form.Row>
                  <Col lg="12">
                    <Form.Group controlId="adminEmail">
                      <h1 className="label-credentials">
                        {t("resetAdminPassword.email")}
                      </h1>
                      <Form.Control
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("resetAdminPassword.emailRequired")}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="adminPassword">
                      <h1 className="label-credentials">
                        {t("login.password")}
                      </h1>
                      <Form.Control
                        type="password"
                        autoComplete="new-password"
                        required
                        //pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("admin.userManagement.persist.validPassword")}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="adminConfirmPassword">
                      <h1 className="label-credentials">
                        {t("login.confirmPassword")}
                      </h1>
                      <Form.Control
                        type="password"
                        autoComplete="new-password"
                        required
                        //pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col lg="12">
                    <div className="buttons" style={{ paddingTop: "15px" }}>
                      <Button
                        type="submit"
                        variant="outline-light"
                        className="login-button"
                        style={{
                          backgroundColor: "#33058d",
                        }}
                      >
                        {isLoadingRequestResetAdminPassword
                          ? t("login.loading")
                          : t("login.changePassword")}
                      </Button>
                    </div>
                  </Col>
                </Form.Row>
              </Form>
            </Card.Body>
          </Card>
        </div>
      }
      <Toast />
    </>
  );
};

export default withNamespaces()(ResetAdminPassword);
