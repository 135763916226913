import React, { useState, useEffect } from "react";
import moment from "moment";
import { startOf, add } from "date-arithmetic";
import { navigate } from "react-big-calendar/lib/utils/constants";
import { v4 as uuidv4 } from "uuid";
import ModalYear from "./ModalYear";
import PaperYear from "./PaperYear";
import { TbBeach } from "react-icons/tb";
import Popover from "@mui/material/Popover";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";

const PaperPopover = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
}));

function createCalendar(currentDate) {
  if (!currentDate) {
    currentDate = moment();
  } else {
    currentDate = moment(currentDate);
  }

  const first = currentDate.clone().startOf("month");
  const last = currentDate.clone().endOf("month");
  const weeksCount = Math.ceil((first.day() + last.date()) / 7);
  const calendar = Object.assign([], { currentDate, first, last });

  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week = [];
    calendar.push(week);
    calendar.year = currentDate.year();
    calendar.month = currentDate.month();

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      const date = currentDate.clone().set("date", day + 1 - first.day());
      date.calendar = calendar;
      week.push(date);
    }
  }

  return calendar;
}

function CalendarDate(props) {
  const {
    dateToRender,
    dateOfMonth,
    events,
    onClick,
    setEvents,
    firstClickDate,
    setMousePosition,
    isEditable,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  let today =
    dateToRender.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
      ? "today"
      : "";

  const handleMouseEnter = (event) => {
    if (firstClickDate && !isHovered) {
      const startDate = moment.min(firstClickDate, dateToRender);
      const endDate = moment.max(firstClickDate, dateToRender);

      const intervalDates = [];
      let currentDay = startDate.clone();
      while (currentDay.isSameOrBefore(endDate, "day")) {
        intervalDates.push(currentDay.format("YYYY-MM-DD"));
        currentDay.add(1, "day");
      }

      setIsHovered(true);

      // Criar um novo evento do tipo "hover" para todo o intervalo de datas
      const hoverEvents = intervalDates.map((date) => ({
        id: uuidv4(),
        start: moment(date).toDate(),
        end: moment(date).endOf("day").toDate(),
        type: "hover",
        title: "Hovered Day",
        color: "#44A49B",
      }));

      setEvents((prevEvents) => [...prevEvents, ...hoverEvents]);

      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          intervalDates.includes(moment(event.start).format("YYYY-MM-DD"))
            ? { ...event, hover: true }
            : event
        )
      );
    }
    setMousePosition({ x: event.pageX, y: event.pageY });
  };
  const handleMouseLeave = () => {
    if (firstClickDate && isHovered) {
      setIsHovered(false);

      setEvents((prevEvents) => {
        // Filtra os eventos de "hover" para remover apenas aqueles do intervalo
        const updatedEvents = prevEvents.filter((event) =>
          event.type === "hover"
            ? moment(event.start).isBefore(firstClickDate) ||
              moment(event.start).isSameOrAfter(dateToRender)
            : true
        );

        return updatedEvents.map((event) =>
          event.hover ? { ...event, hover: false } : event
        );
      });
    } else {
      today =
        dateToRender.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          ? "today"
          : "";
    }
  };

  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <button disabled={true} className="date prev-month">
        {dateToRender.date()}
      </button>
    );
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <button disabled={true} className="date next-month">
        {dateToRender.date()}
      </button>
    );
  }

  const matchedEvent = events.filter((eventDetail) =>
    dateToRender.isSame(moment(eventDetail.start), "day")
  );

  if (matchedEvent.length === 0) {
    matchedEvent.push(
      events.find((eventDetail) =>
        dateToRender.isSame(moment(eventDetail.start), "day")
      )
    );
  }
  return (
    <Badge
      color="warning"
      badgeContent={matchedEvent.length}
      invisible={matchedEvent.length <= 1 || isEditable}
      variant="dot"
      classes="badgeYearCalendar"
    >
      <button
        className={`date in-month ${matchedEvent[0]?.type || today} ${
          matchedEvent[0]?.hover && matchedEvent[0]?.type == "hover"
            ? "highlighted"
            : ""
        }`}
        title={
          matchedEvent.length > 1
            ? matchedEvent.map((event) => event.title).join(", ")
            : matchedEvent[0]?.title
        }
        onClick={() => {
          if (isEditable) {
            onClick(dateToRender);
          }
        }}
        onMouseEnter={(e) => {
          if (isEditable) {
            handleMouseEnter(e);
          }
        }}
        onMouseLeave={() => {
          if (isEditable) {
            handleMouseLeave();
          }
        }}
        style={{
          padding:
            matchedEvent[0]?.type == "feriasmarked" ||
            matchedEvent[0]?.type == "intervals" ||
            matchedEvent[0]?.type == "ferias"
              ? "1px"
              : "",
        }}
      >
        {matchedEvent.length === 1 ? (
          <>
            {matchedEvent[0]?.type == "feriasmarked" && (
              <TbBeach
                style={{
                  fontSize: "small",
                  alignSelf: "center",
                  color: matchedEvent[0]?.color || "grey",
                }}
              />
            )}
            {matchedEvent[0]?.type == "ferias" && (
              <TbBeach
                style={{
                  fontSize: "small",
                  alignSelf: "center",
                  color: matchedEvent[0]?.color || "grey",
                }}
              />
            )}
            {matchedEvent[0]?.type == "intervals" && (
              <TbBeach
                style={{
                  fontSize: "small",
                  alignSelf: "center",
                  color: matchedEvent[0]?.color || "grey",
                }}
              />
            )}
            {matchedEvent[0]?.type == "datasMarcacoes" && (
              <TbBeach
                style={{
                  fontSize: "small",
                  alignSelf: "center",
                  color: matchedEvent[0]?.color || "grey",
                }}
              />
            )}
            {matchedEvent[0]?.type == "feriasPendentes" && (
              <TbBeach
                style={{
                  fontSize: "small",
                  alignSelf: "center",
                  color: matchedEvent[0]?.color || "grey",
                }}
              />
            )}
          </>
        ) : (
          <TbBeach
            style={{
              fontSize: "small",
              alignSelf: "center",
              color: "grey",
            }}
          />
        )}
        {dateToRender.date()}
      </button>
    </Badge>
  );
}

function CalendarComponent({
  date,
  events,
  setEvents,
  convertToEventsMarkedDays,
  settoastText,
  setShowToast,
  t,
  isEditable,
  singleDaySelection,
  setRemoveAll,
  anchorEl,
  setAnchorEl,
  open,
}) {
  const [calendar, setCalendar] = useState(null);
  const [firstClickDate, setFirstClickDate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [dateToCompare, setDateToCompare] = useState(undefined);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setCalendar(createCalendar(date));
  }, [date]);

  if (!calendar) {
    return null;
  }

  const handleClick = (day) => {
    setDateToCompare(day);

    const { x, y } = mousePosition;

    const top = y;
    const left = x;

    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";

    setAnchorEl({ top: top, left: left });
  };

  const handleAddIntervalEvent = (dateToRender) => {
    const isEventAlreadyExists = events.some(
      (event) =>
        ["feriados", "folgas", "ferias"].includes(event.type) &&
        moment(event.start).isSame(dateToRender, "day")
    );
    const isEventAlreadyMarked = events.some(
      (event) =>
        ["feriasmarked", "intervals"].includes(event.type) &&
        moment(event.start).isSame(dateToRender, "day")
    );
    if (isEventAlreadyExists) {
      return;
    }
    if (isEventAlreadyMarked) {
      const isEventSolo = events.some(
        (event) =>
          ["feriasmarked", "intervals"].includes(event.type) &&
          moment(event.start).isSame(dateToRender, "day") &&
          event.multi === true
      );
      handleClick(dateToRender);

      return;
    }
    if (!firstClickDate) {
      const singleDayEvent = {
        id: uuidv4(),
        start: moment(dateToRender).toDate(),
        end: moment(dateToRender).endOf("day").toDate(),
        type: "feriasmarked",
        title: t("portalrh.workDays.selectedDay"),
        color: "#44A49B",
        intervals: [],
        checked: true,
        checkedHalfDay: false,
        multi: true,
      };
      setEvents((prevEvents) => [...prevEvents, singleDayEvent]);
      setFirstClickDate(moment(dateToRender));
    } else {
      const startDate = moment.min(firstClickDate, dateToRender);
      const endDate = moment.max(firstClickDate, dateToRender);
      const intervals = [];
      let currentDay = startDate.clone();
      while (currentDay.isSameOrBefore(endDate, "day")) {
        const formattedDate = currentDay.format("YYYY-MM-DD");
        const dateAlreadyExists = events.some(
          (event) =>
            [
              "feriados",
              "folgas",
              "ferias",
              "intervals",
              "feriasmarked",
            ].includes(event.type) &&
            moment(event.start).format("YYYY-MM-DD") === formattedDate
        );
        if (!dateAlreadyExists) {
          intervals.push(formattedDate);
        }
        currentDay.add(1, "day");
      }

      const intervalEvents = intervals.map((intervalDate) => ({
        id: uuidv4(),
        start: moment(intervalDate).toDate(),
        end: moment(intervalDate).endOf("day").toDate(),
        type: "intervals",
        title: "Hovered Interval",
        color: "#44A49B",
        multi: true,
      }));

      setEvents((prevEvents) => [...prevEvents, ...intervalEvents]);
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.type !== "hover")
      );

      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          moment(event.start).isSame(firstClickDate, "day")
            ? { ...event, intervals }
            : event
        )
      );

      setFirstClickDate(null);
      setRemoveAll(true);
    }
  };

  const handleAddSingleEvent = (date) => {
    const isEventAlreadyExists = events.some(
      (event) =>
        event.type !== "feriasmarked" &&
        event.type != "intervals" &&
        moment(event.start).isSame(date, "day")
    );
    if (isEventAlreadyExists) {
      return;
    }
    if (isEditable) {
      const daysMarked = convertToEventsMarkedDays(
        [date],
        "feriasmarked",
        "#44A49B"
      );

      // Verifica se a data é do tipo "daysmarked" e já está nos eventos

      const isEventSolo = events.some(
        (event) =>
          ["feriasmarked", "intervals"].includes(event.type) &&
          moment(event.start).isSame(daysMarked[0].start, "day") &&
          event.multi === true
      );
      if (isEventSolo) {
        handleClick(daysMarked[0].start);
      } else {
        setEvents((prevEvents) => [...prevEvents, ...daysMarked]);
        setRemoveAll(true);
      }

      // const isEventAlreadyAdded = events.some(
      //   (event) =>
      //     event.type === "feriasmarked" &&
      //     moment(event.start).isSame(daysMarked[0].start, "day")
      // );

      // if (isEventAlreadyAdded) {
      //   setEvents((prevEvents) =>
      //     prevEvents.filter(
      //       (event) =>
      //         !(
      //           event.type === "feriasmarked" &&
      //           moment(event.start).isSame(daysMarked[0].start, "day")
      //         )
      //     )
      //   );
      // } else {
      //   setEvents((prevEvents) => [...prevEvents, ...daysMarked]);
      //   setRemoveAll(true);
      // }
    }
  };

  const lang = calendar.currentDate._locale._abbr;
  const weekdays = {
    pt: ["D", "S", "T", "Q", "Q", "S", "S"],
    en: ["S", "M", "T", "W", "T", "F", "S"],
    es: ["D", "L", "M", "X", "J", "V", "S"],
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
    setDateToCompare(undefined);

    document.body.style.overflow = "";
    document.body.style.position = "";
  };

  return (
    <>
      <div className="month">
        <div className="month-name">
          {calendar.currentDate.format("MMMM").toUpperCase()}
        </div>
        {weekdays[lang].map((day, index) => (
          <span key={index} className="day">
            {day}
          </span>
        ))}
        {calendar.map((week, index) => (
          <div key={index}>
            {week.map((date) => (
              <CalendarDate
                key={date.date()}
                isEditable={isEditable}
                dateToRender={date}
                dateOfMonth={calendar.currentDate}
                onClick={(date) => {
                  if (isEditable) {
                    !singleDaySelection
                      ? handleAddIntervalEvent(date)
                      : handleAddSingleEvent(date);
                  }
                }}
                events={events}
                setEvents={setEvents}
                firstClickDate={firstClickDate}
                setMousePosition={setMousePosition}
              />
            ))}
          </div>
        ))}
      </div>
      {dateToCompare !== undefined && (
        <Popover
          open={open}
          anchorReference="anchorPosition"
          anchorPosition={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <PaperPopover elevation={8} variant="elevation">
            <PaperYear
              events={events}
              dateToCompare={dateToCompare}
              handleAddIntervalEvent={handleAddIntervalEvent}
              setEvents={setEvents}
              singleDaySelection={singleDaySelection}
              handleClosePopOver={handleClosePopOver}
            />
          </PaperPopover>
        </Popover>
      )}
      {/* 
      <ModalYear
        events={events}
        showModal={showModal}
        dateToCompare={dateToCompare}
        handleAddIntervalEvent={handleAddIntervalEvent}
        setShowModal={setShowModal}
        setEvents={setEvents}
        singleDaySelection={singleDaySelection}
      /> */}
    </>
  );
}
function Year({
  date,
  events,
  setEvents,
  convertToEventsMarkedDays,
  settoastText,
  setShowToast,
  t,
  isEditable,
  singleDaySelection,
  setRemoveAll,
}) {
  let range = Year.range(date);
  const months = [];
  const firstMonth = startOf(date, "year");
  const singleDayValue = singleDaySelection === "single" ? true : false;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  for (let i = 0; i < 12; i++) {
    months.push(
      <CalendarComponent
        key={i + 1}
        date={add(firstMonth, i, "month")}
        events={events}
        setEvents={setEvents}
        convertToEventsMarkedDays={convertToEventsMarkedDays}
        settoastText={settoastText}
        setShowToast={setShowToast}
        isEditable={isEditable}
        singleDaySelection={singleDayValue}
        setRemoveAll={setRemoveAll}
        t={t}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
      />
    );
  }

  return (
    <div className="year scrollBar-visible-year">
      {months.map((month) => month)}
    </div>
  );
}

Year.range = (date) => {
  return [startOf(date, "year")];
};

Year.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return add(date, -1, "year");

    case navigate.NEXT:
      return add(date, 1, "year");

    default:
      return date;
  }
};

Year.title = (date, { localizer }) =>
  localizer.format(date, "yearHeaderFormat");

export default Year;
