import React, { useRef, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import DateRange from "./DateRange";
import "~/pages/Liftworld/assets/scss/_inputDateSingle.scss";

/**
 *
 * @param rangeName check DateRange.js for range details
 */
const InputDateSingle = ({
  placeholder,
  disabled,
  text,
  rangeName,
  handleChange,
  dates,
  isRange,
  position
}) => {
  
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendar, setCalendar] = useState(dates != null ? dates : [new Date(), new Date()]);
  const [currentDate, setCurrentDate] = useState("dd/mm/yyyy")
  const calendarId = useRef(uuid());

  useEffect(() => {
    if(dates === null || dates === undefined)
    {
      setCurrentDate("dd/mm/yyyy")
      return;
    }

    if(dates[0] === "")
    {
      setCurrentDate("dd/mm/yyyy")
      return;
    }
      
      var string =       `${dates[0].getFullYear()}-${
        dates[0].getMonth().toString().length === 1
          ? "0" + (dates[0].getMonth() + 1)
          : dates[0].getMonth() + 1
      }-${
        dates[0].getDate().toString().length === 1
          ? "0" + dates[0].getDate()
          : dates[0].getDate()
      }`

    const dateInfo = string.split("-")
    setCurrentDate(
      dateInfo != null && dateInfo != undefined && dateInfo?.length > 0 && dateInfo[0] != "NaN"
      ? dateInfo[2] + "/" + dateInfo[1] + "/" + dateInfo[0] 
      : "dd/mm/yyyy"
    );

  }, [dates]);

  const clearHandler = () => {
    setCalendar([undefined, undefined]);
    if (handleChange) {
      handleChange({ type: "date", data: [undefined, undefined] });
      handleChange({ type: "hour", data: [undefined, undefined] });
    }
  };

  const onDateChange = () => {
    if (handleChange) handleChange({ type: "date", data: calendar });
    setShowCalendar(false);
  };

  return (
    <div
      className="liftworld-element-input-date-wrapper"
    >
      {text && <div className="liftworld-element-input-date-label">{text}</div>}
      <Form.Group
        controlId={`formText-field`}
        style={{ width: "100%", marginBottom: "0px" }}
      >
        <div
          id={`input-date-${calendarId.current}`}
          disabled={disabled}
          className={`liftworld-element-input-wrapper-date ${position}`}
          onClick={() => !disabled && setShowCalendar(true)}
        >
          <input
            readOnly
            className="liftworld-element-input-field-date left"
            placeholder={placeholder ? placeholder : ""}
            disabled={disabled}
            type="text"
            value={dates != null ? currentDate : "dd/mm/yyyy"}
          />
          {showCalendar && (
            <DateRange
              isRange={false}
              id={calendarId.current}
              dates={calendar}
              rangeName={rangeName}
              setDates={setCalendar}
              setShowCalendar={setShowCalendar}
              submit={onDateChange}
              clear={clearHandler}
            />
          )}
        </div>
      </Form.Group>
    </div>
  );
};

export default withNamespaces()(InputDateSingle);
