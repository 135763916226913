import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Col, Button, Accordion } from "react-bootstrap";
import FilterToggle from "./FilterToggle";
import { withNamespaces } from "react-i18next";
import "~/assets/css/icons.css";
import DatePicker from "~/components/DatePicker";
import TextFilter from "./TextFilter";
import SelectFilter from "./SelectFilter";
import Dropdown from "~/components/Dropdown";
const devLogConsole = require("~/utils/devLog");
const ProcessFilters = ({ app, ...props }) => {
  const {
    t,
    flowId,
    startAt,
    endAt,
    nProcess,
    previousUser,
    flowFiltersValues,
    hiddenDefaults,
    handleFlowFiltersValues,
    handleFlowId,
    handleEndAt,
    handleStartAt,
    handlePreviousUser,
    handleSearch,
    handleNProcess,
    handleReset,
    handleClosed,
    handleActors,
    isExternalPage = false,
  } = props;

  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { rawMenus, rawFlows } = useSelector((state) => state.applicationMenuReducer);

  const [styleSheet, setFormStyleSheet] = useState("");

  const [flowFilters, setFlowFilters] = useState(undefined);
  const [fluxos, setFluxos] = useState([]);
  const [cleanDropdown, setCleanDropdown] = useState(false); 
  const [filterToggle, setFilterToggle] = useState(true); //default show all filters

  const actorsArray = {
    [t("taskPage.general.openByMe")]: true,
    [t("taskPage.general.openByAll")]: false,
  };
  
  const stateArray = {
    [t("taskPage.general.stateOpen")]: true,
    [t("taskPage.general.stateClosed")]: false,
  };


  useEffect(() => {
    if (menu) {
      let app = "";
      if (isExternalPage) {
        app = "bpm";
      } else {
        //find app from url
        const arr1 =
          window.location.href.includes("/uniksystem/") &&
          window.location.href.split("/uniksystem/");
        if (arr1.length > 1) {
          const arr2 = arr1[1];
          const arr3 = arr2.split("/");
          app = arr3[0];
        }
      }

      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/processos` || item.route === `${app}/processes`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        const styleSheet_ =
          extraConfiguration_ && extraConfiguration_.styleSheet;
        //devLogConsole(styleSheet_)
        setFormStyleSheet(styleSheet_);
      }
    }
  }, [menu]);

  /*Get flow Filters defined in Extra Configuration*/
  useEffect(() => {
    if (menu) {
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/pesquisar` || item.route === `${app}/search`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        let flowFilters_;
        let filterToggle_;
        if (flowId && extraConfiguration_[flowId]) {
          flowFilters_ = extraConfiguration_[flowId].filters; //filters for a  specific flow
        } else {
          flowFilters_ = extraConfiguration_.defaultExtraFilters; //extra filters for all flows
        }
        filterToggle_ = extraConfiguration_.filterToggle;
        setFilterToggle(filterToggle_);
        setFluxos(Object.keys(extraConfiguration_));
        setFlowFilters(flowFilters_ ? flowFilters_ : undefined);
        handleFlowFiltersValues(undefined); /*Clean flow filters*/
      }
    }
  }, [menu, flowId]);

  const [activeKey, setActiveKey] = useState("0");
  const [toggleIcon, setToggleIcon] = useState("icon-down");
  useEffect(() => {
    setActiveKey(filterToggle === "false" ? null : "0");
    setToggleIcon(filterToggle === "false" ? "icon-down" : "icon-up");
  }, [filterToggle]);

  const renderFlowOptions = () => {
    if (app === "adatacapture") {
      return renderFlowListDatacapture()
    }
    return renderFlowListNormal()
  };

  const renderFlowListNormal = ()=>{
    const filtered = rawMenus.filter((menu) => {
      const flowId = menu.url && menu.url.split("flowid=")[1];
      return fluxos.some((f) => f === flowId);
    });
    return filtered.map((e) => {
      return {
        label: e.label,
        value: e.url.split("flowid=")[1],
        selected: false,
      };
    });
  }
  const renderFlowListDatacapture = () => {
    const filteredFlows = rawFlows.filter((menu) => {
      const flowId = menu.url && menu.url.split("flowid=")[1];
      return flowId;
    });
  
    const flowData = filteredFlows.map((e, index) => {
      return {
        label: e.label,
        value: e.url.split("flowid=")[1],
        selected: index === 0 ? true : false // Define o primeiro como 'true', o resto como 'false'
      };
    });
  
    return flowData;
  }
  
  

  const renderFilterState = () => {
    return Object.entries(stateArray).map(([key, value]) => {
      return {
        label: key,
        value: value,
        selected: false,
      };
    });
  };

  const renderFilterActors = () => {
    return Object.entries(actorsArray).map(([key, value]) => {
      return {
        label: key,
        value: value,
        selected: false,
      };
    });
  };

  const renderFlowFilters = () => {
    if (flowFilters) {
      const getValue = (variable) => {
        return flowFiltersValues && flowFiltersValues[variable]
          ? flowFiltersValues[variable]
          : "";
      };
      const handleChange = (variable, value) => {
        const newValues = { ...flowFiltersValues, [variable]: value };
        handleFlowFiltersValues(newValues);
      };

      const filters = flowFilters.map((filter, index) => {
        if (filter.type === "text" && filter.variable === "from")
          return (
            <Col lg="3" key={index}>
              <Form.Label>
                {t(`taskPage.general.${filter.variable}`)}
              </Form.Label>
              <Form.Control
                type="text"
                value={previousUser}
                onChange={(e) => handlePreviousUser(e.target.value)}
              />
            </Col>
          );
        if (filter.type === "text")
          return (
            <TextFilter
              filter={filter}
              key={index}
              value={getValue(filter.variable)}
              onChange={handleChange}
            />
          );
        if (filter.type === "select")
          return (
            <SelectFilter
              filter={filter}
              key={index}
              value={getValue(filter.variable)}
              onChange={handleChange}
            />
          );
      });
      return <Form.Row>{filters}</Form.Row>;
    }
  };

  const isHidden = (filter) => {
    if (hiddenDefaults) {
      return hiddenDefaults.some((f) => f === filter) ? true : false;
    } else return false;
  };

  return (
    <Accordion activeKey={activeKey}>
      <FilterToggle
        eventKey="0"
        setToggleIcon={setToggleIcon}
        setActiveKey={setActiveKey}
        toggleIcon={toggleIcon}
      />
      <Accordion.Collapse eventKey="0">
        <Form>
          {/*Default Filters start */}
          <Form.Row>
            {!isHidden("flowMenu") && (
              <Col lg="4">
                <Form.Label>{t("taskPage.general.flow")}</Form.Label>
                <Dropdown
                  list={renderFlowOptions()}
                  handleSubmit={handleFlowId}
                  emptyDefault={true}
                  resetDropdown={cleanDropdown}
                  setResetDropdown={setCleanDropdown}
                  flowId={flowId}
                />
              </Col>
            )}
            {styleSheet?.showMoreFilter ? (
              <>
                {!isHidden("flowMenu") && (
                  <Col lg="4">
                    <Form.Label>{t("taskPage.general.state")}</Form.Label>
                    <Dropdown
                      list={renderFilterState()}
                      handleSubmit={handleClosed}
                      emptyDefault={true}
                      resetDropdown={cleanDropdown}
                      setResetDropdown={setCleanDropdown}
                    />
                  </Col>
                )}
                {!isHidden("flowMenu") && (
                  <Col lg="4">
                    <Form.Label>{t("taskPage.general.actors")}</Form.Label>
                    <Dropdown
                      list={renderFilterActors()}
                      handleSubmit={handleActors}
                      emptyDefault={true}
                      resetDropdown={cleanDropdown}
                      setResetDropdown={setCleanDropdown}
                    />
                  </Col>
                )}
              </>
            ) : (
              ""
            )}
          </Form.Row>
          <br />
          <Form.Row>
            {!isHidden("from") && (
              <Col lg="4">
                <Form.Label>{t("taskPage.general.dataRegisto")}</Form.Label>
                <DatePicker
                  value={startAt}
                  onChange={(e) => handleStartAt(e)}
                  format="dd/MM/yyyy"
                />
              </Col>
            )}
            {!isHidden("to") && (
              <Col lg="4">
                <Form.Label> &nbsp;</Form.Label>
                <DatePicker
                  value={endAt}
                  onChange={(e) => handleEndAt(e)}
                  format="dd/MM/yyyy"
                />
              </Col>
            )}
            {!isHidden("pnumber") && (
              <Col lg="4">
                <Form.Label>{t("taskPage.general.processNumber")}</Form.Label>
                <Form.Control
                  type="text"
                  value={nProcess}
                  onChange={(e) => handleNProcess(e.target.value)}
                />
              </Col>
            )}
          </Form.Row>
          {/*Default Filters end */}
          <br />
          {renderFlowFilters()}
          <br />
          <Button className="card-button" onClick={() => handleSearch()}>
            {t("general.search")}
          </Button>
          <Button
            className="card-button"
            onClick={() => {handleReset(flowId); setCleanDropdown(true);}}
            style={{ marginLeft: "15px" }}
          >
            Reset
          </Button>
        </Form>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default withNamespaces()(ProcessFilters);
