import React, { useCallback, useEffect, useState, useRef } from "react";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";
import "../../RhCollaborator/RhCollaborator.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getCollaborators,
  getMarcacaoFerias,
  getAllMarcacaoFerias,
  postMarcacaoFerias,
  postMarcacaoFeriasConfirm,
  getInfoSaldoTrabalhoRemoto,
  getExportVals,
} from "~/store/ducks/portalrh/actionTypes";
import { getIflowFormdata } from "store/ducks/processes/actionTypes";
import Sidebar from "../../RhCollaborator/Sidebar";
import InputLabel from "@mui/material/InputLabel";
import { v4 as uuidv4 } from "uuid";
import { withNamespaces } from "react-i18next";
import DatePicker from "~/components/DatePicker";
import {
  Button as ButtonBoot,
  Card,
  Row,
  Col,
  Form,
  Modal,
  ListGroup,
  Alert,
} from "react-bootstrap";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.css";
import "~/assets/css/scheduler.css";
import Dropdown from "~/components/Dropdown";
import { Dropdown as DropDownBoot } from "react-bootstrap";
import moment from "moment";
import {
  colors,
  iflowConstants,
  variablesEvents,
  navigate,
} from "./ColorVariables";
import Spinner from "~/components/Spinner";
import { Button, Stack, Tabs, Tab } from "@mui/material";
import { grey } from "@mui/material/colors";
import { TextField } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import oauth from "~/utils/oauth";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  FaCalendarDay,
  FaCalendarAlt,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaUmbrellaBeach,
  FaBookmark,
  FaListUl,
  FaTrash,
  FaClock,
  FaCheckCircle,
  FaMinusCircle,
  FaTimesCircle,
  FaEye,
  FaCalendarPlus,
  FaCircle,
  FaPlus,
  FaFileExport,
} from "react-icons/fa";
import { CgCalendarToday } from "react-icons/cg";
import { HiMiniCalendarDays } from "react-icons/hi2";
import { TbBeach } from "react-icons/tb";
import { GoPlusCircle } from "react-icons/go";
import { MdOutlineRemoveCircleOutline } from "react-icons/md";
import Toast from "react-bootstrap/Toast";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FlowModal from "~/components/FlowModal";
import {
  setErrorPostMarcacaoFerias,
  setErrorPostMarcacaoFeriasConfirm,
  setExportVals,
} from "~/store/ducks/portalrh/actionTypes";

import { getOrganizationById } from "~/store/ducks/organizations/actionTypes";
import { getRHBolsaHorasConfiguration } from "~/store/ducks/rhadminConfiguration/actionTypes";
import Year from "./Year";

import uuid from "uuid/v1";

import { Popover } from "@material-ui/core";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AlertTitle from "@mui/material/AlertTitle";

const AlertMaterial = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SchedulerComponent = ({
  portalRhMenus,
  designacao,
  path,
  t,
  isEditable,
  showCalendarAndTable,
  loadEventsStart,
  setLoadEventsStart,
  selectedUser,
  setSelectedUser,
  grupos,
  setgrupos,
  activeButton,
  setActiveButton,
  hideChangeView,
  infoDataWorkHour,
  dataSelectedRequest,
  showAllGroups = false,
  exportBtn,
}) => {
  const { user } = useSelector((state) => state.globalReducer);
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { iflowpid } = useSelector((state) => state.processesReducer);
  const { rhbolsaHorasConfiguration } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );
  const {
    collaborators,
    marcacaoFerias,
    isLoadingMarcacaoFerias,
    marcacaoFeriasmsg,
    marcacaoFeriasmsgConfirm,
    gruposTrabalhoRemoto,
    trabalhoRemotoInfoSaldo,
    exportMsg,
    isLoadingExport,
    marcacaoFeriasCalendario,
  } = useSelector((state) => state.portalrhReducer);
  const { rhbolsaHorasUsersGroup } = useSelector(
    (state) => state.rhadminConfigurationReducer
  );
  const { organization } = useSelector((state) => state.organizationsReducer);

  const [menuSideBar, setMenuSideBar] = useState(null);
  const [selectedLocale, setSelectedLocale] = useState(userSettings.language);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [events, setEvents] = useState([]);
  const [localizer, setLocalizer] = useState(momentLocalizer(moment));
  const [dateSelected, setDateSelected] = useState(new Date());
  const [loadNewDateSelected, setLoadNewDateSelected] = useState(new Date());
  const [workdays_hours, setworkdays_hours] = useState({});
  const [defaultView, setDefaultView] = useState("year");
  const [showToast, setShowToast] = useState(false);
  const [showToastMSG, setShowToastMSG] = useState(false);
  const [toastText, settoastText] = useState("");
  const [showModalWithDaysMarked, setShowModalWithDaysMarked] = useState(false);
  const [showModalWithDaysMarkedConfirm, setShowModalWithDaysMarkedConfirm] =
    useState(false);

  const [openExport, setOpenExport] = useState(false);
  const [messageTranslated, setmessageTranslated] = useState("");
  const [openExportError, setOpenExportError] = useState(false);
  const [rangesState, setrangesState] = useState({});

  const [selectedGroup, setSelectedGroup] = useState("");
  const [criteriaGroup, setCriteriaGroup] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersColor, setSelectedUsersColor] = useState({});
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectAll, setSelectAll] = useState(showAllGroups);
  const [displayedUsers, setDisplayedUsers] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popupEvent, setPopupEvent] = useState(null);

  const [showModalCheckDays, setShowModalCheckDays] = useState(false);
  const [showModalCriteria, setShowModalCriteria] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [removeall, setRemoveAll] = useState(false);

  const [singleDaySelection, setSingleDaySelection] = useState("single");

  const [endAt, setEndAt] = useState("");
  const [startAt, setStartAt] = useState("");
  const [starEndtMonth, setstarEndtMonth] = useState({
    start: "",
    end: "",
  });

  const open = Boolean(anchorEl);

  const [key, setKey] = useState(designacao);

  const dispatch = useDispatch();

  const userNamePortalRH = collaborators.find((collaborator) => {
    return user.legacyUsers.some((legacyUser) => {
      return (
        legacyUser.username === collaborator.username &&
        legacyUser.legacyApplication.label === "portalrh"
      );
    });
  });
  useEffect(() => {
    setShowToastMSG(false);
    setOpenExport(false);
    setmessageTranslated("");
    setOpenExportError(false);
    setShowModalWithDaysMarked(false);
    setShowModalWithDaysMarkedConfirm(false);
    setShowModalWithDaysMarked(false);
    setShowModalWithDaysMarkedConfirm(false);
    const { start, end } = getMonthRange(dateSelected);
    rangeChange({ start, end });
    setstarEndtMonth({
      start: moment(dateSelected).startOf("month").startOf("week"),
      end: moment(dateSelected).endOf("month").endOf("week"),
    });
  }, []);

  useEffect(() => {
    dispatch(getCollaborators());
    portalRhMenus.map((menu) => {
      if (menu.designacao == designacao) {
        setMenuSideBar(menu);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const eventsDays = events.filter(
      (event) =>
        event.type === "feriasmarked" ||
        event.type === "hover" ||
        event.type === "intervals"
    );

    if (eventsDays.length <= 0) {
      setRemoveAll(false);
    }
  }, [events]);

  useEffect(() => {
    if (exportMsg !== "") {
      let messagetrans;
      if (exportMsg === "exportedScreen") {
        messagetrans = t("portalrh.workDays.export.exportedScreen");
      } else if (exportMsg === "exportedCriteria") {
        messagetrans = t("portalrh.workDays.export.exportedCriteria");
      }
      if (exportMsg.includes("exportedYear")) {
        let splited = exportMsg.split("::");
        messagetrans =
          t(`portalrh.workDays.export.${splited[0]}`) + "_" + splited[1];
      }
      setmessageTranslated(messagetrans);
      setOpenExport(true);
    }
  }, [exportMsg]);

  useEffect(() => {
    if (
      Object.keys(gruposTrabalhoRemoto).length > 0 &&
      infoDataWorkHour !== undefined
    ) {
      for (const grupo of gruposTrabalhoRemoto) {
        for (const usuario of grupo.userList) {
          if (usuario.id === infoDataWorkHour) {
            setSelectedGroup(grupo.grupo.id);
            const usersInGroup = grupo.userList.map((user) => user.numFuncionario);
            setSelectedUsers(usersInGroup);
            break;
          }
        }
      }
    } else {
      if (Object.keys(gruposTrabalhoRemoto).length > 0) {
        if (selectedGroup === "") {
          const allUsers = gruposTrabalhoRemoto.flatMap(
            (group) => group.userList
          );
          setDisplayedUsers(allUsers);
          const usersInGroup = allUsers.map((user) => user.numFuncionario);
          setSelectedUsers(usersInGroup);
          setSelectAll(true);
        }
      }
    }

    if (dataSelectedRequest != undefined)
      getDatesFromDays(
        dataSelectedRequest.length > 0
          ? new Date(dataSelectedRequest[0].data)
          : new Date()
      );
  }, [gruposTrabalhoRemoto, infoDataWorkHour]);

  useEffect(() => {
    if (userNamePortalRH != undefined) {
      callUpdateMarcacaoFerias();
    }
  }, [loadNewDateSelected, userNamePortalRH]);

  const callUpdateMarcacaoFerias = () => {
    if (isEditable) {
      const payload = {
        usrId: userNamePortalRH.id,
        entidade: userNamePortalRH.codEntidade,
        numFunc: userNamePortalRH.funcionarioNum,
        ano: loadNewDateSelected.getFullYear(),
      };
      dispatch(getMarcacaoFerias(payload));
      const payloadInfo = {
        usrId: userNamePortalRH.id,
        ano: loadNewDateSelected.getFullYear(),
      };
      //dispatch(getInfoSaldoTrabalhoRemoto(payloadInfo));
    } else {
      const selectedGroupInfo =
        Object.keys(gruposTrabalhoRemoto).length > 0 &&
        gruposTrabalhoRemoto.find((group) => group.grupo.id === selectedGroup);
      if (selectedGroupInfo) {
        const lepNums = selectedGroupInfo.userList.map((user) => user.numFuncionario);
        const payload = {
          lepNum: lepNums,
          ano: loadNewDateSelected.getFullYear(),
        };
        dispatch(getAllMarcacaoFerias(payload));
      } else {
        const lepNums = displayedUsers.map((user) => user.numFuncionario);
        const payload = {
          lepNum: lepNums,
          ano: loadNewDateSelected.getFullYear(),
        };
        dispatch(getAllMarcacaoFerias(payload));
      }
    }
  };

  useEffect(() => {
    setForceUpdate((prevKey) => prevKey + 1);
    if (Object.keys(marcacaoFerias).length > 0) {
      setworkdays_hours(marcacaoFerias);
    } else {
      setworkdays_hours([]);
    }
  }, [marcacaoFerias]);

  useEffect(() => {
    // if (Object.keys(workdays_hours).length > 0) {
    const supportedLanguages = ["es", "pt"];
    if (userSettings != undefined) {
      if (supportedLanguages.includes(selectedLocale)) {
        require(`moment/locale/${selectedLocale}.js`);
        moment.locale(selectedLocale);
        moment.updateLocale(selectedLocale, {
          week: {
            dow: 0,
          },
        });
        setLocalizer(momentLocalizer(moment));
      } else {
        moment.locale("en");
        setLocalizer(momentLocalizer(moment));
      }
      setSelectedLocale(userSettings.language);
      //if (userNamePortalRH != undefined) getDatesFromDays(dateSelected);
    }
    // }
  }, [userSettings.language, selectedLocale, workdays_hours]);

  useEffect(() => {
    if (Object.keys(marcacaoFeriasmsg).length > 0) {
      setShowModalWithDaysMarkedConfirm(true);
      setShowModalWithDaysMarked(false);
    } else {
      setShowModalWithDaysMarkedConfirm(false);
      setShowModalWithDaysMarked(false);
    }
  }, [marcacaoFeriasmsg]);

  useEffect(() => {
    if (marcacaoFeriasmsgConfirm != "") {
      setShowToastMSG(true);
      settoastText(marcacaoFeriasmsgConfirm);
      callUpdateMarcacaoFerias();
    } else {
      setShowToastMSG(false);
      settoastText("");
    }
  }, [marcacaoFeriasmsgConfirm]);

  useEffect(() => {
    setTimeout(() => {
      setShowToast(false);
      settoastText("");
    }, 5000);
  }, [showToast]);

  useEffect(() => {
    setTimeout(() => {
      setShowToastMSG(false);
      settoastText("");
      dispatch(setErrorPostMarcacaoFeriasConfirm(""));
    }, 5000);
  }, [showToastMSG]);

  localizer.formats.yearHeaderFormat = "YYYY";

  function convertToEvents(data, type, color) {
    return data.map((item) => {
      const date = moment(item.data);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      return {
        id: uuidv4(),
        title: item.obs,
        start: startLocal,
        end: endLocal,
        allDay: "true",
        type: type,
        color: color,
      };
    });
  }

  function convertToEventsFolgas(data, type, color) {
    return data.map((item) => {
      const date = moment(item);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      return {
        id: uuidv4(),
        title: t("portalrh.workDays.folgas"),
        start: startLocal,
        end: endLocal,
        type: type,
        color: color,
      };
    });
  }

  function convertToEventsDiasMarcados(data, type, color) {
    return data.map((item) => {
      const date = moment(item);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      return {
        id: uuidv4(),
        title: t("portalrh.workDays.folgas"),
        start: startLocal,
        end: endLocal,
        type: type,
        color: color,
      };
    });
  }

  function convertToEventsDiasMarcadosTeam(data, type) {
    return data.map((item) => {
      const date = moment(item.data);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      const color = item.userId === infoDataWorkHour ? "#44B89B" : item.color;

      return {
        id: uuidv4(),
        title: item.userId,
        start: startLocal,
        end: endLocal,
        type: type,
        color: color,
        state: item.aprovado,
      };
    });
  }

  function convertToEventsMarkedDays(data, type, color) {
    return data.map((item) => {
      const date = moment(item);
      date.set({ hour: 8, minute: 0, second: 0 });
      const startUtc = date.clone();
      const endUtc = date.clone().set({ hour: 20, minute: 0, second: 0 });

      const offset = new Date().getTimezoneOffset();
      const startLocal = new Date(
        startUtc.toDate().getTime() - offset * 60 * 1000
      );
      const endLocal = new Date(endUtc.toDate().getTime() - offset * 60 * 1000);
      return {
        id: uuidv4(),
        title: t("portalrh.workDays.selectedDay"),
        start: startLocal,
        end: endLocal,
        type: type,
        color: color,
        checked: true,
        checkedHalfDay: false,
        multi: true,
      };
    });
  }

  useEffect(() => {
    if (Object.keys(workdays_hours).length > 0) {
      const feriados = convertToEvents(
        workdays_hours?.feriados,
        "feriados",
        "#D6D6D6"
      );
      const folgas = convertToEventsFolgas(
        workdays_hours?.folgas,
        "folgas",
        "#D6D6D6"
      );
      const ferias = convertToEventsDiasMarcados(
        workdays_hours?.ferias,
        "ferias",
        "#4447A4"
      );
      const datasMarcacoes = convertToEventsDiasMarcados(
        workdays_hours?.datasMarcacoes,
        "datasMarcacoes",
        "#4447A4"
      );
      const feriasPendentes = convertToEventsDiasMarcados(
        workdays_hours?.feriasPendentes,
        "feriasPendentes",
        "#CEBA3B"
      );
      setEvents([
        ...feriados,
        ...folgas,
        ...ferias,
        ...datasMarcacoes,
        ...feriasPendentes,
      ]);
    } else {
      setEvents([]);
    }
  }, [workdays_hours]);

  const onDrillDown = (slotInfo) => {
    if (isEditable) {
      if (slotInfo.getMonth() !== dateSelected.getMonth()) {
        return;
      }

      const isEventAlreadyExists = events.some(
        (event) =>
          event.type !== "feriasmarked" &&
          moment(event.start).isSame(slotInfo, "day")
      );
      if (isEventAlreadyExists) {
        return;
      }
      setSelectedSlot(slotInfo);
      //setShowModalCheckDays(true);
      confirmCheckDay(slotInfo);
    }
  };

  const handleModalCloseCheckDays = () => {
    setShowModalCheckDays(false);
    setSelectedSlot(null);
  };

  const handleModalCloseCriteria = () => {
    setShowModalCriteria(false);
    setCriteriaGroup("");
    setEndAt("");
    setStartAt("");
  };

  const handleEventAddition = (eventType) => {
    if (eventType === "normal") {
      confirmCheckDay(selectedSlot);
    } else if (eventType === "custom") {
      confirmCheckDay(selectedSlot);
    }
    {
      return;
    }

    setShowModalCheckDays(false);
    setSelectedSlot(null);
  };
  const confirmCheckDay = (slotInfo) => {
    const daysMarked = convertToEventsMarkedDays(
      [slotInfo],
      "feriasmarked",
      "#44A49B"
    );

    const isEventAlreadyAdded = events.some(
      (event) =>
        event.type === "feriasmarked" &&
        moment(event.start).isSame(daysMarked[0].start, "day")
    );

    if (isEventAlreadyAdded) {
      setEvents((prevEvents) =>
        prevEvents.filter(
          (event) =>
            !(
              event.type === "feriasmarked" &&
              moment(event.start).isSame(daysMarked[0].start, "day")
            )
        )
      );
      setRemoveAll(true);
    } else {
      setEvents((prevEvents) => [...prevEvents, ...daysMarked]);
    }
  };

  const getDatesFromDays = (date) => {
    const currentDate = date;
    setDateSelected(currentDate);
    setstarEndtMonth({
      start: moment(date).startOf("month").startOf("week"),
      end: moment(date).endOf("month").endOf("week"),
    });
    const { start, end } = getMonthRange(currentDate);
    rangeChange({ start, end });
    if (currentDate.getFullYear() !== loadNewDateSelected.getFullYear()) {
      setLoadNewDateSelected(currentDate);
    }
    handleRestartMarkup();
  };

  const rangeChange = useCallback(
    (range) => {
      setrangesState(range);
      return {};
    },
    [events]
  );

  const getMonthRange = (selectedDate) => {
    const startOfMonth = moment(selectedDate).startOf("month").toDate();
    const endOfMonth = moment(selectedDate).endOf("month").toDate();

    return { start: startOfMonth, end: endOfMonth };
  };

  const dayPropGetter = useCallback(
    (date) => {
      const hasDaysMarkedEvent = events.some(
        (event) =>
          event.type != "feriasmarked" &&
          event.type != "ferias" &&
          moment(event.start).isSame(date, "day")
      );
      const hasDiasMarcadosEvent = events.some(
        (event) =>
          event.type != "ferias" &&
          event.type != "feriasmarked" &&
          moment(event.start).isSame(date, "day")
      );
      if (dataSelectedRequest != undefined) {
        const isDateSelected = dataSelectedRequest.some((selectedDate) =>
          moment(selectedDate.data).isSame(date, "day")
        );

        if (isDateSelected) {
          return {
            className: "daytoConfirm",
            // // style: {
            // //   backgroundColor: "darkslategray",
            // // },
          };
        }
      }
      // if (dataSelectedRequest && moment(dataSelectedRequest).isSame(date, "day")) {
      //   return {
      //     style: {
      //       backgroundColor: "rgba(10, 100, 94, 0.298)"
      //     },
      //   };
      // }

      if (hasDaysMarkedEvent) {
        return {
          style: {
            backgroundColor: "#D6D6D6",
            color: "#666",
            pointerEvents: "none",
          },
        };
      }
      if (hasDiasMarcadosEvent) {
        return {
          style: {
            backgroundColor: "#F7F7F7",
            color: "#666",
            pointerEvents: "none",
          },
        };
      }

      return {};
    },
    [events]
  );

  const handleGroupChange = (event) => {
    setSelectAll(true);
    if (event.target.value) {
      setEvents([]);
      setForceUpdate((prevKey) => prevKey + 1);
      setworkdays_hours([]);
    }

    setSelectedGroup(event.target.value);
    if (event.target.value === "") {
      const allUsers = gruposTrabalhoRemoto.flatMap((group) => group.userList);
      setDisplayedUsers(allUsers);
      const usersInGroup = allUsers.map((user) => user.numFuncionario);
      setSelectedUsers(usersInGroup);
    } else {
      const selectedGroup = gruposTrabalhoRemoto.find(
        (group) => group.grupo.id === event.target.value
      );
      setDisplayedUsers(selectedGroup ? selectedGroup.userList : []);
      const usersInGroup = selectedGroup.userList.map((user) => user.numFuncionario);
      setSelectedUsers(usersInGroup);
    }

    setSelectedTab(0);
    //handleUserChange("selectAll", false)
  };
  const handleUserChange = (userId, vaaa) => {
    if (userId === "selectAll") {
      const allUserIds = displayedUsersList.map((user) => user.numFuncionario);
      setSelectedUsers(selectAll ? [] : allUserIds);
      setSelectAll(!selectAll);
    } else {
      const updatedSelectedUsers = selectedUsers.includes(userId)
        ? selectedUsers.filter((id) => id !== userId)
        : [...selectedUsers, userId];

      setSelectedUsers(updatedSelectedUsers);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    if (!isEditable) {
      if (selectedUsers.length > 0) {
        filterWorkdaysByUsers();
      } else {
        setEvents([]);
      }
    }
  }, [selectedUsers, workdays_hours]);

  useEffect(() => {
    if (selectedGroup != "") {
      const selectedGroupInfo =
        Object.keys(gruposTrabalhoRemoto).length > 0 &&
        gruposTrabalhoRemoto.find((group) => group.grupo.id === selectedGroup);

      if (selectedGroupInfo) {
        const lepNums = selectedGroupInfo.userList.map((user) => user.numFuncionario);
        const payload = {
          lepNum: lepNums,
          ano: loadNewDateSelected.getFullYear(),
        };
        dispatch(getAllMarcacaoFerias(payload));
      }
    } else {
      const lepNums = displayedUsers.map((user) => user.numFuncionario);
      const payload = {
        lepNum: lepNums,
        ano: loadNewDateSelected.getFullYear(),
      };
      dispatch(getAllMarcacaoFerias(payload));
    }
  }, [selectedGroup, displayedUsers]);

  const filterWorkdaysByUsers = () => {
    if (workdays_hours?.diasmarcadosTeam) {
      const filteredDiasMarcadosTeam = workdays_hours.diasmarcadosTeam.filter(
        (diasMarcados) => selectedUsers.includes(diasMarcados.userId)
      );

      // Atualize o estado selectedUsersColor com as cores associadas a cada usuário
      const updatedSelectedUsersColor = { ...selectedUsersColor };

      filteredDiasMarcadosTeam.forEach((diasMarcados) => {
        const { userId, color } = diasMarcados;
        updatedSelectedUsersColor[userId] = color;
      });

      setSelectedUsersColor(updatedSelectedUsersColor);
      const diasMarcadosTeamEvents = convertToEventsDiasMarcadosTeam(
        filteredDiasMarcadosTeam,
        "ferias"
      );
      setEvents([...diasMarcadosTeamEvents]);
    }
  };

  const handleSearchChange = (event) => {
    setSearchFilter(event.target.value);
    setSelectedTab(0);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const allUsers =
    (Object.keys(gruposTrabalhoRemoto).length > 0 &&
      gruposTrabalhoRemoto.find((group) => group.grupo.id === selectedGroup)
        ?.userList) ||
    displayedUsers;

  const filteredUsers = allUsers.filter((user) =>
    user.nomeCurto.toLowerCase().includes(searchFilter.toLowerCase())
  );

  const displayedUsersList =
    selectedTab === 0
      ? filteredUsers
      : selectedGroup === ""
      ? allUsers
      : allUsers.filter((user) => selectedUsers.includes(user.numFuncionario));

  const listUsersByGroupConstructor = () => {
    return (
      <div>
        <FormControl>
          <Select
            value={selectedGroup}
            label={t("portalrh.workDays.groups")}
            onChange={handleGroupChange}
            displayEmpty
            inputProps={{ "aria-label": "Select group" }}
          >
            <MenuItem value="">{t("portalrh.workDays.all")}</MenuItem>
            {gruposTrabalhoRemoto.map((group) => (
              <MenuItem key={group.grupo.id} value={group.grupo.id}>
                {group.grupo.designacao}
              </MenuItem>
            ))}
          </Select>

          <>
            {selectedGroup !== "" && (
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#33058d",
                    color: "#33058d",
                    borderRadius: "0px 0px 8px 0px",
                  },
                }}
              >
                <Tab label={t("portalrh.workDays.all")} />
                <Tab label={t("portalrh.workDays.selected")} />
              </Tabs>
            )}

            {selectedTab == 0 && (
              <>
                <TextField
                  label={t("portalrh.workDays.searchCol")}
                  variant="standard"
                  value={searchFilter}
                  onChange={handleSearchChange}
                  size="small"
                  style={{ margin: "10px 0px" }}
                />
                {searchFilter === "" && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectAll}
                        onChange={() => handleUserChange("selectAll")}
                      />
                    }
                    label={t("portalrh.workDays.selectAll")}
                  />
                )}
              </>
            )}
          </>
        </FormControl>
        <div
          style={{
            overflowY: "auto",
            maxHeight: hideChangeView ? "480px" : "500px",
            margin: selectedTab == 0 ? "0px 10px 10px" : "10px 10px 10px",
            height: hideChangeView ? "480px" : "500px",
          }}
          className="scrollBar-visible-year"
        >
          <FormGroup>
            {displayedUsersList.map((user) => (
              <FormControlLabel
                key={uuidv4()}
                control={
                  <Checkbox
                    checked={selectedUsers.includes(user.numFuncionario)}
                    onChange={() => handleUserChange(user.numFuncionario)}
                    style={{
                      color: selectedUsers.includes(user.numFuncionario)
                        ? selectedUsersColor[user.numFuncionario]
                        : "inherit",
                    }}
                  />
                }
                label={user.nomeCurto}
              />
            ))}
          </FormGroup>
        </div>
      </div>
    );
  };

  const schedulerContrutor = () => {
    return (
      <div key={forceUpdate} style={{ width: "100%", margin: !isEditable && "0px 0px 0px 25px" }}>
        <>
          {isEditable ? (
            <Calendar
              localizer={localizer}
              events={events}
              date={dateSelected}
              toolbar={true}
              defaultView={defaultView}
              setEvents={setEvents}
              convertToEventsMarkedDays={convertToEventsMarkedDays}
              settoastText={settoastText}
              setShowToast={setShowToast}
              isEditable={isEditable}
              t={t}
              views={{
                day: false,
                week: false,
                month: false,
                year: Year,
              }}
              max={moment(new Date().setHours(20, 0, 0, 0)).toDate()}
              min={moment(new Date().setHours(8, 0, 0, 0)).toDate()}
              startAccessor="start"
              endAccessor="end"
              selectable={true}
              popup
              onNavigate={getDatesFromDays}
              onView={(e) => setDefaultView(e)}
              dayPropGetter={dayPropGetter}
              onDrillDown={onDrillDown}
              singleDaySelection={singleDaySelection}
              setRemoveAll={setRemoveAll}
              onRangeChange={rangeChange}
              formats={{ eventTimeRangeFormat: () => null }}
              messages={{ year: "Year" }}
              tooltipAccessor={constructTooltip}
              eventPropGetter={(myEventsList) => {
                const border = myEventsList.color
                  ? "2px solid " + myEventsList.color
                  : "blue";
                const opacity = 0.3;
                const backgroundColor = myEventsList.color
                  ? `${myEventsList.color}${Math.floor(opacity * 255).toString(
                      16
                    )}`
                  : "blue";

                if (myEventsList.type === "ferias") {
                  return {
                    className: myEventsList.type === "ferias" ? "ferias" : "",
                    style: {
                      border,
                      cursor: "default",
                      color: myEventsList.color || "blue",
                      backgroundColor,
                    },
                  };
                }
                if (myEventsList.type === "feriasmarked") {
                  return {
                    className:
                      myEventsList.type === "feriasmarked"
                        ? "feriasmarked"
                        : "",
                    style: {
                      border,
                      cursor: "default",
                      color: myEventsList.color || "blue",
                      backgroundColor,
                    },
                  };
                }
                return null;
              }}
              components={{
                event: Event,
                month: {
                  dateHeader: CustomDateHeader,
                },
                toolbar: CustomToolbar,
              }}
            />
          ) : (
            <Calendar
              localizer={localizer}
              events={events}
              date={dateSelected}
              toolbar={true}
              defaultView={defaultView}
              setEvents={setEvents}
              convertToEventsMarkedDays={convertToEventsMarkedDays}
              settoastText={settoastText}
              setShowToast={setShowToast}
              isEditable={isEditable}
              t={t}
              views={{
                day: false,
                week: false,
                month: true,
                year: Year,
              }}
              max={moment(new Date().setHours(20, 0, 0, 0)).toDate()}
              min={moment(new Date().setHours(8, 0, 0, 0)).toDate()}
              startAccessor="start"
              endAccessor="end"
              selectable={false}
              popup={true}
              //showAllEvents={true}
              onNavigate={getDatesFromDays}
              onView={(e) => setDefaultView(e)}
              dayPropGetter={dayPropGetter}
              onDrillDown={onDrillDown}
              tooltipAccessor={constructTooltip}
              formats={{ eventTimeRangeFormat: () => null }}
              messages={{ year: "Year" }}
              eventPropGetter={(myEventsList) => {
                const border = myEventsList.color
                  ? "2px solid " + myEventsList.color
                  : "blue";
                const opacity = 0.3;
                const backgroundColor = myEventsList.color
                  ? `${myEventsList.color}${Math.floor(opacity * 255).toString(
                      16
                    )}`
                  : "blue";
                if (myEventsList.type === "ferias") {
                  const eventos = eventosDoDia(myEventsList.start);
                  if (eventos.length > 2) {
                    return {
                      className: myEventsList.type === "ferias" ? "ferias" : "",
                      style: {
                        border: "0px",
                        cursor: "default",
                        backgroundColor: "transparent",
                      },
                    };
                  }
                  return {
                    className: myEventsList.type === "ferias" ? "ferias" : "",
                    style: {
                      border,
                      cursor: "default",
                      color: myEventsList.color || "blue",
                      backgroundColor,
                    },
                  };
                }
                if (myEventsList.type === "feriasmarked") {
                  return {
                    className:
                      myEventsList.type === "feriasmarked"
                        ? "feriasmarked"
                        : "",
                    style: {
                      border,
                      cursor: "default",
                      color: myEventsList.color || "blue",
                      backgroundColor,
                    },
                  };
                }
                return null;
              }}
              components={{
                event: Event,
                month: {
                  dateHeader: CustomDateHeader,
                },
                toolbar: CustomToolbar,
              }}
            />
          )}
          <Popover
            open={open}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={anchorEl}
          >
            {Array.isArray(popupEvent) &&
              popupEvent.map((event) => (
                <div
                  key={event.id}
                  style={{ padding: "10px", cursor: "default" }}
                >
                  <CreatePopUpEntry event={event} />
                </div>
              ))}
            <Stack
              direction="row"
              style={{ placeContent: "center" }}
              alignItems="center"
              spacing={2}
              margin="5px"
            >
              <ButtonBoot variant="danger" onClick={handleClose}>
                {t("portalrh.workDays.close")}
              </ButtonBoot>
            </Stack>
          </Popover>

          {/* {dataSelectedRequest === undefined && (
            <div
              id="legenda"
              style={{ padding: "0px", textAlignLast: "start" }}
            >
              {isEditable && (
                <Button
                  size="small"
                  variant="text"
                  style={{
                    pointerEvents: "none",
                    padding: "0px 10px 0px 0px",
                    color: "#4C4B4B",
                  }}
                >
                  <FaCircle
                    color="rgb(171, 171, 171)"
                    style={{ margin: "0px 5px" }}
                  />
                  {t("portalrh.workDays.legend")}
                </Button>
              )}
              <Button
                size="small"
                variant="text"
                style={{
                  pointerEvents: "none",
                  padding: "0px 10px 0px 0px",
                  color: "#4C4B4B",
                }}
              >
                <FaClock color="darkgoldenrod" style={{ margin: "0px 5px" }} />
                {t("portalrh.workDays.Pendente")}
              </Button>
              <Button
                size="small"
                variant="text"
                style={{
                  pointerEvents: "none",
                  padding: "0px 10px 0px 0px",
                  color: "#4C4B4B",
                }}
              >
                <FaCheckCircle color="green" style={{ margin: "0px 5px" }} />
                {t("portalrh.workDays.Aprovado")}
              </Button>
              <Button
                size="small"
                variant="text"
                style={{
                  pointerEvents: "none",
                  padding: "0px 10px 0px 0px",
                  color: "#4C4B4B",
                }}
              >
                <FaTimesCircle color="red" style={{ margin: "0px 5px" }} />
                {t("portalrh.workDays.refused")}
              </Button>
            </div>
          )} */}
        </>
      </div>
    );
  };

  const CreatePopUpEntry = ({ event }) => {
    const border = event.color ? `2px solid ${event.color}` : "2px solid blue";
    const opacity = 0.3;
    const backgroundColor = event.color
      ? `${event.color}${Math.floor(opacity * 255).toString(16)}`
      : "blue";

    const style = {
      color: event.color,
      background: backgroundColor,
      border: border,
    };

    return (
      <p className="popupP" style={style}>
        {createEventImageDependingState(event.state)}
        {event.title}
      </p>
    );
  };

  const constructTooltip = (event) => {
    return (
      event.title + " - " + createEventDescriptionDependingState(event.state)
    );
  };

  function CustomDateHeader({ label, drilldownView, onDrillDown, isOffRange }) {
    if (isEditable) {
      const filteredEvents = events.filter(
        (event) => event.start.getMonth() === dateSelected.getMonth()
      );
      const padNumber = (number) => (number < 10 ? `0${number}` : number);

      const hasEvents = filteredEvents.some((event) => {
        const eventDayString = padNumber(event.start.getDate().toString());

        return event.type !== "feriasmarked" && eventDayString === label;
      });

      const hasEventsMark = filteredEvents.some((event) => {
        const eventDayString = padNumber(event.start.getDate().toString());
        return event.type === "feriasmarked" && eventDayString === label;
      });

      return (
        <div onClick={onDrillDown} className="custom-date-header">
          <div className="dh-item header-left">
            {!isOffRange && !hasEvents ? (
              hasEventsMark ? (
                <MdOutlineRemoveCircleOutline color="#b1b1b1" />
              ) : (
                <GoPlusCircle color="#b1b1b1" />
              )
            ) : null}
          </div>
          <div className="dh-item header-right">
            <a>{label}</a>
          </div>
        </div>
      );
    } else {
      return (
        <div onClick={onDrillDown} className={`custom-date-header`}>
          <div className="dh-item header-right">
            <a>{label}</a>
          </div>
        </div>
      );
    }
  }

  const handleClick = (event, selectedEvent) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const top = rect.top - rect.height;

    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";

    setAnchorEl({ top: top, left: rect.left });
    setPopupEvent(selectedEvent);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopupEvent(null);

    document.body.style.overflow = "";
    document.body.style.position = "";
  };

  function Event({ event }) {
    if (event.type == "feriasmarked") {
      return (
        <div>
          <TbBeach
            style={{
              fontSize: "small",
              alignSelf: "center",
              marginRight: "5px",
              color: event.color,
            }}
          />
          <span
            className="eventSpan"
            style={{ display: "inline-flex", marginTop: "2px" }}
          >
            {event.title}
          </span>
        </div>
      );
    }
    if (event.type == "intervals") {
      const eventos = eventosDoDia(event.start);
      if (eventos.length > 2) {
        return (
          <div
            onClick={(e) => handleClick(e, eventos)}
            style={{ textAlign: "center" }}
            title={`${eventos.length} ${t("portalrh.workDays.eventos")}`}
          >
            <span
              className="eventSpan"
              style={{
                display: "inline-flex",
                marginTop: "2px",
                backgroundColor: "rgba(51,5,141, 0.4)",
                padding: "5px",
                width: "40px",
                borderRadius: "20px",
                fontSize: "small",
                border: "2px solid rgb(51,5,141)",
                color: "rgb(51,5,141)",
              }}
            >
              + {eventos.length}
            </span>
          </div>
        );
      }

      return (
        <div>
          {createEventImageDependingState(event.state)}
          <TbBeach
            style={{
              fontSize: "small",
              alignSelf: "center",
              marginRight: "5px",
              color: event.color,
            }}
          />
          <span
            className="eventSpan"
            style={{ display: "inline-flex", marginTop: "2px" }}
          >
            {event.title}
          </span>
        </div>
      );
    }
    // if (event.type == "ferias") {
    //   return (
    //     <div>
    //       {createEventImageDependingState(event.state)}
    //       <span
    //         className="eventSpan"
    //         style={{ display: "inline-flex", marginTop: "2px" }}
    //       >
    //         {event.title}
    //       </span>
    //     </div>
    //   );
    // }

    return null;
  }

  const eventosDoDia = (date) =>
    events.filter(
      (evento) =>
        evento.start.getDate() === date.getDate() &&
        evento.start.getMonth() === date.getMonth() &&
        evento.start.getFullYear() === date.getFullYear()
    );
  const createEventImageDependingState = (state) => {
    if (state === 0) {
      return (
        <FaClock
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "darkgoldenrod",
          }}
        />
      );
    }
    if (state === 1) {
      return (
        <FaClock
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "darkgoldenrod",
          }}
        />
      );
    }
    if (state === 2) {
      return (
        <FaMinusCircle
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "red",
          }}
        />
      );
    }
    if (state === 3) {
      return (
        <FaCheckCircle
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "green",
          }}
        />
      );
    }
    if (state === 4) {
      return (
        <FaTimesCircle
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "red",
          }}
        />
      );
    }
    if (state === 5) {
      return (
        <FaEye
          style={{
            fontSize: "small",
            alignSelf: "center",
            marginRight: "5px",
            color: "green",
          }}
        />
      );
    }
  };

  const createEventDescriptionDependingState = (state) => {
    if (state === 1) {
      return t("portalrh.workDays.Pendente");
    }
    if (state === 2) {
      return t("portalrh.workDays.Anulado");
    }
    if (state === 3) {
      return t("portalrh.workDays.Aprovado");
    }
    if (state === 4) {
      return t("portalrh.workDays.Recusado");
    }
    if (state === 5) {
      return t("portalrh.workDays.Visto");
    }
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[400]),
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: grey[200],
    },
  }));

  const ColorButtonArrow = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[600],
    "&:hover": {
      backgroundColor: grey[500],
    },
  }));

  const handleSendDays = () => {
    const eventsDays = events.filter((event) => event.type === "feriasmarked");
    if (eventsDays.length <= 0) {
      return;
    }
    setShowModalWithDaysMarked(true);
  };

  const removeDayFromEvents = (day) => {
    const updatedEvents = events.filter((event) => {
      return !(event.type === day.type && event.id === day.id);
    });
    setEvents(updatedEvents);
  };

  const handleSaveWorkDays = () => {
    const eventsDays = events.filter((event) => event.type === "feriasmarked");

    let dates = [];
    eventsDays.forEach((event) => {
      let eventDates = [new Date(event.start).toISOString().split("T")[0]];
      if (event.intervals) {
        event.intervals.forEach((interval) => {
          eventDates.push(new Date(interval).toISOString().split("T")[0]);
        });
      }
      dates.push({ data: eventDates });
    });

    if (userNamePortalRH !== undefined) {
      const payload = {
        anoAnterior: false,
        dates: dates,
        userId: userNamePortalRH.id,
      };
      dispatch(postMarcacaoFerias(payload));
      handleRestartMarkup();
      setShowModalWithDaysMarked(false);
      //setShowModalWithDaysMarkedConfirm(false);
    }
  };

  const handleSaveWorkDaysConfirm = () => {
    const eventsDays = events.filter((event) => event.type === "feriasmarked");

    let dates = [];
    eventsDays.forEach((event) => {
      let eventDates = [new Date(event.start).toISOString().split("T")[0]];
      if (event.intervals) {
        event.intervals.forEach((interval) => {
          eventDates.push(new Date(interval).toISOString().split("T")[0]);
        });
      }
      dates.push({ dates: eventDates });
    });

    if (userNamePortalRH !== undefined) {
      const payload = {
        anoAnterior: false,
        dates: dates,
        userId: userNamePortalRH.id,
      };
      //dispatch(postMarcacaoFeriasConfirm(payload));
      setShowModalWithDaysMarkedConfirm(false);
      setShowModalWithDaysMarked(false);
    }
  };

  const handleCheckboxChange = (day, type) => {
    setEvents((prevEvents) => {
      const updatedEventsDays = prevEvents.map((eventDay) => {
        if (eventDay.id === day.id) {
          // Verifica se o estado atual é diferente do novo estado
          const newChecked = type === "diaInteiro" ? true : false;
          const newCheckedHalfDay = type === "diaInteiro" ? false : true;

          return {
            ...eventDay,
            checked: newChecked,
            checkedHalfDay: newCheckedHalfDay,
          };
        }

        return eventDay;
      });

      return updatedEventsDays;
    });
  };

  function ModalWithDaysMarked(props) {
    const eventsDays = events.filter((event) => event.type === "feriasmarked");

    if (eventsDays.length < 1) {
      setShowModalWithDaysMarked(false);
      setShowModalWithDaysMarkedConfirm(false);
      return null;
    }
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        key={uuid()}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{t("portalrh.workDays.listmarkedDays")}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup
            style={{
              overflowY: "auto",
              maxHeight: "350px",
              padding: "10px",
            }}
            className="scrollBar-visible-year"
          >
            {renderListModals(eventsDays, true)}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              className="card-button-red"
              onClick={props.onHide}
            >
              {t("portalrh.workDays.close")}
            </Button>
            <Button
              variant="contained"
              color="success"
              className="card-button-scheduler"
              onClick={() => handleSaveWorkDays()}
            >
              {t("portalrh.workDays.submitDay")}
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    );
  }

  function ModalWithCheckDays() {
    return (
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        key={uuid()}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{t("portalrh.workDays.listmarkedDays")}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              className="card-button-red"
              onClick={handleModalCloseCheckDays}
            >
              {t("portalrh.workDays.close")}
            </Button>
            <Button
              variant="contained"
              color="success"
              className="card-button-scheduler"
              onClick={() => handleSaveWorkDays()}
            >
              {t("portalrh.workDays.submitDay")}
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    );
  }

  function ModalWithCriteria(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        key={uuid()}
        expport
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>{t("portalrh.workDays.export.title")}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ margin: "10px 0px" }}>
            <Col lg="12" style={{ padding: "0px" }}>
              <InputLabel id="demo-controlled-open-select-label">
                {t("portalrh.workDays.export.groups")}
              </InputLabel>
              <Select
                value={criteriaGroup}
                label="Grupos"
                onChange={(e) => setCriteriaGroup(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Select group" }}
              >
                <MenuItem value="">{t("portalrh.workDays.all")}</MenuItem>
                {Object.keys(gruposTrabalhoRemoto).length > 0 &&
                  gruposTrabalhoRemoto.map((group) => (
                    <MenuItem key={group.grupo.id} value={group.grupo.id}>
                      {group.grupo.designacao}
                    </MenuItem>
                  ))}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <InputLabel id="demo-controlled-open-select-label">
                {t("portalrh.workDays.export.startDate")}
              </InputLabel>
              <DatePicker
                value={startAt}
                onChange={(e) => setStartAt(e)}
                format="dd/MM/yyyy"
              />
            </Col>
            <Col lg="6">
              <InputLabel id="demo-controlled-open-select-label">
                {t("portalrh.workDays.export.endDate")}
              </InputLabel>
              <DatePicker
                value={endAt}
                onChange={(e) => setEndAt(e)}
                format="dd/MM/yyyy"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              className="card-button-red"
              onClick={handleModalCloseCriteria}
            >
              {t("portalrh.workDays.close")}
            </Button>
            <Button
              variant="contained"
              color="success"
              className="card-button-scheduler"
              onClick={() => handleSendCriteriaData()}
            >
              {t("portalrh.workDays.export.btn")}
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    );
  }

  const renderListModals = (entry, add) =>
    entry.map((day) => (
      <ListGroup.Item
        key={day.date}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        {" "}
        {day?.intervals != undefined && day?.intervals.length > 0 ? null : (
          <div style={{ display: "flex", marginBottom: "5px" }}>
            <FormControlLabel
              key={uuidv4()}
              style={{ margin: "0px" }}
              label={t("portalrh.workDays.diaInteiro")}
              control={
                <Checkbox
                  style={{ margin: "0px" }}
                  checked={
                    add ? day.checked : day.diaInteiro === "0" ? true : false
                  }
                  disabled={add ? false : true}
                  onChange={() => handleCheckboxChange(day, "diaInteiro")}
                />
              }
            />
            <FormControlLabel
              key={uuidv4()}
              style={{ margin: "0px", marginLeft: "10px" }}
              label={t("portalrh.workDays.diasMarcadosMeiodia")}
              control={
                <Checkbox
                  style={{ margin: "0px" }}
                  checked={
                    add
                      ? day.checkedHalfDay
                      : day.diaInteiro === "1"
                      ? true
                      : false
                  }
                  disabled={add ? false : true}
                  onChange={() => handleCheckboxChange(day, "halfDay")}
                />
              }
            />
          </div>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <b
            style={{ cursor: "default", display: "flex", alignItems: "center" }}
          >
            <>
              {add
                ? moment(day.start).format(
                    `DD [${t("portalrh.workDays.de")}] MMMM [${t(
                      "portalrh.workDays.de"
                    )}] YYYY`
                  )
                : moment(day.date).format(
                    `DD [${t("portalrh.workDays.de")}] MMMM [${t(
                      "portalrh.workDays.de"
                    )}] YYYY`
                  )}
              {add && (
                <div
                  style={{
                    cursor: "pointer",
                    marginLeft: "auto",
                    color: "#AD3E3E",
                  }}
                  onClick={() => removeDayFromEvents(day)}
                >
                  <FaTrash />
                </div>
              )}
            </>
          </b>
          {day?.intervals !== undefined && day?.intervals.length > 0 && (
            <>
              {day.intervals.map((interval, index) => (
                <b>
                  {moment(interval).format(
                    `DD [${t("portalrh.workDays.de")}] MMMM [${t(
                      "portalrh.workDays.de"
                    )}] YYYY`
                  )}
                </b>
              ))}
            </>
          )}
        </div>
      </ListGroup.Item>
    ));

  function ModalWithDaysMarkedConfirm(props) {
    const eventsDays = events.filter((event) => event.type === "feriasmarked");

    if (eventsDays.length < 1) {
      setShowModalWithDaysMarked(false);
      setShowModalWithDaysMarkedConfirm(false);
      return null;
    }
    return (
      <>
        {Object.keys(marcacaoFeriasmsg).length > 0 && (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            key={uuid()}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h4>{t("portalrh.workDays.daysToConfirm")}</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {marcacaoFeriasmsg.semanal.length > 0 && (
                <>
                  <p style={{ margin: "0px" }}>
                    {t("portalrh.workDays.textoDiasSemanais.1")}{" "}
                    {marcacaoFeriasmsg.semanal.length}{" "}
                    {t("portalrh.workDays.textoDiasSemanais.2")}
                  </p>
                  <ListGroup
                    style={{
                      overflowY: "auto",
                      maxHeight: "225px",
                      padding: "10px",
                    }}
                    className="scrollBar-visible-year"
                  >
                    {renderListModals(marcacaoFeriasmsg.semanal)}
                  </ListGroup>
                </>
              )}

              {marcacaoFeriasmsg.exedente.length > 0 && (
                <>
                  <p style={{ margin: "0px" }}>
                    {t("portalrh.workDays.textoDiasExcedentes.1")}{" "}
                    {marcacaoFeriasmsg.exedente.length}{" "}
                    {t("portalrh.workDays.textoDiasExcedentes.2")}
                  </p>
                  <ListGroup
                    style={{
                      overflowY: "auto",
                      maxHeight: "225px",
                      padding: "10px",
                    }}
                    className="scrollBar-visible-year"
                  >
                    {renderListModals(marcacaoFeriasmsg.exedente)}
                  </ListGroup>
                </>
              )}

              {marcacaoFeriasmsg.bolsa.length > 0 && (
                <>
                  <p style={{ margin: "0px" }}>
                    {t("portalrh.workDays.textoBolsa.1")}{" "}
                    {marcacaoFeriasmsg.bolsa.length}{" "}
                    {t("portalrh.workDays.textoBolsa.2")}
                  </p>
                  <ListGroup
                    style={{
                      overflowY: "auto",
                      maxHeight: "330px",
                      padding: "10px",
                    }}
                    className="scrollBar-visible-year"
                  >
                    {renderListModals(marcacaoFeriasmsg.bolsa)}
                  </ListGroup>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Stack
                direction="row"
                spacing={2}
                style={{
                  width: "100%",
                  justifyContent: "right",
                  padding: "0px 16px",
                }}
              ></Stack>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  className="card-button-red"
                  onClick={props.onHide}
                >
                  {t("portalrh.workDays.close")}
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  className="card-button-scheduler"
                  onClick={() => handleSaveWorkDaysConfirm()}
                >
                  {t("portalrh.workDays.confirmDays")}
                </Button>
              </Stack>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }

  const toastResult = (text) => {
    return (
      <Toast>
        <Toast.Body className="bg-danger" style={{ color: "white" }}>
          {text}
        </Toast.Body>
      </Toast>
    );
  };

  const toastResultMSG = (text) => {
    if (text === "error")
      return (
        <Toast>
          <Toast.Body className="bg-danger" style={{ color: "white" }}>
            {t("portalrh.workDays.error")}
          </Toast.Body>
        </Toast>
      );
    if (text === "sucess")
      return (
        <Toast>
          <Toast.Body className="bg-success" style={{ color: "white" }}>
            {t("portalrh.workDays.success")}
          </Toast.Body>
        </Toast>
      );
  };

  const handleExport = (type) => {
    if (type === "criteria") {
      setShowModalCriteria(true);
    } else {
      let list;
      if (type === "screen") {
        const filteredDiasMarcados = marcacaoFerias.diasmarcadosTeam.filter(
          (item) => {
            // Verificar se o userId está presente em selectedUsers
            return (
              selectedUsers.includes(item.userId) &&
              new Date(item.data).getMonth() === dateSelected.getMonth()
            );
          }
        );
        list = filteredDiasMarcados;
      }
      if (type === "year") {
        const filteredDiasMarcados = marcacaoFerias.diasmarcadosTeam.filter(
          (item) => {
            // Verificar se o userId está presente em selectedUsers
            return (
              selectedUsers.includes(item.userId) &&
              new Date(item.data).getFullYear() === dateSelected.getFullYear()
            );
          }
        );
        list = filteredDiasMarcados;
      }
      const monthName = moment(dateSelected).format("MMMM");
      const yearDate = moment(dateSelected).format("YYYY");
      const payload = {
        type,
        list,
        monthName,
        yearDate,
      };
      sendExportData(payload);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSendCriteriaData = () => {
    if (Object.keys(gruposTrabalhoRemoto).length > 0) {
      let ids;
      if (criteriaGroup === "") {
        const allUsersCriteria = gruposTrabalhoRemoto.flatMap(
          (group) => group.userList
        );
        ids = allUsersCriteria.map((user) => user.id);
      } else {
        const selectedGroupCriteria = gruposTrabalhoRemoto.find(
          (group) => group.grupo.id === criteriaGroup
        );
        ids = selectedGroupCriteria.userList.map((user) => user.id);
      }
      if (startAt === "" || endAt === "") {
        setOpenExportError(true);
        return;
      }
      const payload = {
        type: "criteria",
        dataInicio: formatDate(new Date(startAt)),
        dataFim: formatDate(new Date(endAt)),
        ids,
      };
      sendExportData(payload);
    }
  };

  const sendExportData = (payload) => {
    //dispatch(getExportVals(payload));
    handleModalCloseCriteria();
  };

  const handleSingleDaySelection = (event, newAlignment) => {
    setSingleDaySelection(newAlignment);
  };

  const handleRestartMarkup = () => {
    const filteredEvents = events.filter(
      (event) =>
        event.type !== "feriasmarked" &&
        event.type !== "hover" &&
        event.type !== "intervals"
    );
    setEvents(filteredEvents);
    setRemoveAll(false);
  };

  class CustomToolbar extends React.Component {
    render() {
      let {
        localizer: { messages },
        label,
      } = this.props;
      return (
        <>
          <div className="rbc-toolbar">
            <Row style={{ width: "100%", marginBottom: "20px" }}>
              {isEditable && (
                <Col sm="12" md="4" lg="4" style={{ padding: "0px" }}>
                  <Button
                    variant="contained"
                    color="success"
                    className="card-button-scheduler"
                    onClick={handleSendDays}
                    style={{ color: "white" }}
                  >
                    <span style={{ display: "inline-flex", gap: "0.2rem" }}>
                      <FaCalendarPlus />
                      {t("portalrh.workDays.newMark")}
                    </span>
                  </Button>
                </Col>
              )}
              <Col sm="12" md="2" lg="2" style={{ padding: "0px" }}>
                {showToast && toastResult(toastText)}
                {showToastMSG && toastResultMSG(toastText)}
              </Col>
            </Row>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
              {!isEditable && exportBtn && (
                <Col sm="12" md={"6"} lg={"6"} style={{ padding: "0px" }}>
                  <DropDownBoot>
                    <DropDownBoot.Toggle
                      id="dropdown-basic"
                      style={{
                        backgroundColor: "#33058d",
                        borderColor: "#33058d",
                        color: "white",
                      }}
                    >
                      <FaFileExport style={{ paddingRight: "5px" }} />
                      {t("portalrh.workDays.export.btn")}
                    </DropDownBoot.Toggle>

                    <DropDownBoot.Menu>
                      <DropDownBoot.Item onClick={() => handleExport("screen")}>
                        {t("portalrh.workDays.export.item1")}
                      </DropDownBoot.Item>
                      <DropDownBoot.Item onClick={() => handleExport("year")}>
                        {t("portalrh.workDays.export.item2")}
                      </DropDownBoot.Item>
                      <DropDownBoot.Item
                        onClick={() => handleExport("criteria")}
                      >
                        {t("portalrh.workDays.export.item3")}
                      </DropDownBoot.Item>
                    </DropDownBoot.Menu>
                  </DropDownBoot>
                </Col>
              )}
              <Col
                sm="12"
                md={exportBtn ? "6" : !hideChangeView ? "5" : "12"}
                lg={exportBtn ? "6" : !hideChangeView ? "5" : "12"}
                style={{ padding: "0px" }}
                className={hideChangeView ? "text-end" : ""}
              >
                <Stack
                  spacing={1}
                  direction="row"
                  style={{
                    alignItems: "center",
                    display: window.innerWidth <= 1150 ? "inline-block" : "",
                    justifyContent: !isEditable ? "flex-end" : "",
                  }}
                >
                  <ColorButtonArrow
                    size="small"
                    variant="text"
                    startIcon={<FaAngleDoubleLeft />}
                    onClick={this.navigate.bind(null, navigate.PREVIOUS)}
                  />
                  <span
                    className="rbc-toolbar-label"
                    style={{ maxWidth: "15%" }}
                  >
                    {label}
                  </span>
                  <ColorButtonArrow
                    size="small"
                    variant="text"
                    startIcon={<FaAngleDoubleRight />}
                    onClick={this.navigate.bind(null, navigate.NEXT)}
                  />
                  {removeall && (
                    <InputLabel onClick={handleRestartMarkup} id="removeMarkup">
                      Recomeçar marcação
                    </InputLabel>
                  )}
                </Stack>
              </Col>
              {!hideChangeView && (
                <Col
                  className="text-end"
                  style={{
                    padding: "0px",
                    display: "inline-flex",
                    justifyContent: "right",
                    gap: "1rem",
                    alignItems: "baseline",
                  }}
                >
                  <InputLabel id="typeMarkup">Tipo de Marcação</InputLabel>
                  <ToggleButtonGroup
                    value={singleDaySelection}
                    exclusive
                    onChange={handleSingleDaySelection}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="single" aria-label="Dia">
                      <CgCalendarToday /> Dia
                    </ToggleButton>
                    <ToggleButton value="interval" aria-label="Intervalo">
                      <HiMiniCalendarDays /> Intervalo
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Col>
              )}
            </Row>
            <Row style={{ width: "100%", marginBottom: "20px" }}>
              {Object.keys(marcacaoFeriasCalendario).length > 0 &&
                marcacaoFeriasCalendario?.situacao.length > 0 && (
                  <Col sm="12" md="12" lg="12" style={{ padding: "0px" }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panelfirst"
                        id="panelfirst"
                      >
                        Situação
                      </AccordionSummary>
                      <AccordionDetails className="scrollBar-autorizersDateInformation">
                        {!isLoadingMarcacaoFerias &&
                          marcacaoFeriasCalendario?.situacao.length > 0 &&
                          marcacaoFeriasCalendario?.situacao.map(
                            (item, index) => (
                              <Alert style={{ margin: "0px", display: "grid" }}>
                                <AlertTitle>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span>
                                      Cód. Entidade - {item.codigoEntidade}
                                    </span>
                                    <span>Nome - {item.nome}</span>
                                  </div>
                                </AlertTitle>{" "}
                                <span style={{ fontSize: "small" }}>
                                  Dias de Férias - {item.diasFerias}
                                </span>
                                <span style={{ fontSize: "small" }}>
                                  {" "}
                                  Dias por Gozar Ano Anterior -{" "}
                                  {item.diasPorGozarAnoAnt}{" "}
                                </span>
                                <span style={{ fontSize: "small" }}>
                                  Entidade - {item.entidade}
                                </span>
                              </Alert>
                            )
                          )}
                      </AccordionDetails>
                    </Accordion>
                  </Col>
                )}
            </Row>
            {/* {isEditable && Object.keys(trabalhoRemotoInfoSaldo).length > 0 && (
              <Row style={{ width: "100%", marginBottom: "20px" }}>
                <Col sm="12" md="12" lg="12" style={{ padding: "0px" }}>
                  <span>
                    {" "}
                    {t("portalrh.workDays.textoNrDiasBolsa.1")}{" "}
                    {trabalhoRemotoInfoSaldo.situacao}{" "}
                    {t("portalrh.workDays.textoNrDiasBolsa.2")}{" "}
                    {trabalhoRemotoInfoSaldo.bolsa}{" "}
                    {t("portalrh.workDays.textoNrDiasBolsa.3")}
                  </span>
                </Col>
              </Row>
            )} */}
          </div>
        </>
      );
    }
    navigate = (action) => {
      this.props.onNavigate(action);
    };
    changeView = (view) => {
      this.props.onView(view);
    };
  }
  const handleModalClose = () => {
    // Desmarcar todos os dias marcados
    const updatedEventsDays = events.map((eventDay) => ({
      ...eventDay,
      checked: true,
      checkedHalfDay: false,
    }));

    setEvents(updatedEventsDays);
    setShowModalWithDaysMarked(false);
    setShowModalWithDaysMarkedConfirm(false);
  };

  const handleCloseExport = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setExportVals(""));
    setOpenExport(false);
    setmessageTranslated("");
  };

  const handleCloseExportError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenExportError(false);
  };

  return (
    <>
      <Spinner spinning={isLoadingExport} wrapper />
      <Snackbar
        open={openExport}
        autoHideDuration={5000}
        onClose={handleCloseExport}
      >
        <AlertMaterial
          onClose={handleCloseExport}
          severity="success"
          sx={{ width: "100%" }}
        >
          {messageTranslated}
        </AlertMaterial>
      </Snackbar>
      <Snackbar
        open={openExportError}
        autoHideDuration={5000}
        onClose={handleCloseExportError}
      >
        <AlertMaterial
          onClose={handleCloseExportError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {t("portalrh.workDays.export.dateToExport")}
        </AlertMaterial>
      </Snackbar>
      <Sidebar menuSideBar={menuSideBar} path={path} />
      {isLoadingMarcacaoFerias ? (
        <Spinner spinning={isLoadingMarcacaoFerias} wrapper />
      ) : (
        <>
          <div
            style={{ padding: !isEditable ? "" : !hideChangeView ? "" : "10px", display: "flex" }}
          >
            {!isEditable &&
              Object.keys(gruposTrabalhoRemoto).length > 0 &&
              listUsersByGroupConstructor()}
            <>{selectedLocale && schedulerContrutor()}</>
          </div>
        </>
      )}
      <ModalWithDaysMarked
        show={showModalWithDaysMarked}
        onHide={handleModalClose}
      />
      <ModalWithDaysMarkedConfirm
        show={showModalWithDaysMarkedConfirm}
        onHide={handleModalClose}
      />
      <ModalWithCheckDays
        show={showModalCheckDays}
        onHide={handleModalCloseCheckDays}
      />
      <ModalWithCriteria
        show={showModalCriteria}
        onHide={handleModalCloseCriteria}
      />
    </>
  );
};

export default withNamespaces()(SchedulerComponent);
