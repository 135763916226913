import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
import RhTableList from "./RhTableList";
import { withNamespaces } from "react-i18next";
import {
  getMedicalRecord,
  updateMedicalRecord,
  createMedicalRecord,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";
import { Col, Form, Row } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import ButtonsUpdate from "./ButtonsUpdate";
import ButtonsCreate from "./ButtonsCreate";

const RhMedicalRecord = ({ rowCollaborator, t }) => {
  const [examDate, setExamDate] = useState("");
  const [oldExamDate, setOldExamDate] = useState("");
  const [nextExamDate, setNextExamDate] = useState("");
  const [rowClicked, setRowClicked] = useState(false);
  const [checkAdmission, setCheckAdmission] = useState(false);
  const [checkPeriodical, setCheckPeriodical] = useState(false);
  const [checkOccasional, setCheckOccasional] = useState(false);
  const [checkOther, setCheckOther] = useState(false);
  const [checkAble, setCheckAble] = useState(false);
  const [checkConditionalAble, setCheckConditionalAble] = useState(false);
  const [checkTemporarilyUnable, setCheckTemporarilyUnable] = useState(false);
  const [checkDefinitelyUnable, setCheckDefinitelyUnable] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [medicalRecordPage, setMedicalRecordPage] = useState(false);
  const [newButtonClicked, setNewButtonClicked] = useState(false);
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
  // const [lepNum, setLepNum] = useState("");
  const [empCode, setEmpCode] = useState("");
  const [validated, setValidated] = useState(false);
  const [isClickedBtnUpdate, setIsClickedBtnUpdate] = useState(false);

  const dispatch = useDispatch();

  const { medicalData, isLoadingUpdateMedicalRecord } = useSelector(
    (state) => state.personalReducer
  );
  useEffect(() => {
    if (Object.keys(rowCollaborator).length > 0) {
      dispatch(
        getMedicalRecord(
          rowCollaborator.funcionarioNum,
          rowCollaborator.codEntidade
        )
      );
      setMedicalRecordPage(true);
    }
  }, [rowCollaborator, isLoadingUpdateMedicalRecord]);

  const handleExamDate = (e) => {
    setExamDate(e);
  };

  const handleNextExamDate = (e) => {
    setNextExamDate(e);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      updateData(false);
      setIsClickedBtnUpdate(!isClickedBtnUpdate);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
    }
  };

  const handleChecExamType = (e, condition) => {
    switch (condition) {
      case "admission":
        setCheckAdmission(e.target.value);
        setCheckPeriodical("0");
        setCheckOccasional("0");
        setCheckOther("0");
        break;
      case "periodical":
        setCheckPeriodical(e.target.value);
        setCheckAdmission("0");
        setCheckOccasional("0");
        setCheckOther("0");
        break;
      case "occasional":
        setCheckOccasional(e.target.value);
        setCheckPeriodical("0");
        setCheckAdmission("0");
        setCheckOther("0");
        break;
      case "other":
        setCheckOther(e.target.value);
        setCheckOccasional("0");
        setCheckPeriodical("0");
        setCheckAdmission("0");
        break;
      default:
        setCheckOccasional("0");
        setCheckPeriodical("0");
        setCheckAdmission("0");
        setCheckOther("0");
    }
  };

  const handleChecExamResult = (e, condition) => {
    switch (condition) {
      case "Able":
        setCheckAble(e.target.value);
        setCheckConditionalAble("0");
        setCheckTemporarilyUnable("0");
        setCheckDefinitelyUnable("0");
        break;
      case "CoditionalAble":
        setCheckConditionalAble(e.target.value);
        setCheckAble("0");
        setCheckTemporarilyUnable("0");
        setCheckDefinitelyUnable("0");
        break;
      case "TemporarilyUnable":
        setCheckTemporarilyUnable(e.target.value);
        setCheckConditionalAble("0");
        setCheckAble("0");
        setCheckDefinitelyUnable("0");
        break;
      case "DefinitelyUnable":
        setCheckDefinitelyUnable(e.target.value);
        setCheckTemporarilyUnable("0");
        setCheckConditionalAble("0");
        setCheckAble("0");
        break;
      default:
        setCheckTemporarilyUnable("0");
        setCheckConditionalAble("0");
        setCheckAble("0");
        setCheckDefinitelyUnable("0");
    }
  };

  useEffect(() => {
    const dataHandling = (data) => {
      if (data?.length > 0) {
        const checkDate = (date, set) => {
          if (date !== null && date !== undefined) {
            return set(new Date(date));
          } else {
            set(date);
          }
        };
        checkDate(data[0].examDate, setExamDate);
        checkDate(data[0].examDate, setOldExamDate);
        checkDate(data[0].nextExameDate, setNextExamDate);

        setCheckAdmission(data[0].admission);
        setCheckPeriodical(data[0].periodic);
        setCheckOccasional(data[0].ocasional);
        setCheckOther(data[0].other);
        setCheckAble(data[0].able);

        setCheckConditionalAble(data[0].conditionalAble);
        setCheckTemporarilyUnable(data[0].temporaryUnable);
        setCheckDefinitelyUnable(data[0].definitiveUnable);
        // setLepNum(data[0].lepNum);
        setEmpCode(data[0].empCode);
      } else {
        setCheckAdmission("0");
        setCheckPeriodical("0");
        setCheckOccasional("0");
        setCheckOther("0");
        setExamDate();
        setNextExamDate();
        setCheckAble("0");
        setCheckConditionalAble("0");
        setCheckTemporarilyUnable("0");
        setCheckDefinitelyUnable("0");
      }
    };
    if (rowClicked) {
      dataHandling(rowClicked);
    } else {
      dataHandling(medicalData);
    }
  }, [medicalData, rowClicked]);

  const takeClickedRow = (data) => {
    setRowClicked([data]);
  };

  const formatExamType = (cell, row) => {
    if (row.admission === "1") {
      return <p>Admission</p>;
    }
    if (row.periodic === "1") {
      return <p>Periodic</p>;
    }
    if (row.ocasional === "1") {
      return <p>Ocasional</p>;
    }
    if (row.other === "1") {
      return <p>Other</p>;
    }
  };

  const formatExamResult = (cell, row) => {
    if (row.able === "1") {
      return <p>Able</p>;
    }

    if (row.conditionalAble === "1") {
      return <p>Conditional Able</p>;
    }

    if (row.temporaryUnable === "1") {
      return <p>Temporary Unable</p>;
    }

    if (row.definitiveUnable === "1") {
      return <p>Definitive Unable</p>;
    }
  };
  const formatEndData = (cell) => {
    if (cell !== null) {
      let converToIso = new Date(cell).toISOString();
      return DateTime.fromISO(converToIso).toLocaleString();
    }
  };

  const columns = [
    {
      text: t(`portalrh.MedicalRecord.examType`),
      sort: true,
      formatter: formatExamType,
      style: { fontSize: "12px" },
    },
    {
      dataField: "examDate",
      text: t(`portalrh.MedicalRecord.ExamDate`),
      sort: true,
      formatter: formatEndData,
      style: { fontSize: "12px" },
    },
    {
      text: t(`portalrh.MedicalRecord.ExamResult`),
      sort: true,
      formatter: formatExamResult,
      style: { fontSize: "12px" },
    },
    {
      dataField: "nextExameDate",
      text: t(`portalrh.MedicalRecord.NextExamDate`),
      sort: true,
      formatter: formatEndData,
      style: { fontSize: "12px" },
    },
  ];
  const OnTakingBolean = (data) => {
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "disabled";
  }
  const cleanInputs = () => {
    setCheckAdmission("0");
    setCheckPeriodical("0");
    setCheckOccasional("0");
    setCheckOther("0");
    setExamDate();
    setNextExamDate();
    setCheckAble("0");
    setCheckConditionalAble("0");
    setCheckTemporarilyUnable("0");
    setCheckDefinitelyUnable("0");
  };

  const medicalRecordObject = {
    id: rowCollaborator.funcionarioNum,
    companyId: rowCollaborator.codEntidade,
    admission: checkAdmission,
    periodic: checkPeriodical,
    ocasional: checkOccasional,
    other: checkOther,
    examDate: examDate,
    nextExameDate: nextExamDate,
    able: checkAble,
    conditionalAble: checkConditionalAble,
    temporaryUnable: checkTemporarilyUnable,
    definitiveUnable: checkDefinitelyUnable,
    oldExamDate: oldExamDate,
    lepNum: rowCollaborator.funcionarioNum,
    empCode: empCode,
  };
  const updateData = (bollean) => {
    setMedicalRecordPage(bollean);
    delete medicalRecordObject.lepNum;
    delete medicalRecordObject.empCode;
    dispatch(updateMedicalRecord(medicalRecordObject));
  };

  const createNewData = (bollean) => {
    setMedicalRecordPage(bollean);

    dispatch(createMedicalRecord(medicalRecordObject));
  };

  const handleUpdateButtonClicked = (bollean) => {
    if (!bollean) {
      setUpdateButtonClicked(true);
    }
    if (bollean) {
      setUpdateButtonClicked(false);
    }
  };

  const handleNewButtonClicked = (bollean) => {
    if (!bollean) {
      cleanInputs();
      setNewButtonClicked(true);
    }
    if (bollean) {
      setNewButtonClicked(false);
    }
  };

  return (
    <div className="collaboratoRH__view">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="buttons__rhAdmin">
          {!updateButtonClicked ? (
            <ButtonsCreate
              createNewData={createNewData}
              OnTakingBolean={OnTakingBolean}
              handleNewButtonClicked={handleNewButtonClicked}
              medicalRecordPage={medicalRecordPage}
            />
          ) : null}
          {!newButtonClicked ? (
            <ButtonsUpdate
              OnTakingBolean={OnTakingBolean}
              updateData={updateData}
              medicalRecordPage={medicalRecordPage}
              handleUpdateButtonClicked={handleUpdateButtonClicked}
              isClicked={isClickedBtnUpdate}
              setIsClicked={setIsClickedBtnUpdate}
            />
          ) : null}
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="2" md="3">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.MedicalRecord.examType`)}
            </Form.Label>
            <Form.Check
              className="rhcollaborator__labels__"
              type="radio"
              name="group1"
              label={t(`portalrh.MedicalRecord.Admission`)}
              checked={checkAdmission === "1"}
              style={{ paddingBottom: 10 }}
              onChange={(e) => handleChecExamType(e, "admission")}
              disabled={isClicked ? false : true}
              value="1"
            />
            <Form.Check
              className="rhcollaborator__labels__"
              type="radio"
              name="group1"
              label={t(`portalrh.MedicalRecord.Periodical`)}
              checked={checkPeriodical === "1"}
              style={{ paddingBottom: 10 }}
              onChange={(e) => handleChecExamType(e, "periodical")}
              disabled={isClicked ? false : true}
              value="1"
            />
            <Form.Check
              className="rhcollaborator__labels__"
              type="radio"
              name="group1"
              label={t(`portalrh.MedicalRecord.Occasional`)}
              checked={checkOccasional === "1"}
              style={{ paddingBottom: 10 }}
              onChange={(e) => handleChecExamType(e, "occasional")}
              disabled={isClicked ? false : true}
              value="1"
            />
            <Form.Check
              className="rhcollaborator__labels__"
              type="radio"
              name="group1"
              label={t(`portalrh.MedicalRecord.Other`)}
              checked={checkOther === "1"}
              style={{ paddingBottom: 10 }}
              onChange={(e) => handleChecExamType(e, "other")}
              disabled={isClicked ? false : true}
              value="1"
            />
          </Col>

          {/* //other                 */}

          <Col xl="2" md="3">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.MedicalRecord.ExamResult`)}
            </Form.Label>
            <Form.Check
              className="rhcollaborator__labels__"
              type="radio"
              name="group2"
              label={t(`portalrh.MedicalRecord.Able`)}
              checked={checkAble === "1"}
              style={{ paddingBottom: 10 }}
              onChange={(e) => handleChecExamResult(e, "Able")}
              disabled={isClicked ? false : true}
              value="1"
            />
            <Form.Check
              className="rhcollaborator__labels__"
              type="radio"
              name="group2"
              label={t(`portalrh.MedicalRecord.CoditionalAble`)}
              checked={checkConditionalAble === "1"}
              //handleChecExamResult
              style={{ paddingBottom: 10 }}
              onChange={(e) => handleChecExamResult(e, "CoditionalAble")}
              disabled={isClicked ? false : true}
              value="1"
            />
            <Form.Check
              className="rhcollaborator__labels__"
              type="radio"
              name="group2"
              label={t(`portalrh.MedicalRecord.TemporarilyUnable`)}
              checked={checkTemporarilyUnable === "1"}
              style={{ paddingBottom: 10 }}
              onChange={(e) => handleChecExamResult(e, "TemporarilyUnable")}
              disabled={isClicked ? false : true}
              value="1"
            />
            <Form.Check
              className="rhcollaborator__labels__"
              type="radio"
              name="group2"
              label={t(`portalrh.MedicalRecord.DefinitelyUnable`)}
              checked={checkDefinitelyUnable === "1"}
              style={{ paddingBottom: 10 }}
              onChange={(e) => handleChecExamResult(e, "DefinitelyUnable")}
              disabled={isClicked ? false : true}
              value="1"
            />
          </Col>
        </div>
        <div
          className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
        >
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels">
              {t(`portalrh.MedicalRecord.ExamDate`)}
            </Form.Label>
            <DateTimePicker
              value={examDate}
              onChange={handleExamDate}
              locale="pt-PT"
              format="dd/MM/yyyy"
              className="form-control"
              disabled={isClicked ? false : true}
            />
          </Col>
          <Col xl="4" md="4">
            <Form.Label className="rhcollaborator__labels__before">
              {t(`portalrh.MedicalRecord.NextExamDate`)}
            </Form.Label>
            <Form.Label className="rhcollaborator__labels__after">
              {t(`portalrh.MedicalRecord.NextExamDate`)}
            </Form.Label>
            <DateTimePicker
              value={nextExamDate}
              onChange={handleNextExamDate}
              locale="pt-PT"
              format="dd/MM/yyyy"
              className="form-control"
              disabled={isClicked ? false : true}
            />
          </Col>
        </div>
        <RhTableList
          tableId={"34"}
          columns={columns}
          data={medicalData}
          onClickedRow={takeClickedRow}
        />
      </Form>
    </div>
  );
};
export default withNamespaces()(RhMedicalRecord);
