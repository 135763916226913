import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Puzzle from "~/components/Puzzle";

import { closeIframesScreens } from "./util";
import {
  closeSideBar,
  openSideBar,
} from "~/store/ducks/applicationMenu/actionTypes";

import "~/assets/scss/layout.scss";

const SidebarContainer = ({ ...props }) => {
  const { showSideBar, menu } = useSelector(
    (state) => state.applicationMenuReducer
  );
  const [fullScreenIframe, setFullScreenIframe] = useState(false);
  const dispatch = useDispatch();
  const isMobile = window.innerWidth < 992 ? true : false;

  useEffect(() => {
    const url = window.location.href;
    if (
      url.includes("rh/portal") ||
      url.includes("portalrh/portal") ||
      url.includes("bpm/admin")
    ) {
      setFullScreenIframe(true);
    } else {
      setFullScreenIframe(false);
    }
    if (isMobile) {
      if (!url.includes("processos") && !url.includes("backoffice")) {
        dispatch(closeSideBar());
      }
    }
  }, [window.location.href]);

  useEffect(() => {
    let timeout;
    if (showSideBar) {
      document.getElementById("sidebarContainer").style.display = "block";
    } else {
      /*if (isMobile) {
         document.getElementById("sidebarContainer").animate([
           // keyframes
           { transform: 'translateX(0px)' },
           { transform: 'translateX(-300px)' }
         ], {
           // timing options
           duration: 500,
           animationIterationCount: 0
         });
        timeout = setTimeout(() => {
          document.getElementById("sidebarContainer").style.display = "none";
        }, 400);
      } else {*/
      document.getElementById("sidebarContainer").style.display = "none";
    }
    return () => clearTimeout(timeout);
  }, [showSideBar]);

  useEffect(() => {
    const url = window.location.href;
    if (fullScreenIframe) {
      dispatch(closeSideBar());
    } else {
      if (isMobile) {
        if (url.includes("processos") || url.includes("backoffice")) {
          dispatch(openSideBar());
        } else dispatch(closeSideBar());
      } else dispatch(openSideBar());

      closeIframesScreens();
    }
  }, [fullScreenIframe]);

  return (
    <>
      <div
        id="sidebarContainer"
        className="sidebar"
      >
        {props.children}
        {isMobile && <Puzzle />}
      </div>
    </>
  );
};

export default SidebarContainer;
