import React, { useState, useEffect } from "react";
import moment from "moment";
import { startOf, add } from "date-arithmetic";
import { navigate } from "react-big-calendar/lib/utils/constants";
import {
  FaBookmark,
  FaUmbrellaBeach,
  FaClock,
  FaMinusCircle,
  FaCheckCircle,
  FaTimesCircle,
  FaEye
} from "react-icons/fa";

function createCalendar(currentDate) {
  if (!currentDate) {
    currentDate = moment();
  } else {
    currentDate = moment(currentDate);
  }

  const first = currentDate.clone().startOf("month");
  const last = currentDate.clone().endOf("month");
  const weeksCount = Math.ceil((first.day() + last.date()) / 7);
  const calendar = Object.assign([], { currentDate, first, last });

  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week = [];
    calendar.push(week);
    calendar.year = currentDate.year();
    calendar.month = currentDate.month();

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      const date = currentDate.clone().set("date", day + 1 - first.day());
      date.calendar = calendar;
      week.push(date);
    }
  }

  return calendar;
}

function CalendarDate(props) {
  const { dateToRender, dateOfMonth, events, onClick } = props;
  const today =
    dateToRender.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
      ? "today"
      : "";

  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <button disabled={true} className="date prev-month">
        {dateToRender.date()}
      </button>
    );
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <button disabled={true} className="date next-month">
        {dateToRender.date()}
      </button>
    );
  }

  const matchedEvent = events.find((eventDetail) =>
    dateToRender.isSame(moment(eventDetail.start), "day")
  );

  return (
    <button
      className={`date in-month ${matchedEvent?.type || today}`}
      title={matchedEvent?.title}
      onClick={() => onClick(dateToRender)}
      style={{
        padding:
          matchedEvent?.type == "daysmarked" ||
          matchedEvent?.type == "diasMarcados"
            ? "1px"
            : "",
      }}
    >
      {matchedEvent?.type == "daysmarked" && (
        <FaBookmark
          style={{
            fontSize: "xx-small",
            alignSelf: "center",
            color: matchedEvent?.color || "grey",
          }}
        />
      )}
      {matchedEvent?.type == "ferias" && (
        <FaUmbrellaBeach
          style={{
            fontSize: "xx-small",
            alignSelf: "center",
            color: matchedEvent?.color || "grey",
          }}
        />
      )}
      {matchedEvent?.type == "diasMarcados" && matchedEvent?.state === 1 && (
        <FaClock
          style={{
            fontSize: "xx-small",
            alignSelf: "center",
            color: "darkgoldenrod",
          }}
        />
      )}
      {matchedEvent?.type == "diasMarcados" && matchedEvent?.state === 2 && (
        <FaMinusCircle
          style={{
            fontSize: "xx-small",
            alignSelf: "center",
            color: "red",
          }}
        />
      )}
      {matchedEvent?.type == "diasMarcados" && matchedEvent?.state === 3 && (
        <FaCheckCircle
          style={{
            fontSize: "xx-small",
            alignSelf: "center",
            color: "green",
          }}
        />
      )}
      {matchedEvent?.type == "diasMarcados" && matchedEvent?.state === 4 && (
        <FaTimesCircle
          style={{
            fontSize: "xx-small",
            alignSelf: "center",
            color: "red",
          }}
        />
      )}
      {matchedEvent?.type == "diasMarcados" && matchedEvent?.state === 5 && (
        <FaEye
          style={{
            fontSize: "xx-small",
            alignSelf: "center",
            color: "green",
          }}
        />
      )}
      {dateToRender.date()}
    </button>
  );
}

function CalendarComponent({
  date,
  events,
  setEvents,
  convertToEventsMarkedDays,
  settoastText,
  setShowToast,
  t,
  isEditable,
}) {
  const [calendar, setCalendar] = useState(null);

  useEffect(() => {
    setCalendar(createCalendar(date));
  }, [date]);

  if (!calendar) {
    return null;
  }

  const lang = calendar.currentDate._locale._abbr;
  const weekdays = {
    pt: ["D", "S", "T", "Q", "Q", "S", "S"],
    en: ["S", "M", "T", "W", "T", "F", "S"],
    es: ["D", "L", "M", "X", "J", "V", "S"],
  };

  return (
    <div className="month">
      <div className="month-name">
        {calendar.currentDate.format("MMMM").toUpperCase()}
      </div>
      {weekdays[lang].map((day, index) => (
        <span key={index} className="day">
          {day}
        </span>
      ))}
      {calendar.map((week, index) => (
        <div key={index}>
          {week.map((date) => (
            <CalendarDate
              key={date.date()}
              dateToRender={date}
              dateOfMonth={calendar.currentDate}
              onClick={(date) =>
                handleAddToEvents(
                  date,
                  events,
                  setEvents,
                  convertToEventsMarkedDays,
                  settoastText,
                  setShowToast,
                  isEditable,
                  t
                )
              }
              events={events}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

const handleAddToEvents = (
  date,
  events,
  setEvents,
  convertToEventsMarkedDays,
  settoastText,
  setShowToast,
  isEditable,
  t
) => {
  const isEventAlreadyExists = events.some(
    (event) =>
      event.type !== "daysmarked" && moment(event.start).isSame(date, "day")
  );
  if (isEventAlreadyExists) {
    return;
  }
  if (isEditable) {
    const daysMarked = convertToEventsMarkedDays(
      [date],
      "daysmarked",
      "#44A49B"
    );

    // Verifica se a data é do tipo "daysmarked" e já está nos eventos
    const isEventAlreadyAdded = events.some(
      (event) =>
        event.type === "daysmarked" &&
        moment(event.start).isSame(daysMarked[0].start, "day")
    );

    if (isEventAlreadyAdded) {
      setEvents((prevEvents) =>
        prevEvents.filter(
          (event) =>
            !(
              event.type === "daysmarked" &&
              moment(event.start).isSame(daysMarked[0].start, "day")
            )
        )
      );
    } else {
      setEvents((prevEvents) => [...prevEvents, ...daysMarked]);
    }
  }
};

function Year({
  date,
  events,
  setEvents,
  convertToEventsMarkedDays,
  settoastText,
  setShowToast,
  t,
  isEditable,
}) {
  let range = Year.range(date);
  const months = [];
  const firstMonth = startOf(date, "year");
  for (let i = 0; i < 12; i++) {
    months.push(
      <CalendarComponent
        key={i + 1}
        date={add(firstMonth, i, "month")}
        events={events}
        setEvents={setEvents}
        convertToEventsMarkedDays={convertToEventsMarkedDays}
        settoastText={settoastText}
        setShowToast={setShowToast}
        isEditable={isEditable}
        t={t}
      />
    );
  }

  return (
    <div className="year scrollBar-visible-year">
      {months.map((month) => month)}
    </div>
  );
}

Year.range = (date) => {
  return [startOf(date, "year")];
};

Year.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return add(date, -1, "year");

    case navigate.NEXT:
      return add(date, 1, "year");

    default:
      return date;
  }
};

Year.title = (date, { localizer }) =>
  localizer.format(date, "yearHeaderFormat");

export default Year;
