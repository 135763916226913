import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  getInformationProcessRemoteWork,
  approveDeleteRemoveDayRemoteWork,
} from "~/store/ducks/portalrh/actionTypes";
import paginationFactory from "react-bootstrap-table2-paginator";
import pagination from "~/components/pagination/currentSizePerPage/10";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Spinner from "~/components/Spinner";
import BootstrapTable from "react-bootstrap-table-next";
import {
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Alert,
} from "react-bootstrap";
import "./modalCss.css";
import { format, parseISO } from "date-fns";
import { Button as ButtonMaterial, Stack, Box } from "@mui/material";
import SchedulerComponent from "../../TrabalhoRemoto/Scheduler";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert as AlertMaterial } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import { FaClock, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { setErrorPostTrabalhoRemotoConfirm, setErrorPostTrabalhoRemoto } from "~/store/ducks/portalrh/actionTypes";
const ModalRemoteWork = ({
  t,
  process,
  closeButton,
  showModalRemoteWork,
  handleCloseModalRemoteWork,
  setmakeUpdate,
  path,
  isEditable,
  showCalendarAndTable,
  loadEventsStart,
  portalRhMenus,
  designacao,
  styleSheet,
}) => {
  const { infoProcessRemoteWork, isLoadingInfoProcRemoteWork, collaborators } =
    useSelector((state) => state.portalrhReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { genericAuthorizationState } = useSelector(
    (state) => state.portalrhReducer
  );
  const userNamePortalRH = collaborators.find((collaborator) => {
    return user.legacyUsers.some((legacyUser) => {
      return (
        legacyUser.username === collaborator.username &&
        legacyUser.legacyApplication.label === "portalrh"
      );
    });
  });
  useEffect(() => {
    dispatch(setErrorPostTrabalhoRemotoConfirm(""));
    dispatch(setErrorPostTrabalhoRemoto({}))
  }, []);
  const [infoData, setinfoData] = useState({});
  const [modalConfirmation, setmodalConfirmation] = useState(false);
  const [conditionApproveRequest, setConditionApproveRequest] = useState(false);
  const [comentario, setComentario] = useState("");
  const [checkboxValues, setCheckboxValues] = useState({});
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (process != undefined) {
      const payload = {
        id: process.id,
      };
      dispatch(getInformationProcessRemoteWork(payload));
    }
  }, [process]);

  useEffect(() => {
    setinfoData(infoProcessRemoteWork);

    const newCheckboxValues =
      infoProcessRemoteWork.trabalhoremoto != undefined &&
      infoProcessRemoteWork.trabalhoremoto.reduce((acc, curr) => {
        acc[curr.id] = { selected: true, id: curr.id };
        return acc;
      }, {});

    setCheckboxValues(newCheckboxValues);
  }, [infoProcessRemoteWork]);

  const buildNrDiasColumn = (_, row, index) => {
    if (row.qtDias === 1 || row.qtDias === "1") {
      return t("portalrh.workDays.diaInteiro");
    }
    return t("portalrh.workDays.diasMarcadosMeiodia");
  };

  function dateFormater(cell, row) {
    if (row.data) {
      const parsedDate = parseISO(cell);
      return format(parsedDate, "dd/MM/yyyy");
    }
  }

  const buildApprovedColumn = (_, row, index) => {
    if (row.aprovado === 1) {
      return t("portalrh.workDays.Pendente");
    }
    if (row.aprovado === 2) {
      return t("portalrh.workDays.Anulado");
    }
    if (row.aprovado === 3) {
      return t("portalrh.workDays.Aprovado");
    }
    if (row.aprovado === 4) {
      return t("portalrh.workDays.Recusado");
    }
    if (row.aprovado === 5) {
      return t("portalrh.workDays.Visto");
    }
  };
  const handleCheckboxChange = (rowId, checked) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [rowId]: { selected: checked, id: rowId },
    }));
  };

  const CheckboxComponent = ({ row }) => {
    const [checked, setChecked] = useState(true);

    // useEffect(() => {
    //   console.log(checkboxValues)
    //   setChecked(checkboxValues[row.id] || false);
    // }, [checkboxValues]);

    return (
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          setChecked(e.target.checked);
          handleCheckboxChange(row.id, e.target.checked);
        }}
      />
    );
  };

  useEffect(() => {
    setColumns([
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "data",
        text: t("portalrh.requestTable.date"),
        sort: true,
        formatter: dateFormater,
      },
      {
        dataField: "qtDias",
        text: t("portalrh.workDays.quantidadeDias"),
        sort: true,
        formatter: buildNrDiasColumn,
      },
      {
        dataField: "aprovado",
        text: t("portalrh.requestTable.state"),
        sort: true,
        formatter: buildApprovedColumn,
      },
      {
        dataField: "checkbox",
        text: "",
        formatter: (cell, row) => {
          if (+genericAuthorizationState === 1) {
            return <CheckboxComponent row={row} />;
          } else {
            return null;
          }
        },
      },
    ]);
  }, [checkboxValues]);

  const handleApproveRequest = (aceptReject) => {
    setConditionApproveRequest(aceptReject);
    setmodalConfirmation(true);
  };

  const handleSend = () => {
    if (!conditionApproveRequest) if (comentario === "") return;
    setmodalConfirmation(false);
    let payload;
    if (conditionApproveRequest) {
      payload = {
        requestAprovalDTO: {
          ped_id: process.id,
          state: 1,
          username: userNamePortalRH.username,
          comment: comentario,
        },
        remoteWorkAprovalDTO: Object.values(checkboxValues),
      };
    } else {
      payload = {
        requestAprovalDTO: {
          ped_id: process.id,
          state: 0,
          username: userNamePortalRH.username,
          comment: comentario,
        },
        remoteWorkAprovalDTO: Object.values(checkboxValues),
      };
    }
    dispatch(approveDeleteRemoveDayRemoteWork(payload));
    setmakeUpdate(true);
    handleCloseModalRemoteWork();
    setComentario("");
  };
  const { SearchBar } = Search;

  const createState = (item) => {
    return (
      <>
        {item === "Pendente" && (
          <OverlayTrigger
            overlay={<Tooltip>{t(`portalrh.workDays.${item}`)}</Tooltip>}
            placement="right"
          >
            <FaClock style={{ color: "#FFA500" }} />
          </OverlayTrigger>
        )}
        {item === "Aprovado" && (
          <OverlayTrigger
            overlay={<Tooltip>{t(`portalrh.workDays.${item}`)}</Tooltip>}
            placement="right"
          >
            <FaCheckCircle
              style={{
                color: "#008000",
              }}
            />
          </OverlayTrigger>
        )}
        {item === "Rejeitado" && (
          <OverlayTrigger
            overlay={<Tooltip>{t(`portalrh.workDays.${item}`)}</Tooltip>}
            placement="right"
          >
            <FaTimesCircle
              style={{
                color: "#FF0000",
              }}
            />
          </OverlayTrigger>
        )}
        {item === "Recusado" && (
          <OverlayTrigger
            overlay={<Tooltip>{t(`portalrh.workDays.${item}`)}</Tooltip>}
            placement="right"
          >
            <FaTimesCircle
              style={{
                color: "#FF0000",
              }}
            />
          </OverlayTrigger>
        )}
      </>
    );
  };

  return (
    <>
      {Object.keys(infoData).length > 0 ? (
        <>
          <Modal
            show={showModalRemoteWork}
            dialogClassName={"modal-tarefa-97w custom-modal"}
            centered
            backdrop="static"
            onHide={() => {
              handleCloseModalRemoteWork();
              setComentario("");
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("portalrh.workDays.remoteWork")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                className={
                  styleSheet?.processScroll
                    ? "scrollBar-visibleModalMyTeam"
                    : ""
                }
              >
                <Row>
                  <Col lg="4">
                    <div
                      style={{
                        margin: window.innerWidth <= 768 ? "0px" : "20px 30px",
                      }}
                    >
                      <div className="custom-table custom-table-small tableDateInformation scrollBar-tableDateInformation">
                        <ToolkitProvider
                          keyField="id"
                          data={
                            !isLoadingInfoProcRemoteWork &&
                            infoData?.trabalhoremoto
                          }
                          columns={columns}
                          search
                        >
                          {(props) => (
                            <div>
                              <div
                                style={{
                                  paddingBottom: "20px",
                                  display: "flex",
                                }}
                              >
                                {infoData?.request[0] && (
                                  <p>
                                    {t("portalrh.workDays.pedidoFeitoPor.1")}{" "}
                                    <b>{infoData.request[0].username} </b>
                                    {t(
                                      "portalrh.workDays.pedidoFeitoPor.2"
                                    )}{" "}
                                    <b>
                                      {format(
                                        parseISO(
                                          infoData.request[0].creation_date
                                        ),
                                        "dd/MM/yyyy"
                                      )}
                                    </b>
                                  </p>
                                )}
                              </div>
                              <div style={{ display: "flex" }}>
                                {infoData?.trabalhoremoto.length > 1 && (
                                  <SearchBar
                                    {...props.searchProps}
                                    placeholder={t("Search")}
                                  />
                                )}
                              </div>
                              <Spinner
                                spinning={isLoadingInfoProcRemoteWork}
                                wrapper
                              />
                              {!isLoadingInfoProcRemoteWork && (
                                <>
                                  <h6>
                                    {t("portalrh.workDays.diasSolicitados")}
                                  </h6>
                                  <BootstrapTable
                                    {...props.baseProps}
                                    bootstrap4
                                    //pagination={paginationFactory(pagination)}
                                    noDataIndication={t(
                                      "portalrh.personalData.noRequests"
                                    )}
                                    striped
                                    hover
                                  />
                                </>
                              )}
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    </div>
                    <div
                      style={{
                        margin: window.innerWidth <= 768 ? "0px" : "20px 30px",
                      }}
                    >
                      <AlertMaterial severity="info">
                        <AlertTitle>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              {t("portalrh.workDays.lastComment")}{" "}
                              <span style={{ fontSize: "x-small" }}>
                                {!isLoadingInfoProcRemoteWork &&
                                  infoData?.comentarios.length > 0 &&
                                  infoData?.comentarios && (
                                    <>
                                      {format(
                                        parseISO(infoData?.comentarios[0].data),
                                        "yyyy/MM/dd"
                                      )}{" "}
                                      - {infoData?.comentarios[0].username}
                                    </>
                                  )}
                              </span>
                            </span>
                            <span>
                              {!isLoadingInfoProcRemoteWork &&
                                infoData?.comentarios.length > 0 &&
                                infoData?.comentarios &&
                                createState(infoData?.comentarios[0].estado)}
                            </span>
                          </Stack>
                        </AlertTitle>
                        {!isLoadingInfoProcRemoteWork &&
                          infoData?.comentarios.length > 0 &&
                          infoData?.comentarios && (
                            <>
                              {infoData?.comentarios[0].comentario === ""
                                ? t("portalrh.workDays.emptyComment")
                                : infoData?.comentarios[0].comentario}
                            </>
                          )}
                      </AlertMaterial>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panelfirst"
                          id="panelfirst"
                        >
                          {t("portalrh.workDays.todosComentarios")}
                        </AccordionSummary>
                        <AccordionDetails className="scrollBar-autorizersDateInformation">
                          {!isLoadingInfoProcRemoteWork &&
                            infoData?.comentarios.length > 0 &&
                            infoData?.comentarios.map((item, index) => (
                              <>
                                <Alert style={{ margin: "0px" }}>
                                  <AlertTitle>
                                    <span>
                                      {item.username}
                                      <span style={{ fontSize: "x-small" }}>
                                        {" "}
                                        -{" "}
                                        {format(
                                          parseISO(item.data),
                                          "yyyy/MM/dd HH:mm"
                                        )}
                                      </span>
                                    </span>
                                  </AlertTitle>{" "}
                                  <span style={{ fontSize: "small" }}>
                                    {item.comentario === ""
                                      ? t("portalrh.workDays.emptyComment")
                                      : item.comentario}
                                  </span>
                                </Alert>
                              </>
                            ))}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div
                      style={{
                        margin: window.innerWidth <= 768 ? "0px" : "20px 30px",
                      }}
                      className="scrollBar-autorizersDateInformation"
                    >
                      <h6>{t("portalrh.workDays.listaAprovadores")}</h6>
                      <ListGroup>
                        {!isLoadingInfoProcRemoteWork &&
                          infoData?.autorizacao.map((item, index) => (
                            <ListGroup.Item key={index}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "self-end",
                                }}
                              >
                                {item.username} -{" "}
                                {t(`portalrh.workDays.${item.state}`)}{" "}
                                {createState(item.state)}
                              </span>
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    </div>
                    {infoData?.trabalhoremoto.length > 0 &&
                      infoData?.trabalhoremoto[0].comentario && (
                        <Alert key="danger" variant="danger">
                          <b>{t("portalrh.workDays.refuseMessage")}:</b>{" "}
                          {infoData?.trabalhoremoto[0].comentario}
                        </Alert>
                      )}
                    {+genericAuthorizationState === 1 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "0px 10px",
                          marginTop: "20px",
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <Button
                            variant="danger"
                            onClick={() => handleApproveRequest(false)}
                          >
                            {t("portalrh.workDays.reject")}
                          </Button>
                          <Button
                            className="card-button"
                            onClick={() => handleApproveRequest(true)}
                            style={{ marginLeft: "15px" }}
                          >
                            {t("portalrh.workDays.approve")}
                          </Button>
                        </Stack>
                      </Box>
                    )}
                    {+genericAuthorizationState === 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "0px 10px",
                          marginTop: "20px",
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <Button
                            className="card-button"
                            onClick={() => handleApproveRequest(true)}
                            style={{ marginLeft: "15px" }}
                          >
                            {t("portalrh.workDays.takeNotice")}
                          </Button>
                        </Stack>
                      </Box>
                    )}
                  </Col>
                  <Col>
                    <SchedulerComponent
                      portalRhMenus={portalRhMenus}
                      designacao={designacao}
                      path={path}
                      isEditable={false}
                      showCalendarAndTable={showCalendarAndTable}
                      loadEventsStart={loadEventsStart}
                      hideChangeView={true}
                      infoDataWorkHour={infoData.request[0].user_id}
                      dataSelectedRequest={infoData?.trabalhoremoto}
                      //dataSelectedRequest={infoData.request[0].creation_date}
                      showAllGroups={true}
                    />
                    <div
                      id="legenda"
                      style={{ padding: "0px 10px", textAlignLast: "end" }}
                    >
                      <ButtonMaterial
                        size="small"
                        variant="text"
                        style={{
                          pointerEvents: "none",
                          padding: "0px 0px 0px 10px",
                          color: "#4C4B4B",
                        }}
                      >
                        <FaClock
                          color="darkgoldenrod"
                          style={{ margin: "0px 5px" }}
                        />
                        {t("portalrh.workDays.Pendente")}
                      </ButtonMaterial>
                      <ButtonMaterial
                        size="small"
                        variant="text"
                        style={{
                          pointerEvents: "none",
                          padding: "0px 0px 0px 10px",
                          color: "#4C4B4B",
                        }}
                      >
                        <FaCheckCircle
                          color="green"
                          style={{ margin: "0px 5px" }}
                        />
                        {t("portalrh.workDays.Aprovado")}
                      </ButtonMaterial>
                      <ButtonMaterial
                        size="small"
                        variant="text"
                        style={{
                          pointerEvents: "none",
                          padding: "0px 0px 0px 10px",
                          color: "#4C4B4B",
                        }}
                      >
                        <FaTimesCircle
                          color="red"
                          style={{ margin: "0px 5px" }}
                        />
                        {t("portalrh.workDays.Recusado")}
                      </ButtonMaterial>
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <Spinner spinning={isLoadingInfoProcRemoteWork} wrapper={true} />
      )}
      <Modal
        show={modalConfirmation}
        dialogClassName={"modal-tarefa-50w custom-modal"}
        centered
        backdrop="static"
        onHide={() => {
          setmodalConfirmation(false);
          setComentario("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("portalrh.workDays.remoteWork")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="12">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    {!conditionApproveRequest
                      ? t("portalrh.workDays.refuseMessage")
                      : t("portalrh.workDays.comentario")}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={comentario}
                    onChange={(e) => setComentario(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0px 10px",
                  marginTop: "20px",
                }}
              >
                <Stack direction="row" spacing={2}>
                  <Button
                    className="card-button"
                    onClick={() => handleSend()}
                    style={{ marginLeft: "15px" }}
                  >
                    {t("portalrh.workDays.confirmDays")}
                  </Button>
                </Stack>
              </Box>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default withNamespaces()(ModalRemoteWork);
