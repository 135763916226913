import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/icons.css";
import ButtonUpload from "~/components/Buttons/UploadButton";
import Messages from "~/components/Messages";
import Spinner from "~/components/Spinner";
import {
  clearDocument,
  clearDocuments,
  deleteDocument,
  updateDocument,
  findDocumentsByFilters,
  getCollaborators,
  getDocumentBinary,
  getDocuments,
  getOperations,
  getPreviewBinary,
  setPreviewBinary,
} from "~/store/ducks/portalrh/actionTypes";
import { getEmailTemplates } from "~/store/ducks/udw/actionTypes";
import { requestUser } from "~/containers/Layout/actions";
import { loadLegacyUsers, loadLegacyApps } from "~/pages/Admin/User/actions";
import { findUDWUsers, sendEmail } from "~/store/ducks/udw/actionTypes";
import { toLocaleDateString } from "~/utils";
import DocumentFilters from "./DocumentFilters";
import PreviewModal from "./PreviewModal";
import DisclaimerModal from "./DisclaimerModal";
import SearchButton from "./SearchButton";
import SearchButtonFilters from "./SearchButtonFilters";
import UploadModal from "./UploadModal";
import { pagination } from "~/components/pagination/currentSizePerPage/5";

import { PORTALRH_MIDDLEWARE } from "~/utils/constants";
import "~/assets/scss/_docTable.scss";
const devLogConsole = require("~/utils/devLog");
const DocumentTable = ({ t, manager }) => {
  const dispatch = useDispatch();
  const {
    documents: docs,
    isLoadingDocuments,
    document: documentBinary,
    isDeletingDocument,
    operations,
    isLoadingOperations,
    collaborators,
    isLoadingCollaborators,
  } = useSelector((state) => state.portalrhReducer);

  const DOWNLOAD = "download";
  const PREVIEW = "preview";

  const { emailTemplates } = useSelector((state) => state.udwReducer);
  const { user } = useSelector((state) => state.globalReducer);
  const { udwUsers } = useSelector((state) => state.udwReducer);
  const { legacyUsers, isLoadingLegacyUsers, legacyApps } = useSelector(
    (state) => state.userManagementReducer
  );

  const [filteredDocs, setFilteredDocs] = useState(docs);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const [uploadEmMassa, setUploadEmMassa] = useState(false);

  /*Filters start */
  const [expirationDateStart, setExpirationDateStart] = useState("");
  const [expirationDateEnd, setExpirationDateEnd] = useState("");

  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const [uploadedDateStart, setUploadedDateStart] = useState(yesterday);
  const [uploadedDateEnd, setUploadedDateEnd] = useState();

  const [selectedOperation, setSelectedOperation] = useState("");
  const [docType, setDoctype] = useState("");
  const [numFuncionario, setNumFuncionario] = useState("");
  const [nome, setNome] = useState("");
  const [fileName, setFileName] = useState("");
  const [uploadedBy, setUploadedBy] = useState("");

  const [seenStatus, setSeenStatus] = useState("all");
  /*Filters end */

  const [disclaimerState, setDisclaimerState] = useState("");

  const defaultFilter = uploadedDateStart && {
    uploadStartDate: uploadedDateStart.getTime(),
  };

  const portalSender = collaborators.filter(
    (el) =>
      el.username ===
      user.legacyUsers.filter(
        (el) => el.legacyApplication.label === PORTALRH_MIDDLEWARE.context
      )[0].username
  )[0];

  useEffect(() => {
    
    if (manager) {
      if (defaultFilter == null) {
        !isDeletingDocument && dispatch(findDocumentsByFilters({}));
      } else {
        !isDeletingDocument && dispatch(findDocumentsByFilters(defaultFilter));
      }
    } else {
      !isDeletingDocument && dispatch(getDocuments());
    }
    dispatch(getCollaborators());
    dispatch(getEmailTemplates(PORTALRH_MIDDLEWARE.context, t));
    dispatch(requestUser());
    return () => {
      dispatch(clearDocuments());
      dispatch(clearDocument());
    };
  }, [isDeletingDocument, manager]);

  //add operation and doctype and uploadedBy since bootstrap table will not update on redux update
  useEffect(() => {
    if (docs) {
      if (operations.length > 1 && collaborators.length > 1) {
        const getColumn = (name, row) => {
          let result = "";
          if (row.operationId && operations) {
            const operation = operations.find((op) => op.id == row.operationId);
            if (operation && operation.name && name === "operation")
              result = operation.name;
            if (
              operation &&
              operation.documentClassList &&
              name === "docType"
            ) {
              const docType = operation.documentClassList.find(
                (d) => d.id == row.classId
              );
              if (docType && docType.name) result = docType.name;
            }
            if (collaborators && name === "uploaded") {
              const collaborator = collaborators.find(
                (c) => c.id == row.uploadedBy
              );
              if (collaborator && collaborator.nome) result = collaborator.nome;
            }
          }

          return result;
        };
        const docs_ = docs.map((doc) => {
          doc.operation = getColumn("operation", doc);
          doc.docType = getColumn("docType", doc);
          doc.uploaded = getColumn("uploaded", doc);
          return doc;
        });

        setFilteredDocs(docs_);
      }
    }
  }, [docs, operations, collaborators]);

  useEffect(() => {
    dispatch(getOperations(t));
    dispatch(loadLegacyApps());
  }, []);

  useEffect(() => {
    const portalApp = legacyApps.filter(
      (el) => el.label === PORTALRH_MIDDLEWARE.context
    )[0];
    if (portalApp !== undefined) {
      dispatch(loadLegacyUsers(portalApp.id));
    }
  }, [legacyApps]);

  useEffect(() => {
    if (documentBinary) {
      //download file
      const a = document.createElement("a");
      a.href = `data:${documentBinary.documentType};base64,${documentBinary.data}`;
      a.download = documentBinary.fileName;
      a.click();
    }
  }, [documentBinary]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleOpenUploadModal = (e, uploadEmMassa) => {
    e.stopPropagation();
    e.preventDefault();
    setShowUploadModal(true);
    setUploadEmMassa(uploadEmMassa);
  };
  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
  };

  const handleDelete = () => {
    dispatch(deleteDocument(selectedDocument.id));
    handleCloseDeleteModal();
    if(manager){
    if (defaultFilter == null) {
      dispatch(findDocumentsByFilters({}));
    } else {
     dispatch(findDocumentsByFilters(defaultFilter));
    }} else{
      dispatch(getDocuments())
    }
  };

  const handleDownloadDocument = (row) => {
    dispatch(getDocumentBinary(row.id));
  };

  const handleOpenPreviewModal = (row) => {
    if (row && row.id) {
      setShowPreviewModal(true);
      setSelectedDocument(row);
      dispatch(getPreviewBinary(row.id));
    }
  };

  const handleOpenDisclaimerModal = (row) => {
    setShowDisclaimerModal(true);
  };

  const handleUpdateDocument = (row, state) => {
    if (!row.seen && row.userId === portalSender.id) {
      row.seen = true;
      dispatch(updateDocument(row));
    }
    if (state === PREVIEW) {
      handleOpenPreviewModal(row);
    } else if (state === DOWNLOAD) {
      handleDownloadDocument(row);
    }
  };

  function renderTooltip(text, ...props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {text}
      </Tooltip>
    );
  }

  const canUpdate = (row) => {
    if (row) {
      if (row.notificationId === 0 && row.requestId === 0) {
        if (manager) {
          //TODO delete only uploaded by manager implement getMyId()
          return true;
        } else {
          if (row.userId == row.uploadedBy) return true;
          else return false;
        }
      } else return false;
    } else return false;
  };

  const buildActionsColumn = (_, row, {}) => (
    <>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(
          t("portalrh.documentManagement.downloadDocument")
        )}
      >
        <i
          className="icon-download table-action-icon"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (row.seen !== false || row.userId !== portalSender.id) {
              handleDownloadDocument(row);
            } else if (row.seen === false && row.userId === portalSender.id) {
              setDisclaimerState(DOWNLOAD);
              handleOpenDisclaimerModal(row);
            }
          }}
        />
      </OverlayTrigger>

      {canUpdate(row) ? (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            t("portalrh.documentManagement.deleteDocument")
          )}
        >
          <i
            className="icon-trash table-action-icon"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowDeleteModal(true);
              setSelectedDocument(row);
            }}
          />
        </OverlayTrigger>
      ) : (
        <></>
      )}
    </>
  );

  const seenDocHandler = (row) => {
    return row.seen === false ? "docTableUnseen" : "docTableSeen";
  };

  const columns = [
    {
      dataField: "operation",
      text: t("portalrh.documentManagement.operation"),
      sort: true,
      align: "center",
      classes: function callback(cell, row, rowIndex, colIndex) {
        return seenDocHandler(row);
      },
      headerStyle: () => {
        return {
          width: "15%",
          textAlign: "center",
          overflowWrap: "break-word",
        };
      },
    },
    {
      dataField: "docType",
      text: t("portalrh.documentManagement.documentType"),
      sort: true,
      align: "center",
      classes: function callback(cell, row, rowIndex, colIndex) {
        return seenDocHandler(row);
      },
      headerStyle: () => {
        return {
          width: "20%",
          textAlign: "center",
          overflowWrap: "break-word",
        };
      },
    },
    {
      dataField: "fileName",
      text: t("portalrh.documentManagement.filename"),
      sort: true,
      align: "center",
      classes: function callback(cell, row, rowIndex, colIndex) {
        return seenDocHandler(row);
      },
      formatter: (_, row, {}) => <div className="fileName">{row.fileName}</div>,
      headerStyle: () => {
        return {
          width: "25%",
          textAlign: "center",
          overflowWrap: "break-word",
        };
      },
    },
    {
      dataField: "uploadedDate",
      text: t("portalrh.documentManagement.uploadDate"),
      sort: true,
      align: "center",
      classes: function callback(cell, row, rowIndex, colIndex) {
        return seenDocHandler(row);
      },
      formatter: (_, row, {}) => toLocaleDateString(row.uploadedDate),
      headerStyle: () => {
        return {
          width: "12%",
          textAlign: "center",
          overflowWrap: "break-word",
        };
      },
    },
    {
      dataField: "uploaded",
      text: t("portalrh.documentManagement.sender"),
      sort: true,
      align: "center",
      classes: function callback(cell, row, rowIndex, colIndex) {
        return seenDocHandler(row);
      },
      headerStyle: () => {
        return {
          width: "15%",
          textAlign: "center",
          overflowWrap: "break-word",
        };
      },
    },
    {
      dataField: "actions",
      text: t("general.actions"),
      align: "center",
      classes: function callback(cell, row, rowIndex, colIndex) {
        return seenDocHandler(row);
      },
      formatter: buildActionsColumn,
      headerStyle: () => {
        return {
          width: "100px",
          textAlign: "center",
          overflowWrap: "break-word",
        };
      },
    },
  ];

  if (manager) {
    const nome = {
      dataField: "nome",
      text: t("portalrh.documentManagement.receiver"),
      sort: true,
      classes: function callback(cell, row, rowIndex, colIndex) {
        return seenDocHandler(row);
      },
      headerStyle: () => {
        return {
          width: "15%",
          textAlign: "center",
          overflowWrap: "break-word",
        };
      },
    };
    columns.splice(5, 0, nome);
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      devLogConsole("ES document Table row clicked");
      e.stopPropagation();
      e.preventDefault();
      setSelectedDocument(row);
      if (
        !e.target.className.includes("icon-trash") &&
        !e.target.className.includes("icon-download") &&
        !e.target.className.includes("fileName")
      ) {
        if (row.seen !== false || row.userId !== portalSender.id) {
          handleOpenPreviewModal(row);
        } else if (row.seen === false && row.userId === portalSender.id) {
          setDisclaimerState(PREVIEW);
          handleOpenDisclaimerModal(row);
        }
      }
    },
  };

  const handleReset = () => {
    setExpirationDateEnd("");
    setExpirationDateStart("");
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    setUploadedDateStart(yesterday);
    setUploadedDateEnd("");
    setSelectedOperation("");
    setDoctype("");
    setNumFuncionario("");
    setNome("");
    setFileName("");
    setUploadedBy("");
    setDisclaimerState("");

    manager
      ? dispatch(
          findDocumentsByFilters({ uploadStartDate: yesterday.getTime() })
        )
      : dispatch(getDocuments());
  };

  const clearAfterSuccessMessageModal = () => {
    //close all modals
    setShowPreviewModal(false);
    setShowUploadModal(false);

    setUploadEmMassa(false);

    dispatch(setPreviewBinary(null));
    manager
      ? dispatch(findDocumentsByFilters(defaultFilter))
      : dispatch(getDocuments());
  };
  const clearAfterErrorMessageModal = () => {
    //close all modals
    setShowPreviewModal(false);
    setShowUploadModal(false);

    setUploadEmMassa(false);

    dispatch(setPreviewBinary(null));
    manager
      ? dispatch(findDocumentsByFilters(defaultFilter))
      : dispatch(getDocuments());
  };

  const { SearchBar } = Search;

  return (
    <>
      <DocumentFilters
        manager={manager}
        handleExpirationDateEnd={setExpirationDateEnd}
        handleExpirationDateStart={setExpirationDateStart}
        expirationDateEnd={expirationDateEnd}
        expirationDateStart={expirationDateStart}
        uploadedDateStart={uploadedDateStart}
        uploadedDateEnd={uploadedDateEnd}
        setUploadedDateStart={setUploadedDateStart}
        setUploadedDateEnd={setUploadedDateEnd}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        operations={operations}
        selectedOperation={selectedOperation}
        setSelectedOperation={setSelectedOperation}
        docType={docType}
        setDoctype={setDoctype}
        numFuncionario={numFuncionario}
        nome={nome}
        setNumFuncionario={setNumFuncionario}
        setNome={setNome}
        fileName={fileName}
        uploadedBy={uploadedBy}
        setFileName={setFileName}
        setUploadedBy={setUploadedBy}
        collaborators={collaborators}
        setSeenStatus={setSeenStatus}
        seenStatus={seenStatus}
      />

      {Array.isArray(filteredDocs) && (
        <ToolkitProvider
          keyField="id"
          data={filteredDocs.reverse()} //order documents starting with the most recent ones
          columns={columns}
          search={{ searchFormatted: true }}
        >
          {(props) => (
            <div>
              <Form.Row>
                <Col
                  lg="12"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                  }}
                >
                  {activeKey === "0" && (
                    <div style={{ marginBottom: "0.5rem" }}>
                      {manager ? (
                        <SearchButtonFilters
                          expirationDateStart={expirationDateStart}
                          expirationDateEnd={expirationDateEnd}
                          uploadedDateStart={uploadedDateStart}
                          uploadedDateEnd={uploadedDateEnd}
                          selectedOperation={selectedOperation}
                          docType={docType}
                          numFuncionario={numFuncionario}
                          nome={nome}
                          fileName={fileName}
                          uploadedBy={uploadedBy}
                          collaborators={collaborators}
                          seenStatus={seenStatus}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <i
                              className="icon-search"
                              style={{ height: "18px", marginRight: "10px" }}
                            />
                            {t("general.search")}
                          </div>
                        </SearchButtonFilters>
                      ) : (
                        <SearchButton
                          docs={docs}
                          setFilteredDocs={setFilteredDocs}
                          expirationDateStart={expirationDateStart}
                          expirationDateEnd={expirationDateEnd}
                          selectedOperation={selectedOperation}
                          docType={docType}
                          seenStatus={seenStatus}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <i
                              className="icon-search"
                              style={{ height: "18px", marginRight: "10px" }}
                            />
                            {t("general.search")}
                          </div>
                        </SearchButton>
                      )}

                      <Button
                        className="card-outlined-button "
                        onClick={() => handleReset()}
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                      >
                        {t("portalrh.documentManagement.resetFilters")}
                      </Button>
                    </div>
                  )}
                  {filteredDocs.length > 0 && (
                    <div
                      className="search-wrapper"
                      style={{
                        flexGrow: activeKey === "0" && "1",
                        minWidth: "180px",
                      }}
                    >
                      <SearchBar
                        {...props.searchProps}
                        placeholder={t("portalrh.documentManagement.searchKey")}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      marginBottom: "0.5rem",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <ButtonUpload
                      text={t("portalrh.documentManagement.upload")}
                      onClick={(e) => handleOpenUploadModal(e, false)}
                    />
                    {manager && (
                      <div style={{ marginLeft: "10px" }}>
                        <ButtonUpload
                          text={t("portalrh.documentManagement.uploadEmMassa")}
                          onClick={(e) => handleOpenUploadModal(e, true)}
                        />
                      </div>
                    )}
                  </div>
                </Col>
              </Form.Row>
              <div
                className="custom-table custom-table-small"
                style={{ paddingTop: "20px" }}
              >
                <BootstrapTable
                  {...props.baseProps}
                  id="id"
                  bootstrap4
                  striped
                  pagination={paginationFactory(pagination)}
                  noDataIndication={
                    isDeletingDocument
                      ? ""
                      : t("portalrh.documentManagement.dataNotFound")
                  }
                  rowEvents={rowEvents}
                  pageSize={5}
                  classes="docTableManagement"
                  hover
                />
              </div>
              <Spinner
                spinning={
                  isLoadingDocuments ||
                  isDeletingDocument ||
                  isLoadingCollaborators ||
                  isLoadingOperations
                }
              />
            </div>
          )}
        </ToolkitProvider>
      )}
      <UploadModal
        manager={manager}
        collaborators={collaborators}
        isLoadingCollaborators={isLoadingCollaborators}
        showUploadModal={showUploadModal}
        handleCloseUploadModal={handleCloseUploadModal}
        operations={operations}
        isLoadingOperations={isLoadingOperations}
        uploadEmMassa={uploadEmMassa}
        emailTemplates={emailTemplates}
        user={user}
        udwUsers={udwUsers}
        findUDWUsers={findUDWUsers}
        legacyUsers={legacyUsers}
        isLoadingLegacyUsers={isLoadingLegacyUsers}
        sendEmail={sendEmail}
      />
      <PreviewModal
        showModal={showPreviewModal}
        closeModal={setShowPreviewModal}
        selectedDocument={selectedDocument}
        canUpdate={canUpdate(selectedDocument)}
      />
      <DisclaimerModal
        showModal={showDisclaimerModal}
        closeModal={setShowDisclaimerModal}
        handleUpdateDocument={handleUpdateDocument}
        selectedDocument={selectedDocument}
        state={disclaimerState}
      />
      <Modal show={showDeleteModal} onHide={() => handleCloseDeleteModal()}>
        <Modal.Header closeButton>
          <Modal.Title>{t("general.remove")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDocument &&
            `${t("portalrh.documentManagement.deleteDocument")}: ${
              selectedDocument.fileName
            } ?`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleCloseDeleteModal()}>
            {t("general.back")}
          </Button>
          <Button
            className="modal-submit-button"
            onClick={() => handleDelete()}
          >
            {t("general.remove")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Messages
        afterHideSuccess={clearAfterSuccessMessageModal}
        afterHideError={clearAfterErrorMessageModal}
      />
    </>
  );
};
export default withNamespaces()(DocumentTable);
