import _uniqueId from "lodash/uniqueId";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import arrowDown from "~/pages/Easypay/assets/img/icons/arrow-down-grey.png";
import arrowUp from "~/pages/Easypay/assets/img/icons/arrow-up-blue.png";
import "~/pages/Easypay/assets/scss/_dropdown2.scss";
import "../assets/scss/_inputDropdown.scss";

/*
    Props explanation:
        - t (used when needing to translate any 'general' words) **REQUIRED**
        - list (list of options the dropdown is going to use) **REQUIRED**
        - handleSubmit={(value) => handleSelectChange(value)} **REQUIRED**
        - placeholder **DEFAULT is "option"**
        - required={required} **DEFAULT is false**
        - disabled (used when user wants the input to be disabled)
*/

/* IMPORTANT */
/* When using list make sure you have it in the correct layout. Example:
  
        list = [
            {"label": "label here",
            "value": "value here"}
                ]

    If you have any option in the list that you want to make as default input 
    just add in the property "selected" :true to the list option
*/
const devLogConsole = require("~/utils/devLog");
const InputDropdown = (props) => {
  const {
    t,
    list = [{ label: t("general.dataNotFound") }],
    required = false,
    handleSubmit = false,
    placeholder = "Selecionar",
    disabled,
    name,
    position,
    size,
    initialSelected,
    handleInputSubmit,
    hoursList,
    currentIndex,
    itemIndex,
    activityIndex,
    optionIndex,
    workplanIndex,
    dropdownDisabled
  } = props;

  const { workplans } = useSelector((state) => state.budgetManagementReducer);
  function repeat() {
    var arr = [];
    for (var i = 0; i < list.length; i++) {
      arr.push("");
    }
    return arr;
  }

  const emptyArray = repeat();
  const [selectedValue, setSelectedValue] = useState("");
  const [hoursArray, setHoursArray] = useState([]);
  const [label, setLabel] = useState("");
  const [displayList, setDisplayList] = useState(list);
  const [showDropdown, setShowDropdown] = useState(false);
  const [textValue, setTextValue] = useState("");

  //Randomly creates an id for dropdown and input
  const id = useRef(_uniqueId(""));

  useEffect(() => {
    devLogConsole(hoursList)
    var temp = [];
    for (var i = 0; i < hoursList.length; i++) {
      temp.push(hoursList[i].hoursValue);
    }
    setHoursArray(temp);

    if (
      workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
        .opcoes[optionIndex].horas[currentIndex].horasPropostas
    ) {
      setLabel(
        workplans[workplanIndex].items[itemIndex].atividades[activityIndex]
          .opcoes[optionIndex].horas[currentIndex].horasPropostas
      );
    }
  }, [
    hoursArray.length === 0,
    workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[
      optionIndex
    ].horas[currentIndex].horasPropostas,
    workplans[workplanIndex].items[itemIndex].atividades[activityIndex].opcoes[
      optionIndex
    ].horas[currentIndex].horasPropostasArray
  ]);

  /*
    Function that runs when clicking any option
  */
  const handleSelect = (index) => {
    handleInputSubmit({ hoursValue: hoursArray[index], i: index });
  };

  /*
    Toggles dropdown list everytime user clicks in input
    If first element is selected liftworld-shows it highlighted
  */
  const toggleList = () => {
    document
      .getElementById(`dropDown-${id.current}`)
      .classList.toggle("liftworld-show");

    if (
      document
        .getElementById(`dropDown-${id.current}`)
        .className.includes("liftworld-show")
    ) {
      setDisplayList(list);
    } else {
    }
  };

  const hideDropDown = (desiredId) => {
    desiredId === undefined
      ? document
          .getElementById(`dropDown-${id.current}`)
          .classList.remove("liftworld-show")
      : document
          .getElementById(`dropDown-${desiredId}`)
          .classList.remove("liftworld-show");
  };

  /*<
     Function that runs whenever the input get out of focus
  */
  const outOfFocus = (e) => {
    hideDropDown(parseInt(e.target.id.match(/\d+/)[0]));
  };

  function handleMainInput() {
    handleSubmit(label);
  }

  const handleUserInputChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setHoursArray({ [name]: newValue });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleMainInput();
    }
  }

  return (
    <>
      <Form.Group controlId={`formText-field`} style={{ margin: "0px" }}>
        <div
          className={"liftworld-input-hours-dropdown-wrapper"}
          onBlur={(e) => {
            setShowDropdown(false);
          }}
        >
          <div
            className={`liftworld-input-dropdown-field-wrapper ${position} ${size}`}
          >
            <input
              disabled={disabled}
              className={`liftworld-input-dropdown-field`}
              id={`formInput-${id.current}`}
              value={label}
              required={required}
              onBlur={() => {
                handleMainInput();
              }}
              onChange={(e) => {
                setLabel(e.target.value);
                setTextValue(e.target.value);
              }}
              name={name}
              onKeyDown={handleKeyDown}
            />
            <div className="liftworld-input-dropdown-icon-wrapper">
              <img
                className="liftworld-input-dropdown-icon"
                src={showDropdown ? arrowUp : arrowDown}
                alt="search-icon"
                onBlur={(e) => {
                  outOfFocus(e);
                  setShowDropdown(false);
                }}
                onMouseDown={() => {
                  toggleList();
                  setShowDropdown(!showDropdown);
                }}
              />
            </div>
          </div>

          <div id={`dropDown-${id.current}`} className="liftworld-dropdown">
            {displayList.map((option, i) => {
              return option?.label === t("general.dataNotFound") ? (
                <option id="liftworld-dropdown-no-result" key={i}>
                  {t("general.dataNotFound")}
                </option>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: "3px",
                  }}
                >
                  <option
                    id={`selected-${id.current}`}
                    key={i}
                    value={option.value}
                    className="liftworld-dropdown-option"
                  >
                    {option.label}
                  </option>
                  <input
                    type="text"
                    autoComplete="off"
                    name={i}
                    value={hoursArray[i]}
                    onBlur={() => handleSelect(i)}
                    onChange={(e) => handleUserInputChange(e)}
                    className="liftworld-hours-dropdown-input-wrapper"
                    disabled = {dropdownDisabled}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <input type="hidden" name={name} value={selectedValue} />
        <Form.Control.Feedback type="invalid">
          {`O campo field é de preenchimento obrigatório`}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default withNamespaces()(InputDropdown);
