import {
  SET_DOCUMENT,
  SET_CONFIRM_PROCESS_MESSAGE,
  SET_FOCUS_ELEMENT,
} from "./actionTypes";

const initialState = {
  currentDocument: null,

  confirmMessage: null,

  focusElement: null,
};

export function iflowReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DOCUMENT:
      return { ...state, currentDocument: action.data };
    case SET_CONFIRM_PROCESS_MESSAGE:
      return { ...state, confirmMessage: action.data };
    case SET_FOCUS_ELEMENT:
      return { ...state, focusElement: action.data };

    default:
      return state;
  }
}
