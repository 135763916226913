import {
  FIND_USAGE_REPORT,
  SET_USAGE_REPORT
} from './actionTypes';

const initialState = {
  usageReport: [],
  isLoadingUsageReport: false
}

export function reportReducer(state = initialState, action) {

  switch (action.type) {
    case FIND_USAGE_REPORT:
      return { ...state, isLoadingUsageReport: true };
    case SET_USAGE_REPORT:
      return { ...state, usageReport: action.data, isLoadingUsageReport: false };
    default:
      return state
  }
}