import { takeLatest } from "redux-saga/effects";

import {
  GET_CRC_DOCUMENT,
  GET_IES_DOCUMENT,
  POST_INQUIRY,
  GET_INQUIRY,
  SEND_EMAIL,
  SEND_EMAIL_ENDPOINT,
  SEND_SMS,
  SEND_SMS_OTP,
  CREATE_ALICE_USER,
  GET_ALICE_REPORT,
  CREATE_MERCHANT,
  GET_MERCHANT,
  ADD_DOCUMENT_TO_MERCHANT,
  GET_TINK_ID,
  GET_TINK_REPORT,
  GET_TINK_TOKEN,
  GET_SIGNED_PDF,
  GET_CARD_INFORMATION,
  GET_SAML,
  GET_ATRIBUTES,
  GET_ALL_USERS,
  GET_USER,
  UPDATE_USER,
  GET_ALICE_TOKEN,
  PROCESS_ALICE_REPORT,
  GET_DOCUMENT_PDA_INFO,
  GENERATE_ALICE_LINK,
  GET_ALICE_PROCESS,
  CREATE_DOCUMENT_CORESYSTEM,
  ADD_DOCUMENT_CORESYSTEM,
  GET_DOCUMENT_CORESYSTEM,
  GET_ALL_DOCUMENTS,
  GET_VERSION_SIGNPDF,
  UPDATE_DOCUMENT,
  POST_MINDEE
} from "../ducks/onboarding/actionTypes";

import {
  getCrcDocument,
  getIesDocument,
  postInquiry,
  getInquiry,
  sendEmail,
  sendEmailEndPoint,
  sendSms,
  sendSmsOTP,
  createAliceUser,
  getAliceReport,
  createMerchant,
  getMerchant,
  addDocumentToMerchant,
  getTinkId,
  getTinkReport,
  getTinkToken,
  getSignedPdfs,
  getCardInformation,
  getSaml,
  getAtributes,
  getAllUsers,
  getAllDocuments,
  getUser,
  updateUser,
  updateDocument,
  getAliceToken,
  processAliceReport,
  getDocumentByPid,
  generateAliceLink,
  getAliceProcess,
  createDocumentCoresystem,
  addDocumentCoresystem,
  getDocumentCoresystem,
  getVersionSignPdf,
  addMindee
} from "../ducks/onboarding/sagas";

export const onboardingSagas = [
  takeLatest(SEND_EMAIL, sendEmail),
  takeLatest(SEND_EMAIL_ENDPOINT, sendEmailEndPoint),
  takeLatest(SEND_SMS_OTP, sendSmsOTP),
  takeLatest(SEND_SMS, sendSms),
  takeLatest(POST_INQUIRY, postInquiry),
  takeLatest(GET_INQUIRY, getInquiry),
  takeLatest(GET_CRC_DOCUMENT, getCrcDocument),
  takeLatest(GET_IES_DOCUMENT, getIesDocument),
  takeLatest(CREATE_ALICE_USER, createAliceUser),
  takeLatest(GET_ALICE_REPORT, getAliceReport),
  takeLatest(CREATE_MERCHANT, createMerchant),
  takeLatest(GET_MERCHANT, getMerchant),
  takeLatest(ADD_DOCUMENT_TO_MERCHANT, addDocumentToMerchant),
  takeLatest(GET_TINK_ID, getTinkId),
  takeLatest(GET_TINK_REPORT, getTinkReport),
  takeLatest(POST_MINDEE, addMindee),
  takeLatest(GET_TINK_TOKEN, getTinkToken),
  takeLatest(GET_SIGNED_PDF, getSignedPdfs),
  takeLatest(GET_CARD_INFORMATION, getCardInformation),
  takeLatest(GET_SAML, getSaml),
  takeLatest(GET_ATRIBUTES, getAtributes),
  takeLatest(GET_ALL_USERS, getAllUsers),
  takeLatest(GET_ALL_DOCUMENTS, getAllDocuments),
  takeLatest(GET_USER, getUser),
  takeLatest(UPDATE_USER, updateUser),
  takeLatest(UPDATE_DOCUMENT, updateDocument),
  takeLatest(GET_ALICE_TOKEN, getAliceToken),
  takeLatest(PROCESS_ALICE_REPORT, processAliceReport),
  takeLatest(GET_DOCUMENT_PDA_INFO, getDocumentByPid),
  takeLatest(GENERATE_ALICE_LINK, generateAliceLink),
  takeLatest(GET_ALICE_PROCESS, getAliceProcess),
  takeLatest(CREATE_DOCUMENT_CORESYSTEM, createDocumentCoresystem),
  takeLatest(ADD_DOCUMENT_CORESYSTEM, addDocumentCoresystem),
  takeLatest(GET_DOCUMENT_CORESYSTEM, getDocumentCoresystem),
  takeLatest(GET_VERSION_SIGNPDF, getVersionSignPdf),
];
