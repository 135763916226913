import React, { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "~/components/Dropdown";
import { withNamespaces } from "react-i18next";
import SearchableDropDown from "~/components/SearchableDropDown";
import DateTimePicker from "react-datetime-picker";
import ButtonsUpdate from "./ButtonsUpdate";
import Messages from "~/components/Messages";
import {
  getPersonalData,
  getPersonalDataDropdown,
  updatePersonalData,
} from "~/store/ducks/collaboratorPersonalData/actionTypes";
import UploadDocument from "../UploadDocuments";
import "../../RhCollaborator.scss";

const RhPersonalData = ({ rowCollaborator, t }) => {
  const [fullName, setFullName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [genderList, setGenderList] = useState([]);
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [parishList, setParishList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [citizenCard, setCitizenCard] = useState("");
  const [emissionDate, setEmissionDate] = useState("");
  const [expiration, setExpirationDate] = useState("");
  const [archive, setArchive] = useState("");
  const [passaport, setPassaport] = useState("");
  const [passaportEmissionDate, setPassaportEmissionDate] = useState("");
  const [passaportExpirationDate, setPassaportExpirationDate] = useState("");
  const [passaportArchive, setPassaportArchive] = useState("");
  const [taxIdNumber, setTaxIdNumber] = useState("");
  const [apportionmentList, setApportionmentList] = useState([]);
  const [socialSecurity, setSocialSecuryty] = useState("");
  const [socialSecurityDate, setSocialSecurytyDate] = useState("");
  const [genderSelected, setGenderSelected] = useState("");
  const [maritalStatusSelected, setMaritalStatusSelected] = useState("");
  const [districtSelected, setDistrictSelected] = useState("");
  const [citySelected, setCitySelected] = useState("");
  const [parishSelected, setParishSelected] = useState("");
  const [nationality1Selected, setNationality1Selected] = useState("");
  const [nationality2Selected, setNationality2Selected] = useState("");
  const [naturalnessSelected, setNaturalnessSelected] = useState("");
  const [apportionmentSelected, setApportionmentSelected] = useState("");
  const [pssNum, setPssNum] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [personalDataPage, setPersonalDataPage] = useState(false);
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
  const [validated, setValidated] = useState(false);
  const [validatedDropdowns, setValidatedDropdowns] = useState(false);

  const {
    personalData,
    personalDataDropdown,
    isLoadingUpdatePersonalData,
    personalDataError,
  } = useSelector((state) => state.personalReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    if (rowCollaborator && Object.keys(rowCollaborator).length > 0) {
      dispatch(
        getPersonalData(
          rowCollaborator.funcionarioNum,
          rowCollaborator.codEntidade
        )
      );
      dispatch(getPersonalDataDropdown());
      setPersonalDataPage(true);
    }
  }, [rowCollaborator, isLoadingUpdatePersonalData]);

  const handleName = (e) => {
    setFullName(e.target.value);
  };

  const handleCitizenCard = (e) => {
    setCitizenCard(e.target.value);
  };

  const handleArchive = (e) => {
    setArchive(e.target.value);
  };

  const handleEmissionDate = (e) => {
    setEmissionDate(e);
  };

  const handleExpirationDate = (e) => {
    setExpirationDate(e);
  };

  const handlePassport = (e) => {
    setPassaport(e.target.value);
  };

  const handlePassportArchive = (e) => {
    setPassaportArchive(e.target.value);
  };

  const handlePassportEmission = (e) => {
    setPassaportEmissionDate(e);
  };

  const handlePassportExpiration = (e) => {
    setPassaportExpirationDate(e);
  };

  const handleTaxIdNumber = (e) => {
    setTaxIdNumber(e.target.value);
  };

  const handleSocialSecurity = (e) => {
    setSocialSecuryty(e.target.value);
  };

  const handleSocialSecurityDate = (e) => {
    setSocialSecurytyDate(e);
  };

  const handleBirthday = (e) => {
    setBirthDate(e);
  };

  const handleGender = (e) => {
    setGenderSelected(e);
  };

  const handleMaritalStatus = (e) => {
    setMaritalStatusSelected(e);
  };

  const handleDistrict = (e) => {
    setDistrictSelected(e);
  };

  const handleCity = (e) => {
    setCitySelected(e);
  };

  const handleParish = (e) => {
    setParishSelected(e);
  };

  const handleNationality1 = (e) => {
    setNationality1Selected(e);
  };

  const handleNationality2 = (e) => {
    setNationality2Selected(e);
  };

  const handleNaturalness = (e) => {
    setNaturalnessSelected(e);
  };

  const hadleApportinmonet = (e) => {
    setApportionmentSelected(e);
  };

  //ButtonsUpdate state
  const [isClickedBtnUpdate, setIsClickedBtnUpdate] = useState(false);

  const validateDropdowns = (isSelected, required) => {
    if (validatedDropdowns && required) {
      if (
        isSelected === "" ||
        isSelected === undefined ||
        isSelected === 0 ||
        isSelected === "0"
      ) {
        return "dropdown-margin dropdown-border-invalid";
      } else {
        return "dropdown-margin dropdown-border-valid";
      }
    }
    if (validatedDropdowns && !required) {
      return "dropdown-margin dropdown-border-valid";
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      setValidatedDropdowns(true);
    } else {
      updateData(false);
      setIsClickedBtnUpdate(!isClickedBtnUpdate);
      OnTakingBolean(isClicked);
      handleUpdateButtonClicked(isClicked);
      // setValidatedDropdowns(true);
    }
  };

  useEffect(() => {
    if (personalDataDropdown && Object.keys(personalDataDropdown).length > 0) {
      setGenderList(personalDataDropdown.collaboratorCode);
      setMaritalStatusList(personalDataDropdown.maritalStatus);
      setDistrictList(personalDataDropdown.districtCode);
      personalDataDropdown.nationalityCode.push({ label: "...", value: 0 });
      setNationalityList(personalDataDropdown.nationalityCode);
      setApportionmentList(personalDataDropdown.apportionmentCode);
    }
    if (personalData?.length > 0) {
      setDistrictSelected(personalData[0].distritCode);

      setGenderSelected(personalData[0].gender);
      setMaritalStatusSelected(personalData[0].maritalStatus);

      setPssNum(personalData[0].pssNum);
      setNationality1Selected(personalData[0].firstNationality);

      setNationality2Selected(personalData[0].secondNationality);

      setApportionmentSelected(personalData[0].apportionment);
      setNaturalnessSelected(personalData[0].naturalness);

      setFullName(personalData[0].name);
      setCitizenCard(personalData[0].citizenCard);
      setArchive(personalData[0].archive);
      setPassaport(personalData[0].passaport);
      setPassaportArchive(personalData[0].passaportArchive);
      setTaxIdNumber(personalData[0].taxIdNumber);
      setSocialSecuryty(personalData[0].socialSecurity);

      const checkDate = (date, set) => {
        if (date !== null && date !== undefined) {
          return set(new Date(date));
        }
      };

      checkDate(personalData[0].birthDate, setBirthDate);
      checkDate(personalData[0].emissionDate, setEmissionDate);
      checkDate(personalData[0].expirationDate, setExpirationDate);
      checkDate(personalData[0].passEmissionDate, setPassaportEmissionDate);
      checkDate(personalData[0].passExpirationDate, setPassaportExpirationDate);
      checkDate(personalData[0].socialSecurityDate, setSocialSecurytyDate);
    } else {
      setDistrictSelected("");
      setGenderSelected("");
      setMaritalStatusSelected("");
      setPssNum("");
      setNationality1Selected("");
      setNationality2Selected("");
      setApportionmentSelected("");
      setNaturalnessSelected("");
      setFullName("");
      setCitizenCard("");
      setArchive("");
      setPassaport("");
      setPassaportArchive("");
      setTaxIdNumber("");
      setSocialSecuryty("");
    }
  }, [personalData, personalDataDropdown, personalDataError, rowCollaborator]);

  useEffect(() => {
    if (personalDataDropdown && Object.keys(personalDataDropdown).length > 0) {
      if (districtSelected) {
        //City's List Filtered
        let cityListFiltered = personalDataDropdown.cityCode?.filter(
          (e) => e.code === districtSelected
        );

        setCityList(cityListFiltered);

        if (personalData?.length > 0 && cityListFiltered?.length > 0) {
          let cityFiltered = personalDataDropdown.cityCode?.filter(
            (e) =>
              e.code == personalData[0].distritCode &&
              e.value == personalData[0].cityCode
          );
          setCitySelected(cityFiltered[0]?.value);
        }
      }
    }
  }, [districtSelected, personalData, personalDataDropdown]);

  useEffect(() => {
    if (cityList.length > 0) {
      //Parish's List Filtered
      if (citySelected && districtSelected) {
        let parsihListFiltered = personalDataDropdown.parishCode?.filter(
          (e) => e.codeDST == districtSelected && e.codeCLH == citySelected
        );
        setParishList(parsihListFiltered);

        //Parish Value Selected
        if (personalData?.length > 0 && parsihListFiltered?.length > 0) {
          let parsihSelectedFiltered = personalDataDropdown.parishCode?.filter(
            (e) =>
              e.codeDST == personalData[0].distritCode &&
              e.codeCLH == personalData[0].cityCode &&
              e.value == personalData[0].parishCode
          );

          setParishSelected(parsihSelectedFiltered[0]?.value);
        }
      }
    }
  }, [
    cityList,
    citySelected,
    districtSelected,
    personalData,
    personalDataDropdown,
  ]);

  const OnTakingBolean = (data) => {
    if (!data) {
      setIsClicked(true);
    }
    if (data) {
      setIsClicked(false);
    }
  };

  let readOnly = {};
  if (!isClicked) {
    readOnly["readOnly"] = "readOnly";
  }

  let disabled = {};
  if (!isClicked) {
    disabled["disabled"] = "true";
  }
  const personalDataObject = {
    id: rowCollaborator.funcionarioNum,
    companyId: rowCollaborator.codEntidade,
    name: fullName,
    birthDate: birthDate,
    gender: genderSelected,
    maritalStatus: maritalStatusSelected,
    distritCode: districtSelected,
    cityCode: citySelected,
    parishCode: parishSelected,
    firstNationality: nationality1Selected,
    secondNationality: nationality2Selected,
    naturalness: naturalnessSelected,
    citizenCard: citizenCard,
    emissionDate: emissionDate,
    expirationDate: expiration,
    archive: archive,
    passaport: passaport,
    passEmissionDate: passaportEmissionDate,
    passExpirationDate: passaportExpirationDate,
    passaportArchive: passaportArchive,
    taxIdNumber: taxIdNumber,
    apportionment: apportionmentSelected,
    socialSecurity: socialSecurity,
    socialSecurityDate: socialSecurityDate,
    pssNum: pssNum,
  };

  const updateData = (bollean) => {
    setPersonalDataPage(bollean);
    dispatch(updatePersonalData(personalDataObject));
  };

  const handleUpdateButtonClicked = (bollean) => {
    if (!bollean) {
      setUpdateButtonClicked(true);
    }
    if (bollean) {
      setUpdateButtonClicked(false);
    }
  };

  // const getValidation = (bollean) => {
  //   if (bollean) {
  //     setValidated(true);
  //   }
  // };

  return (
    <div className="collaboratoRH__view">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <ButtonsUpdate
          OnTakingBolean={OnTakingBolean}
          // updateData={updateData}
          // personalDataPage={personalDataPage}
          // getValidation={getValidation}
          handleUpdateButtonClicked={handleUpdateButtonClicked}
          isClicked={isClickedBtnUpdate}
          setIsClicked={setIsClickedBtnUpdate}
        />
        <>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="8" md="8">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.personalData.name`)}
                </Form.Label>
                {isClicked ? (
                  <Form.Control
                    className={"collaboratorRH__FormControl2"}
                    type="text"
                    value={fullName || ""}
                    onChange={handleName}
                    required
                  />
                ) : (
                  <Form.Control
                    className={"collaboratorRH__FormControl2"}
                    type="text"
                    value={fullName || ""}
                    onChange={() => {}}
                    disabled
                  />
                )}

                <Form.Control.Feedback type="invalid">
                  {`O campo ${`portalrh.personalData.name`} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="4">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.personalData.birthDate`)}
                </Form.Label>
                <DateTimePicker
                  value={birthDate}
                  onChange={handleBirthday}
                  locale="pt-PT"
                  format="dd/MM/yyyy"
                  className={
                    isClicked && validatedDropdowns
                      ? birthDate == null
                        ? "form-control is-invalid"
                        : "form-control is-valid"
                      : "form-control"
                  }
                  disabled={isClicked ? false : true}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">
                  {`O campo ${`portalrh.personalData.birthDate`} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xl="4" md="4">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.personalData.Gender`)}
                </Form.Label>
                <Dropdown
                  className={
                    isClicked
                      ? "collaboratoRH__Dropdown2" &&
                        validateDropdowns(genderSelected, true)
                      : "collaboratoRH__Dropdown"
                  }
                  list={genderList}
                  handleSubmit={handleGender}
                  defaultValue={genderSelected}
                  disabled={isClicked ? false : true}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {`O campo Gender é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xl="4" md="4">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.personalData.MaritalStatus`)}
                </Form.Label>
                <Dropdown
                  className={
                    isClicked
                      ? "collaboratoRH__Dropdown2" &&
                        validateDropdowns(maritalStatusSelected, true)
                      : "collaboratoRH__Dropdown"
                  }
                  list={maritalStatusList}
                  handleSubmit={handleMaritalStatus}
                  defaultValue={maritalStatusSelected}
                  disabled={isClicked ? false : true}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">
                  {`O campo ${`portalrh.personalData.MaritalStatus`} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="4" style={{ marginBottom: "5px" }}>
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.district`)}
              </Form.Label>
              <SearchableDropDown
                className="searchable-input-search-field-wrapper is-valid"
                list={districtList}
                handleSubmit={handleDistrict}
                defaultValue={districtSelected}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4" style={{ marginBottom: "5px" }}>
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.City`)}
              </Form.Label>
              <SearchableDropDown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(citySelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={cityList}
                handleSubmit={handleCity}
                defaultValue={citySelected}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4" style={{ marginBottom: "5px" }}>
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Parish`)}
              </Form.Label>
              <SearchableDropDown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(parishSelected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={parishList}
                handleSubmit={handleParish}
                defaultValue={parishSelected}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Nationality1`)}
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(nationality1Selected, true)
                    : "collaboratoRH__Dropdown"
                }
                list={nationalityList}
                handleSubmit={handleNationality1}
                defaultValue={nationality1Selected}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Nationality2`)}
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(nationality2Selected, false)
                    : "collaboratoRH__Dropdown"
                }
                list={nationalityList}
                handleSubmit={handleNationality2}
                defaultValue={nationality2Selected || ""}
                disabled={isClicked ? false : true}
                required
              />
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Naturalness`)}
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(naturalnessSelected)
                    : "collaboratoRH__Dropdown"
                }
                list={nationalityList}
                handleSubmit={handleNaturalness}
                defaultValue={naturalnessSelected}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="3">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.personalData.BI/CitizenCard`)}
                </Form.Label>
                <Form.Control
                  className={
                    isClicked
                      ? "collaboratorRH__FormControl2"
                      : "collaboratorRH__FormControl"
                  }
                  value={citizenCard || ""}
                  onChange={handleCitizenCard}
                  disabled={isClicked ? false : true}
                  type="number"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {`O campo ${`portalrh.personalData.BI/CitizenCard`} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.IssueDate`)}
              </Form.Label>
              <DateTimePicker
                value={emissionDate}
                onChange={handleEmissionDate}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className={
                  isClicked && validatedDropdowns
                    ? emissionDate == null
                      ? "form-control is-invalid"
                      : "form-control is-valid"
                    : "form-control"
                }
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.ExpirationDate`)}
              </Form.Label>
              <DateTimePicker
                value={expiration}
                onChange={handleExpirationDate}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className={
                  isClicked && validatedDropdowns
                    ? expiration == null
                      ? "form-control is-invalid"
                      : "form-control is-valid"
                    : "form-control"
                }
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="3">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Archive`)}
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={archive || ""}
                onChange={handleArchive}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="3">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Passport`)}
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={passaport || ""}
                onChange={handlePassport}
                disabled={isClicked ? false : true}
              />
            </Col>

            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels__before">
                {t(`portalrh.personalData.IssueDate`)}
              </Form.Label>
              <Form.Label className="rhcollaborator__labels__after">
                {t(`portalrh.personalData.ExpirationDate`)}
              </Form.Label>
              <DateTimePicker
                value={passaportEmissionDate}
                onChange={handlePassportEmission}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className={
                  isClicked && validatedDropdowns
                    ? "form-control is-valid"
                    : "form-control"
                }
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels__before">
                {t(`portalrh.personalData.ExpirationDate`)}
              </Form.Label>
              <Form.Label className="rhcollaborator__labels__after">
                {t(`portalrh.personalData.ExpirationDate`)}
              </Form.Label>
              <DateTimePicker
                value={passaportExpirationDate}
                onChange={handlePassportExpiration}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className={
                  isClicked && validatedDropdowns
                    ? "form-control is-valid"
                    : "form-control"
                }
                di
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="3">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Archive`)}
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={passaportArchive || ""}
                onChange={handlePassportArchive}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="3">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="rhcollaborator__labels">
                  {t(`portalrh.personalData.TaxIDNumber`)}
                </Form.Label>
                <Form.Control
                  className={
                    isClicked
                      ? "collaboratorRH__FormControl2"
                      : "collaboratorRH__FormControl"
                  }
                  value={taxIdNumber || ""}
                  onChange={handleTaxIdNumber}
                  disabled={isClicked ? false : true}
                  type="number"
                  maxLength="9"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {`O campo ${`portalrh.personalData.TaxIDNumber`} é de preenchimento obrigatório`}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels__before">
                {t(`portalrh.personalData.SocialSecurity`)}
              </Form.Label>
              <Form.Label className="rhcollaborator__labels__after">
                {t(`portalrh.personalData.SocialSecurity`)}
              </Form.Label>
              <Form.Control
                className={
                  isClicked
                    ? "collaboratorRH__FormControl2"
                    : "collaboratorRH__FormControl"
                }
                value={socialSecurity || ""}
                onChange={handleSocialSecurity}
                disabled={isClicked ? false : true}
              />
            </Col>
            <Col xl="4" md="4">
              <Form.Label className="rhcollaborator__labels">
                {" "}
                {t(`portalrh.personalData.date`)}
              </Form.Label>
              <DateTimePicker
                value={socialSecurityDate}
                onChange={handleSocialSecurityDate}
                locale="pt-PT"
                format="dd/MM/yyyy"
                className={
                  isClicked && validatedDropdowns
                    ? "form-control is-valid"
                    : "form-control"
                }
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div
            className={isClicked ? "collaboratoRH__org2" : "collaboratoRH__org"}
          >
            <Col xl="4" md="3">
              <Form.Label className="rhcollaborator__labels">
                {t(`portalrh.personalData.Taxoffice`)}
              </Form.Label>
              <Dropdown
                className={
                  isClicked
                    ? "collaboratoRH__Dropdown2" &&
                      validateDropdowns(apportionmentSelected, false)
                    : "collaboratoRH__Dropdown"
                }
                list={apportionmentList}
                handleSubmit={hadleApportinmonet}
                defaultValue={apportionmentSelected}
                disabled={isClicked ? false : true}
              />
            </Col>
          </div>
          <div style={{ paddingTop: "10px" }}>
            <UploadDocument
              isClicked={isClicked}
              uploadFile={personalDataPage}
              rowCollaborator={rowCollaborator}
              id={1}
            />
          </div>
        </>
      </Form>
      <Messages />
    </div>
  );
};
export default withNamespaces()(RhPersonalData);
