import { takeLatest } from "redux-saga/effects";
import {
  SAVE_USER_DATA,
  GET_USER_DATA,
  PUT_USER_ID,
  DELETE_USER_DATA,
  GET_COLLABORATOR_DATA,
} from "../ducks/simulator/actionTypes";
import {
  saveUserData,
  getUserData,
  upDateUserData,
  deleteUserData,
  getCollaboratorData,
} from "../ducks/simulator/sagas";

export const simulatorSagas = [
  takeLatest(SAVE_USER_DATA, saveUserData),
  takeLatest(GET_USER_DATA, getUserData),
  takeLatest(PUT_USER_ID, upDateUserData),
  takeLatest(DELETE_USER_DATA, deleteUserData),
  takeLatest(GET_COLLABORATOR_DATA, getCollaboratorData),
];
