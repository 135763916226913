import React, { useEffect, useState } from "react";
import "../assets/scss/_projectDescriptionSection.scss";
import "../assets/scss/_contentContainer.scss";
import Textbox from "./Textbox";
import { useSelector } from "react-redux";
import TableTextbox from "./TableTextbox";
import TableInput from "./TableInput";
import uuid from "uuid/v1";
const devLogConsole = require("~/utils/devLog");
const ProjectDescriptionSection = ({ workplanIndex }) => {
  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 100,
      text: text,
    };
  }

  const {
    paymentTypes,
    departments,
    factoring,
    collaborators,
    expenses,
    projectTypes,
    providerTypes,
    expenditureTypes,
    workplans,
    triggerLoading,
  } = useSelector((state) => state.budgetManagementReducer);

  var totalOpt1CostValue = 0;
  var totalOpt2CostValue = 0;
  var totalOpt3CostValue = 0;
  var totalOptSelectedCostValue = 0;
  const [startDate, setStartDate] = useState(new Date());

  var totalOpt1SaleValue = 0;
  var totalOpt2SaleValue = 0;
  var totalOpt3SaleValue = 0;
  var totalOptSelectedSaleValue = 0;

  const [option1CostValue, setOption1CostValue] = useState(0);
  const [option1SaleValue, setOption1SaleValue] = useState(0);
  const [option1FinalMargin, setOption1FinalMargin] = useState(0);

  const [option2CostValue, setOption2CostValue] = useState(0);
  const [option2SaleValue, setOption2SaleValue] = useState(0);
  const [option2FinalMargin, setOption2FinalMargin] = useState(0);

  const [option3CostValue, setOption3CostValue] = useState(0);
  const [option3SaleValue, setOption3SaleValue] = useState(0);
  const [option3FinalMargin, setOption3FinalMargin] = useState(0);

  const [optionSelectedCostValue, setOptionSelectedCostValue] = useState(0);
  const [optionSelectedSaleValue, setOptionSelectedSaleValue] = useState(0);
  const [optionSelectedFinalMargin, setOptionSelectedFinalMargin] = useState(0);

  const [showOption2, setShowOption2] = useState(false);
  const [showOption3, setShowOption3] = useState(false);

  const [monthsArray, setMonthsArray] = useState([]);
  const [factureArrayState, setFactureArrayState] = useState([]);
  const [costArrayState, setCostArrayState] = useState([]);
  const [tableTitle, setTableTitle] = useState({});
  const [totalFacture, setTotalFacture] = useState({});
  const [totalCost, setTotalCost] = useState({});
  const [income, setIncome] = useState({});
  const [percIncome, setPercIncome] = useState({});
  const [costHours, setCostHours] = useState({});
  const [hours, setHours] = useState({});
  const [margin, setMargin] = useState({});
  const [percMargin, setPercMargin] = useState({});
  const [marginOk, setMarginOk] = useState({});

  const [factureArrayStateVerify, setFactureArrayStateVerify] = useState([]);
  const [costArrayStateVerify, setCostArrayStateVerify] = useState([]);

  const [totalFactureVerify, setTotalFactureVerify] = useState({});
  const [totalCostVerify, setTotalCostVerify] = useState({});

  const [incomeVerify, setIncomeVerify] = useState({});
  const [percIncomeVerify, setPercIncomeVerify] = useState({});

  const [marginVerify, setMarginVerify] = useState({});
  const [percMarginVerify, setPercMarginVerify] = useState({});

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".");
    const finalString = newArray[0].replace(",", ".") + "," + newArray[1];
    return finalString;
  }

  var factureArray = [];
  var factureArrayVerify = [];
  var costArray = [];
  var costArrayVerify = [];

  var costHoursElement = {};
  var hoursElement = {};

  var gpi = 1.0;
  var fse = 1.0;
  var gpd = 1.0;
  var pc = 1.0;
  var pv = 1.0;

  const months = [
    "jan",
    "feb",
    "mar",
    "abr",
    "mai",
    "jun",
    "jul",
    "ago",
    "set",
    "otu",
    "nov",
    "dec",
  ];
  function getPosition(index, list) {
    if (index === 0) return "start";
    if (index === Object.keys(tableTitle).length - 1) return "end";
  }

  function getNameFromId() {
    const projType = projectTypes.find(
      (x) => x.idTipoProjeto === parseInt(workplans[workplanIndex].tipoProjeto)
    );
    if (projType != null) return projType?.nome;
  }

  function getPositionBottom(index, list) {
    if (index === 0) return "start-end";
    if (index === Object.keys(list).length - 1) return "end";
  }

  const monthsBtwnDates = (startDate, endDate) => {
    if (startDate === null || endDate === null) return;
    var newStart = new Date(startDate);
    var newEnd = new Date(endDate);
    newStart = new Date(
      newStart.getTime() - newStart.getTimezoneOffset() * +60000
    );
    newEnd = new Date(newEnd.getTime() - newEnd.getTimezoneOffset() * +60000);
    var startStr = newStart.toISOString();
    var endStr = newEnd.toISOString();

    startStr = startStr.slice(0, 10);
    endStr = endStr.slice(0, 10);
    var start = startStr.split("-");
    var end = endStr.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]);
      var startMon = i === startYear ? parseInt(start[1]) : 0;

      for (var j = startMon; j <= endMonth; j++) {
        var month = j;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }

    return dates;
  };

  useEffect(() => {
    for (var i = 0; i < workplans[workplanIndex]?.items?.length; i++) {
      for (
        var k = 0;
        k < workplans[workplanIndex].items[i].atividades.length;
        k++
      ) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades[k].opcoes.length;
          j++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[k].opcoes[j].selected
          ) {
            totalOptSelectedCostValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorCustoOpt
            );
            totalOptSelectedSaleValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorVendaOpt
            );
          }
          if (
            workplans[workplanIndex].items[i].atividades[k].opcoes.length < 2
          ) {
            totalOpt2CostValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[0]
                .valorCustoOpt
            );
            totalOpt2SaleValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[0]
                .valorVendaOpt
            );
          }
          if (
            workplans[workplanIndex].items[i].atividades[k].opcoes.length < 3 &&
            j === 0
          ) {
            totalOpt3CostValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[0]
                .valorCustoOpt
            );
            totalOpt3SaleValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[0]
                .valorVendaOpt
            );
          }
          if (j === 0) {
            totalOpt1CostValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorCustoOpt
            );
            totalOpt1SaleValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorVendaOpt
            );
          } else if (j === 1) {
            setShowOption2(true);
            totalOpt2CostValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorCustoOpt
            );
            totalOpt2SaleValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorVendaOpt
            );
          } else if (j === 2) {
            setShowOption3(true);
            totalOpt3CostValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorCustoOpt
            );
            totalOpt3SaleValue += parseFloat(
              workplans[workplanIndex].items[i].atividades[k].opcoes[j]
                .valorVendaOpt
            );
          }
        }
      }
    }

    setOption1CostValue(totalOpt1CostValue);
    setOption1SaleValue(totalOpt1SaleValue);
    setOption1FinalMargin((totalOpt1SaleValue - totalOpt1CostValue).toFixed(2));

    setOption2CostValue(totalOpt2CostValue);
    setOption2SaleValue(totalOpt2SaleValue);
    setOption2FinalMargin((totalOpt2SaleValue - totalOpt2CostValue).toFixed(2));

    setOption3CostValue(totalOpt3CostValue);
    setOption3SaleValue(totalOpt3SaleValue);
    setOption3FinalMargin((totalOpt3SaleValue - totalOpt3CostValue).toFixed(2));

    setOptionSelectedCostValue(totalOptSelectedCostValue);
    setOptionSelectedSaleValue(totalOptSelectedSaleValue);
    setOptionSelectedFinalMargin(
      (totalOptSelectedSaleValue - totalOptSelectedCostValue).toFixed(2)
    );

    if (expenses?.length != 0 && expenses != null && expenses != undefined) {
      const expGPI = expenses.find(
        (x) => x.tipo === "Gastos Pessoal Indireto (GPI)"
      );
      if (expGPI != null) gpi = expGPI.percentagem / 100;

      const expFSE = expenses.find((x) => x.tipo === "FSE");
      if (expFSE != null) fse = expFSE.percentagem / 100;

      const expGPD = expenses.find(
        (x) => x.tipo === "Gastos Pessoal Direto (GPD)"
      );
      if (expGPD != null) gpd = expGPD.percentagem / 100;
    } else {
      fse = 0;
      gpi = 0;
      gpd = 0;
    }

    var factureHoursElement = {};
    var factureSubElement = {};
    var factureCostElement = {};
    var facturFactElement = {};

    var factureHoursVerifyElement = {};
    var factureSubVerifyElement = {};
    var factureCostVerifyElement = {};
    var facturFactVerifyElement = {};

    var titleElement = {};
    var totalFactureElement = {};
    var totalCostElement = {};

    var totalFactureElementVerify = {};
    var totalCostElementVerify = {};

    var costSubElement = {};
    var costCostElement = {};
    var costFactElement = {};

    var costSubVerifyElement = {};
    var costCostVerifyElement = {};
    var costFactVerifyElement = {};

    var incomeElement = {};
    var percentIncomeElement = {};

    var marginElement = {};
    var initialMarginElement = {};
    var percentMarginElement = {};

    var incomeElementVerify = {};
    var percentIncomeElementVerify = {};
    var marginElementVerify = {};
    var initialMarginElementVerify = {};
    var percentMarginElementVerify = {};
    var marginOkElement = {};

    marginOkElement.description = "MARGEM OK/NOT";
    titleElement.description = "Descriçao";

    initialMarginElementVerify.description = "Margem";

    factureHoursVerifyElement.description = "Horas";
    factureSubVerifyElement.description = "Subcontratos";
    factureCostVerifyElement.description = "Despesas Admin";
    facturFactVerifyElement.description = "Factoring";

    factureHoursElement.description = "Horas";
    factureSubElement.description = "Subcontratos";
    factureCostElement.description = "Despesas Admin";
    facturFactElement.description = "Factoring";

    costSubElement.description = "Subcontratos";
    costCostElement.description = "Despesas Admin";
    costFactElement.description = "Factoring";

    costSubVerifyElement.description = "Subcontratos";
    costCostVerifyElement.description = "Despesas Admin";
    costFactVerifyElement.description = "Factoring";

    totalFactureElement.description = "Total a Faturar";
    totalCostElement.description = "Total Custos";

    totalFactureElementVerify.description = "Total a Faturar";
    totalCostElementVerify.description = "Total Custos";

    incomeElement.description = "Income";
    percentIncomeElement.description = "";

    costHoursElement.description = "Custo Horas";
    hoursElement.description = "Horas";

    marginElement.description = "Margem";
    initialMarginElement.description = "Margem";
    percentMarginElement.description = "";

    incomeElementVerify.description = "Income";
    percentIncomeElementVerify.description = "";
    marginElementVerify.description = "Margem";
    percentMarginElementVerify.description = "";

    for (var i = 0; i < departments.length; i++) {
      titleElement[departments[i].nome] = [departments[i].nome];

      marginOkElement[departments[i].nome] = 0;

      factureHoursElement[departments[i].nome] = 0;
      factureSubElement[departments[i].nome] = 0;
      factureCostElement[departments[i].nome] = 0;
      facturFactElement[departments[i].nome] = 0;

      initialMarginElementVerify[departments[i].nome] = 0;

      factureHoursVerifyElement[departments[i].nome] = 0;
      factureSubVerifyElement[departments[i].nome] = 0;
      factureCostVerifyElement[departments[i].nome] = 0;
      facturFactVerifyElement[departments[i].nome] = 0;

      initialMarginElement[departments[i].nome] = 0;

      costSubElement[departments[i].nome] = 0;
      costCostElement[departments[i].nome] = 0;
      costFactElement[departments[i].nome] = 0;

      costSubVerifyElement[departments[i].nome] = 0;
      costCostVerifyElement[departments[i].nome] = 0;
      costFactVerifyElement[departments[i].nome] = 0;

      totalFactureElement[departments[i].nome] = 0;
      totalCostElement[departments[i].nome] = 0;

      totalFactureElementVerify[departments[i].nome] = 0;
      totalCostElementVerify[departments[i].nome] = 0;

      incomeElement[departments[i].nome] = 0;
      percentIncomeElement[departments[i].nome] = 0;

      costHoursElement[departments[i].nome] = 0;
      hoursElement[departments[i].nome] = 0;

      marginElement[departments[i].nome] = 0;
      percentMarginElement[departments[i].nome] = 0;

      incomeElementVerify[departments[i].nome] = 0;
      percentIncomeElementVerify[departments[i].nome] = 0;

      marginElementVerify[departments[i].nome] = 0;
      percentMarginElementVerify[departments[i].nome] = 0;
    }

    titleElement.total = "Total";

    marginOkElement.total = 0;

    initialMarginElementVerify.total = 0;

    initialMarginElement.total = 0;

    costHoursElement.total = 0;

    hoursElement.total = 0;

    percentIncomeElement.total = 0;
    incomeElement.total = 0;
    percentMarginElement.total = 0;
    marginElement.total = 0;

    percentIncomeElementVerify.total = 0;
    incomeElementVerify.total = 0;
    percentMarginElementVerify.total = 0;
    marginElementVerify.total = 0;

    factureHoursElement.total = 0;
    factureSubElement.total = 0;
    factureCostElement.total = 0;
    facturFactElement.total = 0;

    factureHoursVerifyElement.total = 0;
    factureSubVerifyElement.total = 0;
    factureCostVerifyElement.total = 0;
    facturFactVerifyElement.total = 0;

    totalFactureElement.total = 0;

    totalFactureElementVerify.total = 0;

    costSubElement.total = 0;
    costCostElement.total = 0;
    costFactElement.total = 0;

    costSubVerifyElement.total = 0;
    costCostVerifyElement.total = 0;
    costFactVerifyElement.total = 0;

    totalCostElement.total = 0;

    totalCostElementVerify.total = 0;

    factureArray.push(factureHoursElement);
    factureArray.push(factureSubElement);
    factureArray.push(factureCostElement);
    factureArray.push(facturFactElement);

    factureArrayVerify.push(factureHoursVerifyElement);
    factureArrayVerify.push(factureSubVerifyElement);
    factureArrayVerify.push(factureCostVerifyElement);
    factureArrayVerify.push(facturFactVerifyElement);

    costArray.push(costSubElement);
    costArray.push(costCostElement);
    costArray.push(costFactElement);

    costArrayVerify.push(costSubVerifyElement);
    costArrayVerify.push(costCostVerifyElement);
    costArrayVerify.push(costFactVerifyElement);

    buildHoursToFacture();
    setFactureArrayState(factureArray);
    setCostArrayState(costArray);

    for (var i = 0; i < factureArray.length; i++) {
      Object.keys(factureArray[i]).map((key, index) => {
        if (key === "total") {
          //devLogConsole(factureArray[i][key], factureArray[i]);

          totalFactureElement[key] =
            parseFloat(totalFactureElement[key]) +
            parseFloat(factureArray[i][key].toFixed(2));
        } else {
          totalFactureElement[key] =
            totalFactureElement[key] + factureArray[i][key];
        }
      });
    }
    //devLogConsole(totalFactureElement.total);

    for (var i = 0; i < factureArrayVerify.length; i++) {
      Object.keys(factureArrayVerify[i]).map(
        (key, index) =>
          (totalFactureElementVerify[key] =
            totalFactureElementVerify[key] + factureArrayVerify[i][key])
      );
    }

    for (var i = 0; i < costArray.length; i++) {
      Object.keys(costArray[i]).map(
        (key, index) =>
          (totalCostElement[key] = totalCostElement[key] + costArray[i][key])
      );
    }

    for (var i = 0; i < costArrayVerify.length; i++) {
      Object.keys(costArrayVerify[i]).map(
        (key, index) =>
          (totalCostElementVerify[key] =
            totalCostElementVerify[key] + costArrayVerify[i][key])
      );
    }

    Object.keys(totalFactureElement).map(
      (key, index) =>
        (incomeElement[key] =
          parseFloat(totalFactureElement[key]) -
          parseFloat(totalCostElement[key]))
    );

    Object.keys(costHoursElement).map((key, index) => {
      if (key != "total" && key != "description") {
        costHoursElement.total = costHoursElement.total + costHoursElement[key];
      }
    });

    Object.keys(hoursElement).map((key, index) => {
      if (key != "total" && key != "description") {
        const hoursStringArray = hoursElement[key].toString().split(".");
        var minutes = "0." + hoursStringArray[1];
        minutes = Math.ceil(parseFloat(minutes) * 60);
        minutes = minutes.toString();
        if (minutes.length === 1) {
          minutes = "0" + minutes;
        }
        hoursElement[key] = hoursStringArray[0] + ":" + minutes;
      }
    });

    Object.keys(hoursElement).map((key, index) => {
      if (key != "total" && key != "description") {
        const hoursArray = hoursElement[key].split(":");
        var newHoras =
          parseFloat(hoursArray[0]) + parseFloat(hoursArray[1]) / 60;
        hoursElement.total = hoursElement.total + newHoras;
      }
    });

    const hoursStringArray = hoursElement.total.toString().split(".");

    var minutes = "0." + hoursStringArray[1];
    minutes = Math.ceil(parseFloat(minutes) * 60);
    minutes = minutes.toString();
    if (minutes.length === 1) {
      minutes = "0" + minutes;
    }
    hoursElement.total = hoursStringArray[0] + ":" + minutes;

    Object.keys(totalFactureElementVerify).map(
      (key, index) =>
        (incomeElementVerify[key] =
          parseFloat(totalFactureElementVerify[key]) -
          parseFloat(totalCostElementVerify[key]))
    );

    Object.keys(incomeElement).map(
      (key, index) =>
        (percentIncomeElement[key] =
          (parseFloat(incomeElement[key]) /
            parseFloat(totalFactureElement[key])) *
          100)
    );

    Object.keys(incomeElementVerify).map((key, index) => {
      if (totalFactureElementVerify[key] != 0) {
        percentIncomeElementVerify[key] =
          (incomeElementVerify[key] / totalFactureElementVerify[key]) * 100;
      }
    });

    var didCalculateMargin = false;
    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].selected
          ) {
            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                .length;
              m++
            ) {
              var currentHours =
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ];
              for (var a = 0; a < departments.length; a++) {
                if (
                  convertIdToName(
                    currentHours.departamentoHoras,
                    "departments"
                  ) === departments[a].nome
                ) {
                  didCalculateMargin = true;
                  const currentCollaborator = collaborators.find(
                    (x) =>
                      x.idColaborador === parseInt(currentHours.colaborador)
                  );

                  const hoursArray = currentHours.horasPropostas.split(":");
                  var newHoras =
                    parseFloat(hoursArray[0]) + parseFloat(hoursArray[1]) / 60;

                  if (getNameFromId() === "Avença") {
                    //newHoras =
                    //  newHoras *
                    //  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    //    .horas[m].horasPropostasArray.length;
                  }

                  var value1;
                  var value2;

                  if (
                    currentCollaborator != null &&
                    currentCollaborator != undefined
                  ) {
                    value1 = newHoras * currentCollaborator.precoCompra;
                    value2 =
                      value1 +
                      factureArrayVerify[0][departments[a].nome] * fse +
                      factureArrayVerify[0][departments[a].nome] * gpi;
                  }

                  initialMarginElementVerify[departments[a].nome] =
                    initialMarginElementVerify[departments[a].nome] + value2;

                  initialMarginElement[departments[a].nome] =
                    initialMarginElement[departments[a].nome] +
                    currentHours.custosHoras;
                }
              }
            }
          }
        }
      }
    }

    if (didCalculateMargin === false) {
      for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades.length;
          j++
        ) {
          for (
            var k = 0;
            k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                  .length;
                m++
              ) {
                var currentHours =
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .horas[m];
                for (var a = 0; a < departments.length; a++) {
                  if (
                    convertIdToName(
                      currentHours.departamentoHoras,
                      "departments"
                    ) === departments[a].nome
                  ) {
                    didCalculateMargin = true;
                    const currentCollaborator = collaborators.find(
                      (x) =>
                        x.idColaborador === parseInt(currentHours.colaborador)
                    );

                    const hoursArray = currentHours.horasPropostas.split(":");
                    var newHoras =
                      parseFloat(hoursArray[0]) +
                      parseFloat(hoursArray[1]) / 60;

                    if (getNameFromId() === "Avença") {
                      // newHoras =
                      //   newHoras *
                      //   workplans[workplanIndex].items[i].atividades[j].opcoes[
                      //     k
                      //   ].horas[m].horasPropostasArray.length;
                    }
                    var value1;
                    var value2;
                    if (
                      currentCollaborator != null &&
                      currentCollaborator != undefined
                    ) {
                      value1 = newHoras * currentCollaborator.precoCompra;
                      value2 =
                        value1 +
                        factureArrayVerify[0][departments[a].nome] * fse +
                        factureArrayVerify[0][departments[a].nome] * gpi;
                    }

                    initialMarginElementVerify[departments[a].nome] =
                      initialMarginElementVerify[departments[a].nome] + value2;

                    initialMarginElement[departments[a].nome] =
                      initialMarginElement[departments[a].nome] +
                      currentHours.custosHoras;
                  }
                }
              }
            }
          }
        }
      }
    }

    Object.keys(initialMarginElement).map((key, index) => {
      if (key != "total" && key != "description") {
        marginElement[key] =
          parseFloat(incomeElement[key]) -
          parseFloat(initialMarginElement[key]);

        marginElement.total +=
          parseFloat(incomeElement[key]) -
          parseFloat(initialMarginElement[key]);
      }
    });

    Object.keys(initialMarginElementVerify).map((key, index) => {
      if (
        incomeElementVerify[key] != 0 &&
        key != "total" &&
        key != "description"
      ) {
        marginElementVerify[key] =
          parseFloat(incomeElementVerify[key]) -
          initialMarginElementVerify[key];

        marginElementVerify.total +=
          parseFloat(incomeElementVerify[key]) -
          parseFloat(initialMarginElementVerify[key]);
      }
    });

    Object.keys(marginElement).map(
      (key, index) =>
        (percentMarginElement[key] =
          (parseFloat(marginElement[key]) /
            parseFloat(totalFactureElement[key])) *
          100)
    );

    Object.keys(marginElementVerify).map(
      (key, index) =>
        (percentMarginElementVerify[key] =
          (marginElementVerify[key] / totalFactureElementVerify[key]) * 100)
    );

    Object.keys(percentMarginElementVerify).map((key, index) => {
      if (incomeElement[key] === 0) {
        marginOkElement[key] = -1;
      } else if (
        percentMarginElement[key].toFixed(2) <
        percentMarginElementVerify[key].toFixed(2)
      ) {
        marginOkElement[key] = 0;
      } else {
        marginOkElement[key] = 1;
      }
    });

    var monthElement = {};
    monthElement.description = "Rubrica";

    const currentMonths = monthsBtwnDates(
      workplans[workplanIndex].dataInicioProjeto,
      workplans[workplanIndex].dataFinalProjeto
    );

    if (currentMonths === null || currentMonths === undefined) return;
    devLogConsole(currentMonths);
    devLogConsole(months);
    for (var i = 0; i < currentMonths.length; i++) {
      if (currentMonths[i].split("-")[1] === "00") {
        var newYear = parseInt(currentMonths[i].split("-")[0]) - 1;
        monthElement[currentMonths[i]] = months[11] + "/" + newYear;
      } else {
        monthElement[currentMonths[i]] =
          months[parseInt(currentMonths[i].split("-")[1]) - 1] +
          "/" +
          currentMonths[i].split("-")[0];
      }
    }

    monthElement.total = "Total";

    var newTotalFactureElement = {};
    newTotalFactureElement.description = "Faturação";
    var totalFaturacao = 0;

    if (workplans[workplanIndex].resumoFinaceiro.toChange === 1) {
      Object.keys(monthElement).map((key, index) => {
        if (key != "total" && key != "description") {
          if (getNameFromId() === "Avença") {
            newTotalFactureElement[key] = totalFactureElement.total;
            totalFaturacao =
              totalFaturacao + parseFloat(totalFactureElement.total);
          } else {
            if (
              workplans[workplanIndex].faseFinal === "Inicio" ||
              workplans[workplanIndex].faseFinal === null ||
              workplans[workplanIndex].faseFinal === "" ||
              workplans[workplanIndex].faseFinal === undefined
            ) {
              if (index === 1) {
                newTotalFactureElement[key] = totalFactureElement.total;
                totalFaturacao = totalFaturacao + totalFactureElement.total;
              } else {
                newTotalFactureElement[key] = 0;
              }
            } else if (workplans[workplanIndex].faseFinal === "Repartido") {
              //find realNumber
              const realNumber =
                parseFloat(totalFactureElement.total) / currentMonths.length;
              //find 1st number
              const firstNumber = decimalAdjust("floor", realNumber, -2);

              if (index === currentMonths.length) {
                //find last number
                const lastNumber = (
                  totalFactureElement.total -
                  firstNumber * (currentMonths.length - 1)
                ).toFixed(2);

                //string to number
                newTotalFactureElement[key] = parseFloat(
                  parseFloat(lastNumber).toFixed(2)
                );
                totalFaturacao = (
                  totalFaturacao + parseFloat(lastNumber)
                ).toFixed(2);
              } else {
                newTotalFactureElement[key] = firstNumber;
                totalFaturacao = totalFaturacao + parseFloat(firstNumber);
              }
            } else {
              if (index === currentMonths.length) {
                newTotalFactureElement[key] = totalFactureElement.total;
                totalFaturacao = totalFaturacao + totalFactureElement.total;
              } else {
                newTotalFactureElement[key] = 0;
              }
            }
          }
        }
      });

      newTotalFactureElement.total = totalFaturacao;
      workplans[workplanIndex].resumoFinaceiro.toChange = 0;
      workplans[workplanIndex].resumoFinaceiro.canSave = true;
    } else {
      Object.keys(monthElement).map((key, index) => {
        if (key != "total" && key != "description") {
          newTotalFactureElement[key] =
            workplans[workplanIndex].resumoFinaceiro.resumeFactoration[
              index - 1
            ].value;
        }
      });

      newTotalFactureElement.total =
        workplans[workplanIndex].resumoFinaceiro.resumeFactoration[
          workplans[workplanIndex].resumoFinaceiro.resumeFactoration.length - 1
        ].value;
    }

    var newTotalCostElement = {};
    newTotalCostElement.description = "Subcontratos e outros custos";
    var totalCustos = 0;

    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          newTotalCostElement[key] = totalCostElement.total;
          totalCustos =
            totalCustos + parseFloat(totalCostElement.total.toFixed(2));
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              newTotalCostElement[key] = totalCostElement.total;
              totalCustos = totalCustos + totalCostElement.total;
            } else {
              newTotalCostElement[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            //find realNumber
            const realNumber =
              parseFloat(totalCostElement.total) / currentMonths.length;
            //find 1st number
            const firstNumber = decimalAdjust("floor", realNumber, -2);

            if (index === currentMonths.length) {
              //find last number
              const lastNumber = (
                totalCostElement.total -
                firstNumber * (currentMonths.length - 1)
              ).toFixed(2);

              //string to number
              newTotalCostElement[key] = parseFloat(
                parseFloat(lastNumber).toFixed(2)
              );
              totalCustos = (totalCustos + parseFloat(lastNumber)).toFixed(2);
            } else {
              newTotalCostElement[key] = firstNumber;
              totalCustos = totalCustos + parseFloat(firstNumber);
            }
          } else {
            if (index === currentMonths.length) {
              newTotalCostElement[key] = totalCostElement.total;
              totalCustos = totalCustos + totalCostElement.total;
            } else {
              newTotalCostElement[key] = 0;
            }
          }
        }
      }
    });
    devLogConsole(totalCustos);
    newTotalCostElement.total = totalCustos;

    var newTotalIncomeElement = {};
    newTotalIncomeElement.description = "Income";
    var totalIncome = 0;

    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          newTotalIncomeElement[key] =
            parseFloat(newTotalFactureElement[key]) -
            parseFloat(newTotalCostElement[key]);
          totalIncome =
            totalIncome +
            parseFloat(
              parseFloat(newTotalFactureElement[key]) -
                parseFloat(newTotalCostElement[key]).toFixed(2)
            );
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              newTotalIncomeElement[key] = incomeElement.total;
              totalIncome = totalIncome + incomeElement.total;
            } else {
              newTotalIncomeElement[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            //find realNumber
            const realNumber =
              parseFloat(incomeElement.total) / currentMonths.length;
            //find 1st number
            const firstNumber = decimalAdjust("floor", realNumber, -2);

            if (index === currentMonths.length) {
              //find last number
              const lastNumber = (
                incomeElement.total -
                firstNumber * (currentMonths.length - 1)
              ).toFixed(2);

              //string to number
              newTotalIncomeElement[key] = parseFloat(
                parseFloat(lastNumber).toFixed(2)
              );
              totalIncome = (totalIncome + parseFloat(lastNumber)).toFixed(2);
            } else {
              newTotalIncomeElement[key] =
                parseFloat(newTotalFactureElement[key]) -
                parseFloat(newTotalCostElement[key]);
              totalIncome =
                totalIncome +
                parseFloat(newTotalFactureElement[key]) -
                parseFloat(newTotalCostElement[key]);
            }
          } else {
            if (index === currentMonths.length) {
              newTotalIncomeElement[key] = incomeElement.total;
              totalIncome = totalIncome + incomeElement.total;
            } else {
              newTotalIncomeElement[key] = 0;
            }
          }
        }
      }
    });
    newTotalIncomeElement.total = totalIncome;

    var newPercIncomeElement = {};
    newPercIncomeElement.description = "% Income";
    var totalPercIncome = 0;

    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          newPercIncomeElement[key] =
            (newTotalIncomeElement[key] /
              parseFloat(newTotalFactureElement[key])) *
            100;
          totalPercIncome = totalPercIncome + percentIncomeElement.total;
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              newPercIncomeElement[key] = percentIncomeElement.total;
              totalPercIncome = totalPercIncome + percentIncomeElement.total;
            } else {
              newPercIncomeElement[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            newPercIncomeElement[key] =
              (newTotalIncomeElement[key] /
                parseFloat(newTotalFactureElement[key])) *
              100;
            totalPercIncome =
              newTotalIncomeElement.total /
              parseFloat(newTotalFactureElement.total);
          } else {
            if (index === currentMonths.length) {
              newPercIncomeElement[key] = percentIncomeElement.total;
              totalPercIncome = totalPercIncome + percentIncomeElement.total;
            } else {
              newPercIncomeElement[key] = 0;
            }
          }
        }
      }
    });
    newPercIncomeElement.total = percentIncomeElement.total;

    var newCostHoursElement = {};
    newCostHoursElement.description = "Custo das Horas";
    var totalCostHours = 0;

    var totalHoursAdded = [];
    var totalCostAdded = 0;
    var hoursAvenca = 0;
    var cameToSelected = false;
    for (var i = 0; i < currentMonths.length; i++) {
      totalHoursAdded[i] = 0;
    }
    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].selected
          ) {
            cameToSelected = true;
            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                .length;
              m++
            ) {
              var currentHours =
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ];

              totalCostAdded =
                totalCostAdded + parseFloat(currentHours.custosHoras);
              hoursAvenca =
                hoursAvenca +
                parseFloat(currentHours.horasPropostas.split(":")[0]) +
                parseFloat(currentHours.horasPropostas.split(":")[1]) / 60;
              for (
                var u = 0;
                u < currentHours.horasPropostasArray.length;
                u++
              ) {
                totalHoursAdded[u] =
                  totalHoursAdded[u] +
                  parseFloat(
                    currentHours.horasPropostasArray[u].hoursValue.split(":")[0]
                  ) +
                  parseFloat(
                    currentHours.horasPropostasArray[u].hoursValue.split(":")[1]
                  ) /
                    60;
              }
            }
          }
        }
      }
    }

    if (cameToSelected === false) {
      for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades.length;
          j++
        ) {
          for (
            var k = 0;
            k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              cameToSelected = true;
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                  .length;
                m++
              ) {
                var currentHours =
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .horas[m];

                totalCostAdded =
                  totalCostAdded + parseFloat(currentHours.custosHoras);
                hoursAvenca =
                  hoursAvenca +
                  parseFloat(currentHours.horasPropostas.split(":")[0]) +
                  parseFloat(currentHours.horasPropostas.split(":")[1]) / 60;
                for (
                  var u = 0;
                  u < currentHours.horasPropostasArray.length;
                  u++
                ) {
                  devLogConsole(                    parseFloat(
                    currentHours.horasPropostasArray[u].hoursValue.split(
                      ":"
                    )[0]
                  ) +
                  parseFloat(
                    currentHours.horasPropostasArray[u].hoursValue.split(
                      ":"
                    )[1]
                  ) /
                    60)
                  totalHoursAdded[u] =
                    totalHoursAdded[u] +
                    parseFloat(
                      currentHours.horasPropostasArray[u].hoursValue.split(
                        ":"
                      )[0]
                    ) +
                    parseFloat(
                      currentHours.horasPropostasArray[u].hoursValue.split(
                        ":"
                      )[1]
                    ) /
                      60;
                }
              }
            }
          }
        }
      }
    }

    var totalHoras = 0;
    for (var i = 0; i < totalHoursAdded.length; i++) {
      totalHoras = totalHoras + totalHoursAdded[i];
    }

    devLogConsole(totalHoras);
    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          newCostHoursElement[key] = totalCostAdded;
          totalCostHours = totalCostHours + totalCostAdded;
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              newCostHoursElement[key] = totalCostAdded;
              totalCostHours = totalCostHours + totalCostAdded;
            } else {
              newCostHoursElement[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            //find realNumber
            const realNumber =
              parseFloat(totalCostAdded) / currentMonths.length;
            //find 1st number
            const firstNumber = decimalAdjust("floor", realNumber, -2);

            if (index === currentMonths.length) {
              //find last number
              const lastNumber = (
                totalCostAdded -
                firstNumber * (currentMonths.length - 1)
              ).toFixed(2);
                devLogConsole(firstNumber)
                devLogConsole(lastNumber)
              //string to number
              newCostHoursElement[key] = parseFloat(
                parseFloat(lastNumber).toFixed(2)
              );
              totalCostHours = (totalCostHours + parseFloat(lastNumber)).toFixed(2);
            } else {
              newCostHoursElement[key] = totalCostAdded / currentMonths.length;
              totalCostHours =
                totalCostHours + totalCostAdded / currentMonths.length;
            }
          } else {
            if (index === currentMonths.length) {
              newCostHoursElement[key] = totalCostAdded;
              totalCostHours = totalCostHours + totalCostAdded;
            } else {
              newCostHoursElement[key] = 0;
            }
          }
        }
      }
    });
    newCostHoursElement.total = totalCostAdded;

    var newHoursElement = {};
    newHoursElement.description = "Horas";
    var totalHours = 0;

    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          var n = new Date(0, 0);
          n.setSeconds(+hoursAvenca * 60 * 60);
          const hoursStringArray = hoursAvenca.toString().split(".");
          var minutes = "0." + hoursStringArray[1];
          minutes = Math.ceil(parseFloat(minutes) * 60);
          minutes = minutes.toString();
          if (minutes.length === 1) {
            minutes = "0" + minutes;
          }
          newHoursElement[key] = hoursStringArray[0] + ":" + minutes;
          totalHours =
            totalHours +
            parseInt(hoursElement.total.split(":")[0]) +
            parseInt(hoursElement.total.split(":")[1]) / 60;
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              const hoursStringArray = totalHoras.toString().split(".");
              var minutes = "0." + hoursStringArray[1];
              minutes = Math.ceil(parseFloat(minutes) * 60);
              minutes = minutes.toString();
              if (minutes.length === 1) {
                minutes = "0" + minutes;
              }
              newHoursElement[key] = hoursStringArray[0] + ":" + minutes;
            } else {
              newHoursElement[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            if (index === currentMonths.length) {
              var n = new Date(0, 0);
              n.setSeconds(+totalHoursAdded[index - 1] * 60 * 60);
              const hoursStringArray = totalHoursAdded[index - 1]
                .toString()
                .split(".");
              var minutes = "0." + hoursStringArray[1];
              minutes = Math.ceil(parseFloat(minutes) * 60);
              minutes = minutes.toString();
              if (minutes.length === 1) {
                minutes = "0" + minutes;
              }
              const newHours = hoursStringArray[0] + ":" + minutes;
              newHoursElement[key] = newHours;
            } else {
              var hours = totalHoursAdded[index - 1].toString().split(".")[0]
              if(hours.length === 1)
                hours = "0" + hours
  
              var minutes = Math.round(((parseFloat(totalHoursAdded[index - 1].toFixed(2).split(".")[1])/100)*60))
              devLogConsole(minutes)
              if(minutes.toString().length === 1)
                minutes = "0" + minutes
              const newHours = hours + ":" + minutes;
              newHoursElement[key] = newHours;
            }
          } else {
            if (index === currentMonths.length) {
              const hoursStringArray = totalHoras.toString().split(".");
              var minutes = "0." + hoursStringArray[1];
              minutes = Math.ceil(parseFloat(minutes) * 60);
              minutes = minutes.toString();
              if (minutes.length === 1) {
                minutes = "0" + minutes;
              }
              newHoursElement[key] = hoursStringArray[0] + ":" + minutes;
            } else {
              newHoursElement[key] = 0;
            }
          }
        }
      }
    });

    if (getNameFromId() === "Avença") {
      const hoursStringArray = totalHoras.toString().split(".");
      var minutes = "0." + hoursStringArray[1];
      minutes = Math.ceil(parseFloat(minutes) * 60);
      minutes = minutes.toString();
      if (minutes.length === 1) {
        minutes = "0" + minutes;
      }
      newHoursElement.total = hoursStringArray[0] + ":" + minutes;
    } else {
      const hoursStringArray = totalHoras.toString().split(".");
      var minutes = "0." + hoursStringArray[1];
      minutes = Math.ceil(parseFloat(minutes) * 60);
      minutes = minutes.toString();
      if (minutes.length === 1) {
        minutes = "0" + minutes;
      }
      newHoursElement.total = hoursStringArray[0] + ":" + minutes;
    }

    var newMarginElement = {};
    newMarginElement.description = "Margem";
    var totalMargin = 0;

    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          newMarginElement[key] =
            newTotalIncomeElement[key] - newCostHoursElement[key];
          totalMargin = totalMargin + marginElement.total;
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              newMarginElement[key] =
                newTotalIncomeElement.total - newCostHoursElement.total;
              totalMargin = totalMargin + marginElement.total;
            } else {
              newMarginElement[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            newMarginElement[key] =
              newTotalIncomeElement[key] - newCostHoursElement[key];
            //newMarginElement.total = newMarginElement.total + newTotalIncomeElement[key]- newCostHoursElement[key]
          } else {
            if (index === currentMonths.length) {
              newMarginElement[key] =
                newTotalIncomeElement.total - newCostHoursElement.total;
              totalMargin = totalMargin + marginElement.total;
            } else {
              newMarginElement[key] = 0;
            }
          }
        }
      }
    });
    newMarginElement.total =
      newTotalIncomeElement.total - newCostHoursElement.total;

    var newPercMargin = {};
    newPercMargin.description = "% Margem";
    var totalPercIncome = 0;
    Object.keys(monthElement).map((key, index) => {
      if (key != "total" && key != "description") {
        if (getNameFromId() === "Avença") {
          newPercMargin[key] =
            (newMarginElement[key] / newTotalFactureElement[key]) * 100;
          totalPercIncome =
            newMarginElement.total / newTotalFactureElement.total;
        } else {
          if (
            workplans[workplanIndex].faseFinal === "Inicio" ||
            workplans[workplanIndex].faseFinal === null ||
            workplans[workplanIndex].faseFinal === "" ||
            workplans[workplanIndex].faseFinal === undefined
          ) {
            if (index === 1) {
              newPercMargin[key] =
                (newMarginElement[key] / newTotalFactureElement[key]) * 100;
              totalPercIncome = totalPercIncome + percentMarginElement.total;
            } else {
              newPercMargin[key] = 0;
            }
          } else if (workplans[workplanIndex].faseFinal === "Repartido") {
            newPercMargin[key] =
              (newMarginElement[key] / newTotalFactureElement[key]) * 100;
            //newMarginElement.total = newMarginElement.total + newTotalIncomeElement[key]- newCostHoursElement[key]
          } else {
            if (index === currentMonths.length) {
              newPercMargin[key] =
                (newMarginElement[key] / newTotalFactureElement[key]) * 100;
              totalPercIncome = totalPercIncome + percentMarginElement.total;
            } else {
              newPercMargin[key] = 0;
            }
          }
        }
      }
    });
    newPercMargin.total =
      (newMarginElement.total / newTotalFactureElement.total) * 100;

    setMonthsArray(monthElement);

    totalFactureElementVerify.description = "Total a faturar";

    setFactureArrayStateVerify(factureArrayVerify);
    setTotalFactureVerify(totalFactureElementVerify);
    setCostArrayStateVerify(costArrayVerify);
    totalCostElementVerify.description = "Total Custos";
    setTotalCostVerify(totalCostElementVerify);

    setCostHours(newCostHoursElement);
    setHours(newHoursElement);

    incomeElementVerify.description = "Income";
    percentIncomeElementVerify.description = "";

    setIncomeVerify(incomeElementVerify);
    setPercIncomeVerify(percentIncomeElementVerify);

    marginElementVerify.description = "Margem";
    percentMarginElementVerify.description = "";

    setMarginVerify(marginElementVerify);
    setPercMarginVerify(percentMarginElementVerify);

    setMarginOk(marginOkElement);

    marginElement.description = "Margem";
    percentMarginElement.description = "";
    incomeElement.description = "Income";
    percentIncomeElement.description = "";
    totalFactureElement.description = "Total a Faturar";
    totalCostElement.description = "Total Custos";

    setPercMargin(newPercMargin);
    setMargin(newMarginElement);
    setPercIncome(newPercIncomeElement);
    setIncome(newTotalIncomeElement);
    setTotalCost(newTotalCostElement);
    setTotalFacture(newTotalFactureElement);
    setTableTitle(titleElement);
    var newResumeFact = [];
    var newTotalSummed = 0;
    Object.keys(newTotalFactureElement).map((key, index) => {
      if (index > 0) {
        newResumeFact.push({
          index: index,
          value: parseFloat(newTotalFactureElement[key]),
        });

        if (index != Object.keys(newTotalFactureElement).length - 1) {
          newTotalSummed =
            newTotalSummed + parseFloat(newTotalFactureElement[key]);
        }
      }
    });
    workplans[workplanIndex].resumoFinaceiro.resumeFactoration = newResumeFact;

    /*
    devLogConsole(
      "newTotalSummed",
      newTotalSummed,
      "newTotalFactureElement.total",
      newTotalFactureElement.total
    );*/

    if (
      parseFloat(newTotalSummed).toFixed(2) !=
      parseFloat(newTotalFactureElement.total).toFixed(2)
    ) {
      workplans[workplanIndex].resumoFinaceiro.canSave = false;
    } else {
      workplans[workplanIndex].resumoFinaceiro.canSave = true;
    }
  }, [workplans[workplanIndex], triggerLoading]);

  function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split("e");
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
    // Shift back
    value = value.toString().split("e");
    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
  }

  function numberWithCommas(x) {
    if (x === "" || x === null || x === undefined) return;
    const newString = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const newArray = newString.split(".");
    const finalString = newArray[0].replace(",", ".") + "," + newArray[1];
    return finalString;
  }

  function convertIdToName(id, name) {
    if (name === "departments") {
      const dep = departments.find((x) => x.idDepartamento === parseInt(id));
      if (dep != null) return dep?.nome;
    }
    if (name === "paymentTypes" && paymentTypes != null) {
      const payType = paymentTypes.find(
        (x) => x.idTipoPagamento === parseInt(id)
      );
      if (payType != null) return payType?.nome;
    }
  }

  function buildHoursToFacture() {
    var hasSelected = false;
    for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
      for (
        var j = 0;
        j < workplans[workplanIndex].items[i].atividades.length;
        j++
      ) {
        for (
          var k = 0;
          k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
          k++
        ) {
          if (
            workplans[workplanIndex].items[i].atividades[j].opcoes[k].selected
          ) {
            hasSelected = true;

            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                .length;
              m++
            ) {
              var currentHours =
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas[
                  m
                ];

              for (var a = 0; a < departments.length; a++) {
                if (
                  convertIdToName(
                    currentHours.departamentoHoras,
                    "departments"
                  ) === departments[a].nome
                ) {
                  factureArray[0].total =
                    factureArray[0].total +
                    parseFloat(currentHours.valorVendaHoras);
                  factureArray[0][departments[a].nome] =
                    factureArray[0][departments[a].nome] +
                    parseFloat(currentHours.valorVendaHoras);

                  const currentCollaborator = collaborators.find(
                    (x) =>
                      x.idColaborador === parseInt(currentHours.colaborador)
                  );

                  const hoursArray = currentHours.horasPropostas.split(":");
                  var newHoras =
                    parseFloat(hoursArray[0]) + parseFloat(hoursArray[1]) / 60;

                  hoursElement[departments[a].nome] = (
                    parseFloat(hoursElement[departments[a].nome]) + newHoras
                  ).toFixed(2);
                  if (
                    typeof currentHours.custosHoras === "string" ||
                    currentHours.custosHoras instanceof String
                  ) {
                    costHoursElement[departments[a].nome] =
                      costHoursElement[departments[a].nome] +
                      parseFloat(currentHours.custosHoras);
                  } else {
                    costHoursElement[departments[a].nome] =
                      costHoursElement[departments[a].nome] +
                      currentHours.custosHoras;
                  }
                  if (
                    typeof costHoursElement[departments[a].nome] === "string" ||
                    costHoursElement[departments[a].nome] instanceof String
                  ) {
                    costHoursElement[departments[a].nome] = parseFloat(
                      costHoursElement[departments[a].nome]
                    );
                  }

                  if (getNameFromId() === "Avença") {
                    // newHoras =
                    //   newHoras *
                    //   workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    //     .horas[m].horasPropostasArray.length;
                  }
                  var value1;
                  var value2;

                  if (
                    currentCollaborator != null &&
                    currentCollaborator != undefined
                  ) {
                    value1 = newHoras * currentCollaborator.precoCompra;
                    value2 = value1 / gpd;
                  }

                  if (value2 > parseFloat(currentHours.valorVendaHoras)) {
                    factureArrayVerify[0][departments[a].nome] =
                      factureArrayVerify[0][departments[a].nome] +
                      parseFloat(currentHours.valorVendaHoras);
                    factureArrayVerify[0].total =
                      factureArrayVerify[0].total +
                      parseFloat(currentHours.valorVendaHoras);
                  } else {
                    factureArrayVerify[0][departments[a].nome] =
                      factureArrayVerify[0][departments[a].nome] +
                      parseFloat(value2);
                    factureArrayVerify[0].total =
                      factureArrayVerify[0].total + parseFloat(value2);
                  }
                }
              }
            }

            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                .fornecedores.length;
              m++
            ) {
              var currentSuppliers =
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .fornecedores[m];

              //devLogConsole("departments", departments);
              for (var a = 0; a < departments.length; a++) {
                if (
                  convertIdToName(
                    currentSuppliers.departamentoForn,
                    "departments"
                  ) === departments[a].nome
                ) {
                  factureArray[1].total =
                    factureArray[1].total +
                    parseFloat(currentSuppliers.valorVendaForn);

                  factureArray[1][departments[a].nome] =
                    factureArray[1][departments[a].nome] +
                    parseFloat(currentSuppliers.valorVendaForn);

                  if (
                    currentSuppliers.precoCompraForn != 0 &&
                    currentSuppliers.precoCompraForn != null &&
                    currentSuppliers.precoCompraForn != undefined
                  ) {
                    const currentSupplier = providerTypes.find(
                      (x) =>
                        x.idTipoFornecedor === currentSuppliers.tipoFornecedor
                    );

                    factureArrayVerify[1].total =
                      factureArrayVerify[1].total +
                      parseFloat(
                        currentSuppliers.precoCompraForn /
                          (1 - currentSupplier.percentagem / 100)
                      );

                    factureArrayVerify[1][departments[a].nome] =
                      factureArrayVerify[1][departments[a].nome] +
                      parseFloat(
                        currentSuppliers.precoCompraForn /
                          (1 - currentSupplier.percentagem / 100)
                      );

                    costArrayVerify[0].total =
                      costArrayVerify[0].total +
                      parseFloat(currentSuppliers.precoCompraForn);

                    costArrayVerify[0][departments[a].nome] =
                      costArrayVerify[0][departments[a].nome] +
                      parseFloat(currentSuppliers.precoCompraForn);
                  }

                  costArray[0].total =
                    costArray[0].total +
                    parseFloat(currentSuppliers.precoCompraForn);

                  costArray[0][departments[a].nome] =
                    costArray[0][departments[a].nome] +
                    parseFloat(currentSuppliers.precoCompraForn);
                }
              }
            }
            for (
              var m = 0;
              m <
              workplans[workplanIndex].items[i].atividades[j].opcoes[k].despesas
                .length;
              m++
            ) {
              var currentCosts =
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .despesas[m];
              for (var a = 0; a < departments.length; a++) {
                if (
                  convertIdToName(
                    currentCosts.departamentoDesp,
                    "departments"
                  ) === departments[a].nome
                ) {
                  factureArray[2].total =
                    factureArray[2].total +
                    parseFloat(currentCosts.valorVendaDesp);

                  factureArray[2][departments[a].nome] =
                    factureArray[2][departments[a].nome] +
                    parseFloat(currentCosts.valorVendaDesp);

                  if (
                    currentCosts.precoCompraForn != 0 &&
                    currentCosts.precoCompraForn != null &&
                    currentCosts.precoCompraForn != undefined
                  ) {
                    const currentExpense = expenditureTypes.find(
                      (x) =>
                        x.idTipoDespesa === parseInt(currentCosts.tipoDespesa)
                    );
                    factureArrayVerify[2].total =
                      factureArrayVerify[2].total +
                      parseFloat(
                        currentCosts.precoCompraForn /
                          (1 - currentExpense.percentagem / 100)
                      );

                    factureArrayVerify[2][departments[a].nome] =
                      factureArrayVerify[2][departments[a].nome] +
                      parseFloat(
                        currentCosts.precoCompraForn /
                          (1 - currentExpense.percentagem / 100)
                      );

                    costArrayVerify[1].total =
                      costArrayVerify[1].total +
                      parseFloat(currentCosts.precoCompraForn);

                    costArrayVerify[1][departments[a].nome] =
                      costArrayVerify[1][departments[a].nome] +
                      parseFloat(currentCosts.precoCompraForn);
                  }

                  costArray[1].total =
                    costArray[1].total +
                    parseFloat(currentCosts.precoCompraForn);

                  costArray[1][departments[a].nome] =
                    costArray[1][departments[a].nome] +
                    parseFloat(currentCosts.precoCompraForn);
                }
              }
            }
          }
        }
      }
    }

    if (!hasSelected) {
      for (var i = 0; i < workplans[workplanIndex].items.length; i++) {
        for (
          var j = 0;
          j < workplans[workplanIndex].items[i].atividades.length;
          j++
        ) {
          for (
            var k = 0;
            k < workplans[workplanIndex].items[i].atividades[j].opcoes.length;
            k++
          ) {
            if (k === 0) {
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k].horas
                  .length;
                m++
              ) {
                var currentHours =
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .horas[m];
                for (var a = 0; a < departments.length; a++) {
                  if (
                    convertIdToName(
                      currentHours.departamentoHoras,
                      "departments"
                    ) === departments[a].nome
                  ) {
                    factureArray[0].total =
                      factureArray[0].total +
                      parseFloat(currentHours.valorVendaHoras);
                    factureArray[0][departments[a].nome] =
                      factureArray[0][departments[a].nome] +
                      parseFloat(currentHours.valorVendaHoras);

                    const currentCollaborator = collaborators.find(
                      (x) =>
                        x.idColaborador === parseInt(currentHours.colaborador)
                    );

                    const hoursArray = currentHours.horasPropostas.split(":");
                    var newHoras =
                      parseFloat(hoursArray[0]) +
                      parseFloat(hoursArray[1]) / 60;

                    hoursElement[departments[a].nome] = (
                      parseFloat(hoursElement[departments[a].nome]) + newHoras
                    ).toFixed(2);

                    if (
                      typeof currentHours.custosHoras === "string" ||
                      currentHours.custosHoras instanceof String
                    ) {
                      costHoursElement[departments[a].nome] =
                        costHoursElement[departments[a].nome] +
                        parseFloat(currentHours.custosHoras);
                    } else {
                      costHoursElement[departments[a].nome] =
                        costHoursElement[departments[a].nome] +
                        currentHours.custosHoras;
                    }
                    if (
                      typeof costHoursElement[departments[a].nome] ===
                        "string" ||
                      costHoursElement[departments[a].nome] instanceof String
                    ) {
                      costHoursElement[departments[a].nome] = parseFloat(
                        costHoursElement[departments[a].nome]
                      );
                    }

                    if (getNameFromId() === "Avença") {
                      //  newHoras =
                      //    newHoras *
                      //    workplans[workplanIndex].items[i].atividades[j].opcoes[
                      //      k
                      //    ].horas[m].horasPropostasArray.length;
                    }
                    var value1;
                    var value2;

                    if (
                      currentCollaborator != null &&
                      currentCollaborator != undefined
                    ) {
                      value1 = newHoras * currentCollaborator.precoCompra;
                      value2 = value1 / gpd;
                    }

                    if (value2 > parseFloat(currentHours.valorVendaHoras)) {
                      factureArrayVerify[0][departments[a].nome] =
                        factureArrayVerify[0][departments[a].nome] +
                        parseFloat(currentHours.valorVendaHoras);
                      factureArrayVerify[0].total =
                        factureArrayVerify[0].total +
                        parseFloat(currentHours.valorVendaHoras);
                    } else {
                      factureArrayVerify[0][departments[a].nome] =
                        factureArrayVerify[0][departments[a].nome] +
                        parseFloat(value2);
                      factureArrayVerify[0].total =
                        factureArrayVerify[0].total + parseFloat(value2);
                    }
                  }
                }
              }

              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .fornecedores.length;
                m++
              ) {
                var currentSuppliers =
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .fornecedores[m];
                for (var a = 0; a < departments.length; a++) {
                  if (
                    convertIdToName(
                      currentSuppliers.departamentoForn,
                      "departments"
                    ) === departments[a].nome
                  ) {
                    factureArray[1].total =
                      factureArray[1].total +
                      parseFloat(currentSuppliers.valorVendaForn);

                    factureArray[1][departments[a].nome] =
                      factureArray[1][departments[a].nome] +
                      parseFloat(currentSuppliers.valorVendaForn);

                    costArray[0].total =
                      costArray[0].total +
                      parseFloat(currentSuppliers.precoCompraForn);

                    costArray[0][departments[a].nome] =
                      costArray[0][departments[a].nome] +
                      parseFloat(currentSuppliers.precoCompraForn);

                    if (
                      currentSuppliers.precoCompraForn != 0 &&
                      currentSuppliers.precoCompraForn != null &&
                      currentSuppliers.precoCompraForn != undefined
                    ) {
                      const currentSupplier = providerTypes.find(
                        (x) =>
                          x.idTipoFornecedor === currentSuppliers.tipoFornecedor
                      );

                      factureArrayVerify[1].total =
                        factureArrayVerify[1].total +
                        parseFloat(
                          currentSuppliers.precoCompraForn /
                            (1 - currentSupplier.percentagem / 100)
                        );

                      factureArrayVerify[1][departments[a].nome] =
                        factureArrayVerify[1][departments[a].nome] +
                        parseFloat(
                          currentSuppliers.precoCompraForn /
                            (1 - currentSupplier.percentagem / 100)
                        );

                      costArrayVerify[0].total =
                        costArrayVerify[0].total +
                        parseFloat(currentSuppliers.precoCompraForn);

                      costArrayVerify[0][departments[a].nome] =
                        costArrayVerify[0][departments[a].nome] +
                        parseFloat(currentSuppliers.precoCompraForn);
                    }
                  }
                }
              }
              for (
                var m = 0;
                m <
                workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                  .despesas.length;
                m++
              ) {
                var currentCosts =
                  workplans[workplanIndex].items[i].atividades[j].opcoes[k]
                    .despesas[m];
                for (var a = 0; a < departments.length; a++) {
                  if (
                    convertIdToName(
                      currentCosts.departamentoDesp,
                      "departments"
                    ) === departments[a].nome
                  ) {
                    factureArray[2].total =
                      factureArray[2].total +
                      parseFloat(currentCosts.valorVendaDesp);

                    factureArray[2][departments[a].nome] =
                      factureArray[2][departments[a].nome] +
                      parseFloat(currentCosts.valorVendaDesp);

                    costArray[1].total =
                      costArray[1].total +
                      parseFloat(currentCosts.precoCompraForn);

                    costArray[1][departments[a].nome] =
                      costArray[1][departments[a].nome] +
                      parseFloat(currentCosts.precoCompraForn);

                    if (
                      currentCosts.precoCompraForn != 0 &&
                      currentCosts.precoCompraForn != null &&
                      currentCosts.precoCompraForn != undefined
                    ) {
                      const currentExpense = expenditureTypes.find(
                        (x) =>
                          x.idTipoDespesa === parseInt(currentCosts.tipoDespesa)
                      );
                      factureArrayVerify[2].total =
                        factureArrayVerify[2].total +
                        parseFloat(
                          currentCosts.precoCompraForn /
                            (1 - currentExpense.percentagem / 100)
                        );

                      factureArrayVerify[2][departments[a].nome] =
                        factureArrayVerify[2][departments[a].nome] +
                        parseFloat(
                          currentCosts.precoCompraForn /
                            (1 - currentExpense.percentagem / 100)
                        );

                      costArrayVerify[1].total =
                        costArrayVerify[1].total +
                        parseFloat(currentCosts.precoCompraForn);

                      costArrayVerify[1][departments[a].nome] =
                        costArrayVerify[1][departments[a].nome] +
                        parseFloat(currentCosts.precoCompraForn);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    var totalCostProportionElement = {};
    var totalCostProportionElementVerify = {};
    totalCostProportionElement.description = "DESCRIÇÃO";
    for (var i = 0; i < departments.length; i++) {
      totalCostProportionElement[departments[i].nome] = 0;
      totalCostProportionElementVerify[departments[i].nome] = 0;
    }
    totalCostProportionElement.total = 0;
    totalCostProportionElementVerify.total = 0;

    for (var i = 0; i < factureArray.length - 1; i++) {
      Object.keys(factureArray[i]).map(
        (key, index) =>
          (totalCostProportionElement[key] =
            totalCostProportionElement[key] + factureArray[i][key])
      );

      Object.keys(factureArrayVerify[i]).map(
        (key, index) =>
          (totalCostProportionElementVerify[key] =
            totalCostProportionElementVerify[key] + factureArrayVerify[i][key])
      );
    }

    var factName = convertIdToName(
      workplans[workplanIndex].formaPagamento,
      "paymentTypes"
    );
    const fact = factoring.find((x) => x.nome === factName);

    if (fact != null && fact != undefined) {
      const juro =
        ((totalCostProportionElement.total *
          1.23 *
          (fact.percentAdiantamento / 100) *
          (fact.txJuroEfetiva / 100)) /
          360) *
        parseFloat(workplans[workplanIndex].prazoPagamento);
      const impstSJuro = juro * (fact.impostoSeloComissao / 100);
      const comGestao =
        totalCostProportionElement.total * 1.23 * (fact.comissaoGestao / 100);
      const impsCJuro = comGestao * (fact.impostoSeloComissao / 100);
      const totalFactoring = juro + impsCJuro + impstSJuro + comGestao;

      const juroVerify =
        ((totalCostProportionElementVerify.total *
          1.23 *
          (fact.percentAdiantamento / 100) *
          (fact.txJuroEfetiva / 100)) /
          360) *
        parseFloat(workplans[workplanIndex].prazoPagamento);
      const impstSJuroVerify = juroVerify * (fact.impostoSeloComissao / 100);
      const comGestaoVerify =
        totalCostProportionElementVerify.total *
        1.23 *
        (fact.comissaoGestao / 100);
      const impsCJuroVerify =
        comGestaoVerify * (fact.impostoSeloComissao / 100);
      const totalFactoringVerify =
        juroVerify + impsCJuroVerify + impstSJuroVerify + comGestaoVerify;

      //devLogConsole(costArray);

      Object.keys(costArray[2]).map((key, index) => {
        if (key != "total")
          costArray[2][key] =
            costArray[2][key] +
            parseFloat(
              (totalCostProportionElement[key] /
                totalCostProportionElement.total) *
                totalFactoring
            );
        if (key != "description" && key != "total")
          costArray[2].total = costArray[2].total + costArray[2][key];
      });

      Object.keys(costArrayVerify[2]).map((key, index) => {
        if (key != "total")
          costArrayVerify[2][key] =
            costArrayVerify[2][key] +
            parseFloat(
              (totalCostProportionElementVerify[key] /
                totalCostProportionElementVerify.total) *
                totalFactoringVerify
            );

        if (key != "description" && key != "total")
          costArrayVerify[2].total =
            costArrayVerify[2].total + costArrayVerify[2][key];
      });

      costArrayVerify[2].description = "Factoring";
      costArray[2].description = "Factoring";

      if (fact && workplans[workplanIndex].aceitarFormaPagamento === "Sim") {
        Object.keys(costArray[2]).map(
          (key, index) =>
            (factureArray[3][key] = costArray[2][key] / (1 - fact.margemPV))
        );

        Object.keys(costArrayVerify[2]).map(
          (key, index) =>
            (factureArrayVerify[3][key] =
              costArrayVerify[2][key] / (1 - fact.margemPV))
        );
      }

      factureArray[3].description = "Factoring";
      factureArrayVerify[3].description = "Factoring";
    }
  }

  function getPositionTop(index, table) {
    if (index === 0) return "start";
    if (index === Object.keys(table).length - 1) return "top-end";
  }

  function getPercentage(size) {
    return 100 / size + "%";
  }

  function disabledInputs() {
    if (
      (workplans[workplanIndex].faseFinal === "Inicio" ||
        workplans[workplanIndex].faseFinal === null ||
        workplans[workplanIndex].faseFinal === "" ||
        workplans[workplanIndex].faseFinal === undefined ||
        workplans[workplanIndex].faseFinal === "Final") &&
      (getNameFromId() === "Pontual" || getNameFromId() === "Press kit")
    ) {
      return true;
    }

    return false;
  }

  function calc(theform) {
    var num = theform.original.value, rounded = theform.rounded
    var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    return rounded.value = with2Decimals
}

  //devLogConsole(totalFacture);
  return (
    <div className="liftworld-content-container">
      <div className="liftworld-expenses-sales-wrapper">
        <div className="liftworld-text-wrapper">
          Resumo Proposta Financeira do Projeto
        </div>
        <div className="liftworld-expenses-sales-values-wrapper">
          <div className="liftworld-option-wrapper">
            <div className="liftworld-text-wrapper-large">Total Opção 1</div>
            <Textbox
              title={"Valor de Custo"}
              value={
                isNaN(parseFloat(option1CostValue)) ||
                option1CostValue === undefined
                  ? "0,00 €"
                  : numberWithCommas(parseFloat(option1CostValue).toFixed(2)) +
                    " €"
              }
              size={"normal"}
              color={"item"}
            />
            <Textbox
              title={"Valor de Venda"}
              value={
                isNaN(parseFloat(option1SaleValue)) ||
                option1SaleValue === undefined
                  ? "0,00 €"
                  : numberWithCommas(parseFloat(option1SaleValue).toFixed(2)) +
                    " €"
              }
              size={"normal"}
              color={"item"}
            />
            <Textbox
              title={"Margem Final"}
              value={
                isNaN(parseFloat(option1FinalMargin)) ||
                option1FinalMargin === undefined
                  ? "0,00 €"
                  : numberWithCommas(
                      parseFloat(option1FinalMargin).toFixed(2)
                    ) + " €"
              }
              size={"normal"}
              color={"item"}
            />
          </div>
        </div>
        {showOption2 && (
          <div className="liftworld-expenses-sales-values-wrapper">
            <div className="liftworld-option-wrapper">
              <div className="liftworld-text-wrapper-large">Total Opção 2</div>
              <Textbox
                title={"Valor de Custo"}
                value={
                  isNaN(parseFloat(option2CostValue)) ||
                  option2CostValue === undefined
                    ? "0,00 €"
                    : numberWithCommas(
                        parseFloat(option2CostValue).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
              <Textbox
                title={"Valor de Venda"}
                value={
                  isNaN(parseFloat(option2SaleValue)) ||
                  option2SaleValue === undefined
                    ? "0,00 €"
                    : numberWithCommas(
                        parseFloat(option2SaleValue).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
              <Textbox
                title={"Margem Final"}
                value={
                  isNaN(parseFloat(option2FinalMargin)) ||
                  option2FinalMargin === undefined
                    ? "0,00 €"
                    : numberWithCommas(
                        parseFloat(option2FinalMargin).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
            </div>
          </div>
        )}
        {showOption3 && (
          <div className="liftworld-expenses-sales-values-wrapper">
            <div className="liftworld-option-wrapper">
              <div className="liftworld-text-wrapper-large">Total Opção 3</div>
              <Textbox
                title={"Valor de Custo"}
                value={
                  isNaN(parseFloat(option3CostValue)) ||
                  option3CostValue === undefined
                    ? "0,00 €"
                    : numberWithCommas(
                        parseFloat(option3CostValue).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
              <Textbox
                title={"Valor de Venda"}
                value={
                  isNaN(parseFloat(option3SaleValue)) ||
                  option3SaleValue === undefined
                    ? "0,00 €"
                    : numberWithCommas(
                        parseFloat(option3SaleValue).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
              <Textbox
                title={"Margem Final"}
                value={
                  isNaN(parseFloat(option3FinalMargin)) ||
                  option3FinalMargin === undefined
                    ? "0,00 €"
                    : numberWithCommas(
                        parseFloat(option3FinalMargin).toFixed(2)
                      ) + " €"
                }
                size={"normal"}
                color={"item"}
              />
            </div>
          </div>
        )}
        <div className="liftworld-expenses-sales-values-wrapper">
          <div className="liftworld-option-wrapper">
            <div className="liftworld-text-wrapper-large">
              Total de Opções Selecionadas
            </div>
            <Textbox
              title={"Valor de Custo"}
              value={
                isNaN(parseFloat(optionSelectedCostValue)) ||
                optionSelectedCostValue === undefined
                  ? "0,00 €"
                  : numberWithCommas(
                      parseFloat(optionSelectedCostValue).toFixed(2)
                    ) + " €"
              }
              size={"normal"}
              color={"item"}
            />
            <Textbox
              title={"Valor de Venda"}
              value={
                isNaN(parseFloat(optionSelectedSaleValue)) ||
                optionSelectedSaleValue === undefined
                  ? "0,00 €"
                  : numberWithCommas(
                      parseFloat(optionSelectedSaleValue).toFixed(2)
                    ) + " €"
              }
              size={"normal"}
              color={"item"}
            />
            <Textbox
              title={"Margem Final"}
              value={
                isNaN(parseFloat(optionSelectedFinalMargin)) ||
                optionSelectedFinalMargin === undefined
                  ? "0,00 €"
                  : numberWithCommas(
                      parseFloat(optionSelectedFinalMargin).toFixed(2)
                    ) + " €"
              }
              size={"normal"}
              color={"item"}
            />
          </div>
        </div>
        <div style={{ paddingTop: "30px" }} />
        <div className="liftworld-table-header-wrapper">
          <div className="liftworld-table-header-wrapper">
            {Object.keys(monthsArray).map((key, index) => (
              <TableTextbox
                value={monthsArray[key]}
                position={getPositionTop(index, monthsArray)}
                color={"colored"}
                number={index != 0 ? "number" : ""}
              />
            ))}
          </div>
        </div>
        <div className="liftworld-table-header-wrapper">
          {Object.keys(totalFacture).map((key, index) => (
            <div
              style={{
                display: "flex",
                width: getPercentage(Object.keys(totalFacture).length),
              }}
            >
              <TableInput
                field={buildInputField(
                  "",
                  totalFacture[key] === 0 ? "0.00" : totalFacture[key]
                )}
                size={"large"}
                position={"middle"}
                key={uuid()}
                workplanIndex={workplanIndex}
                name={"resumoFinaceiro.resumeFactoration"}
                number={index != 0 ? "number" : ""}
                euro={index != 0 ? true : false}
                disabled={
                  index === 0 ||
                  index === Object.keys(totalFacture).length - 1 ||
                  disabledInputs()
                    ? true
                    : false
                }
                resumoFactIndex={index - 1}
                error={
                  workplans[workplanIndex].resumoFinaceiro.canSave
                    ? ""
                    : index === Object.keys(totalFacture).length - 1
                    ? "error"
                    : ""
                }
              />
            </div>
          ))}
        </div>
        <div className="liftworld-table-header-wrapper">
          <div className="liftworld-table-header-wrapper">
            {Object.keys(totalCost).map((key, index) => (
              <TableTextbox
                value={
                  index === 0
                    ? totalCost[key]
                    : isNaN(parseFloat(totalCost[key])) ||
                      totalCost[key] === undefined
                    ? "0,00 €"
                    : numberWithCommas(parseFloat(totalCost[key]).toFixed(2)) +
                      "€"
                }
                position={"middle"}
                number={index != 0 ? "number" : ""}
              />
            ))}
          </div>
        </div>
        <div className="liftworld-table-header-wrapper">
          <div className="liftworld-table-header-wrapper">
            {Object.keys(income).map((key, index) => (
              <TableTextbox
                value={
                  index === 0
                    ? income[key]
                    : isNaN(parseFloat(income[key])) ||
                      income[key] === undefined
                    ? "0,00 €"
                    : numberWithCommas(parseFloat(income[key]).toFixed(2)) + "€"
                }
                position={"middle"}
                color={"colored"}
                number={index != 0 ? "number" : ""}
              />
            ))}
          </div>
        </div>
        <div className="liftworld-table-header-wrapper">
          <div className="liftworld-table-header-wrapper">
            {Object.keys(percIncome).map((key, index) => (
              <TableTextbox
                value={
                  index === 0
                    ? percIncome[key]
                    : isNaN(parseFloat(percIncome[key])) ||
                      percIncome[key] === undefined
                    ? "0,00 %"
                    : numberWithCommas(parseFloat(percIncome[key]).toFixed(2)) +
                      "%"
                }
                position={getPositionBottom(index, percIncome)}
                color={"colored"}
                number={index != 0 ? "number" : ""}
              />
            ))}
          </div>
        </div>
        <div style={{ paddingTop: "15px" }} />
        <div className="liftworld-proposal-wrapper-padding">
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(costHours).map((key, index) => (
                <TableTextbox
                  value={
                    index === 0
                      ? costHours[key]
                      : isNaN(parseFloat(costHours[key])) ||
                        costHours[key] === undefined
                      ? "0,00 €"
                      : index === Object.keys(costHours).length -1 ? numberWithCommas(parseFloat(costHours[key]).toFixed(2)) + "€" : (
                        numberWithCommas(Math.trunc(parseFloat(costHours[key])*100)/100
                        ).toString()) + "€"
                  }
                  position={getPositionTop(index, costHours)}
                  number={index != 0 ? "number" : ""}
                />
              ))}
            </div>
          </div>
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(hours).map((key, index) => (
                <TableTextbox
                  value={hours[key]}
                  position={getPositionBottom(index, hours)}
                  number={index != 0 ? "number" : ""}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="liftworld-proposal-wrapper-padding">
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(margin).map((key, index) => (
                <TableTextbox
                  value={
                    index === 0
                      ? margin[key]
                      : isNaN(parseFloat(margin[key])) ||
                        margin[key] === undefined
                      ? "0,00 €"
                      : numberWithCommas(parseFloat(margin[key]).toFixed(2)) +
                        "€"
                  }
                  position={getPositionTop(index, margin)}
                  number={index != 0 ? "number" : ""}
                />
              ))}
            </div>
          </div>
          <div className="liftworld-table-header-wrapper">
            <div className="liftworld-table-header-wrapper">
              {Object.keys(percMargin).map((key, index) => (
                <TableTextbox
                  value={
                    index === 0
                      ? percMargin[key]
                      : isNaN(parseFloat(percMargin[key])) ||
                        percMargin[key] === undefined
                      ? "0,00 %"
                      : numberWithCommas(
                          parseFloat(percMargin[key]).toFixed(2)
                        ) + "%"
                  }
                  position={getPositionBottom(index, percMargin)}
                  number={index != 0 ? "number" : ""}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectDescriptionSection;
