import React, { useState, useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const devLogConsole = require("~/utils/devLog");
const Preview = ({ t, documents, handleCloseSignModal, testSignPdf }) => {
  const myRef = useRef(null);
  const [coordinates, setCoordinates] = useState({ x: 0.1, y: 0.1 });
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(null);
  const [lastPage, setLastPage] = useState(true);
  const [reduced, setReduced] = useState(false);
  const [givenLocation, setGivenLocation] = useState("");
  const [givenReason, setGivenReason] = useState("");
  const [canvasWidth, setCanvasWidth] = useState(765);
  const [canvasHeight, setCanvasHeight] = useState(1000);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (numPages && lastPage) {
      setPageNumber(numPages);
    }
  }, [numPages, lastPage]);

  useEffect(() => {
    if (pageNumber == numPages) setLastPage(true);
    else setLastPage(false);
  }, [pageNumber]);

  useEffect(() => {
    const button1 = document.getElementById("button1");
    if (button1) {
      button1.focus();
    }
  }, [numPages]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    showHelper();
  }
  function onRenderSuccess() {
    const elements = document.getElementsByClassName("react-pdf__Page__canvas");
    if (elements[0]) {
      elements[0].width && setCanvasWidth(elements[0].width);
      elements[0].height && setCanvasHeight(elements[0].height);
    }
  }

  const hideHelper = () => {
    const button1 = document.getElementById("button1");
    if (button1) button1.style.display = "none";
  };
  const showHelper = () => {
    const button1 = document.getElementById("button1");
    if (button1) button1.style.display = "block";
  };

  function onLoadError() {
    hideHelper();
    setDisabled(true);
  }
  const handleRelativeCoords = (e) => {
    e.stopPropagation();
    e.preventDefault();
    hideHelper();

    const bounds = e.target.getBoundingClientRect();
    const x = e.clientX - bounds.left;
    const y = e.clientY - bounds.top;
    const xrel = (x / bounds.width).toFixed(2);
    const yrel = (y / bounds.height).toFixed(2); //bottom left
    const yrelTopLeft = ((y + 100) / bounds.height).toFixed(2); //top left
    setCoordinates({ x: xrel, y: yrelTopLeft });

    const canvas = document.getElementById("a4-canvas");
    const xCanvas = canvas.width * xrel;
    const yCanvas = canvas.height * yrel;
    //devLogConsole("noraml css coordinates" , x,y)
    //devLogConsole("canvas calculated coords", xCanvas, yCanvas)

    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = "#2980b9";
    context.beginPath();
    context.rect(xCanvas, yCanvas, 120, 40);
    context.fill();
  };

  const renderOptions = () => {
    if (numPages) {
      const options = [];
      for (let i = 1; i <= numPages; i++) {
        options.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
      return options;
    }
  };

  function parseNumber() {
    if (pageNumber) {
      const parsed = Number.parseInt(pageNumber);
      if (Number.isNaN(parsed)) {
        return 1;
      }
      return parsed;
    }
    return 1;
  }

  return (
    <>
      <Modal.Body>
        {numPages > 1 && (
          <div style={{ display: "flex", padding: "10px 0px" }}>
            <div style={{ padding: "0.375rem 0" }}>
              {t("signature.page")} &nbsp;
            </div>
            <Form.Control
              as="select"
              style={{ width: "auto"}}
              onChange={(e) => setPageNumber(e.target.value)}
              value={pageNumber || ""}
            >
              {renderOptions()}
            </Form.Control>
            <div style={{ padding: "0.375rem 0" }}>
              {" "}
              &nbsp; {t("signature.of")} {numPages}
            </div>
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 0px",
          }}
        >
          {numPages > 1 && (
            <Col sm="12" md="8" lg="8">
              <Form.Row>
                <Form.Check
                  type="switch"
                  id="last-page"
                  label={t("signature.lastPage")}
                  style={{ marginBottom: "10px" }}
                  checked={lastPage}
                  onChange={() => setLastPage((lastPage) => !lastPage)}
                />
              </Form.Row>
            </Col>
          )}
          <Col sm="12" md="8" lg="8">
            <Form.Row>
              <Form.Check
                type="switch"
                id="reduced"
                label={t("signature.reduced")}
                style={{ marginBottom: "10px" }}
                checked={reduced}
                onChange={() => setReduced((reduced) => !reduced)}
              />
            </Form.Row>
          </Col>
          <Col sm="12" md="8" lg="8">
            <Form.Row>
              <Form.Label>{t("signature.givenReason")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                type="text"
                value={givenReason}
                onChange={(e) => {
                  setGivenReason(e.target.value);
                }}
              />
            </Form.Row>
          </Col>
          <br />
          <Col sm="12" md="8" lg="8">
            <Form.Row>
              <Form.Label>{t("signature.givenLocation")}</Form.Label>
              <Form.Control
                autoComplete="off"
                required
                type="text"
                style={{ marginBottom: "10px" }}
                value={givenLocation}
                onChange={(e) => {
                  setGivenLocation(e.target.value);
                }}
              />
            </Form.Row>
          </Col>
        </div>
        <br />
        {documents[0] && documents[0].file && (
          <>
            <div style={{ position: "relative" }}>
              <Document
                file={documents[0].file}
                onLoadSuccess={onDocumentLoadSuccess}
                className="preview-canvas-wrapper"
              >
                {pageNumber && (
                  <Page
                    pageNumber={parseNumber()}
                    canvasRef={myRef}
                    onRenderSuccess={onRenderSuccess}
                    onLoadError={onLoadError}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                )}
              </Document>
              <canvas
                id="a4-canvas"
                onClick={(e) => {
                  {
                    handleRelativeCoords(e);
                  }
                }}
                width={canvasWidth}
                height={canvasHeight}
                style={{
                  zIndex: "100",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
              ></canvas>
            </div>
          </>
        )}

        {
          <button
            id="button1"
            className="helper-button"
            style={{ display: "none" }}
          >
            {t("signature.signHelper")}
          </button>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="card-button onboarding-button"
          style={{ backgroundColor: "#33058D", border: "none" }}
          disabled={disabled}
          onClick={() => {
            handleCloseSignModal(false);
            testSignPdf(
              coordinates,
              pageNumber,
              lastPage,
              reduced,
              givenLocation,
              givenReason
            );
          }}
        >
          {t("signature.signPdf")}
        </Button>
      </Modal.Footer>
    </>
  );
};
export default withNamespaces()(Preview);
