import React from "react";
import { Button } from "react-bootstrap";
import uploadIcon from "~/assets/img/icons/icon-upload.png";

const UploadButton = ({ disabled, text, onClick, type, onChange }) => {
  return (
    <Button
      type={type ? type : "button"}
      disabled={disabled}
      variant="outline-primary"
      onClick={type !== "file" ? onClick : null}
      className="custom-button"
      onChange={onChange}
    >
      {text}
      <img src={uploadIcon} style={{ marginLeft: "10px" }} alt="upload" />
    </Button>
  );
};

export default UploadButton;
