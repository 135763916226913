export const SET_DOCUMENT = "iflow@SET_DOCUMENT";
export const FIND_DOCUMENT = "iflow@FIND_DOCUMENT";

export const setDocument = (currentDocument) => {
  return { type: SET_DOCUMENT, data: currentDocument };
};
export const findDocument = (docid) => ({ type: FIND_DOCUMENT, docid });

export const SET_CONFIRM_PROCESS_MESSAGE = "iflow@SET_CONFIRM_PROCESS_MESSAGE";
export const setConfirmProcessMessage = (message) => ({
  type: SET_CONFIRM_PROCESS_MESSAGE,
  data: message,
});

export const SET_FOCUS_ELEMENT = "iflow@SET_FOCUS_ELEMENT";

export const setFocusElement = (element) => {
  return { type: SET_FOCUS_ELEMENT, data: element };
};
