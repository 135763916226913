import { put } from "redux-saga/effects";
import axios from "axios";
import http from "~/utils/config/http";
import { activeError } from "~/components/Messages/actions";
import {
  resetIflowDocumentsLinkComponent,
  setIflowDocumentsLinkComponent,
  setUserSettingsStamp,
  setSignatureCoordinates,
  setIflowDocumentTableFileComponent,
  resetIflowDocumentTableFileComponent,
  setTableFileSignClicked,
} from "./actionTypes";
import { IFLOW_APP } from "~/utils/constants";
const devLogConsole = require("~/utils/devLog");
//iFlow/document?
export function* getIflowDocumentLinkComponent({ data }) {
  try {
    //devLogConsole("data url", data.url);
    const resp = yield axios.get(`${data.url}`, { responseType: "blob" });
    yield put(
      setIflowDocumentsLinkComponent(resp.data, data.href, data.filename)
    );
  } catch (e) {
    yield put(activeError("getIflowDocumentLinkComponent"));
    yield put(resetIflowDocumentsLinkComponent());
  }
}

/*unikUserSettings start. Stamp update.*/
export function* findUserSettingsStamp({ username }) {
  try {
    const resp = yield http.get(`/api/mongo/user/${username}`);
    yield put(setUserSettingsStamp(resp.data));
  } catch (e) {
    yield put(setUserSettingsStamp(null));
  }
}

export function* updateUserSettingsStamp({ username, stamp }) {
  const data = new FormData();
  data.append("stamp", stamp);
  try {
    const resp = yield http.put(
      `/api/mongo/user/update-stamp/${username}`,
      data
    );
    yield put(setUserSettingsStamp(resp.data));
  } catch (e) {}
}
/*unikUserSettings end*/

export function* getSignatureCoordinates({ data }) {
  try {
    const response = yield http.get(`/jsessionidretriever`, IFLOW_APP);

    if (
      data &&
      data.docid &&
      data.flowid &&
      data.pid &&
      data.subpid &&
      response?.data?.jsessionId
    ) {
      const resp = yield http.post(`/api/signature-coordinates/find`, {
        jsessionId: response?.data?.jsessionId,
        docId: data.docid,
        flowId: data.flowid,
        pid: data.pid,
        subpid: data.subpid,
        hash: data.isHash,
      });
      yield put(setSignatureCoordinates(resp.data, data.docid));
    }
  } catch (e) {
    devLogConsole(e);
  }
}

//iFlow/document?
export function* getIflowDocumentTableFileComponent({ data }) {
  let error;
  for (let i = 0; i < 2; i++) {
    try {
      error = null;
      //yield put(resetIflowDocumentTableFileComponent());
      const resp = yield axios.get(`${data.url}`, { responseType: "blob" });
      yield put(
        setIflowDocumentTableFileComponent(resp.data, data.docid, data.filename)
      );
      break;
    } catch (e) {
      error = e;
    }
  }
  if (error) {
    yield put(activeError(`Não conseguimos obter o documento  ${data.docid}`));
    yield put(resetIflowDocumentTableFileComponent());
    yield put(setTableFileSignClicked(false));
  }
}
