import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop, DragPreviewImage } from "react-dnd";
import ItemTypes from "./ItemTypes";
import CardEarningMonthly from "../../widgets/CardEarningMonthly";
import TaskCompleted from "../../widgets/TaskCompleted";
import Projetos from "../../widgets/Projetos";
import GoogleNewsWidget from "../../widgets/GoogleNewsWidget";
import DefaultWidget from "../../widgets/DefaultWidget";
import LineChartWidget from "../../widgets/LineChartWidget";
import ProcessosTratadosWidget from "../../widgets/ProcessosTratadosWidget";
import DesempenhoBPMWidget from "../../widgets/DesempenhoBPMWidget";
import SlaReportWidget from "../../widgets/SlaReportWidget";
import ProcessStatisticsWidget from "../../widgets/ProcessStatisticsWidget";
import Tasks from "../../widgets/Tasks";
import NextTask from "../../widgets/NextTask";
import ProfileWidget from "../../widgets/ProfileWidget";
import ServicoGenericoWidget from "../../widgets/ServicoGenericoWidget";
import NotesWidget from "../../widgets/NotesWidget";
import TasksWidget from "../../widgets/TasksWidget";
import ProcessesWidget from "../../widgets/ProcessesWidget";
import DataCaptureWidget from "../../widgets/DataCaptureWidget";
import ErrorWidget from "../../widgets/ErrorWidget";
import LatestTasksWidget from "../../widgets/LatestTasksWidget";
import ProcessesStatusWidget from "../../widgets/ProcessesStatusWidget";
import { useRouteMatch } from "react-router-dom";
import { updateUserSettings, deleteUserWidget } from "~/pages/User/actions";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/styles.css";
import grab from "~/assets/img/icons/grab.svg";
import { Button, Modal, Col } from "react-bootstrap";

import MyRequestsWidget from "../../widgets/Requests/MyRequestsWidget";
import AuthorizationsWidget from "../../widgets/Requests/AuthorizationsWidget";
import { updateUserWidget } from '~/pages/User/actions';

//WebTime
import MyRequestsWebTimeWidget from "../../widgets/Requests/MyRequestsWebTimeWidget";
import AuthorizationsWebTimeWidget from "../../widgets/Requests/AuthorizationsWebTimeWidget";

import CheckInAndOutWidget from "../../widgets/CheckInAndOutWidget";

const Widget = ({ widget, list, index, widgets }) => {
  const { userSettings } = useSelector((state) => state.userSettingsReducer);
  const { id } = widget;

  const dispatch = useDispatch();
  const ref = useRef(null);
  const { path } = useRouteMatch();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const apagarWidget = () => {
    if (
      userSettings.dashboards &&
      userSettings.dashboards.some((item) => item.application === path)
    ) {
      const sourceListIndex = list;
      const oldIndex = index;
      const dashboard = userSettings.dashboards.find(
        (item) => item.application === path
      );
      const placements = dashboard.placements ? dashboard.placements : [[]];
      /*exists bug on the backend, sometimes widgets do not match placements, here is workaround*/
      const newSourceList = Array.from(placements[sourceListIndex]).filter(
        (id) => widgets.some((widget) => widget.id === id)
      );
      newSourceList.splice(oldIndex, 1);
      const newPlacements = Array.from(placements);
      newPlacements.splice(sourceListIndex, 1);
      newPlacements.splice(sourceListIndex, 0, newSourceList);

      let newDashboards = userSettings.dashboards.map((item) => {
        if (item.application === path) {
          item.placements = newPlacements;
          return item;
        } else return item;
      });
      const payload = {
        ...userSettings,
        dashboards: newDashboards,
      };
      dispatch(updateUserSettings(payload));
      dispatch(deleteUserWidget(widget.id));
    }
    handleClose();
  };

  const [{ opacity }, drag, preview] = useDrag({
    item: { id, list, index, type: ItemTypes.BOX },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.BOX,
    drop(item, monitor) {
      const destinationListIndex = list;
      const newIndex = index;
      const oldIndex = item.index;
      const sourceListIndex = item.list;
      const dragId = item.id;
      const dashboard = userSettings.dashboards.find(
        (item) => item.application === path
      );
      const placements = dashboard.placements ? dashboard.placements : [[]];
      const length = placements[destinationListIndex].length;

      if (destinationListIndex === sourceListIndex && length === 1)
        return undefined;

      if (destinationListIndex === sourceListIndex && newIndex === oldIndex)
        return undefined;

      let newPlacements = Array.from(placements);
      if (destinationListIndex === sourceListIndex) {
        let newIndex_;
        if (newIndex === undefined) {
          newIndex_ = length - 1;
        } else {
          newIndex_ = newIndex;
        }
        /*exists bug on the backend, sometimes widgets do not match placements, here is workaround*/
        const newDestinationList = Array.from(
          placements[destinationListIndex]
        ).filter((id) => widgets.some((widget) => widget.id === id));
        newDestinationList.splice(oldIndex, 1);
        newDestinationList.splice(newIndex_, 0, dragId);
        newPlacements.splice(destinationListIndex, 1);
        newPlacements.splice(destinationListIndex, 0, newDestinationList);
      } else {
        /*exists bug on the backend, sometimes widgets do not match placements, here is workaround*/
        const newDestinationList = Array.from(
          placements[destinationListIndex]
        ).filter((id) => widgets.some((widget) => widget.id === id));
        const newSourceList = Array.from(placements[sourceListIndex]).filter(
          (id) => widgets.some((widget) => widget.id === id)
        );
        let newIndex_;
        if (length === 0) newIndex_ = 0;
        if (newIndex === undefined && length > 0) newIndex_ = length;
        newDestinationList.splice(newIndex_, 0, dragId);
        newSourceList.splice(oldIndex, 1);
        newPlacements.splice(destinationListIndex, 1);
        newPlacements.splice(destinationListIndex, 0, newDestinationList);
        newPlacements.splice(sourceListIndex, 1);
        newPlacements.splice(sourceListIndex, 0, newSourceList);
      }

      const newDashboards = userSettings.dashboards.map((item) => {
        if (item.application === path) {
          item.placements = newPlacements;
          return item;
        } else return item;
      });

      const payload = {
        ...userSettings,
        dashboards: newDashboards,
      };
      dispatch(updateUserSettings(payload));
      return undefined;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  drag(drop(ref));

  const Components = {
    NotesWidget,
    GoogleNewsWidget,
    TasksWidget,
    DataCaptureWidget,
    ProcessesWidget,
    Tasks,
    NextTask,
    CardEarningMonthly,
    TaskCompleted,
    Projetos,

    ProfileWidget,
    ServicoGenericoWidget,
    DefaultWidget,
    LineChartWidget,
    DesempenhoBPMWidget,
    SlaReportWidget,
    ProcessStatisticsWidget,
    ProcessosTratadosWidget,
    LatestTasksWidget,
    ProcessesStatusWidget,

    ErrorWidget,

    MyRequestsWidget,
    AuthorizationsWidget,

    MyRequestsWebTimeWidget,
    AuthorizationsWebTimeWidget,

    CheckInAndOutWidget,
  };
  let Component = Components[widget.widget.component]
    ? Components[widget.widget.component]
    : null;

  let widgetSize = widget?.userConfiguration?.widthSize ? widget.userConfiguration.widthSize : 4;

  const [width, setWidth] = useState(parseFloat(widgetSize));
  const [height, setheight] = useState(400);
  const [isResizing, setIsResizing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [textMove, settextMove] = useState(false)
  const [textClick, settextClick] = useState(false)

  useEffect(()=>{
    if(widget.userConfiguration != null){
      switch (widget.userConfiguration.widthSize) {
        case "4":
          setheight(210)
          setWidth(widget.userConfiguration.widthSize)
          break;
        case "5":
          setheight(630)
          setWidth(6)
          break;
        case "6":
          setheight(315)
          setWidth(6)
        break;
        case "8":
          setheight(420)
          setWidth(widget.userConfiguration.widthSize)
          break;
        case "12":
          setheight(630)
          setWidth(widget.userConfiguration.widthSize)
          break;
        default:
          setheight(400)
          setWidth(widget.userConfiguration.widthSize)
          break;
      }
    }
  },[widget])

  const handleMouseDown = (width) => {
    switch (width) {
      case "4":
        setheight(210)
        setWidth(width)
        sendUpdate(width)
        break;
      case "5":
        setheight(630)
        setWidth(6)
        sendUpdate("5")
        break;
      case "6":
        setheight(315)
        setWidth(width)
        sendUpdate(width)
      break;
      case "8":
        setheight(420)
        setWidth(width)
        sendUpdate(width)
        break;
      case "12":
        setheight(630)
        setWidth(width)
        sendUpdate(width)
        break;
      default:
        setheight(400)
        setWidth(width)
        sendUpdate(width)
        break;
    }
  };

  const sendUpdate = (width) => {
    if(widget.userConfiguration == null) return
    const payload = {
      id: widget?.id,
      widgetId: widget?.widgetId,
      widget: widget?.widget,
      userConfiguration: {
        type: widget?.userConfiguration?.type,
        configurationId: widget?.userConfiguration?.configurationId,
        widthSize: width
      },
    };
    dispatch(updateUserWidget(payload));
  };

  return (
    <>
      {Component ? (
        <>
          <div
            className="resizable-col"
            style={{ width: width != null ? `${width * 8.3333}%` : "auto", background: isResizing ? "rgb(241,241,241)" : "", borderRadius: isResizing ? "10px": ""}}
            // onMouseMove={handleMouseMove}
            // onMouseUp={handleMouseUp}
          >
              <div className="content">
                <DragPreviewImage connect={preview} src={grab} />
                <Col
                  ref={ref}
                  className="box-wrapper"
                  style={{
                    opacity: opacity,
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <Component
                    widget={widget}
                    apagarWidget={() => handleShow(true)}
                    handleMouseDown={handleMouseDown}
                    isResizing={isResizing}
                    textMove={textMove}
                    textClick={textClick}
                    height={height}
                    width={width}
                  />
                </Col>
              </div>
          </div>
        </>
      ) : (
        <>
          <DragPreviewImage connect={preview} src={grab} />
          <Col
            ref={ref}
            className="box-wrapper"
            style={{
              opacity: opacity,
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
            xs="12"
            md={widgetSize}
          >
            <ErrorWidget
              widget={widget}
              apagarWidget={() => handleShow(true)}
            />
          </Col>
        </>
      )}
      <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>Remover widget ? </Modal.Body>

        <Modal.Footer style={{ backgroundColor: "transparent" }}>
          <Button variant="danger" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            className="modal-submit-button"
            onClick={() => apagarWidget()}
          >
            Remover
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Widget;
