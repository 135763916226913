import { takeLatest, all } from "redux-saga/effects";
import {
  REQUEST_LOGIN,
  LOGOUT,
  REQUEST_LOGIN_GLOBAL_SOLVER,
  GOOGLE_SIGN_IN,
  LDAP_SIGN_IN,
  GET_SAML_URL_REQUEST,
  SIGN_IN_SAML,
  MICROSOFT_SIGN_IN,
  UDW_ADFS_SIGN_IN,
  GET_WINDOWS_AUTH_CONFIG,
  GET_KEYCLOAK_AUTH_CONFIG,
  GET_WINDOWS_AUTH_CONFIG_IFLOW,
  GET_REQUEST_INTRANET_LOGIN,
  GET_WINDOWS_AUTH_CONFIG_SIGN_PDF,
} from "../../pages/Login/actions";
import {
  requestLogin,
  requestLogout,
  requestLoginGlobalSolver,
  requestGoogleSignIn,
  requestLDAPSignIn,
  getSAMLRequestURL,
  signInSAML,
  requestMicrosoftSignIn,
  requestADFSSignIn,
  doWindowsSignIn,
  doWindowsSignInIFlow,
  doWindowsSignInSignPdf,
  doKeycloakSignIn,
  doGenericLoginFromIntranet,
} from "../../pages/Login/sagas";

import {
  REQUEST_USERS,
  LOAD_LEGACY_APPS,
  ADD_USER,
  DELETE_USER,
  UPDATE_USER,
  REMOVE_USER_PICTURE,
  LOAD_LEGACY_USERS,
  LOAD_UNIK_USER_BY_ID,
  REQUEST_ALL_ROLES,
  CREATE_SIMPLE_USER,
  PULL_LEGACY_USERS,
  PULL_LEGACY_USERS_ONLY_APP,
  UPDATE_USER_SETTINGS_ADATACAPTURE,
  REQUEST_EXTENDED_ATTRIBUTES,
} from "~/pages/Admin/User/actions";
import {
  requestUsers,
  loadLegacyApps,
  addUser,
  deleteUser,
  updateUser,
  removeUserPicture,
  loadLegacyUsers,
  loadUnikUser,
  requestAllRoles,
  createSimpleUser,
  pullLegacyUsers,
  pullLegacyUsersOnlyApp,
  updateUserSettingsADatacapture,
  requestAllExtendedAttributes,
} from "~/pages/Admin/User/sagas";

import { REQUEST_USER } from "~/containers/Layout/actions";
import { requestUser } from "~/containers/Layout/sagas";

import {
  CREATE_USER_SETTINGS,
  UPDATE_USER_SETTINGS,
  UPDATE_UNIKUSER_SETTINGS,
  UPDATE_SIGNATURE_REDUCED,
  GET_ISFIRSTLOGIN,
  POST_ISFIRSTLOGIN,
} from "~/pages/User/actions";
import {
  createUserSettings,
  updateUserSettings,
  updateUnikUserSettings,
  updateSignatureReduced,
  getIsFirstLogin,
  postIsFirstLogin,
} from "~/pages/User/sagas";

import {
  FIND_ONLINE_USERS,
  FIND_CHAT,
  INIT_CHAT,
  SEND_MESSAGE,
} from "~/components/chat/actions";
import {
  findOnlineUsers,
  findChat,
  initChat,
  sendMessage,
} from "~/components/chat/sagas";

import {
  CREATE_BASE_WIDGET,
  UPDATE_BASE_WIDGET,
  GET_BASE_WIDGETS,
  DELETE_BASE_WIDGET,
  GET_PUZZLE_ITEMS,
  GET_PUZZLE_ITEMS_BY_ORG_ID,
  SAVE_PUZZLE_ITEMS,
  REQUEST_CONFIGURATION,
  GET_AUTH_MODES,
  UPDATE_CONFIGURATION,
} from "~/pages/Admin/SystemSettings/actions";

import {
  createBaseWidget,
  getBaseWidgets,
  updateBaseWidget,
  deleteBaseWidget,
  getPuzzleItems,
  getPuzzleItemsByOrgId,
  savePuzzleItems,
  requestConfiguration,
  getAuthModes,
  updateConfiguration,
} from "~/pages/Admin/SystemSettings/sagas";

import {
  FIND_WIDGET_BY_ID,
  FIND_ALL_WIDGETS,
  SAVE_USER_WIDGET,
  UPDATE_USER_WIDGET,
  DELETE_USER_WIDGET,
  FIND_WIDGETS_BY_APPLICATION,
  FIND_UNIKUSER_SETTINGS,
} from "~/pages/User/actions";
import {
  findWidgetById,
  findAllWidgets,
  saveUserWidget,
  updateUserWidget,
  deleteUserWidget,
  findWidgetsByApplication,
  findUnikUserSettings,
} from "~/pages/User/sagas";

import {
  GET_ROLES,
  PERSIST_ROLE,
  GET_ROLE_BY_ID,
  DELETE_ROLE,
  ALL_USERS_BY_ROLE,
  UPDATE_UNIK_USERS,
  ADD_DEFAULT_ROLE,
  DELETE_DEFAULT_ROLE,
  FIND_DEFAULT_ROLES,
  LOAD_LEGACY_ROLES,
} from "~/pages/Admin/Role/actions";

import {
  getRoles,
  persistRole,
  getRoleById,
  deleteRole,
  allUsersByRole,
  updateUnikUsers,
  addDefaultRole,
  deleteDefaultRole,
  findDefaultRoles,
  loadLegacyRoles,
} from "~/pages/Admin/Role/sagas";

import {
  GET_RULES,
  GET_RULE_INFORMATION_BY_ID,
  UPDATE_UNIK_USERS_RULE,
  PERSIST_RULE,
  DELETE_RULE,
} from "~/pages/Admin/Rules/actions";

import {
  getRules,
  getRuleInformationById,
  updateUnikUsersRule,
  persistRule,
  deleteRule,
} from "~/pages/Admin/Rules/sagas";

import {
  GET_PERMISSIONS,
  PERSIST_PERMISSION,
  GET_PERMISSION_BY_ID,
  DELETE_PERMISSION,
} from "~/pages/Admin/Permission/actions";

import {
  getPermissions,
  persistPermission,
  getPermissionById,
  deletePermission,
} from "~/pages/Admin/Permission/sagas";

import {
  FIND_ALL_BATCHS,
  FIND_BATCH_BY_FILTERS,
  FIND_BATCH_BY_ID,
  SAVE_DOCUMENT_INTO_BATCH,
  SAVE_DOCUMENLIST_INTO_BATCH,
  HANDLE_DELETE_BATCH,
  HANDLE_DELETE_DOCUMENT,
  REFRESH_DOCUMENT,
  GET_METADATA_DOCUMENT,
  GET_METADATA_DOCUMENT_FILENAME,
  GET_DOCUMENT,
  SAVE_METADATA_DOCUMENT,
  SUBMIT_TO_IFLOW,
  HANDLE_UPDATE_BATCH,
  GET_IFLOW_CONFIGURATION,
  HANDLE_DELETE_DOCUMENTS,
  HANDLE_UPDATE_DOCUMENTS,
  HANDLE_UPDATE_DOCUMENT,
  FIND_ALL_BATCHS_WITH_BINARY,
  HANDLE_UPDATE_APPROVED,
  REFRESH_APPROVED_DATE,
  FIND_ALL_CUSTOM_BATCHS,
  FIND_ALL_BINARY_CUSTOM_BATCHS,
  HANDLE_SAVE_PO_DOCUMENT,
} from "~/pages/DataCapture/actions";
import {
  findAllBatchs,
  findBatchByFilters,
  findBatchById,
  saveDocumentIntoBatch,
  saveDocumentListIntoBatch,
  deleteBatch,
  deleteDocument,
  refreshDocument,
  getMetadataDocument,
  getMetadataDocumentFilename,
  getDocument,
  saveMetadataDocument,
  submitToiFlow,
  updateBatch,
  updateDocuments,
  acceptDuplicate,
  getIflowConfiguration,
  deleteDocuments,
  findAllBatchsWithBinary,
  refreshApproved,
  refreshApprovedDate,
  findAllCustomBatchs,
  findAllCustomBinaryBatchs,
  savePoNumber,
} from "~/pages/DataCapture/sagas";

import {
  FIND_MFILES_BY_FILTERS,
  DOWNLOAD_MFILE,
} from "~/pages/DataCapture/components/MFiles/actions";
import {
  findMFilesByFilters,
  downloadMfile,
} from "~/pages/DataCapture/components/MFiles/sagas";

//Ducks
import { applicationSagas } from "./applicationSagas";
import { applicationMenuSagas } from "./applicationMenuSagas";
import { resetPasswordSagas } from "./resetPasswordSagas";
import { udwSagas } from "./udwSagas";
import { legacyPermissionSagas } from "./legacyPermissionSagas";
import { oauthSagas } from "./oauthSagas";
import { notificationsSagas } from "./notificationsSagas";
import { tasksSagas } from "./tasksSagas";
import { processesSagas } from "./processesSagas";
import { systemSettingsApplicationsSagas } from "./systemSettingsApplicationsSagas";
import { iflowReportsSagas } from "./iflowReportsSagas";
import { organizationSagas } from "./organizationSagas";
import { datacaptureConfigurationSagas } from "./datacaptureConfigurationSagas";
import { reportSagas } from "./reportSagas";
import { portalrhSagas } from "./portalrhSagas";
import { siadapraSagas } from "./siadapraSagas";
import { onboardingSagas } from "./onboardingSagas";
import { budgetManagementSagas } from "./budgetManagementSagas";
import { onboardingSettingsSagas } from "./onboardingSettingsSagas";
import { registerOrgAdminSagas } from "./registerOrgAdminSagas";
import { easypaySagas } from "./easypaySagas";
import { portalRhMenuSagas } from "./portalRhMenuSagas";
import { taskFoldersSagas } from "./taskFoldersSagas";
import { processAnnotationsSagas } from "./processAnnotationsSagas";
import { iflowSagas } from "./iflowSagas";
import { budgetmgrConfigurationSagas } from "./budgetmgrConfigurationSagas";
import { rhadminConfigurationSagas } from "./rhadminConfigurationSagas";
import { simulatorSagas } from "./simulatorSagas";
import { innuxSagas } from "./innuxSagas";
import { processesSignatureSagas } from "./processesSignatureSagas";

import { personalDataSagas } from "./personalDataSagas";
import { delegacoesSagas } from "./delegacoes";
import { claimsManagementSagas } from "./claimsManagementSagas";

export function* root() {
  yield all([
    //Ducks
    ...applicationSagas,
    ...applicationMenuSagas,
    ...resetPasswordSagas,
    ...udwSagas,
    ...legacyPermissionSagas,
    ...oauthSagas,
    ...notificationsSagas,
    ...tasksSagas,
    ...processesSagas,
    ...systemSettingsApplicationsSagas,
    ...iflowReportsSagas,
    ...organizationSagas,
    ...datacaptureConfigurationSagas,
    ...reportSagas,
    ...portalrhSagas,
    ...siadapraSagas,
    ...onboardingSagas,
    ...onboardingSettingsSagas,
    ...registerOrgAdminSagas,
    ...easypaySagas,
    ...portalRhMenuSagas,
    ...taskFoldersSagas,
    ...processAnnotationsSagas,
    ...budgetManagementSagas,
    ...claimsManagementSagas,
    ...iflowSagas,
    ...budgetmgrConfigurationSagas,
    ...rhadminConfigurationSagas,
    ...simulatorSagas,
    ...innuxSagas,

    ...personalDataSagas,
    ...delegacoesSagas,
    ...processesSignatureSagas,

    //Old way
    takeLatest(REQUEST_LOGIN, requestLogin),
    takeLatest(LOGOUT, requestLogout),
    takeLatest(REQUEST_LOGIN_GLOBAL_SOLVER, requestLoginGlobalSolver),
    takeLatest(GOOGLE_SIGN_IN, requestGoogleSignIn),
    takeLatest(LDAP_SIGN_IN, requestLDAPSignIn),
    takeLatest(GET_SAML_URL_REQUEST, getSAMLRequestURL),
    takeLatest(SIGN_IN_SAML, signInSAML),
    takeLatest(MICROSOFT_SIGN_IN, requestMicrosoftSignIn),

    takeLatest(REQUEST_USERS, requestUsers),

    takeLatest(LOAD_LEGACY_APPS, loadLegacyApps),
    takeLatest(LOAD_LEGACY_USERS, loadLegacyUsers),
    takeLatest(ADD_USER, addUser),
    takeLatest(DELETE_USER, deleteUser),
    takeLatest(UPDATE_USER, updateUser),
    takeLatest(REMOVE_USER_PICTURE, removeUserPicture),
    takeLatest(LOAD_UNIK_USER_BY_ID, loadUnikUser),
    takeLatest(REQUEST_ALL_ROLES, requestAllRoles),

    takeLatest(CREATE_SIMPLE_USER, createSimpleUser),
    takeLatest(PULL_LEGACY_USERS, pullLegacyUsers),
    takeLatest(PULL_LEGACY_USERS_ONLY_APP, pullLegacyUsersOnlyApp),

    takeLatest(REQUEST_USER, requestUser),

    takeLatest(CREATE_USER_SETTINGS, createUserSettings),
    takeLatest(UPDATE_USER_SETTINGS, updateUserSettings),
    takeLatest(UPDATE_UNIKUSER_SETTINGS, updateUnikUserSettings),
    takeLatest(UPDATE_SIGNATURE_REDUCED, updateSignatureReduced),
    takeLatest(
      UPDATE_USER_SETTINGS_ADATACAPTURE,
      updateUserSettingsADatacapture
    ),
    takeLatest(GET_ISFIRSTLOGIN, getIsFirstLogin),
    takeLatest(POST_ISFIRSTLOGIN, postIsFirstLogin),

    takeLatest(FIND_ONLINE_USERS, findOnlineUsers),
    takeLatest(FIND_CHAT, findChat),
    takeLatest(INIT_CHAT, initChat),
    takeLatest(SEND_MESSAGE, sendMessage),

    takeLatest(CREATE_BASE_WIDGET, createBaseWidget),
    takeLatest(GET_BASE_WIDGETS, getBaseWidgets),
    takeLatest(UPDATE_BASE_WIDGET, updateBaseWidget),
    takeLatest(DELETE_BASE_WIDGET, deleteBaseWidget),

    takeLatest(REQUEST_CONFIGURATION, requestConfiguration),
    takeLatest(GET_AUTH_MODES, getAuthModes),
    takeLatest(UPDATE_CONFIGURATION, updateConfiguration),

    takeLatest(GET_PUZZLE_ITEMS, getPuzzleItems),
    takeLatest(GET_PUZZLE_ITEMS_BY_ORG_ID, getPuzzleItemsByOrgId),
    takeLatest(SAVE_PUZZLE_ITEMS, savePuzzleItems),

    takeLatest(FIND_WIDGET_BY_ID, findWidgetById),
    takeLatest(FIND_ALL_WIDGETS, findAllWidgets),
    takeLatest(SAVE_USER_WIDGET, saveUserWidget),
    takeLatest(UPDATE_USER_WIDGET, updateUserWidget),
    takeLatest(DELETE_USER_WIDGET, deleteUserWidget),
    takeLatest(FIND_WIDGETS_BY_APPLICATION, findWidgetsByApplication),
    takeLatest(FIND_UNIKUSER_SETTINGS, findUnikUserSettings),

    takeLatest(GET_ROLES, getRoles),
    takeLatest(PERSIST_ROLE, persistRole),
    takeLatest(GET_ROLE_BY_ID, getRoleById),
    takeLatest(DELETE_ROLE, deleteRole),
    takeLatest(ALL_USERS_BY_ROLE, allUsersByRole),
    takeLatest(UPDATE_UNIK_USERS, updateUnikUsers),
    takeLatest(ADD_DEFAULT_ROLE, addDefaultRole),
    takeLatest(DELETE_DEFAULT_ROLE, deleteDefaultRole),
    takeLatest(FIND_DEFAULT_ROLES, findDefaultRoles),
    takeLatest(LOAD_LEGACY_ROLES, loadLegacyRoles),

    takeLatest(GET_RULES, getRules),
    takeLatest(GET_RULE_INFORMATION_BY_ID, getRuleInformationById),
    takeLatest(UPDATE_UNIK_USERS_RULE, updateUnikUsersRule),
    takeLatest(PERSIST_RULE, persistRule),
    takeLatest(DELETE_RULE, deleteRule),

    takeLatest(GET_PERMISSIONS, getPermissions),
    takeLatest(PERSIST_PERMISSION, persistPermission),
    takeLatest(GET_PERMISSION_BY_ID, getPermissionById),
    takeLatest(DELETE_PERMISSION, deletePermission),

    takeLatest(FIND_ALL_BATCHS, findAllBatchs),
    takeLatest(FIND_BATCH_BY_FILTERS, findBatchByFilters),
    takeLatest(FIND_BATCH_BY_ID, findBatchById),
    takeLatest(SAVE_DOCUMENT_INTO_BATCH, saveDocumentIntoBatch),
    takeLatest(SAVE_DOCUMENLIST_INTO_BATCH, saveDocumentListIntoBatch),
    takeLatest(HANDLE_DELETE_BATCH, deleteBatch),
    takeLatest(REFRESH_DOCUMENT, refreshDocument),
    takeLatest(GET_METADATA_DOCUMENT, getMetadataDocument),
    takeLatest(GET_METADATA_DOCUMENT_FILENAME, getMetadataDocumentFilename),
    takeLatest(GET_DOCUMENT, getDocument),
    takeLatest(SAVE_METADATA_DOCUMENT, saveMetadataDocument),
    takeLatest(HANDLE_DELETE_DOCUMENT, deleteDocument),
    takeLatest(HANDLE_UPDATE_BATCH, updateBatch),
    takeLatest(SUBMIT_TO_IFLOW, submitToiFlow),
    takeLatest(GET_IFLOW_CONFIGURATION, getIflowConfiguration),
    takeLatest(HANDLE_DELETE_DOCUMENTS, deleteDocuments),
    takeLatest(HANDLE_UPDATE_DOCUMENTS, updateDocuments),
    takeLatest(HANDLE_UPDATE_DOCUMENT, acceptDuplicate),
    takeLatest(FIND_ALL_BATCHS_WITH_BINARY, findAllBatchsWithBinary),
    takeLatest(FIND_ALL_CUSTOM_BATCHS, findAllCustomBatchs),
    takeLatest(FIND_ALL_BINARY_CUSTOM_BATCHS, findAllCustomBinaryBatchs),
    takeLatest(FIND_MFILES_BY_FILTERS, findMFilesByFilters),
    takeLatest(DOWNLOAD_MFILE, downloadMfile),
    takeLatest(REQUEST_EXTENDED_ATTRIBUTES, requestAllExtendedAttributes),
    takeLatest(GET_WINDOWS_AUTH_CONFIG, doWindowsSignIn),
    takeLatest(GET_KEYCLOAK_AUTH_CONFIG, doKeycloakSignIn),
    takeLatest(GET_WINDOWS_AUTH_CONFIG_IFLOW, doWindowsSignInIFlow),
    takeLatest(GET_WINDOWS_AUTH_CONFIG_SIGN_PDF, doWindowsSignInSignPdf),
    takeLatest(HANDLE_UPDATE_APPROVED, refreshApproved),
    takeLatest(REFRESH_APPROVED_DATE, refreshApprovedDate),
    takeLatest(GET_REQUEST_INTRANET_LOGIN, doGenericLoginFromIntranet),
    takeLatest(HANDLE_SAVE_PO_DOCUMENT, savePoNumber),
  ]);
}
