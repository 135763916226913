import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { Card } from "react-bootstrap";

import LoginLogo from "./LoginLogo";
import LoginLogoNB from "./LoginLogoNB";
import RegularAuthentication from "./RegularAuthentication";
import RegularAuthenticationNB from "./RegularAuthenticationNB";
import GoogleAuthentication from "./GoogleAuthentication";
import MicrosoftAuthentication from "./MicrosoftAuthentication";
import UdwADFSAuthentication from "./UdwADFSAuthentication";
import SignInWithSection from "./SignInWithSection";
import SAMLAuthentication from "./SAMLAuthentication";
import RegularAuthenticationSpark from "./RegularAuthenticationSpark";
import Toast from "~/components/Toast";
import FullSpinner from "~/components/Spinner";
import Error500 from "~/components/Exception/Error500";
import DrpaFooter from "~/pages/Login/components/DrpaFooter";
import DrpaLogo from "~/assets/img/logos/acores-pesca.png";
import ISELLogo from "~/assets/img/logos/isel-logo.png";
import UnikPeopleLogo from "~/assets/img/logos/unikpeople-logo.png";
import SparkLogo from "~/assets/img/logos/spark.png";
import MarquesLogo from "~/assets/img/logos/marques.png";
import FHCLogo from "~/assets/img/logos/fhc.png";
import IPSLogo from "~/assets/img/logos/ips.png";
import ADCLogo from "~/assets/img/logos/adc.png";
import LusitecaLogo from "~/assets/img/logos/lusiteca.png";
import BuzzLogo from "~/assets/img/logos/buzz.png";
import EdenRedLogo from "~/assets/img/logos/edenred.png";

import "~/assets/css/styles.css";

import * as authDomain from "../utils/AuthDomain";
import { requestConfiguration } from "~/pages/Admin/SystemSettings/actions";
import EasyPaySSO from "./EasyPaySSO";
import WindowsAuthentication from "./WindowsAuthentication";
import KeycloakAuthentication from "./KeycloakAuthentication";
import RegularAuthenticationISEL from "./RegularAuthenticationISEL";

const LoginForm = (props) => {
  const { t, setKeycloakLoginClicked, setWindowsLoginClicked } = props;
  const dispatch = useDispatch();
  const {
    configuration,
    isLoadingConfiguration,
    successRetrieveConfiguration,
  } = useSelector((state) => state.adminConfigReducer);

  const [counter, setCounter] = useState(0);
  const [firstRender, setFirstRender] = useState(false);
  useEffect(() => {
    dispatch(requestConfiguration());
  }, []);

  authDomain.setConfiguration(configuration);

  function clickKeycloakLogin() {
    setKeycloakLoginClicked(true);
  }

  function clickWindowsLogin() {
    setWindowsLoginClicked(true);
  }

  useEffect(() => {
    if (counter === 0) {
      setFirstRender(true);
      setCounter(1);
    }

    if (counter >= 1) {
      setFirstRender(false);
    }
  }, [counter]);

  useEffect(() => {
    if (configuration && configuration.browserTabName) {
      document.title = configuration.browserTabName || "";
    }
  }, [configuration]);

  const renderLoginForm = () => {
    let config = configuration?.loginPage;
    if (config === "nblp") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <LoginLogoNB />
              <hr style={{ marginTop: "0px" }} />
              <Card className="card-login">
                <Card.Body>
                  {authDomain.isRegularAuthentication() && (
                    <RegularAuthenticationNB />
                  )}
                  <SignInWithSection>
                    {authDomain.isADFSAuthentication() && (
                      <UdwADFSAuthentication />
                    )}
                    {authDomain.isMicrosoftAuthentication() && (
                      <MicrosoftAuthentication isNB={true} />
                    )}
                  </SignInWithSection>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "drpa") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={DrpaLogo}
                        alt="açores pesca"
                      />
                    </div>
                    {authDomain.isRegularAuthentication() && (
                      <RegularAuthentication />
                    )}
                    {(authDomain.isGoogleAuthentication() ||
                      authDomain.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomain.isGoogleAuthentication() && (
                          <GoogleAuthentication />
                        )}
                        {authDomain.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication />
                        )}
                        {authDomain.isADFSAuthentication() && (
                          <UdwADFSAuthentication />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomain.isSAMLAuthentication() && (
                      <SAMLAuthentication />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                  </div>
                  <div className="drpa-login-2">
                    A{" "}
                    <a
                      href="https://portal.azores.gov.pt/web/drp"
                      target="_blank"
                    >
                      Direção Regional das Pescas – DRP
                    </a>{" "}
                    é o órgão operativo da{" "}
                    <a
                      href="https://portal.azores.gov.pt/web/srmp"
                      target="_blank"
                    >
                      Secretaria Regional do Mar e das Pescas
                    </a>
                    , que tem em vista a execução das política na área das
                    pescas.
                    <p>
                      Esta plataforma online assume como principal desígnio
                      desburocratizar a execução de protocolos celebrados no
                      âmbito do incentivo à coletividade no setor das pescas.
                    </p>
                    <p>
                      O acesso a esta plataforma é gerido pela DRP, devendo o
                      acesso ser requerido por correio eletrónico.
                    </p>
                    <p>
                      Morada: Colónia Alemã, Rua Consul Dabney 9900-014 Horta{" "}
                    </p>
                    <p>
                      Email:{" "}
                      <a href="mailto:Info.drp@azores.gov.pt">
                        Info.drp@azores.gov.pt
                      </a>{" "}
                    </p>
                    <p>Telefone: 292 202 400</p>
                  </div>
                </Card.Body>
              </Card>
              {(authDomain.isIFlowSaml() || authDomain.isUDWSaml()) && (
                <Card.Footer className="text-muted">
                  <small>LDAP/SAML Authentication</small>
                </Card.Footer>
              )}
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
          {!isLoadingConfiguration && <DrpaFooter />}
        </div>
      );
    } else if (config === "isel") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        className="isel-logo"
                        src={ISELLogo}
                        alt="açores pesca"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "24px",
                        fontWeight: "bold",
                        minWidth: "fit-content",
                        paddingBottom: "25px",
                      }}
                    >
                      PORTAL DO COLABORADOR
                    </div>
                    {authDomain.isRegularAuthentication() && (
                      <RegularAuthenticationISEL />
                    )}
                    {(authDomain.isGoogleAuthentication() ||
                      authDomain.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomain.isGoogleAuthentication() && (
                          <GoogleAuthentication />
                        )}
                        {authDomain.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication />
                        )}
                        {authDomain.isADFSAuthentication() && (
                          <UdwADFSAuthentication />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomain.isSAMLAuthentication() && (
                      <SAMLAuthentication />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="açores pesca"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              {(authDomain.isIFlowSaml() || authDomain.isUDWSaml()) && (
                <Card.Footer className="text-muted">
                  <small>LDAP/SAML Authentication</small>
                </Card.Footer>
              )}
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "sparkCapital") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={SparkLogo}
                        alt="Spark Capital"
                      />
                    </div>
                    {authDomain.isRegularAuthentication() && (
                      <RegularAuthenticationSpark />
                    )}
                    {(authDomain.isGoogleAuthentication() ||
                      authDomain.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomain.isGoogleAuthentication() && (
                          <GoogleAuthentication />
                        )}
                        {authDomain.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication />
                        )}
                        {authDomain.isADFSAuthentication() && (
                          <UdwADFSAuthentication />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomain.isSAMLAuthentication() && (
                      <SAMLAuthentication />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="açores pesca"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "grupoMarques") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={MarquesLogo}
                        alt="Grupo Marques"
                      />
                    </div>
                    {authDomain.isRegularAuthentication() && (
                      <RegularAuthentication />
                    )}
                    {(authDomain.isGoogleAuthentication() ||
                      authDomain.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomain.isGoogleAuthentication() && (
                          <GoogleAuthentication />
                        )}
                        {authDomain.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication />
                        )}
                        {authDomain.isADFSAuthentication() && (
                          <UdwADFSAuthentication />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomain.isSAMLAuthentication() && (
                      <SAMLAuthentication />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="Unik Logo"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "buzz") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={BuzzLogo}
                        alt="Buzz DMC"
                      />
                    </div>
                    {authDomain.isRegularAuthentication() && (
                      <RegularAuthentication isStyled={"buzz"} />
                    )}
                    {(authDomain.isGoogleAuthentication() ||
                      authDomain.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomain.isGoogleAuthentication() && (
                          <GoogleAuthentication />
                        )}
                        {authDomain.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication />
                        )}
                        {authDomain.isADFSAuthentication() && (
                          <UdwADFSAuthentication />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomain.isSAMLAuthentication() && (
                      <SAMLAuthentication />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="Unik Logo"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "edenred") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={EdenRedLogo}
                        alt="EdenRed"
                      />
                    </div>
                    {authDomain.isRegularAuthentication() && (
                      <RegularAuthentication isStyled={"edenred"} />
                    )}
                    {(authDomain.isGoogleAuthentication() ||
                      authDomain.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomain.isGoogleAuthentication() && (
                          <GoogleAuthentication />
                        )}
                        {authDomain.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication />
                        )}
                        {authDomain.isADFSAuthentication() && (
                          <UdwADFSAuthentication />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomain.isSAMLAuthentication() && (
                      <SAMLAuthentication />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="Unik Logo"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    }else if (config === "lusiteca") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={LusitecaLogo}
                        alt="Lusiteca"
                      />
                    </div>
                    {authDomain.isRegularAuthentication() && (
                      <RegularAuthentication />
                    )}
                    {(authDomain.isGoogleAuthentication() ||
                      authDomain.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomain.isGoogleAuthentication() && (
                          <GoogleAuthentication />
                        )}
                        {authDomain.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication />
                        )}
                        {authDomain.isADFSAuthentication() && (
                          <UdwADFSAuthentication />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomain.isSAMLAuthentication() && (
                      <SAMLAuthentication />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="Unik Logo"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    }else if (config === "future") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper" style={{textAlign: 'center'}}>
                      <img
                        className="pesca-logo"
                        src={FHCLogo}
                        alt="Future Healthcare"
                      />
                    </div>
                    {authDomain.isRegularAuthentication() && (
                      <RegularAuthentication />
                    )}
                    {(authDomain.isGoogleAuthentication() ||
                      authDomain.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomain.isGoogleAuthentication() && (
                          <GoogleAuthentication />
                        )}
                        {authDomain.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication />
                        )}
                        {authDomain.isADFSAuthentication() && (
                          <UdwADFSAuthentication />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomain.isSAMLAuthentication() && (
                      <SAMLAuthentication />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="Unik Logo"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else if (config === "ips") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={IPSLogo}
                        alt="Politécnico de Snatarém"
                      />
                    </div>
                    {authDomain.isRegularAuthentication() && (
                      <RegularAuthentication />
                    )}
                    {(authDomain.isGoogleAuthentication() ||
                      authDomain.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomain.isGoogleAuthentication() && (
                          <GoogleAuthentication />
                        )}
                        {authDomain.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication />
                        )}
                        {authDomain.isADFSAuthentication() && (
                          <UdwADFSAuthentication />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomain.isSAMLAuthentication() && (
                      <SAMLAuthentication />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="Unik Logo"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    }else if (config === "adc") {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <Card className="card-login drpa">
                <Card.Body>
                  <div className="drpa-login-1">
                    <div className="pesca-logo-wrapper">
                      <img
                        className="pesca-logo"
                        src={ADCLogo}
                        alt="AD&C - Agência para o Desenvolvimento e Coesão, I.P."
                      />
                    </div>
                    {authDomain.isRegularAuthentication() && (
                      <RegularAuthentication />
                    )}
                    {(authDomain.isGoogleAuthentication() ||
                      authDomain.isMicrosoftAuthentication()) && (
                      <SignInWithSection>
                        {authDomain.isGoogleAuthentication() && (
                          <GoogleAuthentication />
                        )}
                        {authDomain.isMicrosoftAuthentication() && (
                          <MicrosoftAuthentication />
                        )}
                        {authDomain.isADFSAuthentication() && (
                          <UdwADFSAuthentication />
                        )}
                      </SignInWithSection>
                    )}
                    {authDomain.isSAMLAuthentication() && (
                      <SAMLAuthentication />
                    )}
                    <div
                      style={{
                        paddingTop: "15px",
                      }}
                    >
                      <Link to="/forgot-password" className="text-muted">
                        <small>{t("login.forgotPassword")}</small>
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "80%",
                        fontWeight: "400",
                        color: "#707980",
                        paddingTop: "15px",
                        alignItems: "center",
                      }}
                    >
                      <div>Powered by :</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.uniksystem.com/pt/unikpeople/",
                            "_blank"
                          )
                        }
                      >
                        <img
                          className="unikpeople-logo"
                          src={UnikPeopleLogo}
                          alt="Unik Logo"
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    } else {
      return (
        <div className="overlay">
          {!isLoadingConfiguration && successRetrieveConfiguration && (
            <>
              <LoginLogo />
              <hr style={{ marginTop: "0px" }} />
              <Card className="card-login">
                <Card.Body>
                  {authDomain.isRegularAuthentication() && (
                    <RegularAuthentication />
                  )}
                  {(authDomain.isGoogleAuthentication() ||
                    authDomain.isMicrosoftAuthentication() ||
                    authDomain.isWindowsAuthentication() ||
                    authDomain.isKeycloakAuthentication()) && (
                    <SignInWithSection>
                      {authDomain.isGoogleAuthentication() && (
                        <GoogleAuthentication />
                      )}
                      {authDomain.isMicrosoftAuthentication() && (
                        <MicrosoftAuthentication />
                      )}
                      {authDomain.isWindowsAuthentication() && (
                        <WindowsAuthentication
                          auto={authDomain.isAutoLoginWindows()}
                          click={clickWindowsLogin}
                        />
                      )}
                      {authDomain.isKeycloakAuthentication() && (
                        <KeycloakAuthentication
                          auto={authDomain.isAutoLoginKeycloak()}
                          click={clickKeycloakLogin}
                        />
                      )}
                      {authDomain.isADFSAuthentication() && (
                        <UdwADFSAuthentication />
                      )}
                    </SignInWithSection>
                  )}
                  {authDomain.isSAMLAuthentication() && <SAMLAuthentication />}
                  {(authDomain.isUnikSystem() || authDomain.isUDWGoogle()) &&
                    config !== "cma" && (
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "30px",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          <small>{`${t("register.noAccount")} `}</small>

                          <Link to="/register" className="text-muted">
                            <small className="create-acc-label">
                              {t("register.createOne")}
                            </small>
                          </Link>
                        </span>
                        <span>
                          <Link to="/forgot-password" className="text-muted">
                            <small>{t("login.forgotPassword")}</small>
                          </Link>
                        </span>
                      </div>
                    )}
                </Card.Body>
              </Card>
              {(authDomain.isIFlowSaml() || authDomain.isUDWSaml()) && (
                <Card.Footer className="text-muted">
                  <small>LDAP/SAML Authentication</small>
                </Card.Footer>
              )}
            </>
          )}
          {!isLoadingConfiguration && !successRetrieveConfiguration && (
            <Error500 />
          )}
        </div>
      );
    }
  };

  return (
    <>
      {renderLoginForm()}
      <Toast />
      <FullSpinner spinning={isLoadingConfiguration} wrapper />

      {firstRender ? <EasyPaySSO /> : null}
    </>
  );
};

export default withRouter(withNamespaces()(LoginForm));
