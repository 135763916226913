import React from "react";
import CreateTextInput from "./CreateTextInput";
import CreateLink from "./CreateLink";
import CreateDateInput from "./CreateDateInput";
import CreateSearchableDropdown from "./CreateSearchableDropdown";
import CreateMultipleFileInput from "./CreateMultipleFileInput";
import CreateAlert from "./CreateAlert";
import CreateRichText from "./CreateRichText";
import CreateTextArea from "./CreateTextArea";
import { Form, Row, Col } from "react-bootstrap";
import uuid from "uuid/v1";

const CreateRowFromColumns = (props) => {
  const { field, columns, styleSheet } = props;

  const addColumns = () => {
    let content = [];
    columns.map((column) => {
      const firstField =
        field.type === "textlabel" ||
        field.type === "link" ||
        field.type === "textmessage"
          ? field.text
          : field.variable;
      let fieldToAdd;
      if (Array.isArray(column.field)) {
        fieldToAdd =
          column.field[0]?.type === "textlabel" ||
          field.type === "link" ||
          field.type === "textmessage"
            ? column.field[0]?.text
            : column.field[0]?.variable;
      } else
        fieldToAdd =
          column.field?.type === "textlabel" ||
          field.type === "link" ||
          field.type === "textmessage"
            ? column.field?.text
            : column.field?.variable;

      if (column !== "" && column.field && firstField !== fieldToAdd) {
        let fieldArray = column.field ? column.field : [];
        if (!Array.isArray(fieldArray)) {
          fieldArray = [fieldArray];
        }

        return fieldArray.map((field, index) => {
          if (field.type === "textbox") {
            content.push(
              <CreateTextInput
                field={field}
                key={field.variable}
                isChild={true}
              />
            );
          } else if (field.type === "selection") {
            content.push(
              <CreateSearchableDropdown
                field={field}
                key={field.variable}
                isChild={true}
              />
            );
          } else if (field.type === "datecal") {
            content.push(
              <CreateDateInput
                field={field}
                key={field.variable}
                isChild={true}
              />
            );
          } else if (field.type === "textlabel") {
            content.push(
              <Form.Group
                controlId={`formlabel-${field.type}`}
                key={`${field.type}-${uuid()}`}
                as={Row}
                style={{
                  marginLeft: "5px",
                  paddingRight: "5px",
                  flexGrow: "1",
                }}
              >
                <Form.Label className="iflow-form-label-fix" column>
                  {field.text}
                </Form.Label>
                {field.value && (
                  <span
                    style={{
                      fontWeight: "500",
                      paddingTop: "0.375rem",
                      paddingBottom: "0.375rem",
                    }}
                  >
                    {field.value}
                  </span>
                )}
              </Form.Group>
            );
          } else if (field.type === "link") {
            content.push(
              <CreateLink
                field={field}
                key={`${field.type}_${field.text}`}
                isChild={true}
              />
            );
          } else if (field.type === "file") {
            content.push(
              <CreateMultipleFileInput
                field={field}
                key={`${field.type}_${field.text}`}
                isChild={true}
              />
            );
          } else if (field.type === "textmessage") {
            content.push(
              <Form.Group
                controlId={`formlabel-${field.type}`}
                key={`${field.type}-${uuid()}`}
                as={Row}
                style={{
                  marginLeft: "5px",
                  paddingRight: "5px",
                  flexGrow: "1",
                }}
              >
                <CreateAlert field={field} isChild={true} variant="info" />
              </Form.Group>
            );
          } else if (field.type === "textarea") {
            return field?.text?.includes("::richText")
              ? content.push(
                  <CreateRichText
                    field={field}
                    key={field.variable}
                    styleSheet={styleSheet}
                    isChild={true}
                  />
                )
              : content.push(
                  <CreateTextArea
                    field={field}
                    key={field.variable}
                    styleSheet={styleSheet}
                    isChild={true}
                  />
                );
          }
        });
      }
    });
    return content;
  };

  const renderFields = (field) => {
    if (field.type === "textbox") {
      return (
        <CreateTextInput field={field} key={field.variable}>
          {columns && addColumns()}
        </CreateTextInput>
      );
    } else if (field.type === "selection") {
      return (
        <CreateSearchableDropdown
          field={field}
          key={field.variable}
          isRowFromColumns={true}
        >
          {columns && addColumns()}
        </CreateSearchableDropdown>
      );
    } else if (field.type === "datecal") {
      return (
        <CreateDateInput field={field} key={field.variable}>
          {columns && addColumns()}
        </CreateDateInput>
      );
    } else if (field.type === "textlabel") {
      return (
        <Form.Group
          controlId={`formlabel-${field.type}`}
          key={field.text}
          as={Row}
          style={{
            flexWrap: "nowrap",
            marginLeft: "0px",
            marginRight: "0px",
            flexGrow: "1",
          }}
        >
          <Form.Label column className="iflow-form-label-fix">
            {field.text}
          </Form.Label>
          <Col
            style={{
              paddingRight: "0px",
              paddingLeft: "0px",
              flexGrow: "2",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "auto" }}
            >
              {field.value && (
                <span
                  style={{
                    fontWeight: "500",
                    paddingTop: "0.375rem",
                    paddingBottom: "0.375rem",
                  }}
                >
                  {field.value}
                </span>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  flexGrow: "1",
                  flexBasis: "0",
                }}
              >
                {columns && addColumns()}
              </div>
            </div>
          </Col>
        </Form.Group>
      );
    } else if (field.type === "filler") {
      return (
        <Form.Group
          controlId={`formlabel-${field.type}`}
          key={field.text}
          as={Row}
          style={{
            flexWrap: "nowrap",
            marginLeft: "0px",
            marginRight: "0px",
            flexGrow: "1",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                flexGrow: "1",
                flexBasis: "0",
              }}
            >
              {columns && addColumns()}
            </div>
          </div>
        </Form.Group>
      );
    } else if (field.type === "link") {
      return (
        <CreateLink field={field} key={field.variable}>
          {columns && addColumns()}
        </CreateLink>
      );
    } else if (field.type === "textmessage") {
      return (
        <Form.Group
          controlId={`formlabel-${field.type}`}
          key={field.text}
          as={Row}
          style={{
            flexWrap: "nowrap",
            marginLeft: "0px",
            marginRight: "0px",
            flexGrow: "1",
          }}
        >
          <div style={{ width: "300px" }}>
            <CreateAlert field={field} variant="info" />
          </div>
          <Col
            style={{
              paddingRight: "0px",
              paddingLeft: "0px",
              flexGrow: "2",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "auto" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  flexGrow: "1",
                  flexBasis: "0",
                }}
              >
                {columns && addColumns()}
              </div>
            </div>
          </Col>
        </Form.Group>
      );
    } else return null;
  };
  return field ? (
    <>
      {" "}
      {renderFields(field)}
      {styleSheet?.highLightRows && <hr />}
    </>
  ) : (
    <div></div>
  );
};
export default CreateRowFromColumns;
