import { setFolders, setUpdateFolder } from "./actionTypes";
import { put } from "redux-saga/effects";
import qs from "qs";
import http from "~/utils/config/http";
import { activeError } from "~/components/Messages/actions";
import { BPM_MIDDLEWARE } from "~/utils/constants";

export function* findFolders({ username, application }) {
  try {
    const resp = yield http.get(
      `/folder/findByUser?username=${username}&application=${application}`,
      BPM_MIDDLEWARE
    );
    yield put(setFolders(resp.data));
  } catch (e) {
    yield put(setFolders([]));
    yield put(activeError("general.error"));
  }
}

export function* saveFolder({ payload }) {
  try {
    yield put(setUpdateFolder(false));
    yield http.post(
      `/folder/save?username=${payload.username}&application=${payload.application}`,
      payload.folder,
      BPM_MIDDLEWARE
    );
    yield put(setUpdateFolder(true));
  } catch (e) {
    yield put(setUpdateFolder(false));
    yield put(activeError("general.error"));
  }
}

export function* updateFolders({ payload }) {
  try {
    yield put(setUpdateFolder(false));
    yield http.post(
      `/folder/update?username=${payload.username}&application=${payload.application}`,
      payload.folders,
      BPM_MIDDLEWARE
    );
    yield put(setUpdateFolder(true));
  } catch (e) {
    yield put(setUpdateFolder(false));
    yield put(activeError("general.error"));
  }
}

export function* deleteFolder({ id }) {
  try {
    yield put(setUpdateFolder(false));
    yield http.delete(`/folder/delete/${id}`, BPM_MIDDLEWARE);
    yield put(setUpdateFolder(true));
  } catch (e) {
    yield put(setUpdateFolder(false));
    yield put(activeError("general.error"));
  }
}

export function* editActivityFolder({ payload }) {
  try {
    yield put(setUpdateFolder(false));
    yield http.get(
      `/folder/updateActivity?${qs.stringify(payload)}`,
      BPM_MIDDLEWARE
    );
    yield put(setUpdateFolder(true));
  } catch (e) {
    yield put(setUpdateFolder(false));
    yield put(activeError("general.error"));
  }
}
