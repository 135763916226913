export const abbreviateString = (value, sizeAbrev) => {
  return value.length > sizeAbrev ? value.slice(0, sizeAbrev - 1) + "…" : value;
}

export const toLocaleString = dateStr => (
  dateStr ? new Date(dateStr).toLocaleString('pt-PT') : ''
)
export const toLocaleStringHistory = dateStr => (
  dateStr!=null ? new Date(dateStr).toLocaleString('pt-PT') : '------------'
)

export const toLocaleDateString = dateStr => (
  dateStr ? new Date(dateStr).toLocaleDateString('pt-PT') : ''
)

export const buildDocNumberColumn = (cell, row) => {
  return row?.metadata?.document_identifier;
}

export const buildTotalAmoutColumn = (cell, row) => {
  return row?.metadata?.amount_total;
}

export const buildVendorNameColum = (cell, row) => {
  return row?.metadata?.supplier_name;
}

export const createBase64StringFromBinary = (data) => {
  if (data.charAt(0) == 'P') {
    return "data:image/svg+xml;base64," + data; //"svg"
  } else if (data.charAt(0) == '/') {
    return "data:image/jpeg;base64," + data; //"jpeg";
  } else if (data.charAt(0) == 'R') {
    return "data:image/gig;base64," + data; //"gif";
  } else if (data.charAt(0) == 'i') {
    return "data:image/png;base64," + data; //"png";
  }
}