import qs from "qs";
import { put } from "redux-saga/effects";
import http from "~/utils/config/http";

import {
  persistOrgAdminDone,
  validateOrgAdminDone,
  setTemporaryUser,
} from "./actionTypes";

import {
  toastActiveSuccess,
  toastActiveError,
} from "~/components/Toast/actions";

export function* persistOrgAdmin({ payload }) {
  try {
    yield put(setTemporaryUser({}));
    const resp = yield http.post("/api/open/create-org-admin", payload);
    if (resp.status === 201) {
      yield put(persistOrgAdminDone(true));
      delete payload.password;
      yield put(setTemporaryUser(payload));
    } else {
      yield put(persistOrgAdminDone(false));
      yield put(toastActiveError("Error! User was not saved."));
    }
  } catch (e) {
    const { response } = e;
    yield put(persistOrgAdminDone(false));
    yield put(
      toastActiveError(
        response.data.message ? response.data.message : response.data
      )
    );
  }
}

export function* validateOrgAdmin({ payload, redirect }) {
  try {
    const resp = yield http.post(`/api/open/validate?${qs.stringify(payload)}`);
    if (resp.status === 201) {
      yield put(validateOrgAdminDone(true));
      yield put(toastActiveSuccess("User was validated successfully!"));
      yield put(setTemporaryUser({}));
    } else {
      if (redirect) {
        window.location.href = "/#/";
      }
      yield put(validateOrgAdminDone(false));
      yield put(toastActiveError("Error! User was not validated."));
    }
  } catch (e) {
    const { response } = e;
    yield put(validateOrgAdminDone(false));
    if (response.status === 400) {
      if (redirect) {
        window.location.href = "/#/";
      }
      yield put(toastActiveError("Error! User was not validated."));
    } else {
      if (redirect) {
        window.location.href = "/#/";
      }
      yield put(
        toastActiveError(
          response.data.message ? response.data.message : response.data
        )
      );
    }
  }
}
