import { remove, find, some, findIndex } from "lodash";

import {
  FIND_ALL_BATCHS,
  FIND_ALL_BATCHS_WITH_BINARY,
  FIND_BATCH_BY_FILTERS,
  SET_BATCHS,
  SET_BATCHS_WITH_BINARY,
  SET_CUSTOM_BATCHS,
  SET_CUSTOM_BINARY_BATCHS,
  FIND_BATCH_BY_ID,
  SET_BATCH_BY_ID,
  CLEAR_BATCHS,
  CLEAR_BATCHS_WITH_BINARY,
  SAVE_DOCUMENT_INTO_BATCH,
  ADD_DOCUMENT_TO_BATCH,
  SUCCESS_SAVE_DOCUMENT_BATCH,
  FAIL_SAVE_DOCUMENT_BATCH,
  CLEAR_SAVED_BATCH,
  HANDLE_DELETE_BATCH,
  SET_DELETED_BATCH,
  HANDLE_DELETE_DOCUMENT,
  SET_DELETED_DOCUMENT,
  HANDLE_REMOVE_DOCUMENT,
  HANDLE_DELETE_DOCUMENTS,
  SET_DELETED_DOCUMENTS,
  HANDLE_REMOVE_DOCUMENTS,
  REFRESH_DOCUMENT,
  SET_REFRESHED_DOCUMENT,
  GET_METADATA_DOCUMENT,
  SET_METADATA_DOCUMENT,
  GET_DOCUMENT,
  SET_DOCUMENT,
  CLEAR_IS_REFRESHED,
  SAVE_METADATA_DOCUMENT,
  SET_SAVED_METADATA_DOCUMENT,
  HANDLE_UPDATE_BATCH,
  SET_UPDATED_BATCH,
  SET_IFLOW_CONFIGURATION,
  HANDLE_UPDATE_DOCUMENTS,
  SET_UPDATED_DOCUMENTS,
  HANDLE_UPDATE_DOCUMENT,
  SET_UPDATED_DOCUMENT,
  HANDLE_UPDATE_APPROVED,
  HANDLE_CHANGED_APPROVED,
  HANDLE_RESET_APPROVED,
  REFRESH_APPROVED_DATE,
  GET_METADATA_DOCUMENT_FILENAME,
  HANDLE_SAVE_PO_DOCUMENT
} from "../actions";

const initialState = {
  batchs: [],
  isLoadingBatchs: false,
  custombatchs:false,
  custombinarybatchs:[],

  batchsWithBinary: [],
  isLoadingBatchsWithBinary: false,

  batch: {
    documents: [],
  },
  isUploadingFile: false,
  saveErrorMessage: undefined,

  isDeletedBatch: false,
  isLoadingDeleteBatch: false,
  isDeletingDocument: false,
  isDeletedDocument: undefined,

  document: undefined,
  isRefreshing: false,

  metadata: undefined,
  isLoadingMetadata: false,
  isSavingMetadataDocument: false,
  previewDocument: undefined, //used in preview MetadataModal as selected document
  originalDocument: undefined, //used to find Original document by id

  updatedBatch: false,

  updatedDocuments: [],
  isUpdatedDocuments: false,

  updatedDocument: [],
  isUpdatedDocument: false,

  iflowConfiguration: undefined,
};

let findedBatch;
let index;

export const dataCaptureReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_ALL_BATCHS:
      return { ...state, isLoadingBatchs: true };
    case FIND_ALL_BATCHS_WITH_BINARY:
      return { ...state, isLoadingBatchsWithBinary: true };
    case FIND_BATCH_BY_FILTERS:
      return { ...state, isLoadingBatchs: true };

    case SET_BATCHS:
      return {
        ...state,
        isLoadingBatchs: false,
        batchs: action.batchs,
        isUpdatedDocuments: false,
      };
    case SET_BATCHS_WITH_BINARY:
      return {
        ...state,
        isLoadingBatchsWithBinary: false,
        batchsWithBinary: action.batchs,
      };
      case SET_CUSTOM_BATCHS:
        return {
          ...state,
          isLoadingBatchs: false,
          custombatchs: action.custombatchs,
        };
        case SET_CUSTOM_BINARY_BATCHS:
          return {
            ...state,
            isLoadingBatchs: false,
            custombinarybatchs: action.custombinarybatchs,
          };

    case FIND_BATCH_BY_ID:
      return { ...state, isLoadingBatch: true };

    case SET_BATCH_BY_ID:
      return { ...state, isLoadingBatch: false, batch: action.batch };

    case CLEAR_BATCHS:
      return {
        ...state,
        batchs: [],
        dataiFlow: undefined,
        lastUploadedDocuments: [],
      };

    case CLEAR_BATCHS_WITH_BINARY:
      return {
        ...state,
        isLoadingBatchsWithBinary: false,
        batchsWithBinary: [],
      };

    case SAVE_DOCUMENT_INTO_BATCH:
      return { ...state, isUploadingFile: true };

    case ADD_DOCUMENT_TO_BATCH:
      return {
        ...state,
        batch: {
          ...state.batch,
          documents: [...state.batch.documents, action.document],
        },
      };

    case SUCCESS_SAVE_DOCUMENT_BATCH:
      return {
        ...state,
        isUploadingFile: false,
        batch: action.savedBatch,
      };

    case FAIL_SAVE_DOCUMENT_BATCH:
      return {
        ...state,
        isUploadingFile: false,
        saveErrorMessage: action.errorMessage,
      };

    case CLEAR_SAVED_BATCH:
      return { ...state, batch: initialState.batch };
    case HANDLE_DELETE_BATCH:
      return {
        ...state,
        isLoadingDeleteBatch: true,
        isDeletedBatch: undefined,
      };

    case SET_DELETED_BATCH:
      return {
        ...state,
        isLoadingDeleteBatch: false,
        isDeletedBatch: action.isDeletedBatch,
      };

    case HANDLE_UPDATE_BATCH:
      return { ...state, updatedBatch: false };

    case SET_UPDATED_BATCH:
      return { ...state, updatedBatch: action.updatedBatch };

    case HANDLE_DELETE_DOCUMENT:
      return {
        ...state,
        isDeletingDocument: true,
        isDeletedDocument: undefined,
      };

    case SET_DELETED_DOCUMENT:
      return {
        ...state,
        isDeletingDocument: false,
        isDeletedDocument: action.isDeletedDocument,
      };

    case HANDLE_REMOVE_DOCUMENT:
      const _batch = state.batch;
      remove(_batch.documents, (document) => document.id === action.document);
      return {
        ...state,
        batch: _batch,
      };

    case HANDLE_DELETE_DOCUMENTS:
      return {
        ...state,
        isDeletingDocuments: true,
        isDeletedDocuments: undefined,
      };

    case SET_DELETED_DOCUMENTS:
      return {
        ...state,
        isDeletingDocuments: false,
        isDeletedDocuments: action.isDeletedDocuments,
      };

    case HANDLE_REMOVE_DOCUMENTS:
      return {
        ...state,
        batch: {
          documents: [],
        },
        batchs: [],
      };

    case REFRESH_DOCUMENT:
      return { ...state, isRefreshing: true };

    case CLEAR_IS_REFRESHED:
      return { ...state, isRefreshing: false };

    case SET_REFRESHED_DOCUMENT:
      findedBatch = find(state.batchs, (batch) =>
        some(batch.documents, (document) => document.id === action.document.id)
      );
      index = findIndex(findedBatch.documents, { id: action.document.id });
      findedBatch.documents.splice(index, 1, action.document);
      return { ...state, isRefreshing: false };

    case HANDLE_UPDATE_DOCUMENTS:
      return { ...state, isUpdatedDocuments: false };

    case SET_UPDATED_DOCUMENTS:
      return {
        ...state,
        updatedDocuments: action.updatedDocuments,
        isUpdatedDocuments: true,
      };

    case HANDLE_UPDATE_DOCUMENT:
      return { ...state, isUpdatedDocument: false };

    case SET_UPDATED_DOCUMENT:
      return {
        ...state,
        updatedDocument: action.updatedDocument,
        isUpdatedDocument: true,
      };

    case GET_METADATA_DOCUMENT:
      return { ...state, isLoadingMetadata: true, previewDocument: undefined };

      case GET_METADATA_DOCUMENT_FILENAME:
        return { ...state, isLoadingMetadata: true, previewDocument: undefined };

    case SET_METADATA_DOCUMENT:
      return {
        ...state,
        isLoadingMetadata: false,
        metadata: action.data && action.data.metadata,
        previewDocument: action.data,
      };

    case GET_DOCUMENT:
      return { ...state, originalDocument: undefined };

    case SET_DOCUMENT:
      return { ...state, originalDocument: action.data };

    case SAVE_METADATA_DOCUMENT:
      return { ...state, isSavingMetadataDocument: true };

    case SET_SAVED_METADATA_DOCUMENT:
      findedBatch = find(state.batchs, (batch) =>
        some(batch.documents, (document) => document.id === action.document.id)
      );
      index = findIndex(findedBatch.documents, { id: action.document.id });
      findedBatch.documents.splice(index, 1, action.document);
      return { ...state, isSavingMetadataDocument: false };

    case SET_IFLOW_CONFIGURATION:
      return { ...state, iflowConfiguration: action.configuration };
    
    case HANDLE_UPDATE_APPROVED: 
      return { ...state, batchId: action.batchId, documentId: action.id};
    case HANDLE_CHANGED_APPROVED:
      return {...state, changed: true}
    case HANDLE_RESET_APPROVED:
      return {...state, changed: false}
    case REFRESH_APPROVED_DATE:
      return {...state, batchId: action.batchId, documentId: action.id, newDate: action.newDate}
    case HANDLE_SAVE_PO_DOCUMENT:
      return {
        ...state
      };
    default:
      return state;
  }
};
