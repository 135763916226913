import React, { useEffect } from "react";
import { Card, Row } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import logo from "~/assets/img/logos/unik-logo.png";
import { PERMISSION_ABOUT } from "~/utils/constants/permissions";

const AboutComponent = (props) => {
  const { t } = props;

  const AboutRow = ({ children }) => (
    <p style={{ margin: "0px", padding: "0px" }}>{children}</p>
  );

  useEffect(() => {
    if (document.getElementById("globalsolver"))
      document.getElementById("globalsolver").style.display = "none";
  });

  return (
    <ProtectedComponent permissions={[{ label: PERMISSION_ABOUT }]}>
      <div className="main-card-v2">
        <Card
          bsPrefix="card-flat"
          style={{ padding: "50px", overflow: "hidden" }}
        >
          <Card.Body>
            <Row>
              <div className="col-6">
                <img className="img-fluid" src={logo} alt="UDW" />
              </div>
              <div className="col-5">
                <span className="font-weight-bold">Unik Platform</span>
                <AboutRow>
                  {t("about.version")} 2021-{process.env.REACT_APP_VERSION}{" "}
                </AboutRow>
                <AboutRow>
                  <b>Build ID:</b> {process.env.REACT_APP_VERSION}
                </AboutRow>
                <AboutRow>
                  <b>Frontend {t("about.version")}:</b>{" "}
                  {process.env.REACT_APP_VERSION}
                </AboutRow>
                <AboutRow>
                  <b>Backend {t("about.version")}:</b>{" "}
                </AboutRow>
                <AboutRow>
                  <b>{t("about.releaseNotes")}:</b>
                </AboutRow>
                <br />
                <AboutRow>
                  <a href="https://www.uniksystem.com" target="_blank">
                    Copyright ©2021 Uniksystem. {t("about.rights")}{" "}
                  </a>
                </AboutRow>
              </div>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </ProtectedComponent>
  );
};

export default withNamespaces()(AboutComponent);
