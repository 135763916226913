import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import uuid from "uuid/v1";
import PTFlag from "~/pages/Easypay/assets/img/icons/flags/PT.png";
import iconWarning from "~/pages/Easypay/assets/img/icons/icon-alert.png";
import "../assets/css/styles.css";
import "../assets/scss/_backOfficeClientSection.scss";
import BenefModalRadioButtonWrapper from "./BenefModalRadioButtonWrapper";
import Checkbox from "./Checkbox";
import Input from "./Input";
import Dropdown from "./InputDropdown";
import InputSearch from "./InputSearch";
import Link from "./Link";
import RadioButtonItem from "./RadioButtonItem";
const devLogConsole = require("~/utils/devLog");
const BackOfficeClientSection = ({ t, data }) => {
  const organizationTypeList = [
    { label: "Fundação", value: "Fundação", selected: true },
    { label: "Companhia", value: "Companhia", selected: false },
    { label: "Empresa", value: "Empresa", selected: false },
  ];

  const mobileList = [
    { label: "PT(+351)", value: "PT(+351)", selected: true },
    { label: "DE(+050)", value: "DE(+050)", selected: false },
    { label: "ES(+092)", value: "ES(+092)", selected: false },
  ];

  const inputSearchList = [
    {
      label: "Agricultura, produção animal, caça, floresta e pesca",
      value: "Agricultura, produção animal, caça, floresta e pesca",
      selected: true,
    },
    {
      label: "Engenharia Mecanica",
      value: "Engenharia Mecanica",
      selected: false,
    },
    { label: "Consultora", value: "Consultora", selected: false },
  ];

  const [transactionVolume, setTransactionVolume] = useState(
    t("bpm.onboarding.backoffice.administration.header50000")
  );
  const [productType, setProductType] = useState(
    t("bpm.onboarding.backoffice.administration.headerAssets")
  );
  const [lorem, setLorem] = useState("Opção 01");
  function handleSelectChange(e) {
    devLogConsole(e);
  }

  function buildInputField(text, value) {
    return {
      value: value,
      obligatory: false,
      variable: "",
      maxlenght: 9,
      text: text,
    };
  }

  const updateUser = (value, variable) => {
    const variableName = variable?.split("-");
    if (variableName.length === 2) {
      data[variableName[0]][variableName[1]] = value;
    }
  };

  useEffect(() => {
    data?.onboardingEntity?.tipoEntidade &&
      setActiveAccountType(data?.onboardingEntity?.tipoEntidade);
  }, [data]);

  const [activeAccountType, setActiveAccountType] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  return (
    <div className="easypay-backoffice-account-sections-wrapper">
      <div className="easypay-backoffice-registry-section-header">
        {t(`bpm.onboarding.backoffice.user.verticalTabClient`)}
      </div>
      <div className="easypay-backoffice-account-section-account-type-wrapper">
        <div className="easypay-backoffice-client-blue-header">
          {t(`bpm.onboarding.backoffice.user.labelContactData`)}
        </div>
      </div>
      <div className="easypay-backoffice-client-section-input-wrapper">
        <Input
          field={buildInputField(
            t(`bpm.onboarding.backoffice.user.labelName`),
            data?.client?.nome
          )}
          name="client-nome"
          onChange={updateUser}
        />
      </div>
      <div className="easypay-backoffice-client-section-columns-wrapper">
        <div className="easypay-backoffice-client-section-column-wrapper">
          <div className="easypay-backoffice-client-section-small-input-wrapper">
            <Dropdown
              text={t(`bpm.onboarding.backoffice.user.labelCountry`)}
              required={true}
              placeholder="N/a"
              list={mobileList}
              handleSubmit={(e) => handleSelectChange(e)}
            />
          </div>
          <div className="easypay-backoffice-client-section-small-input-wrapper">
            <Input
              field={buildInputField(
                t(`bpm.onboarding.backoffice.user.labelMobile`),
                data?.client?.numTelemovel
              )}
              name="client-numTelemovel"
              onChange={updateUser}
            />
          </div>
        </div>
        <div className="easypay-backoffice-client-section-input-wrapper">
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.administration.headerEmail`),
              data?.client?.email
            )}
            name="client-email"
            onChange={updateUser}
          />
        </div>
      </div>
      <div className="easypay-backoffice-client-blue-header">
        {t(`bpm.onboarding.backoffice.user.tabOrganization`)}
      </div>
      <div className="easypay-backoffice-client-section-columns-wrapper">
        <div className="easypay-backoffice-client-section-small-input-wrapper">
          <Dropdown
            text={t(
              `bpm.onboarding.backoffice.administration.organizationTypeHeader`
            )}
            required={true}
            placeholder="N/a"
            list={organizationTypeList}
            handleSubmit={(e) => handleSelectChange(e)}
          />
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelNIPC`) + "::disabled",
              data?.onboardingEntity?.nipc
            )}
          />
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelFormaObg`) + "::disabled",
              data?.onboardingEntity?.formaObrigar
            )}
          />
        </div>
        <div className="easypay-backoffice-client-section-small-input-wrapper">
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelName`),
              data?.onboardingEntity?.nomeEntidade
            )}
            name="onboardingEntity-nomeEntidade"
            onChange={updateUser}
          />
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.user.labelCAE`) + "::disabled",
              data?.onboardingEntity?.cae
            )}
          />
          <div className="easypay-backoffice-client-section-columns-wrapper">
            <div className="easypay-backoffice-client-section-small-input-wrapper">
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.user.labelCertificateCod`) +
                    "::disabled",
                  data?.onboardingEntity?.cprc
                )}
              />
            </div>
            <div className="easypay-backoffice-client-section-small-input-wrapper">
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.user.labelValidity`) +
                    "::disabled",
                  data?.onboardingEntity?.dataValidade
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="easypay-backoffice-client-blue-header">
        {t(`bpm.onboarding.backoffice.user.adressTitle`)}
      </div>
      <div className="easypay-backoffice-client-small-wrapper">
        <div className="easypay-backoffice-client-section-small-header">
          {t(`bpm.onboarding.backoffice.administration.headerHostCountry`)}
        </div>
        <div className="easypay-backoffice-country-falag-text-wrapper">
          <img src={PTFlag} />
          <div className="easypay-backoffice-host-country-text">
            {data?.onboardingEntity?.pais}
          </div>
        </div>
      </div>
      <div className="easypay-backoffice-link-wrapper">
        <Link
          text={t(`bpm.onboarding.backoffice.administration.linkChange`)}
          link=""
        />
      </div>
      <div className="easypay-backoffice-account-section-zipcode-locality-wrapper">
        <div className="easypay-backoffice-account-section-small-input-wrapper">
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.administration.headerZipCode`),
              data?.onboardingEntity?.codPostal
            )}
            name="onboardingEntity-codPostal"
            onChange={updateUser}
          />
        </div>
        <div className="easypay-backoffice-account-section-small-input-wrapper">
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.administration.headerLocality`),
              data?.onboardingEntity?.localidade
            )}
            name="onboardingEntity-localidade"
            onChange={updateUser}
          />
        </div>
      </div>
      <div className="easypay-backoffice-account-section-addresses-wrapper">
        <div className="easypay-backoffice-account-section-input-wrapper">
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.administration.headerAddress-1`),
              data?.onboardingEntity?.moradaSede
            )}
            name="onboardingEntity-moradaSede"
            onChange={updateUser}
          />
        </div>
        <div className="easypay-backoffice-account-section-input-wrapper">
          <Input
            field={buildInputField(
              t(`bpm.onboarding.backoffice.administration.headerAddress-2`),
              data?.onboardingEntity?.moradaSede
            )}
            name="onboardingEntity-moradaSede"
            onChange={updateUser}
          />
        </div>
      </div>
      <div className="easypay-backoffice-client-blue-header">
        {t(`bpm.onboarding.backoffice.administration.businessButton`)}
      </div>
      <div className="easypay-backoffice-business-section-subheader-wrapper">
        <div className="easypay-text-icon">
          <img src={iconWarning} alt="Icon Check" />
        </div>
        <div className="easypay-backoffice-business-section-subheader-text">
          {t(
            `bpm.onboarding.backoffice.administration.subHeaderChangeBusinessData`
          )}
        </div>
      </div>
      <div className="easypay-backoffice-host-country-header">
        {t(`bpm.onboarding.backoffice.administration.organizationTypeHeader`)}
      </div>
      <BenefModalRadioButtonWrapper
        key={uuid()}
        variant={"easypay-radio-button-row"}
        activeTab={activeAccountType}
        setActivetab={setActiveAccountType}
      >
        <RadioButtonItem
          key={uuid()}
          text={"ENI"}
          uniqueName={"backofficeRadioEntityType"}
          index={0}
          name="eni"
          subHeader={
            t(`bpm.onboarding.backoffice.administration.subHeaderENI`) +
            "::card-text-light"
          }
        />
        <RadioButtonItem
          key={uuid()}
          text={"Empresas"}
          uniqueName={"backofficeRadioEntityType"}
          index={1}
          name="empresas"
          subHeader={
            t(`bpm.onboarding.backoffice.administration.companySubHeader`) +
            "::card-text-light"
          }
        />
        <RadioButtonItem
          key={uuid()}
          text={"Outro"}
          index={2}
          uniqueName={"backofficeRadioEntityType"}
          name="outro"
          subHeader={
            t(`bpm.onboarding.backoffice.administration.otherSubHeader`) +
            "::card-text-light"
          }
        />
      </BenefModalRadioButtonWrapper>
      {activeAccountType !== "eni" && (
        <>
          <div className="easypay-backoffice-account-section-entity-data-header">
            {t(`bpm.onboarding.backoffice.administration.entityDataHeader`)}
          </div>
          <div className="easypay-backoffice-account-section-cod-validity-wrapper">
            <div className="easypay-backoffice-account-section-input-wrapper">
              <Input
                field={buildInputField(
                  t(
                    `bpm.onboarding.backoffice.administration.codCertidaoHeader`
                  ) + "::disabled",
                  data?.onboardingEntity?.cprc
                )}
              />
            </div>
            <div className="easypay-backoffice-account-section-input-wrapper">
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.administration.validityHeader`) +
                    "::disabled",
                  data?.onboardingEntity?.dataValidade
                )}
              />
            </div>
          </div>
        </>
      )}
      {activeAccountType === "outro" && (
        <>
          <div className="easypay-backoffice-account-section-organization-nipc-cae-name-wrapper">
            <div className="easypay-backoffice-account-section-input-wrapper">
              <Dropdown
                text={t(
                  `bpm.onboarding.backoffice.administration.organizationTypeHeader`
                )}
                required={true}
                placeholder="N/a"
                list={organizationTypeList}
                handleSubmit={(e) => handleSelectChange(e)}
              />
              <Input
                field={buildInputField(
                  "NIPC::disabled",
                  data?.onboardingEntity?.nipc
                )}
              />
            </div>
            <div className="easypay-backoffice-account-section-input-wrapper">
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.administration.headerCAE`) +
                    "::disabled",
                  data?.onboardingEntity?.cae
                )}
              />
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.administration.headerName`),
                  data?.onboardingEntity?.nomeEntidade
                )}
              />
            </div>
          </div>
          <div className="easypay-backoffice-account-section-entity-data-header">
            {t(`bpm.onboarding.backoffice.administration.headerAddress`)}
          </div>
          <div className="easypay-backoffice-account-section-zipcode-locality-wrapper">
            <div className="easypay-backoffice-account-section-small-input-wrapper">
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.administration.headerZipCode`),
                  data?.onboardingEntity?.codPostal
                )}
              />
            </div>
            <div className="easypay-backoffice-account-section-small-input-wrapper">
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.administration.headerLocality`),
                  data?.onboardingEntity?.localidade
                )}
              />
            </div>
          </div>
          <div className="easypay-backoffice-account-section-addresses-wrapper">
            <div className="easypay-backoffice-account-section-input-wrapper">
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.administration.headerAddress-1`),
                  data?.onboardingEntity?.moradaSede
                )}
              />
            </div>
            <div className="easypay-backoffice-account-section-input-wrapper">
              <Input
                field={buildInputField(
                  t(`bpm.onboarding.backoffice.administration.headerAddress-2`),
                  data?.onboardingEntity?.moradaSede
                )}
              />
            </div>
          </div>
        </>
      )}
      {/* <InputSearch
        field={{ text: "inputLabel" }}
        required={true}
        text={t(`bpm.onboarding.backoffice.administration.headerBusinessArea`)}
        placeholder="Área de Negócios"
        list={inputSearchList}
        handleSubmit={(value) => handleSelectChange(value)}
      />
      <div className="easypay-backoffice-business-section-payment-type-header">
        {t(`bpm.onboarding.backoffice.administration.headerPaymentType`)}
      </div>
      <div className="easypay-backoffice-business-section-main-checkbox-wrapper">
        <div className="easypay-backoffice-business-section-checkbox-wrapper">
          <Checkbox
            name="checkbox1"
            id="check1"
            value={"Multibanco"}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
          <Checkbox
            name="checkbox2"
            id="check2"
            value={t(
              `bpm.onboarding.backoffice.administration.headerVISAMasterCard`
            )}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
          <Checkbox
            name="checkbox3"
            id="check3"
            value={t(
              `bpm.onboarding.backoffice.administration.headerDirectDebit`
            )}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
        </div>
        <div className="easypay-backoffice-business-section-checkbox-wrapper">
          <Checkbox
            name="checkbox4"
            id="check4"
            value={t(
              `bpm.onboarding.backoffice.administration.headerBankTicket`
            )}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
          <Checkbox
            name="checkbox5"
            id="check5"
            value="MBWay"
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
          <Checkbox
            name="checkbox6"
            id="check6"
            value={t(`bpm.onboarding.backoffice.administration.headerIBAN`)}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
        </div>
      </div>
      <div className="easypay-backoffice-business-section-payment-type-header">
        {t(`bpm.onboarding.backoffice.administration.headerTransactionSize`)}
      </div>
      <BenefModalRadioButtonWrapper
        key={uuid()}
        variant={"easypay-radio-button-row"}
        activeTab={transactionVolume}
        setActivetab={setTransactionVolume}
      >
        <RadioButtonItem
          key={uuid()}
          text={t(`bpm.onboarding.backoffice.administration.header50000`)}
          uniqueName={"backofficeRadioTransactionSize"}
          index={0}
          name={t(`bpm.onboarding.backoffice.administration.header50000`)}
          subHeader={""}
          checked
        />
        <RadioButtonItem
          key={uuid()}
          text={t(
            `bpm.onboarding.backoffice.administration.headerBetween50000`
          )}
          uniqueName={"backofficeRadioTransactionSize"}
          index={1}
          name={t(
            `bpm.onboarding.backoffice.administration.headerBetween50000`
          )}
          subHeader={""}
        />
        <RadioButtonItem
          key={uuid()}
          text={t(`bpm.onboarding.backoffice.administration.headerAbove150000`)}
          index={2}
          uniqueName={"backofficeRadioTransactionSize"}
          name={t(`bpm.onboarding.backoffice.administration.headerAbove150000`)}
          subHeader={""}
        />
      </BenefModalRadioButtonWrapper>
      <div className="easypay-backoffice-business-section-payment-type-header">
        {t(`bpm.onboarding.backoffice.administration.headerProductType`)}
      </div>
      <BenefModalRadioButtonWrapper
        key={uuid()}
        variant={"easypay-radio-button-row"}
        activeTab={productType}
        setActivetab={setProductType}
      >
        <RadioButtonItem
          key={uuid()}
          text={t(`bpm.onboarding.backoffice.administration.headerAssets`)}
          uniqueName={"backofficeRadioProductType"}
          index={0}
          name={t(`bpm.onboarding.backoffice.administration.headerAssets`)}
          subHeader={""}
          checked
        />
        <RadioButtonItem
          key={uuid()}
          text={t(`bpm.onboarding.backoffice.administration.headerServices`)}
          uniqueName={"backofficeRadioProductType"}
          index={1}
          name={t(`bpm.onboarding.backoffice.administration.headerServices`)}
          subHeader={""}
        />
        <RadioButtonItem
          key={uuid()}
          text={t(
            `bpm.onboarding.backoffice.administration.headerAssetsAndServices`
          )}
          index={2}
          uniqueName={"backofficeRadioProductType"}
          name={t(
            `bpm.onboarding.backoffice.administration.headerAssetsAndServices`
          )}
          subHeader={""}
        />
      </BenefModalRadioButtonWrapper>
      <div className="easypay-backoffice-business-section-payment-type-header">
        5.Lorem ipsum dolor
      </div>
      <BenefModalRadioButtonWrapper
        key={uuid()}
        variant={"easypay-radio-button-row"}
        activeTab={lorem}
        setActivetab={setLorem}
      >
        <RadioButtonItem
          key={uuid()}
          text={"Opção 01"}
          uniqueName={"backofficeRadioLorem"}
          index={0}
          name={"Opção 01"}
          subHeader={""}
          checked
        />
        <RadioButtonItem
          key={uuid()}
          text={"Opção 02"}
          uniqueName={"backofficeRadioLorem"}
          index={1}
          name={"Opção 02"}
          subHeader={""}
        />
        <RadioButtonItem
          key={uuid()}
          text={"Opção 03"}
          index={2}
          uniqueName={"backofficeRadioLorem"}
          name={"Opção 03"}
          subHeader={""}
        />
      </BenefModalRadioButtonWrapper> */}
    </div>
  );
};
export default withNamespaces()(BackOfficeClientSection);
