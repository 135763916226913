import React, { useEffect } from "react";
import { Card, Button, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "~/assets/css/icons.css";
import "~/assets/css/styles.css";
import { withNamespaces } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { pagination } from "~/components/pagination/currentSizePerPage/8";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useRouteMatch, useHistory } from "react-router-dom";

import {
  updateBaseWidget,
  getBaseWidgets,
} from "~/pages/Admin/SystemSettings/actions";
const devLogConsole = require("~/utils/devLog");
const SourceTable = ({
  selectedSource,
  setSelectedSource,
  widget,
  ...props
}) => {
  const { t } = props;
  const { path } = useRouteMatch();
  const { SearchBar } = Search;
  const history = useHistory();
  const dispatch = useDispatch();
  const configurations =
    widget && widget.configurations ? widget.configurations : [];

  const { isUpdated } = useSelector((state) => state.adminConfigReducer);
  useEffect(() => {
    dispatch(getBaseWidgets());
  }, [dispatch, isUpdated]);

  const buildActionsColumn = (_, row, index) => (
    <>
      <i
        className="icon-trash table-action-icon"
        onClick={() => deleteSource(row)}
      />
      <i
        className="icon-edit table-action-icon"
        onClick={() => editSettings(row, index)}
      />
    </>
  );

  const buildApplicationColumn = (_, row) => {
    const dashboardApps = row.apps.filter((app) => app.description.includes("Dashboard"));
   const otherApps = row.apps.filter((app) => !app.description.includes("Dashboard"));
  
    return (
      <>
        {otherApps.map((app, index) => (
          <Badge
            pill
            key={index}
            variant="info"
            className="pointer lg-badge mr-2"
            style={{ marginBottom: "5px" }}
          >
            {app.description}
          </Badge>
        ))}
        {dashboardApps.length > 0 && (
          <Badge
            pill
            variant="info"
            className="pointer lg-badge mr-2"
            style={{ marginBottom: "5px" }}
          >
            Dashboard
          </Badge>
        )}
      </>
    );
  };
  

  const columns = [
    {
      dataField: "name",
      text: t("ServicoGenericoWidget.sourceName"),
      sort: true,
    },
    {
      dataField: "apps",
      text: t("ServicoGenericoWidget.application"),
      formatter: buildApplicationColumn,
    },
    {
      dataField: "accao",
      text: t("ServicoGenericoWidget.action"),
      formatter: buildActionsColumn,
      headerStyle: () => {
        return { width: "80px", textAlign: "center" };
      },
    },
  ];

  const add = () => {
    setSelectedSource(undefined);
    history.push(`${path}/edit`);
  };

  const returnToSettings = () => {
    history.push("/uniksystem/admin/widgetSettings");
  };

  const deleteSource = (row) => {
    devLogConsole(row, widget)
    let newConfiguration = widget.configurations.filter(
      (item) => item.id !== row.id
    );
    const payload = {
      ...widget,
      configurations: newConfiguration,
      idToRemove: row.id
    };
    dispatch(updateBaseWidget(payload));
  };

  const editSettings = (row) => {
    history.push(`${path}/edit`);
    setSelectedSource(row);
  };

  return (
    <>
      <Card.Body>
        <div className="custom-table custom-table-small">
          <ToolkitProvider
            keyField="id"
            data={configurations}
            columns={columns}
            search={{
              defaultSearch: "",
            }}
          >
            {(props) => (
              <div>
                <div style={{ paddingBottom: "20px" }}>
                  <SearchBar
                    {...props.searchProps}
                    placeholder={t("ServicoGenericoWidget.search")}
                  />
                  <Button
                    style={{ marginLeft: "30px", borderRadius:"23px", minHeight:"42px" }}
                    
                    variant="danger"
                    onClick={() => returnToSettings()}
                  >
                    {t("general.back")}
                  </Button>
                  <Button
                    className="card-button"
                    style={{ marginLeft: "10px" }}
                    onClick={add}
                  >
                    {t("general.add")}
                  </Button>
                </div>
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  pagination={paginationFactory(pagination)}
                  noDataIndication={t("ServicoGenericoWidget.sourceNotFound")}
                  striped
                  hover
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </Card.Body>
    </>
  );
};

export default withNamespaces()(SourceTable);
