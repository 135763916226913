import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";

import * as actions from "~/store/ducks/systemSettings/applications/actionTypes";

import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { remove } from "lodash";

import {
  loadLegacyUsers,
  pullLegacyUsersOnlyApp,
  clearTotalPulledLegacy,
} from "../actions";
import "../scss/legacy-user-table.scss";
import Spinner from "../../../../components/Spinner";

import { BPM_MIDDLEWARE } from "~/utils/constants";
import { FaRedoAlt } from "react-icons/fa";

const LegacyUserModal = (props) => {
  const {
    selectedLegacyUser,
    isToReadOnly,
    showModal,
    closeModal,
    selectedLegacyApplication,
    legacyUsers,
    setLegacyUsers,
    legacyApplications,
    setLegacyApplications,
    t,
  } = props;

  const dispatch = useDispatch();

  const [showAlert, setShowAlert] = useState(false);

  const {
    legacyUsers: legacyUsersList,
    isLoadingLegacyUsers,
    isLoadingPullLegacyUsers,
    totalPulledLegacyUsers,
  } = useSelector((state) => state.userManagementReducer);

  useEffect(() => {
    if (selectedLegacyApplication.id) {
      dispatch(loadLegacyUsers(selectedLegacyApplication.id));
    }
  }, [dispatch, selectedLegacyApplication]);

  useEffect(() => {
    if (totalPulledLegacyUsers !== undefined) {
      dispatch(loadLegacyUsers(selectedLegacyApplication.id));
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 6000);
    }
  }, [dispatch, totalPulledLegacyUsers]);

  useEffect(() => {
    return () => {
      dispatch(clearTotalPulledLegacy());
    };
  }, []);

  function handleLegacyUsers(legacyUser) {
    if (legacyUser?.legacyApplication?.label === "portalrh") {
      isToReadOnly(true);
      selectedLegacyUser(legacyUser.extendedAttributes);
    }
    removeItemFromTable();
    setLegacyUsers([...legacyUsers, legacyUser]);
    closeModal();
  }

  function handleLegacyApplications() {
    removeItemFromTable();
    setLegacyApplications([...legacyApplications, selectedLegacyApplication]);
    closeModal();
  }

  function handlePullLegacyUsers(appName) {
    dispatch(pullLegacyUsersOnlyApp(appName));
  }

  function removeItemFromTable() {
    remove(legacyUsers, { legacyApplication: selectedLegacyApplication });
    remove(legacyApplications, selectedLegacyApplication);
  }

  function handleCancelButton() {
    //remove(selectedLegacyUsers, { legacyApp: selectedLegacyApp.id })
    closeModal();
    setShowAlert(false);
  }

  //Table configs
  const buildActionColumn = (_, row, {}) => (
    <i
      className="icon-add lg-icon add-table-icon pointer"
      onClick={() => handleLegacyUsers(row)}
    />
  );

  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "username",
      text: t("admin.userManagement.persist.modal.userColumn"),
    },
    {
      dataField: "select",
      text: t("admin.userManagement.persist.modal.selectColumn"),
      formatter: buildActionColumn,
      align: () => "center",
    },
  ];

  const options = {
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
    ],
    hideSizePerPage: true,
  };

  const getVariantAlert = () => {
    if (totalPulledLegacyUsers === undefined) {
      return "danger";
    } else {
      //const { newOnes, updated, removed } = totalPulledLegacyUsers;
      //return newOnes === 0 && updated === 0 && removed === 0 ? 'warning' : 'success'
      return "success";
    }
  };

  const getMessageAlert = () => {
    if (totalPulledLegacyUsers === undefined) {
      return `${t("admin.userManagement.persist.modal.errorMessage")}`;
    } else {
      /*const { newOnes, updated, removed } = totalPulledLegacyUsers;
      return `${newOnes.length} ${t('admin.userManagement.persist.modal.newOnes')} |
            ${updated.length} ${t('admin.userManagement.persist.modal.updated')} |
            ${removed.length} ${t('admin.userManagement.persist.modal.removed')}`*/
      return `${t("admin.userManagement.persist.modal.updated")}`;
    }
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => closeModal()}
      centered
      backdrop="static"
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {`${selectedLegacyApplication.description} ${t(
            "admin.userManagement.persist.modal.users"
          )}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {showAlert && (
          <Alert
            variant={getVariantAlert()}
            onClose={() => setShowAlert(false)}
            dismissible
          >
            {getMessageAlert()}
          </Alert>
        )}

        <Button
          variant="success"
          onClick={() => handleLegacyApplications()}
          style={{ marginBottom: 10 }}
        >
          {t("admin.userManagement.persist.modal.unikUserButton")}
        </Button>

        {selectedLegacyApplication.label === BPM_MIDDLEWARE.context && (
          <Button
            variant="info"
            style={{ float: "right" }}
            onClick={() => handlePullLegacyUsers("bpm")}
          >
            <span style={{ paddingRight: 5 }}>
              <FaRedoAlt />
            </span>
            {t("admin.userManagement.persist.modal.updateBPMUsers")}
          </Button>
        )}

        {!isLoadingLegacyUsers && (
          <Row noGutters="true">
            <Col>
              <div className="custom-table custom-table-small">
                <ToolkitProvider
                  keyField="id"
                  data={legacyUsersList}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <>
                      <SearchBar
                        {...props.searchProps}
                        placeholder={t(
                          "admin.userManagement.persist.modal.filterUsername"
                        )}
                      />
                      <BootstrapTable
                        {...props.baseProps}
                        id="modal-user-table"
                        keyField="id"
                        pagination={paginationFactory(options)}
                        noDataIndication={t(
                          "admin.userManagement.list.emptyTable"
                        )}
                        striped
                        hover
                      />
                    </>
                  )}
                </ToolkitProvider>
              </div>
            </Col>
          </Row>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" onClick={() => handleCancelButton()}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>

      <Spinner spinning={isLoadingLegacyUsers || isLoadingPullLegacyUsers} />
    </Modal>
  );
};

export default withNamespaces()(LegacyUserModal);
