import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";
import { Card } from "react-bootstrap";
import { closeIframesScreens } from "~/containers/SidebarContainer/util";
import { generateRequestMenu } from "~/containers/GenerateRequestMenu";
import { useSelector, useDispatch } from "react-redux";
import { getCollaborators } from "~/store/ducks/portalrh/actionTypes";
import { getUOSiadapra, setUOSiadapra } from "~/store/ducks/siadapra/actionTypes";
import Spinner from "~/components/Spinner";
import NavTabs from "../NavTabs";
import Sidebar from "../RhCollaborator/Sidebar";
import Organizacao from "./Organizacao";
import Dirigentes from "./Dirigentes";
const devLogConsole = require("~/utils/devLog");

const Delegations = ({
  portalRhMenus,
  designacao,
  path,
  sectionName,
  activeSelected,
  t,
  type,
  styleSheet,
}) => {
  const { user } = useSelector((state) => state.globalReducer);
  const { collaborators } = useSelector((state) => state.portalrhReducer);
  const [menuSideBar, setMenuSideBar] = useState(null);
  const [menus, setMenus] = useState({});
  const [containMenu, setContainMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUOSiadapra([]))
    dispatch(getUOSiadapra());
  }, []);

  useEffect(() => {
    dispatch(getCollaborators());
    portalRhMenus.map((menu) => {
      if (menu.designacao == designacao) {
        devLogConsole(menu);
        setMenuSideBar(menu);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    setMenus({});
    closeIframesScreens();
    setContainMenu(false);
    setMenus(generateRequestMenu(portalRhMenus, designacao, setContainMenu));
  }, []);

  const userNamePortalRH = collaborators.find(
    (e) => e.username == user.legacyUsers[0].username
  );

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "default";
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner spinning={isLoading} transparent={false} />
      ) : (
        <div style={{ display: "flex" }}>
          <Sidebar menuSideBar={menuSideBar} path={path} />
          <div
            className="main-card-v2"
            style={{ overflow: "auto", scrollY: "auto" }}
          >
            <Card
              bsPrefix="card-flat"
              style={{ minHeight: "80vh", overflow: "auto" }}
            >
              <Card.Header className="justify-content-between">
                <NavTabs
                  titleMenu={t(`${sectionName}`)}
                  menus={menus}
                  activeSelected={activeSelected}
                  containMenu={containMenu}
                  setIsLoading={setIsLoading}
                  t={t}
                />
              </Card.Header>
              <Card.Body>
                {type === "organizacao" && (
                  <Organizacao t={t} styleSheet={styleSheet} />
                )}
                {type === "dirigentes" && (
                  <Dirigentes t={t} styleSheet={styleSheet} />
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default withNamespaces()(Delegations);
