import React from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import MobileTaskCard from "./MobileTaskCard";
import PnumberColumn from "./PnumberColumn";
import { getColorByStatus, timeConversion } from "./utils";
import { toLocaleString } from "~/utils";
import he from "he";

import warning_alert from "~/assets/img/icons/alert_amber.png";
import danger_alert from "~/assets/img/icons/alert_red.png";

/*Helper Functions start */
export const buildPendingColumn = (created) => {
  if (created) {
    const millis = Date.now() - new Date(created);
    return timeConversion(millis);
  }
  return "";
};

export const abbreviateTextColumn = (
  text,
  rowIndex,
  translations,
  language
) => {
  let texttoDecode;
  if (!text.includes("::t::")) {
    texttoDecode = text;
  }
  const lingua = language.trim();
  const cleanTextField = text.replace(/'/g, "").trim();
  const splittext = cleanTextField.split("::t::");

  const textoFinal = splittext
    .map((tt) => {
      const splitSplitedText = tt.split("bmp.");
      if (splitSplitedText.length > 1 && translations?.[lingua]) {
        return (
          splitSplitedText[0] +
          translations[lingua]["bmp." + splitSplitedText[1]]
        );
      } else {
        return tt;
      }
    })
    .join("");

  texttoDecode = textoFinal;

  return (
    <OverlayTrigger
      key={rowIndex}
      placement="left"
      overlay={
        <Tooltip id={`tooltip-${rowIndex}`}>{he?.decode(texttoDecode)}</Tooltip>
      }
    >
      <span>{he?.decode(texttoDecode)}</span>
    </OverlayTrigger>
  );
};

export const buildSLAColumn = (SLA) => {
  if (SLA) {
    const toDate = (dateStr) => {
      const day = dateStr.slice(0, 2);
      const month = dateStr.slice(2, 4);
      const year = dateStr.slice(4, 8);
      const hour = dateStr.slice(8, 10);
      const min = dateStr.slice(10, 12);
      const sec = dateStr.slice(12, 14);
      return new Date(year, month - 1, day, hour, min, sec);
    };
    const millis = toDate(SLA) - Date.now();
    return millis ? timeConversion(millis) : SLA;
  }
  return "";
};

export const buildStatusColumn = (row) => {
  if (
    row.metadata &&
    row.metadata.some((item) => item.name === "PROCESS_STATUS")
  ) {
    const status = row.metadata.find(
      (item) => item.name === "PROCESS_STATUS"
    ).value;
    if (status) {
      const variant = getColorByStatus(status);
      //const translatedStatus = translateStatus(status);
      return (
        <Badge pill variant={variant}>
          {status.toUpperCase()}
        </Badge>
      );
    }
  }
  return (
    <Badge pill variant="warning">
      INDETERMINADO
    </Badge>
  );
};

const toDate = (dateStr) => {
  const day = dateStr.slice(0, 2);
  const month = dateStr.slice(2, 4);
  const year = dateStr.slice(4, 8);
  const hour = dateStr.slice(8, 10);
  const min = dateStr.slice(10, 12);
  const sec = dateStr.slice(12, 14);
  return new Date(year, month - 1, day, hour, min, sec);
};

export const buildTTLColumn = (row) => {
  if (row.TTL) {
    const date = toDate(row.TTL);
    if (date) {
      const diff = Date.now() - date;
      let variant = "info";
      if (diff > 0) variant = "danger";
      if (diff > -86400000 && diff < 0)
        /*86400000 1 day in milliseconds*/
        variant = "warning";

      const renderTTLAlert = () => {
        if (variant === "danger") {
          return (
            <div
              style={{
                width: "85px",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {date.toLocaleString()}
              <img
                src={danger_alert}
                height="20"
                alt=""
                style={{ marginLeft: "0.3em" }}
              />
            </div>
          );
        } else if (variant === "warning") {
          return (
            <div
              style={{
                width: "85px",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {date.toLocaleString()}
              <img
                src={warning_alert}
                height="20"
                alt=""
                style={{ marginLeft: "0.3em" }}
              />
            </div>
          );
        } else
          return (
            <div
              style={{
                width: "85px",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {date.toLocaleString()}
            </div>
          );
      };
      return renderTTLAlert();
    } else return "";
  } else {
    //no ttl
    return buildTTLColumnDemo(row);
  }
};

export const getTTLVariant = (row) => {
  let variant = "info";
  if (row.TTL) {
    const date = toDate(row.TTL);
    if (date) {
      const diff = Date.now() - date;
      if (diff > 0) variant = "danger";
      if (diff > -86400000 && diff < 0)
        /*86400000 1 day in milliseconds*/
        variant = "warning";
      return variant;
    } else return variant;
  }
  return variant;
};

const buildTTLColumnDemo = (row) => {
  if (row.created) {
    const toDate = () => {
      let myDate = new Date(row.created);
      return myDate.setDate(myDate.getDate() + 1);
    };
    const TTLdate = toDate();
    if (TTLdate) {
      const diff = Date.now() - TTLdate;
      let variant = "info";
      if (diff > 0) variant = "danger";
      if (diff > -3600000 * 4 && diff < 0)
        /*86400000 1 day in milliseconds, 3600000 1 hour in milliseconds*/
        variant = "warning";

      const renderTTLAlert = () => {
        if (variant === "danger") {
          return (
            <div
              style={{
                width: "85px",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {toLocaleString(TTLdate)}
              <img
                src={danger_alert}
                height="20"
                alt=""
                style={{ marginLeft: "0.3em" }}
              />
            </div>
          );
        } else if (variant === "warning") {
          return (
            <div
              style={{
                width: "85px",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {toLocaleString(TTLdate)}
              <img
                src={warning_alert}
                height="20"
                alt=""
                style={{ marginLeft: "0.3em" }}
              />
            </div>
          );
        } else
          return (
            <div
              style={{
                width: "85px",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {toLocaleString(TTLdate)}
            </div>
          );
      };
      return renderTTLAlert();
    } else return "";
  }
  return "";
};

export const buildFlowInitialColumn = (row, rowIndex) => {
  const abbreviateInitials = (row, initials, rowIndex, numLetters) => {
    const spantext = () => {
      if (initials) {
        if (initials.length >= numLetters) return initials.slice(0, numLetters);
        else {
          return initials;
        }
      } else {
        if (row && row.flowname && row.flowname.length > 2) {
          const words = row.flowname.split(" ");
          if (words.length == 1) {
            return row.flowname.slice(0, 2);
          } else {
            if (words.length == 2 && words[1].length > 3) {
              return words[0].slice(0, 1) + words[1].slice(0, 1);
            } else if (words.length > 2) {
              return words[0].slice(0, 1) + words[2].slice(0, 1);
            }
          }
        } else return row.flowname.slice(0, numLetters);
      }
    };
    return (
      <OverlayTrigger
        key={rowIndex}
        placement="right"
        overlay={
          <Tooltip
            id={`tooltip-${rowIndex}`}
            style={{
              display: row && row?.flowname ? "block" : "none",
            }}
          >
            {row?.flowname}
          </Tooltip>
        }
      >
        <span>{spantext()}</span>
      </OverlayTrigger>
    );
  };

  return (
    <div className="tasks-pnumber-wrapper">
      <div
        className="tasks-table-badge task-badge-bc"
        style={{
          color: "white",
          width: "45px",
        }}
      >
        <div className="tasks-badge-content">
          {abbreviateInitials(row, row?.initials, rowIndex, 3)}
        </div>
      </div>
    </div>
  );
};

export const buildIflowNumberColumn = (value, formattedType) => {
  if (value && formattedType) {
    if (formattedType === "int") {
      const signal = value.charAt(0) === "1" ? "" : "-";
      const int = value.substring(1, value.length - 5);
      let index = 0;
      for (let i = 0; i < int.length; i++) {
        if (int.charAt(i) !== "0") {
          index = i;
          break;
        }
      }
      return signal + " " + int.substring(index);
    }
  }
  return "";
};

export const buildIflowDateColumn = (value, formattedType) => {
  if (value && formattedType) {
    if (formattedType === "date" && value.length >= 14) {
      /*
      formato: ddMMyyyyHHmmss
      06042022144137378
      será
      06-04-2022 14:41:37
      */
      const date =
        value.substring(0, 2) +
        "-" +
        value.substring(2, 4) +
        "-" +
        value.substring(4, 8);
      /* exclude time +
        " " +
        value.substring(8, 10) +
        ":" +
        value.substring(10, 12) +
        ":" +
        value.substring(12, 14);*/
      return date;
    } else return value;
  }
  return "";
};

/*Helper Functions end */

export const getColumnFormatter = (cell, row, rowIndex, formatExtraData) => {
  if (formatExtraData.value === "PROCESS_STATUS") {
    return buildStatusColumn(row);
  }
  if (formatExtraData.value === "SLA") {
    return buildSLAColumn(row.SLA);
  }
  if (formatExtraData.value === "TTL") {
    return buildTTLColumn(row);
  }
  if (formatExtraData.value === "description") {
    if (row?.description) {
      return abbreviateTextColumn(
        row.description,
        rowIndex,
        formatExtraData?.translations,
        formatExtraData?.language
      );
    }
  }
  if (formatExtraData.value === "pnumber") {
    return (
      <PnumberColumn
        row={row}
        rowIndex={rowIndex}
        userBPM={formatExtraData.userBPM}
        showCategories={formatExtraData.showCategories}
        styleSheetReadFlag={formatExtraData.readFlag}
        styleSheetToolTipMoreOptions={formatExtraData.toolTipMoreOptions}
      />
    );
  }
  if (formatExtraData.value === "pendingPeriod") {
    return buildPendingColumn(row.created);
  }
  if (formatExtraData.value === "initials") {
    return buildFlowInitialColumn(row, rowIndex);
  }
  if (typeof formatExtraData.value === "object") {
    const key = Object.keys(formatExtraData.value)[0];
    const rawType = formatExtraData.value[key].rawType;
    const formattedType = formatExtraData.value[key].formattedType;
    if (rawType === "iflowNumber") {
      return buildIflowNumberColumn(row[key]?.toString(), formattedType);
    } else if (rawType === "iflowDate") {
      return buildIflowDateColumn(row[key]?.toString(), formattedType);
    } else return row[key];
  } else return row[formatExtraData.value];
};

export const getMobileCardFormatter = (
  cell,
  row,
  rowIndex,
  formatExtraData
) => {
  return (
    <MobileTaskCard
      row={row}
      rowIndex={rowIndex}
      formatExtraData={formatExtraData}
    />
  );
};
