import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card } from "react-bootstrap";
import Chart from "chart.js";
import { lineChart } from "./chartDetail.js";
import "~/assets/css/icons.css";
import CustomDropdown from "../CustomDropdown";
import { withNamespaces } from "react-i18next";
import { updateUserWidget } from "~/pages/User/actions";

const LineChartWidget = ({
  apagarWidget,
  widget,
  handleMouseDown,
  isResizing,
  textMove,
  textClick,
  t
}) => {
  const myRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const myChartRef = myRef.current.getContext("2d");
    new Chart(myChartRef, lineChart);
  }, []);

  useEffect(() => {
    if (widget.userConfiguration == null) {
      const payload = {
        id: widget.id,
        widgetId: widget.widgetId,
        userConfiguration: {
          type: ".PowerBIUserConfiguration",
          configurationId: null,
          widthSize: "6",
        },
      };
      dispatch(updateUserWidget(payload));
    }
  }, [widget]);

  useEffect(() => {
    lineChart.data.labels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    lineChart.data.datasets[0].data = [
      0, 10000, 5000, 15000, 10000, 20000, 15000, 25000, 20000, 30000, 25000,
      40000,
    ];
    lineChart.data.datasets[0].label = "Processos";
    const myChartRef = myRef.current.getContext("2d");
    new Chart(myChartRef, lineChart);
  });

  return (
    <div className="main-card-v2">
      <Card bsPrefix="card-flat">
        <Card.Header className="justify-content-between">
          <h6>
            <i className="icon-bell mr-2" /> Gráfico de linha: Processos
            tratados
          </h6>
          <h6>
            {textMove && t("widgets.resizeMove")}
            {textClick && t("widgets.resizeClick")}
          </h6>
          <CustomDropdown
            apagarWidget={apagarWidget}
            handleMouseDown={handleMouseDown}
          />
        </Card.Header>
        {!isResizing && (
          <Card.Body>
            <canvas id="myAreaChart" ref={myRef}></canvas>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};
export default withNamespaces()(LineChartWidget);
