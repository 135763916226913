import {
  CLEAR_RHADMIN_CONFIGURATION,
  SET_RHADMIN_CONFIGURATION,
  PERSIST_RHADMIN_CONFIGURATION,
  SUCCESS_PERSIST_RHADMIN_CONFIGURATION,
  SET_RH_BOLSA_HORAS_CONFIGURATION,
  SET_RH_BOLSA_HORAS_USERS_GROUP,
  CLEAR_RH_BOLSA_HORAS_CONFIGURATION,
  CLEAR_RH_BOLSA_HORAS_USERS_GROUP,
  PERSIST_RH_BOLSA_HORAS_CONFIGURATION,
  SUCCESS_PERSIST_RH_BOLSA_HORAS_CONFIGURATION,
  CLEAR_RH_SALDO_BOLSA_HORAS,
  SET_RH_SALDO_BOLSA_HORAS,
  GET_RH_SALDO_BOLSA_HORAS,
  CLEAR_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS,
  SET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS,
} from "./actionTypes";

const initialState = {
  rhadminConfiguration: {},
  successPersistRHAdminConfiguration: false,
  rhbolsaHorasConfiguration: [],
  rhbolsaHorasUsersGroup: [],
  successPersistRHBolsaHorasConfiguration: false,
  saldoBolsa: [],
  saldoBolsaInicialFinal: "",
  isLoadingSaldo: false,
};

export const rhadminConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RHADMIN_CONFIGURATION:
      return {
        ...state,
        rhadminConfiguration: action.configuration,
      };
    case PERSIST_RHADMIN_CONFIGURATION:
      return {
        ...state,
        successPersistRHAdminConfiguration: false,
      };
    case CLEAR_RHADMIN_CONFIGURATION:
      return {
        ...state,
        rhadminConfiguration: initialState.rhadminConfiguration,
      };
    case SUCCESS_PERSIST_RHADMIN_CONFIGURATION:
      return {
        ...state,
        successPersistRHAdminConfiguration: true,
      };

    //BOLSA HORAS

    case SET_RH_BOLSA_HORAS_CONFIGURATION:
      return {
        ...state,
        rhbolsaHorasConfiguration: action.configuration,
      };
    case SET_RH_BOLSA_HORAS_USERS_GROUP:
      return {
        ...state,
        rhbolsaHorasUsersGroup: action.usersGroup,
      };
    case CLEAR_RH_BOLSA_HORAS_CONFIGURATION:
      return {
        ...state,
        rhbolsaHorasConfiguration: initialState.rhbolsaHorasConfiguration,
      };
    case CLEAR_RH_BOLSA_HORAS_USERS_GROUP:
      return {
        ...state,
        rhbolsaHorasUsersGroup: initialState.rhbolsaHorasUsersGroup,
      };
    case PERSIST_RH_BOLSA_HORAS_CONFIGURATION:
      return {
        ...state,
        successPersistRHBolsaHorasConfiguration: false,
      };
    case SUCCESS_PERSIST_RH_BOLSA_HORAS_CONFIGURATION:
      return {
        ...state,
        successPersistRHBolsaHorasConfiguration: true,
      };
    case CLEAR_RH_SALDO_BOLSA_HORAS:
      return {
        ...state,
        saldoBolsa: initialState.saldoBolsa,
      };
    case SET_RH_SALDO_BOLSA_HORAS:
      return {
        ...state,
        saldoBolsa: action.listSaldo,
        isLoadingSaldo: false,
      };
    case GET_RH_SALDO_BOLSA_HORAS:
      return {
        ...state,
        isLoadingSaldo: true,
      };
    case CLEAR_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS:
      return {
        ...state,
        saldoBolsaInicialFinal: initialState.saldoBolsaInicialFinal,
      };
    case SET_RH_SALDO_INICIAL_FINAL_BOLSA_HORAS:
      return {
        ...state,
        saldoBolsaInicialFinal: action.listSaldoInicialFinal,
        isLoadingSaldo: false,
      };
    default:
      return state;
  }
};
