import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InitalDataSection from "../components/InitalDataSection";
import ProjectDescriptionSection from "../components/ProjectDescriptionSection";
import "../assets/scss/_headerTitle.scss";
import Item from "../components/Item";
import oauth from "~/utils/oauth";
//import SimpleButton from "../components/SimpleButton";
import SimpleButton from "~/pages/Easypay/components/SimpleButton";
import TabSeparator from "~/pages/Easypay/components/TabSeparator";
import TabSeparatorWrapper from "~/pages/Easypay/components/TabSeparatorWrapper";
import AddButton from "~/components/Buttons/AddButton";
import Proposal from "../components/Proposal";
import Modal from "~/pages/Easypay/components/Modal";
import ProvaDeVida from "~/pages/Easypay/components/ProvaDeVida";
import uuid from "uuid/v1";
import BudgetDocumentPreviewPage from "../components/BudgetDocumentPreviewPage";
import {
  getIflowFormdata,
  setIflowTriggerLoading,
  setIflowFormdata,
} from "~/store/ducks/processes/actionTypes";
import ProtectedComponent from "~/components/security/ProtectedComponent";
import { getBudgetmgrConfiguration } from "~/store/ducks/budgetmgrConfiguration/actionTypes";
import Simulator from "../components/Simulator";

import {
  setAllWorkplans,
  saveChangesWorkplan,
  setErrorUpdating,
} from "~/store/ducks/budgetManagement/actionTypes";

import { getDocumentByWorkplan } from "~/store/ducks/budgetManagement/actionTypes";

const WorkPlanPage = ({
  workplanIndex,
  setDefaultActive,
  defaultActive,
  proposalActivated,
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { workplans, errorUpdating, workplan } = useSelector(
    (state) => state.budgetManagementReducer
  );
  const { iflowTriggerLoading, formdata } = useSelector(
    (state) => state.processesReducer
  );

  const { budgetmgrConfiguration } = useSelector(
    (state) => state.budgetmgrConfigurationReducer
  );
  const [submittedWorkplans, setSubmittedWorkplans] = useState([]);

  useEffect(() => {
    //setSubmittedWorkplans(JSON.parse(localStorage.getItem('submittedWorkplans')))
  }, [submittedWorkplans]);
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [isModalWaitingShowing, setIsModalWaitingShowing] = useState(false);
  const [isModalSubmitErrorShowing, setIsModalSubmitErrorShowing] =
    useState(false);

  const [errorString, setErrorString] = useState("");
  const [isModalShowingErrorFact, setIsModalShowingErrorFact] = useState(false);
  const [isModalSaveShowing, setIsModalSaveShowing] = useState(false);
  const [isModalProjectNumberErrorShowing, setIsModalProjectNumberErrorShowing] = useState(false);
  const [isModalErrorUpdatingShowing, setIsModalErrorUpdatingShowing] =
    useState(false);
  const [isModalErrorShowing, setIsModalErrorShowing] = useState(false);
  const [proposalDisabled, setProposalDisabled] = useState(proposalActivated);

  useEffect(() => {
    if (formdata && formdata != null && formdata != undefined) {
      if (iflowTriggerLoading === true && iflowTriggerLoading != null) {
        dispatch(
          getDocumentByWorkplan({
            workPlanId: workplans[workplanIndex].id,
          })
        );
        dispatch(setIflowTriggerLoading(null));
        setProposalDisabled(false);
        setIsModalWaitingShowing(false);
        setIsModalShowing(true);
        var array = JSON.parse(localStorage.getItem("submittedWorkplans"));
        array[workplanIndex] = 1;
        localStorage.setItem("submittedWorkplans", JSON.stringify(array));
      } else if (iflowTriggerLoading === false && iflowTriggerLoading != null) {
        dispatch(setIflowTriggerLoading(null));
        setIsModalWaitingShowing(false);
        setIsModalSubmitErrorShowing(true);
      }
      dispatch(setIflowFormdata(null));
    }
  }, [formdata]);

  useEffect(() => {
    if (user && user.organization && user.organization.id)
      dispatch(getBudgetmgrConfiguration(user.organization.id));
  }, [user]);

  useEffect(() => {
    if (workplans[workplanIndex].resumoFinaceiro === null) {
      workplans[workplanIndex].resumoFinaceiro = {
        resumeFactoration: [],
        resumeCost: [],
        resumeIncome: [],
        resumePercIncome: [],
        resumeCostHours: [],
        resumeHours: [],
        resumeMargem: [],
        resumePercMargin: [],
        toChange: 0,
        canSave: true,
        maxFactTotal: 0,
      };
    }
    if (workplans[workplanIndex].simulador === null) {
      workplans[workplanIndex].simulador = {
        valorLimite: 0,
        horasPropInicial: 0,
        horasValSugerido: 0,
        horasOutroValor: 0,
        horasValorConsiderar: 0,
        subcontratosPropInicial: 0,
        subcontratosValSugerido: 0,
        subcontratosOutroValor: 0,
        subcontratosValorConsiderar: 0,
        despesasPropInicial: 0,
        despesasValSugerido: 0,
        despesasOutroValor: 0,
        despesasValorConsiderar: 0,
        factoringPropInicial: 0,
        factoringValSugerido: 0,
        factoringOutroValor: 0,
        factoringValorConsiderar: 0,
        totalPropInicial: 0,
        totalValSugerido: 0,
        totalOutroValor: 0,
        totalValorConsiderar: 0,
      };
    }
    dispatch(
      saveChangesWorkplan({ content: workplans[workplanIndex], show: false })
    );
  }, []);

  useEffect(() => {
    if (errorUpdating === true && errorUpdating != null) {
      setIsModalErrorUpdatingShowing(true);
      dispatch(setErrorUpdating(null));
      var array = JSON.parse(localStorage.getItem("submittedWorkplans"));
      array[workplanIndex] = 0;
      localStorage.setItem("submittedWorkplans", JSON.stringify(array));
    } else if (errorUpdating === false && errorUpdating != null) {
      setIsModalSaveShowing(true);
      dispatch(setErrorUpdating(null));
      var array = JSON.parse(localStorage.getItem("submittedWorkplans"));
      array[workplanIndex] = 0;
      localStorage.setItem("submittedWorkplans", JSON.stringify(array));
    }
  }, [workplan, workplans]);

  const newItem = {
    nomeObjetivo: "",
    valorCustoObj: 0,
    valorVendaObj: 0,
    objetivoEspecifico: "",
    atividades: [
      {
        nomeAtividade: "",
        valorCustoAct: 0,
        valorVendaAct: 0,
        duracaoAct: 0,
        dataInicioAct: "",
        dataFimAct: "",
        opcoes: [
          {
            selected: false,
            imprimir: false,
            nomeOpcao: "",
            valorCustoOpt: 0,
            valorVendaOpt: 0,
            observaceosOpt: "",
            imagensOpt: [],
            horas: [],
            fornecedores: [],
            despesas: [],
          },
        ],
      },
    ],
    createdDate: null,
    createdBy: "kate",
  };

  function addItem() {
    workplans[workplanIndex].items.push(newItem);
    dispatch(setAllWorkplans(workplans));
  }

  function updateItemFunction() {
    if(workplans[workplanIndex].numeroGanho === "" && (workplans[workplanIndex].estado === "Ganho" || workplans[workplanIndex].estado === "Ongoing"))
    {
      setIsModalProjectNumberErrorShowing(true)
      return
    }
    if (workplans[workplanIndex].resumoFinaceiro.canSave === false) {
      setIsModalShowingErrorFact(true);
      return;
    }
    setProposalDisabled(true);
    workplans[workplanIndex].resumoFinaceiro.toChange = 1;
    dispatch(
      saveChangesWorkplan({ content: workplans[workplanIndex], show: true })
    );
  }

  function createBody() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Plano Submetido!" status="success" />
      </div>
    );
  }

  function createBodyWaiting() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Por favor aguarde." status="waiting" />
      </div>
    );
  }

  function createBodySubmitError() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Erro ao submeter plano" status="error" />
      </div>
    );
  }

  function createBodySave() {
    return (
      <div className="lift-modal-body-wrapper" style={{ minWidth: "400px" }}>
        <ProvaDeVida text="Alterações guardadas" status="success" />
      </div>
    );
  }

  function createBodyError() {
    return (
      <div className="lift-modal-body-wrapper">
        <div>
          <ProvaDeVida text="Erro ao Submeter" status="error" />
          <div style={{ fontWeight: "bold" }}>{errorString}</div>
        </div>
      </div>
    );
  }

  function createBodyErrorUpdating() {
    return (
      <div className="lift-modal-body-wrapper">
        <div>
          <ProvaDeVida text="Erro ao guardar alterações!!" status="error" />
        </div>
      </div>
    );
  }

  function createBodyErrorFact() {
    return (
      <div className="lift-modal-body-wrapper">
        <div>
          <ProvaDeVida
            text="Erro ao guardar alterações! Valor de faturação diferente do total"
            status="error"
          />
        </div>
      </div>
    );
  }

  function createBodyProjectNumberError() {
    return (
      <div className="lift-modal-body-wrapper">
        <div>
          <ProvaDeVida
            text="Nº de projeto não pode ser vazio"
            status="error"
          />
        </div>
      </div>
    );
  }

  function goBack() {
    window.location.reload(false);
  }

  function getIflow() {
    var missingParams = [];
    if (workplans[workplanIndex].clientDirectorProjeto.length === 0) {
      missingParams.push("CLIENT DIRETOR DO PROJETO");
    }
    if (workplans[workplanIndex].leadComercial === "") {
      missingParams.push("LEAD COMERCIAL");
    }
    if (workplans[workplanIndex].equipa.length === 0) {
      missingParams.push("EQUIPA");
    }
    if (workplans[workplanIndex].numClienteProjeto === 0) {
      missingParams.push("Nº CLIENTE PROJETO , NOME CLIENTE PROJETO");
    }
    if (workplans[workplanIndex].clientOwner === "") {
      missingParams.push("CLIENT OWNER");
    }
    if (workplans[workplanIndex].clientPartner === "") {
      missingParams.push("CLIENT PARTNER");
    }
    if (workplans[workplanIndex].nomeProposta === "") {
      missingParams.push("NOME DA PROPOSTA");
    }
    if (workplans[workplanIndex].tipoProjeto === "") {
      missingParams.push("TIPO DE PROJETO");
    }
    if (workplans[workplanIndex].probabilidade === 0) {
      missingParams.push("PROBABILIDADE");
    }
    if (workplans[workplanIndex].business === 0) {
      missingParams.push("BUSINESS");
    }
    if (workplans[workplanIndex].proposta === "") {
      missingParams.push("PROPOSTA");
    }
    if (
      workplans[workplanIndex].dataInicioProjeto === "" ||
      workplans[workplanIndex].dataInicioProjeto === null
    ) {
      missingParams.push("DATA INICIO DO PROJETO");
    }
    if (
      workplans[workplanIndex].dataFinalProjeto === "" ||
      workplans[workplanIndex].dataFinalProjeto === null
    ) {
      missingParams.push("DATA FINAL DO PROJETO");
    }

    var currentErrorString =
      "Falta preencher os seguintes campos obrigatórios: ";
    if (missingParams.length > 0) {
      for (var i = 0; i < missingParams.length; i++) {
        if (i != missingParams.length - 1) {
          currentErrorString = currentErrorString + missingParams[i] + " , ";
        } else {
          currentErrorString = currentErrorString + missingParams[i];
        }
      }
      setErrorString(currentErrorString);
      setIsModalErrorShowing(true);
      return;
    }

    const flowId =
      budgetmgrConfiguration && budgetmgrConfiguration.flowId
        ? budgetmgrConfiguration.flowId
        : "337";
    const payload = {
      url:
        "/inicio_flow.jsp?" +
        "flowid=" +
        flowId +
        "&attr_workplanId=" +
        workplans[workplanIndex].id +
        "&attr_Authorization=" +
        oauth.getAccessToken() +
        "&Authorization=" +
        oauth.getAccessToken(),
    };
    setIsModalWaitingShowing(true);
    setProposalDisabled(true);
    workplans[workplanIndex].resumoFinaceiro.toChange = 1;
    dispatch(getIflowFormdata(payload));
  }

  const isOrgGroupAdmin = () => {
    if (
      user &&
      user.roles &&
      user.roles.some(
        (r) => r.label === "ADMIN" || r.label === "BUDGETMGROrgGroupAdmin"
      )
    ) {
      return true;
    } else return false;
  };

  function isButonsVisible() {
    if (
      workplans[workplanIndex].estado === "Ongoing" ||
      workplans[workplanIndex].estado === "Ganho"
    ) {
      if (isOrgGroupAdmin()) {
        return true;
      } else return false;
    } else return true;
  }
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        paddingLeft: "60px",
        paddingRight: "60px",
        paddingTop: "60px",
        paddingBottom: "60px",
        background: "white",
      }}
    >
      <div className="liftworld-header">PLANO DE TRABALHO</div>
      <Modal
        className="easypay-modal"
        showModal={isModalShowing}
        setShowModal={setIsModalShowing}
        headerContent={"Submissão de Plano"}
        bodyContent={createBody()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalSaveShowing}
        setShowModal={setIsModalSaveShowing}
        headerContent={"Guardar alterações"}
        bodyContent={createBodySave()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalShowingErrorFact}
        setShowModal={setIsModalShowingErrorFact}
        headerContent={"Erro"}
        bodyContent={createBodyErrorFact()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalWaitingShowing}
        setShowModal={setIsModalWaitingShowing}
        headerContent={"Submissão de Plano"}
        bodyContent={createBodyWaiting()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalErrorShowing}
        setShowModal={setIsModalErrorShowing}
        headerContent={"Erro"}
        bodyContent={createBodyError()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalErrorUpdatingShowing}
        setShowModal={setIsModalErrorUpdatingShowing}
        headerContent={"Erro"}
        bodyContent={createBodyErrorUpdating()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalSubmitErrorShowing}
        setShowModal={setIsModalSubmitErrorShowing}
        headerContent={"Erro"}
        bodyContent={createBodySubmitError()}
        key={uuid()}
      ></Modal>
      <Modal
        className="easypay-modal"
        showModal={isModalProjectNumberErrorShowing}
        setShowModal={setIsModalProjectNumberErrorShowing}
        headerContent={"Erro"}
        bodyContent={createBodyProjectNumberError()}
        key={uuid()}
      ></Modal>
      <SimpleButton
        text={"Voltar"}
        variant={"easypay-button-link"}
        onClick={() => goBack()}
      />
      <div style={{ paddingBottom: "20px" }} />
      <div className="">
        <TabSeparatorWrapper defaultActive={defaultActive}>
          <TabSeparator
            text="Dados"
            name="tab1"
            variant={"liftworld-tab-wrapper"}
          >
            <InitalDataSection
              items={
                workplans[workplanIndex] ? workplans[workplanIndex] : workplans
              }
              workplanIndex={workplanIndex}
            />
          </TabSeparator>
          <TabSeparator
            text="Plano de Trabalho"
            name="tab2"
            variant={"liftworld-tab-wrapper"}
          >
            <Item
              list={workplans[0] ? workplans[workplanIndex]?.items : workplans}
              workplanIndex={workplanIndex}
            />
            <div className="liftworld-item-bottom-button-wrapper">
              <AddButton
                text="Adicionar Nova Atividade Principal"
                onClick={() => addItem()}
              />
            </div>
          </TabSeparator>
          <TabSeparator
            text="Resumo financeiro"
            name="tab3"
            variant={"liftworld-tab-wrapper"}
          >
            <ProjectDescriptionSection
              workplan={
                workplans[workplanIndex] ? workplans[workplanIndex] : workplans
              }
              workplanIndex={workplanIndex}
            />
          </TabSeparator>
          <TabSeparator
            text="Simulador"
            name="tab6"
            variant={"liftworld-tab-wrapper"}
            disabled={false}
          >
            <Simulator
              workplan={workplans[workplanIndex]}
              workplanIndex={workplanIndex}
            />
          </TabSeparator>
          <TabSeparator
            text="Proposta Financeira"
            name="tab4"
            variant={"liftworld-tab-wrapper"}
            disabled={proposalDisabled}
          >
            <Proposal workplanIndex={workplanIndex} />
          </TabSeparator>
          <TabSeparator
            text="Orçamento"
            name="tab5"
            variant={"liftworld-tab-wrapper"}
            disabled={proposalDisabled}
          >
            <BudgetDocumentPreviewPage />{" "}
          </TabSeparator>
        </TabSeparatorWrapper>
        {isButonsVisible() && (
          <div className="liftworld-workplan-bottom-button-wrapper">
            <ProtectedComponent
              permissions={[{ label: "app-budgetmgr-workplan-update" }]}
            >
              <SimpleButton
                text={"Submeter Plano"}
                variant={"liftworld-button-primary"}
                onClick={() => getIflow()}
              />
            </ProtectedComponent>
            <ProtectedComponent
              permissions={[{ label: "app-budgetmgr-workplan-update" }]}
            >
              <SimpleButton
                text={"Guardar alterações"}
                variant={"liftworld-button-primary"}
                onClick={() => updateItemFunction()}
              />
            </ProtectedComponent>
          </div>
        )}
      </div>
    </div>
  );
};
export default WorkPlanPage;
