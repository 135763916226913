import { Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";

const ButtonsCreate = ({
  OnTakingBolean,
  createNewData,
  addressPage,
  personalDataPage,
  academicQualificatonsPage,
  taxDataPage,
  houseHoldPage,
  paymentsDataPage,
  trainingSheetPage,
  performancePage,
  medicalRecordPage,
  handleNewButtonClicked,
  t,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleButton = () => {
    setIsClicked(!isClicked);
    OnTakingBolean(isClicked);
    handleNewButtonClicked(isClicked);
  };

  const handleSaveButton = () => {
    if (addressPage) {
      createNewData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleNewButtonClicked(isClicked);
    }

    if (personalDataPage) {
      createNewData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleNewButtonClicked(isClicked);
    }
    if (academicQualificatonsPage) {
      createNewData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleNewButtonClicked(isClicked);
    }
    if (taxDataPage) {
      createNewData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleNewButtonClicked(isClicked);
    }
    if (houseHoldPage) {
      createNewData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleNewButtonClicked(isClicked);
    }
    if (paymentsDataPage) {
      createNewData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleNewButtonClicked(isClicked);
    }
    if (trainingSheetPage) {
      createNewData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleNewButtonClicked(isClicked);
    }
    if (performancePage) {
      createNewData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleNewButtonClicked(isClicked);
    }
    if (medicalRecordPage) {
      createNewData(false);
      setIsClicked(!isClicked);
      OnTakingBolean(isClicked);
      handleNewButtonClicked(isClicked);
    }
  };

  return (
    <>
      {isClicked ? (
        <div className="UpdateButtons_PersonalInformation">
          <Button
            className="card-button"
            onClick={handleSaveButton}
            style={{ marginLeft: 15 }}
          >
            {t(`portalrh.NavBar.ButtonSave`)}
          </Button>

          <Button
            className="card-button"
            style={{ marginLeft: 15 }}
            onClick={handleButton}
          >
            {t(`portalrh.NavBar.ButtonCancel`)}
          </Button>
        </div>
      ) : (
        <div className="UpdateButtons_PersonalInformation">
          <Button
            className="card-button"
            style={{ marginLeft: 15 }}
            onClick={handleButton}
          >
            {t(`portalrh.NavBar.ButtonNew`)}
          </Button>
        </div>
      )}
    </>
  );
};

export default withNamespaces()(ButtonsCreate);
