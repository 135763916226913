import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "react-bootstrap";

import { withNamespaces } from "react-i18next";
import { BPM_MIDDLEWARE } from "~/utils/constants";
import Spinner from "~/components/Spinner";

import ProcessTable from "./ProcessTable";
import FlowModal from "../FlowModal";
import ProcessFilters from "./ProcessFilters";
import PreviewModal from "./PreviewModal";
import Messages from "~/components/Messages";
import oauth from "~/utils/oauth";
import { subDays } from "date-fns";
import { Alert } from "react-bootstrap";
import uuid from "uuid/v1";

import { findLegacyUsers, setLegacyUsers } from "~/store/ducks/udw/actionTypes";
import {
  findIflowProcessesByFilters,
  findIflowProcessesCount,
} from "~/store/ducks/processes/actionTypes";
import { getMenusByFlows } from "~/store/ducks/applicationMenu/actionTypes";

import { AUTOMATED_DATACAPTURE_APP } from "~/utils/constants";

import "~/assets/css/icons.css";

const Processes = ({ app, className, ...props }) => {
  const { t } = props;
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalReducer);
  const { menu } = useSelector((state) => state.applicationMenuReducer);
  const { legacyUsers } = useSelector((state) => state.udwReducer);
  const { isLoadingProcesses, numbercountTotal, numbercount } = useSelector(
    (state) => state.processesReducer
  );

  /*Filters Start*/
  const [flowId, setFlowId] = useState("");
  const [numberProcesses, setNumberProcesses] = useState(0);
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndAt] = useState("");
  const [previousUser, setPreviousUser] = useState("");
  const [flowFiltersValues, setFlowFiltersValues] = useState(undefined);
  const [closedFilter, setClosedFilter] = useState(null);
  const [actorsFilter, setActorsFilter] = useState(null);
  const [nProcess, setNProcess] = useState("");
  const [historyBTN, setHistoryBTN] = useState(false);

  useEffect(() => {
    if (user.legacyUsers) {
      let userBPM = user.legacyUsers.find(
        (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
      );
      let username = userBPM ? userBPM.username : null;
      if (app === "adatacapture") {
        dispatch(getMenusByFlows(username, AUTOMATED_DATACAPTURE_APP));
      }
    }
  }, [user, dispatch]);

  /*Filters End*/

  const handleFlowId = (flowId) => {
    setFlowId(flowId);
  };
  const handleEndAt = (endAt_) => {
    setEndAt(endAt_);
  };
  const handleNProcess = (nProcess_) => {
    setNProcess(nProcess_);
  };
  const handleStartAt = (startAt_) => {
    setStartAt(startAt_);
  };
  const handlePreviousUser = (previousUser_) => {
    setPreviousUser(previousUser_);
  };
  const handleFlowFiltersValues = (values) => {
    setFlowFiltersValues(values);
  };
  const handleClosed = (closed) => {
    setClosedFilter(closed);
  };
  const handleActors = (actors) => {
    setActorsFilter(actors);
  };

  const getUsername = (legacyUsers) => {
    let userBPM = legacyUsers.find(
      (item) => item.legacyApplication.label === BPM_MIDDLEWARE.context
    );
    return userBPM ? userBPM.username : null;
  };
  const userBPM = user.legacyUsers ? getUsername(user.legacyUsers) : null;

  const [defaultFlowId, setDefaultFlowId] = useState(undefined);
  const [period, setPeriod] = useState("");
  const [hiddenDefaults, setHiddenDeafults] = useState(undefined);

  /*Get default values for flowId and period*/
  useEffect(() => {
    const handleDates = (period) => {
      if (period === "all") {
        handleEndAt("");
        handleStartAt("");
      } else {
        const numOfDays = Number.parseInt(period);
        if (numOfDays) {
          handleEndAt(new Date());
          handleStartAt(subDays(new Date(), numOfDays));
        }
      }
    };
    if (menu) {
      const menuItem = menu.find(
        (item) =>
          item.route === `${app}/pesquisar` || item.route === `${app}/search`
      );
      if (menuItem) {
        const extraConfiguration_ = JSON.parse(
          menuItem.extraConfiguration || false
        );
        const defaultFlowId_ = JSON.parse(
          (extraConfiguration_ && extraConfiguration_.defaultFlowId) || false
        );
        const period_ = extraConfiguration_.period;
        if (defaultFlowId_) {
          handleReset(defaultFlowId_);
          handleFlowId(defaultFlowId_);
          setDefaultFlowId(defaultFlowId_);
          handleFlowFiltersValues(undefined); /*Clean flow filters*/
        }
        if (period_) {
          handleDates(period_);
          setPeriod(period_);
        }
        let hiddenDefaults_;
        if (flowId && extraConfiguration_[flowId]) {
          hiddenDefaults_ = extraConfiguration_[flowId].hiddenDefaults; //hidden default filters for a specific flow
        } else {
          hiddenDefaults_ = extraConfiguration_.hiddenDefaults; //hidden default filters for all flows
        }
        setHiddenDeafults(hiddenDefaults_ ? hiddenDefaults_ : undefined);
      }
    }
  }, [menu]);

  useEffect(() => {
    if (hiddenDefaults) {
      if (hiddenDefaults.some((col) => col === "from")) {
        handleEndAt("");
        handleStartAt("");
      }
    }
  }, [hiddenDefaults]);

  const findProcesses = (legacyUsername) => {
    if (userBPM) {
      let metadataname = [];
      let metadatavalue = [];
      if (flowFiltersValues)
        Object.keys(flowFiltersValues).map((key, index) => {
          if (flowFiltersValues[key] !== "") {
            metadataname.push(key);
            metadatavalue.push(flowFiltersValues[key]);
          }
        });
      let payload = {};
      const payloadArray = [
        {
          name: "username",
          value: userBPM,
        },
        {
          name: "application",
          value: app,
        },
        {
          name: "flowid",
          value: flowId,
        },
        {
          name: "pnumber",
          value: nProcess,
        },
        {
          name: "from",
          value: startAt && startAt.getTime(),
        },
        {
          name: "to",
          value: endAt && endAt.getTime(),
        },
        {
          name: "previoususer",
          value: legacyUsername ? legacyUsername : "",
        },
        {
          name: "closed",
          value: closedFilter,
        },
        {
          name: "isIntervenient",
          value: actorsFilter,
        },
      ];
      const payload_ = payloadArray.filter(
        (item) =>
          item.value !== "" && item.value !== undefined && item.value !== null
      );
      payload_.forEach((item) => {
        payload[item.name] = item.value;
      });
      if (metadatavalue.length !== 0)
        payload = { ...payload, metadataname, metadatavalue };
      dispatch(findIflowProcessesByFilters(payload));
    }
  };

  /*Find Process with selected Filter Utilizador(variable from)*/
  useEffect(() => {
    if (legacyUsers && legacyUsers.length !== 0) {
      let legacyUsername;
      legacyUsers.forEach((user) => {
        if (user.legacyApplication.label === BPM_MIDDLEWARE.context)
          legacyUsername = user.username;
      });
      findProcesses(legacyUsername ? legacyUsername : "");
      dispatch(setLegacyUsers([])); /*Clean store*/
    }
  }, [dispatch, legacyUsers]);

  const handleSearch = () => {
    if (previousUser !== "" && previousUser !== undefined) {
      dispatch(findLegacyUsers(previousUser));
    } else findProcesses();
  };

  const handleReset = (flowid) => {
    let startAt_ = "";
    let endAt_ = "";
    if (hiddenDefaults) {
      if (hiddenDefaults.some((col) => col === "from")) {
        startAt_ = "";
        endAt_ = "";
      }
    }
    const payloadArray = [
      {
        name: "username",
        value: userBPM,
      },
      {
        name: "application",
        value: app,
      },
      {
        name: "flowid",
        value: defaultFlowId ? defaultFlowId : flowid,
      },
      {
        name: "from",
        value: startAt_,
      },
      {
        name: "to",
        value: endAt_,
      },
    ];
    let payload = {};
    const payload_ = payloadArray.filter(
      (item) => item.value !== "" && item.value !== undefined
    );
    payload_.forEach((item) => {
      payload[item.name] = item.value;
    });
    dispatch(findIflowProcessesCount(payload));
    dispatch(findIflowProcessesByFilters(payload));
    handleFlowId(defaultFlowId ? defaultFlowId : flowid ? flowid : "");
    handleNProcess("");
    setClosedFilter("");
    setActorsFilter("");
    handleFlowFiltersValues(undefined); /*Clean flow filters*/
    handlePreviousUser("");

    const handleDates = (period) => {
      if (period === "all") {
        handleEndAt("");
        handleStartAt("");
      } else {
        const numOfDays = Number.parseInt(period);
        if (numOfDays) {
          handleEndAt(new Date());
          handleStartAt(subDays(new Date(), numOfDays));
        }
      }
    };
    if (period) {
      handleDates(period);
    } else {
      handleEndAt("");
      handleStartAt("");
    }
  };

  const [selectedProcess, setSelectedProcess] = useState(undefined);
  const [showFlowModal, setShowFlowModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const src = selectedProcess
    ? "iFlow/.." +
      selectedProcess.url +
      "&Authorization=" +
      oauth.getAccessToken()
    : "";

  const handleOpenFlowModal = (row) => {
    setShowFlowModal(true);
    setSelectedProcess(row);
    setHistoryBTN(true);
  };
  const handleCloseFlowModal = () => {
    setShowFlowModal(false);
  };

  return (
    <>
      <div className={className ? className : "main-card-v2"}>
        <Card bsPrefix="card-flat">
          <Card.Header className="justify-content-between">
            <h6>{t("taskPage.general.processos")}</h6>
          </Card.Header>
          <Card.Body>
            <ProcessFilters
              app={app}
              flowId={flowId}
              startAt={startAt}
              endAt={endAt}
              nProcess={nProcess}
              previousUser={previousUser}
              flowFiltersValues={flowFiltersValues}
              hiddenDefaults={hiddenDefaults}
              handleFlowFiltersValues={handleFlowFiltersValues}
              handleFlowId={handleFlowId}
              handleClosed={handleClosed}
              handleActors={handleActors}
              handleStartAt={handleStartAt}
              handleEndAt={handleEndAt}
              handlePreviousUser={handlePreviousUser}
              handleSearch={handleSearch}
              handleReset={handleReset}
              handleNProcess={handleNProcess}
            />
            <>
              <br />
              {!isLoadingProcesses && numbercount >= 500 && (
                <Alert key={uuid()} variant={"info"}>
                  {t("taskPage.general.numbercount.1")} {numbercount}{" "}
                  {t("taskPage.general.numbercount.2")} {numbercountTotal}{" "}
                  {t("taskPage.general.numbercount.3")}{" "}
                  {t("taskPage.general.numbercount.4")}
                </Alert>
              )}
              <br />
              <ProcessTable
                app={app}
                flowId={flowId}
                handleOpenFlowModal={handleOpenFlowModal}
              />
            </>
          </Card.Body>
        </Card>
      </div>

      <FlowModal
        showModal={showFlowModal}
        flowId={flowId}
        setShowFlowModal={setShowFlowModal}
        closeModal={handleCloseFlowModal}
        processNumber={selectedProcess && selectedProcess.pnumber}
        process={selectedProcess}
        src={src}
        app={app}
        historyBTN={historyBTN}
        setHistoryBTN={setHistoryBTN}
      />
      <Messages />
      <Spinner spinning={isLoadingProcesses} wrapper />
    </>
  );
};
export default withNamespaces()(Processes);
