import React from "react";
import { useForm } from "react-hook-form";
import { withNamespaces } from "react-i18next";
import InputDate from "~/pages/Easypay/components/InputDate";
import InputSimpleSearch from "~/pages/Easypay/components/InputSimpleSearch";
import "../assets/scss/_backOfficeInput.scss";
import Dropdown from "./InputDropdown";

const BackOfficeInput = ({
  tableData,
  firstDropdownLabel,
  firstDropdownFilter,
  filterDate,
  filterColor,
  onSetFilters,
  savedFilters,
  disabled,
  t,
}) => {
  const { register, formState, watch } = useForm();

  var timer;

  const dateDefaultValue = () => {
    if (!(savedFilters[2].value === "")) {
      return savedFilters[2].value;
    } else return ["", ""];
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  function numberToColorName(number) {
    if (number === "1") {
      return "Red";
    } else if (number === "2") {
      return "Yellow";
    } else {
      return "Green";
    }
  }

  //creates a list for a given input dropdown given a type and default input using tableData and the current filter settings
  const createInputList = (
    typeNames,
    tableData,
    filterNum,
    defaultInputLabel
  ) => {
    let result = [];
    let hasSelected = false;
    typeNames.forEach((typeName) => {
      tableData.forEach((dataRow) => {
        const firstParameter = typeName;

        if (
          dataRow[firstParameter] &&
          !result.some(
            (element) =>
              element.value.toString().toUpperCase() ===
              dataRow[firstParameter].toString().toUpperCase()
          )
        ) {
          if (
            dataRow[firstParameter].toString().toUpperCase() ===
            savedFilters[filterNum].value.toString().toUpperCase()
          ) {
            result.push({
              label: capitalizeFirstLetter(dataRow[firstParameter].toString()),
              value: dataRow[firstParameter],
              selected: true,
            });

            hasSelected = true;
          } else {
            result.push({
              label: capitalizeFirstLetter(dataRow[firstParameter].toString()),
              value: dataRow[firstParameter],
            });
          }
        }
      });
    });
    if (!hasSelected) {
      result.push({ label: defaultInputLabel, value: "", selected: true });
    } else {
      result.push({ label: defaultInputLabel, value: "" });
    }
    return result;
  };

  const handleSubmitSearchWithDelay = (submitValue, delay) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      onSetFilters(
        {
          type: ["nomeDocumento", "nomeCompleto", "idCliente", "ibanNome"],
          value: submitValue,
        }, // all types must be in an array
        0
      );
    }, delay);
  };

  return (
    <div className="input-grid">
      <div className="grid-search">
        <InputSimpleSearch
          field={{ text: "inputLabel" }}
          required={true}
          text={t("bpm.onboarding.backoffice.administration.inputSearch")}
          placeholder={t(
            "bpm.onboarding.backoffice.administration.inputSearchPlaceholder"
          )}
          defaultInput={savedFilters[0].value}
          handleSubmit={(submitValue) =>
            handleSubmitSearchWithDelay(submitValue, 500)
          }
          disabled={disabled}
          noBorder={true}
        />
      </div>
      <div>
        <InputDate
          text={t("bpm.onboarding.backoffice.administration.inputDate")}
          register={register}
          formState={formState}
          errorMessage="This field is required"
          watch={watch}
          type="created_at"
          placeholder=""
          defaultValue={dateDefaultValue()}
          handleSubmit={(dates) =>
            onSetFilters({ type: [filterDate], value: dates }, 2)
          }
          disabled={disabled}
        />
      </div>
      <Dropdown
        noBorder={true}
        text={t("bpm.onboarding.backoffice.administration.headerState")}
        required={true}
        placeholder="Select Dropdown Option"
        list={createInputList(
          [firstDropdownFilter],
          tableData,
          1,
          t("bpm.onboarding.backoffice.administration.inputFemaleAll")
        )}
        handleSubmit={(submitValue) =>
          onSetFilters({ type: [firstDropdownFilter], value: submitValue }, 1)
        }
        disabled={disabled}
        isColor={false}
      />
    </div>
  );
};

export default withNamespaces()(BackOfficeInput);
