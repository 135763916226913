import React, { useEffect, useState } from "react";
import "~/assets/css/styles.css";
import "~/assets/css/icons.css";
import { Card } from "react-bootstrap";
import "../RhCollaborator.scss";

import TableList from "./TableList";
import RhNavBar from "./RHCollaboratorItems/RhNavBar";

const RhCollaborator = () => {
  const [isCliked, setIsCliked] = useState(false);
  const [rowCollaborator, setRowCollaborator] = useState([]);

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "default";
  }, []);

  //lifting up from TableList.js ( taking a row  and a bollean )
  const takingData = (bollean, row) => {
    setIsCliked(bollean);
    setRowCollaborator(row);
  };

  const takeBoleanBackButton = (bollean) => {
    setIsCliked(bollean);
  };

  return (
    <>
      <div
        className="main-card-v2"
        style={{ overflow: "auto", scrollY: "auto" }}
      >
        <Card bsPrefix="card-flat" style={{ overflow: "auto" }}>
          <Card.Body>
            {!isCliked ? (
              <>
                <TableList onTakeNavOnBolean={takingData} />
              </>
            ) : (
              <>
                <RhNavBar
                  rowCollaborator={rowCollaborator || ""}
                  onBoleanBackButton={takeBoleanBackButton}
                />
              </>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default RhCollaborator;
