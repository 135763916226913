import React from "react";
import "../assets/scss/_proposal.scss";
import "../assets/scss/_option.scss";
import { useDispatch, useSelector } from "react-redux";
import ProposalResumeTable from "./ProposalResumeTable";
import Textbox from "./Textbox";
const ProposalResumeSection = ({
  info,
  workplanIndex,
  percentage,
  hoursPercentage,
  suppliersPercentage,
  costsPercentage,
}) => {
  const dispatch = useDispatch();

  const { workplans, clients } = useSelector(
    (state) => state.budgetManagementReducer
  );

  function convertIdToName(id, name) {
    if (name === "clients" && clients != null) {
      const client = clients.find((x) => x.idCliente === parseInt(id));
      if (client != null) return client?.nome;
    }
  }

  return (
    <div className="liftworld-proposal-resume-wrapper">
      <div className="liftworld-propsoal-input-wrapper">
        <div className="liftworld-text-wrapper-proposal">Cliente</div>
        <div className="liftworld-normal-text-wrapper">
          {convertIdToName(
            workplans[workplanIndex].nomeClienteProjeto,
            "clients"
          )}
        </div>
      </div>
      <div className="liftworld-propsoal-input-wrapper">
        <div className="liftworld-text-wrapper-proposal">Proposta</div>
        <div className="liftworld-normal-text-wrapper">
          {workplans[workplanIndex].nomeProposta}
        </div>
      </div>
      <ProposalResumeTable
        workplanIndex={workplanIndex}
        percentage={percentage}
        hoursPercentage={hoursPercentage}
        suppliersPercentage={suppliersPercentage}
        costsPercentage={costsPercentage}
      />
    </div>
  );
};
export default ProposalResumeSection;
